<template>
  <div v-on:click="openIfClosed" class="search-container" v-bind:class="open ? 'was-clicked' : ''">
    <input class="search-input" type="text" :placeholder="placeholder" v-on:keyup.27="closeIfOpen" v-model="dataInput"></input><i v-on:click.stop="toggleOpened"  v-on:keyup.13="toggleOpened" class="fa fa-search" tabindex="0"></i>
  </div>
</template>
<script type="text/javascript">
  var $ = require('jquery');

  module.exports = {
    props: ['placeholder', 'dataInput', 'toggleOpen'],
    data: function data() {
      var open = false;
      if (this.dataInput !== null && this.dataInput !== '') {
        open = true;
      }
      return {
        open: open
      };
    },
    methods: {
      toggleOpened: function toggleOpened() {
        this.open = !this.open;
        if (!this.open) {
          this.dataInput = '';
        }
        $(this.$el).find('i').blur();
      },
      openIfClosed: function openIfClosed() {
        if (!this.open) {
          this.open = true;
        }
      },
      closeIfOpen: function closeIfOpen() {
        if (this.open) {
          this.open = false;
        }
      }
    },
    watch: {
      open: function watchOpen() {
        if (this.open) {
          $(this.$el).find('input').focus();
        } else {
          $(this.$el).find('input').blur();
        }
      }
    }
  };
</script>