<template>
  <div v-if="!isIe">
    <div class="row my-2" :class="mobileSearchVisible ? 'show-search' : 'show-sort'"  v-if="breakpoint === 'smartphone'">
      <!-- sort -->
      <div
        v-if="breakpoint === 'smartphone'"
        class="visible-xs d-sm-none col sort">
        <div>{{settings.mobile_sort_text}}</div>
        <div
          v-if="settings.mobile_sort_behavior === 'sort-display'"
          :is="'wrapped-select-table-sort'"
          :settings="{options: allMobileSorts}"
          @update:mobile-cols="val => {handleNewSortSelection(val)}"
        ></div>
        <div
          v-else
          :is="'wrapped-select-table-sort'"
          :settings="{options: limitedMobileSorts}"
          @update:mobile-cols="val => {handleNewSortSelection(val)}"
        ></div>
      </div>

      <!-- name search -->
      <div
        v-if="filteredNameSearch"
        :is="filteredNameSearch.tag"
        :settings="filteredNameSearch.data"
        :layout="filteredNameSearch.layout"
        :class="filteredNameSearch.tag"
        class="visible-xs d-sm-none col search"
      ></div>

      <!-- show-search/show-sort -->
      <div class="col-auto d-sm-none visible-xs-block">
        <button class="mobile-search-toggle c-button--action c-button--round c-button--outline d-sm-none visible-xs-inline-block d-flex justify-content-center flex-column align-items-center" v-on:click="toggleMobileSearch">
          <i class="fa-fw " aria-hidden="true" :class="mobileSearchVisible === true ? 'fal fa-times' : 'fas fa-search'"></i>
        </button>
      </div>
    </div>
    <result-table
      :settings="settings"
      :layout="layout"
    ></result-table>
  </div>
  <div v-else>
<!--     <div
      v-if="filteredNameSearch"
      :is="filteredNameSearch.tag"
      :settings="filteredNameSearch.data"
      :layout="filteredNameSearch.layout"
      :class="filteredNameSearch.tag"
      class="visible-xs"
    ></div> -->
    <result-table-simple
      :settings="settings"
      :layout="layout"
    ></result-table-simple>
  </div>
</template>
<script type="text/javascript">
  const TableMixin = require('../mixins/TableMixin');
  const ReactiveFilterMixin = require('../mixins/ReactiveFilterMixin');
  import ResultTable from './ResultTable.vue';
  import ResultTableSimple from './ResultTableSimple.vue';

  module.exports = {
    props: ['settings', 'layout'],
    components: {
      ResultTable,
      ResultTableSimple,
    },
    mixins: [TableMixin, ReactiveFilterMixin],
    data: function data() {
      const isIe = !!document.documentMode;
      return {
        filteredNameSearch: null,
        mobileSearchVisible: false,
        isIe: isIe
      };
    },
    computed: {
      limitedMobileSorts() {
        //
        // the sort options available when the mobile dropdown
        // is configured to only update the sort (not the columns visible)
        //
        const filtered = this.filteredColumns
          .filter(col => col.visible_mobile || col.is_row_header)
          .map(col => this.makeSortFromColumn(col, col.sort_direction_mobile), this);
        return filtered;
      },
      allMobileSorts() {
        //
        // the sort options available when the mobile dropdown
        // is configured to update the table sort & the columns visible on mobile
        //
        const sorts = [];
        this.filteredColumns
          .filter(col => col.is_sortable)
          .forEach(col => {
            sorts.push(this.makeSortFromColumn(col, col.sort_direction_mobile));
          }, this);
        return sorts;
      },
      selectedEntityType: function selectedEntityType() {
        const filters = this.filters;
        if (!filters || !filters.entity_type) return null;
        const selected = filters.entity_type.value.filter(val => val.required);
        if (selected.length) return selected[0].value;
        return selected;
      },
      // filteredNameSearch: function filteredNameSearch() {
      //   const nameSearch = this.$store.state.renderableLayout.name_search;
      //   if (!nameSearch) return null;
      //   if (!this.superFilterSelections) return nameSearch;
      //   if (!Array.isArray(nameSearch)) return nameSearch;
      //   const renderable = this.filterToRenderable(nameSearch)[0];
      //   return renderable;
      // }
    },
    methods: {
      handleNewSortSelection(col) {
        this.clearOtherSorts(col);
        if (this.settings.mobile_sort_behavior === 'sort-display') {
          this.handleNewMobileCol(col);
        }
      },
      handleNewMobileCol(newCol) {
        //
        // if the table is configured to change columns on mobile
        // update the columns that are visible on mobile
        //
        // find the secondary column that should be visible
        //
        const newColId = newCol.replace(/_(asc|desc)$/g, '');
        let newMobileCol = this.filteredColumns.filter(col => col.id === newColId)[0];
        if (newMobileCol.is_row_header) {
          newMobileCol = this.filteredColumns.filter(col => col.visible_mobile_default)[0];
        }
        //
        // set the visible_mobile property on the secondary column & is_row_header (always visible)
        //
        for (let i = 0, l = this.settings.columns.length; i < l; i ++) {
          if (this.settings.columns[i].id !== newMobileCol.id) {
            this.settings.columns[i].visible_mobile = false;
          } else {
            this.settings.columns[i].visible_mobile = true;
          }
        }
      },
      toggleMobileSearch: function toggleMobileSearch() {
        this.mobileSearchVisible = !this.mobileSearchVisible;
      },
      hideMobileSearch: function hideMobileSearch() {
        this.mobileSearchVisible = false;
      }
    },
    watch: {
      selectedEntityType: function selectedEntityType(curr, prev) {
        if (curr !== prev) {
          const nameSearches = this.$store.state.renderableLayout.name_search;
          if (!nameSearches || typeof nameSearches.filter === 'undefined') return;
          if (!curr) return;
          const nameSearch = nameSearches.filter(search => search.data.entity_type[0] === curr)[0];
          this.filteredNameSearch = nameSearch;
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  .search,
  .sort{
    transform: translateX(-100vw);
    transition: transform 1s, opacity 1s;
    opacity: 1;
  }
  .show-search,
  .show-sort{
    display: flex;
    align-items: flex-end;
  }
  .show-search .search{
    transform: translateX(0vw);
  }
  .show-search .sort{
    position: absolute;
    opacity: 0;
  }
  .show-sort .search{
    position: absolute;
    opacity: 0;
  }
  .show-sort .sort{
    transform: translateX(0vw);
  }
  .result-table {
    /* margin-top: 15px; */
  }
</style>
