<template>
  <modal-component class="p-0 intro-modal modal--fullscreen c-modal--full-screen c-modal__mask" :show="show" :click-away="false" :class="settings.show_header_in_intro_modal  ? 'intro-modal--has-header' : ''" :container-class="'c-modal--cta'">
  <header class="header p-header--static p-0 pt-2 p-header" v-if="settings.show_header_in_intro_modal" >
    <div
      :is="layout.header.tag"
      :settings="layout.header.data"
      :layout="layout.header.layout"
      :class="layout.header.tag"
      headerType="containerWidth"
      :hideCompare='true'
    ></div>
  </header>
    <div class="container" style="margin-bottom: 150px;" :class="nameSectionVisible ? 'unscroll' : ''">
      <div class="row my-36  mb-md-5 mt-md-80">
        <!-- WELCOME -->

        <div class="col-lg-12 ">

          <h1 class="text-center c-modal--cta__title">{{settings.short_text}}</h1>
          <p class="text-center mt-3 mt-md-20 c-modal--cta__p">{{settings.long_text}}</p>

        </div>
      </div>
      <div class="row flex-column flex-lg-row align-items-center align-items-lg-end mt-36 mt-md-80">
        <div class="flex-grow-1 col col-lg-auto" style="flex-basis: auto;">

          <div class="h-100" >
            <h2 class="text-center c-modal--cta__subtitle">{{settings.main_prompt_text}}</h2>


        <div
              v-if="layout.school_search"
              :is="layout.school_search.tag"
              :settings="layout.school_search.data"
              :layout="layout.school_search.layout"
              :entities-list="entities"
              class="mt-3"
            ></div>
          </div>
        </div>
        <p style="font-size: 20px; margin-left: 44px; margin-right: 44px;     line-height: 50px;">{{settings.or_text}}</p>
        <!-- </div> -->
        <div class="flex-grow-1 col col-lg-auto " :class="nameSectionVisible ? 'mobile-fullscreen' :''" style="flex-basis: auto;">

          <div class=" h-100">
            <h2 class="text-center c-modal--cta__subtitle">{{settings.school_lookup_text}}</h2>
                 <div
                v-if="layout.address_entry"
                :is="layout.address_entry.tag"
                :settings="layout.address_entry.data"
                :layout="layout.address_entry.layout"
                class="mt-3"
              ></div>
          </div>
        </div>
      </div>
    </div>
  <img :src="settings.welcome_image_url" :alt="settings.welcome_image_alt_text || ''" class="w-100 mt-auto flex-shrink-0" style="margin-bottom: -1px;" ><footer class="" v-if="settings.show_footer_in_intro_modal">
        <div
          :is="layout.footer.tag"
          :settings="layout.footer.data"
          :layout="layout.footer.layout"
          :class="layout.footer.tag"
          class="pt-0"
        ></div>
  </footer>
  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  import sendEvent from 'tembo-js/sendEvent';
  const ModalMixin = require('../../mixins/ModalMixin');

  module.exports = {
    components: { ModalComponent },
    props: ['settings', 'layout', 'show'],
    mixins: [ModalMixin],
    data: function data() {
      return {
        addressSectionVisible: false,
        nameSectionVisible: false
      };
    },
    mounted: function mounted() {
      //
      // hide this modal automatically if the requirements are already satisfied
      //
      if (this.addressIsSatisfied) {
        this.show = false;
        this.closeModal();
      }
    },
    computed: {
      home: function home() {
        return this.$store.state.home;
      },
      entities: function entities() {
        if (this.layout.school_search.data.entities) return this.layout.school_search.data.entities;
        if (!this.$store.getters.entitySet) return [];
        return this.$store.getters.entitySet;
      },
      addressIsSatisfied: function addressIsSatisfied() {
        var addressSatisfied = this.settings.address_required;
        addressSatisfied = addressSatisfied !== undefined && !addressSatisfied;

        if (this.home && this.home.user) addressSatisfied = true;
        return addressSatisfied;
      },
    },
    watch: {
      home(curr, prev) {
        if (this.settings.address_entry_submit) {
          if (!prev.user && curr.user) {
            this.closeModal();
            sendEvent({
              category: 'intro',
              action: 'view_schools',
            });
          }
        }
      },
    },
  };
</script>
<style type="text/css" scoped>
.mobile-cta{

}
.intro-modal__section-close{
  position:  absolute;
      right: 25px;
    top: 0;
    transform: translateY(-50%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,.1), 0 4px 8px rgba(0,0,0,.1);
}
.intro-modal__section-close div{
  display: block;
      width: 34px;
    height: 34px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

</style>
<style>
  .intro-modal__section-close div circle{
  display: none;
}
</style>
