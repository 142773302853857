import debounce from 'lodash/debounce';
import sendEvent from 'tembo-js/sendEvent';
var FilterGroupMixin = require('../mixins/FilterGroupMixin');

module.exports = {
  mixins: [FilterGroupMixin],
  computed: {
    localSelection: {
      get: function localSelection() {
        if (this.values && Object.hasOwnProperty.call(this.values, 'value')) {
          return this.values.value;
        }
        return this.values;
      },
      set: function localSelection(newval) {
        this.handleInputEvent(newval);
      },
    },
  },
  methods: {
    handleInputEvent: debounce(function handleInputEvent(val) {
      if (val || this.localSelection) {
        //
        // debounced method:
        // every 300ms, update the value of the filter to the input value
        // and send an event to google analytics
        //
        // this.values from FilterGroupMixin
        // is a single object: { id: <id>, value: <value> }
        //
        const filterValue = JSON.parse(JSON.stringify(this.values));
        filterValue.value = val;
        this.$store.dispatch('updateFilterValue', {
          filterValue: filterValue,
          updateEntities: true
        });
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_list` : 'list';
        sendEvent({
          category: category,
          action: 'name_search',
          label: val
        });
        this.prevInput = val;
      }
    }, 300)
  },
};
