<template>
  <footer>
    <div class="p-footer">
      <!-- invisible copy of google attribution, for spacing purposes -->
      <div v-if="settings.google" class="p-footer__translated-by-google d-none d-md-flex" style="visibility: hidden;">
        <attribution-google-inline :settings="settings"></attribution-google-inline>
      </div>
      <div class="p-footer__powered-by-tembo">
        <attribution-inline :settings="settings"></attribution-inline>
      </div>
      <div v-if="settings.google" class="p-footer__translated-by-google">
        <attribution-google-inline :settings="settings"></attribution-google-inline>
      </div>
    </div>
  </footer>
</template>
<script type="text/javascript">
  import AttributionInline from './AttributionInline.vue';
  import AttributionGoogleInline from './AttributionGoogleInline.vue';
  module.exports = {
    components: { AttributionInline, AttributionGoogleInline },
    props: ['settings']
  };
</script>