<template>
  <!-- Star Rating: requires star_rating or missing data rule -->
  <div class="entity-attributes__star_rating" v-show="showStarRating">
    <div class="d-inline-flex flex-column align-items-start align-items-md-center" v-if="entityData !== null">
      <div class="p-entity__attributes__title mb-1">{{settings.star_rating_header_text}}</div>
      <div v-if="entityData || entityData === 0" class="c-star-rating c-star-rating--sm" :class="'c-star-rating--' + starCount">
        <span v-for="i in maxStars" class="c-star-rating__star"></span>
      </div>
    </div>
    <!-- Missing data text for star rating -->
    <div v-else class="">{{settings.missing_data_rules.star_rating.missing_data_text}}</div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'entityData'],
    computed: {
      showStarRating() {
        const show = this.starCount || this.starCount === 0 || this.missingDataText;
        this.$emit('update:showStarRating', show);
        return show;
      },
      maxStars() {
        if (this.settings.star_count && !isNaN(Number(this.settings.star_count))) {
          return Number(this.settings.star_count);
        }
        return 5;
      },
      starCount() {
        let entityNumber = Number(this.entityData);
        if (entityNumber || entityNumber === 0) return entityNumber;
        if (this.entityData.value) {
          if (this.entityData.exception_code) return null;

          entityNumber = Number(this.entityData.value);
          if (entityNumber || entityNumber === 0) return entityNumber;
        }
        return null;
      },
      missingDataText() {
        if (this.entityData && this.entityData.exception_code) {
          const exceptionCode = this.entityData.exception_code;
          if (this.settings.missing_data_rules && this.settings.missing_data_rules.star_rating) {
            const missingDataRules = this.settings.missing_data_rules.star_rating;
            //
            // if a message for the given code exists, return it
            //
            if (missingDataRules[exceptionCode]) return missingDataRules[exceptionCode];
            //
            // if there is a general message, return that
            //
            if (missingDataRules.missing_data_text) return missingDataRules.missing_data_text;
          }
        }
        return '';
      },
    }
  };
</script>
