import sendEvent from 'tembo-js/sendEvent';
var utils = require('../store/utils');
var ReactiveFilterMixin = require('../mixins/ReactiveFilterMixin');

module.exports = {
  props: [
    'settings',
    'layout'
  ],
  mixins: [ReactiveFilterMixin],
  computed: {
    home: function home() {
      return this.$store.state.home;
    },
    selectionTally: function selectionTally() {
      var req;
      let ct = 0;
      const value = this.values;
      if (value) {
        for (let i = 0, len = value.length; i < len; i ++) {
          req = value[i].required;
          if (req) ct += 1;
        }
      } else {
        return null;
      }
      if (this.layout && this.layout.filters) {
        for (let i = 0, len = this.layout.filters.length; i < len; i ++) {
          const filter = this.layout.filters[i].data;
          const filterValue = filter.value;
          for (let j = 0, k = filterValue.length; j < k; j ++) {
            req = filterValue[j].required;
            if (req) ct += 1;
          }
        }
      }
      return ct;
    },
    values: function values() {
      //
      // value or values from $store.state.filterValues
      // mapped to the value ids in the layout
      //
      const filterValues = this.$store.state.filterValues;
      if (Array.isArray(this.settings.value)) {
        return this.settings.value.map(v => filterValues[v]);
      }
      return filterValues[this.settings.value];
    },
    requirements: function requirements() {
      var reqs;
      if (!this.values) return null;
      if (Array.isArray(this.values)) {
        reqs = this.values.filter(v => v.required);
      } else {
        reqs = this.values;
      }
      return reqs;
    },
    orderedValues: function orderedValues() {
      return utils.sort(this.values);
    },
    filteredValues: function filteredValues() {
      if (!this.orderedValues) return [];
      if (!this.superFilterSelections || !this.superFiltersRelevant) return this.orderedValues;
      if (this.settings.metadata.super_filter) return this.orderedValues; // assumes one superfilter
      if (this.settings.metadata.prevent_reactive_filter_behavior) return this.orderedValues;
      return this.filterToRenderable(this.orderedValues);
    }
  },
  methods: {
    clearThisFilter: function clearThisFilter(applyDefaults) {
      //
      // clear all selections for any one filter group
      // this will NOT re-select any default selections
      //
      this.$store.dispatch('resetOneFilter', {
        filter: this.settings,
        applyDefaults: applyDefaults,
        updateEntities: true,
      });
      sendEvent({
        category: 'filter',
        action: 'clear_all',
        label: this.settings.metadata.label
      });
      //
      // filter components with an input tag should have
      // a listener for the ClearInput event
      //
      try {
        this.clearInput();
      } catch (e) {
        // eslint-disable-next-line no-console, max-len
        // console.error('This component probably does not have an input element', e.name, e.message);
        // pass
      }
    },
    handleShowDescriptions: function handleShowDescriptions() {
      sendEvent({
        category: 'filter',
        action: 'show_descriptions',
        label: this.settings.metadata.label
      });
      const modalData = JSON.parse(JSON.stringify(this.settings));
      modalData.value = JSON.parse(JSON.stringify(this.values));
      this.$store.dispatch('updateShowModal', { modal: 'filter_descriptions', data: modalData });
    }
  },
  watch: {
    requirements: {
      handler: function watchReqs() {
        var reqs = this.requirements;
        var i;
        var l;
        if (Array.isArray(reqs)) {
          //
          // when all requirements are cleared, if any_option is available, select it
          //
          if (this.settings.metadata.any_option > -1 && reqs.length === 0) {
            for (i = 0, l = this.values.length; i < l; i ++) {
              if (this.values[i].value === 'any') {
                const newval = JSON.parse(JSON.stringify(this.values[i]));
                newval.required = true;
                this.$store.dispatch('updateFilterValue', {
                  filterValue: newval,
                  updateEntities: true
                });
              }
            }
          }
        }
      },
      deep: true
    }
  }
  // TODO:
  // computed properties and/or methods
  // to handle any/all option *changes* for any filter group
};
