<template>
  <li
    v-if="entityData && item.list_format === 'hyphenated'" :key="index">
    <i :class="'mr-1 fas fa-fw ' + item.classes"></i>
    <p class="p--utility !d-inline">{{item.label}}{{item.label ? item.separator : ""}}{{hyphenate(entityData)}} {{item.suffix}}</p>
  </li>
  <li v-else-if="entityData" :key="index" >
    <i :class="'mr-1 fas fa-fw ' + item.classes"></i>
    <p class="p--utility !d-inline">{{item.label}}{{item.label ? item.separator : ""}}{{entityData}} {{item.suffix}}</p>
  </li>
</template>
<script type="text/javascript">
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import { displayListAsRange } from '../store/utils';

  module.exports = {
    props: ['item', 'index', 'entity'],
    computed: {
      entityData() {
        return getDataByProperty(this.item.entity_prop, this.entity);
      }
    },
    methods: {
      hyphenate(valArr) {
        let universe = null;
        if (this.item.universe) {
          universe = this.item.universe;
        }
        return displayListAsRange(valArr, universe);
      },
    }
  };
</script>
<style type="text/css" scoped>
  li {
    list-style: none;
  }
  i{
    color:  #a0b0bf;
  }
</style>
