<template>
    <p class="p--utility">{{displayText}}</p>
</template>
<script type="text/javascript">
/* eslint-disable indent */
    module.exports = {
        props: ['settings'],
        computed: {
            displayText() {
                const max = this.$store.state.compareConfig.max;
                const compareCt = this.$store.state.compares.length;
                const message = this.settings || '[xx] of [yy] selected to compare';
                if (!this.settings) {
                    console.error('displaying default text for CompareStatus.vue'); // eslint-disable-line no-console, max-len
                }
                return message.replace('[xx]', compareCt).replace('[yy]', max);
            }
        }
    };
/* eslint-enable */
</script>
