import arrayToLower from 'tembo-js/arrayToLower';
import convertToNumberIfPossible from 'tembo-js/convertToNumberIfPossible';
import flattenByProperty from 'tembo-js/flattenByProperty';
import getDataByProperty from 'tembo-js/getDataByProperty';
import isZero from 'tembo-js/isZero';
import overlappingArrays from 'tembo-js/overlappingArrays';


module.exports = {
  getConditionalDefaultSort: function getConditionalDefaultSort(getters, settings) {
    var conditionalDefaults = settings.sort_options.conditional_defaults.options;
    var i;
    var l;
    var found = false;
    var settingsValue; // relevant data from main settings object
    var defaultOption; // current default option
    var defaultDependency = settings.sort_options.conditional_defaults.dependency;
    var updatedSettings = JSON.parse(JSON.stringify(settings));
    var filterSet = JSON.parse(JSON.stringify(getters.filterSet));
    if (!filterSet) return '';
    updatedSettings.filters = filterSet;
    for (i = 0, l = conditionalDefaults.length; i < l; i ++) {
      if (!found) {
        defaultOption = conditionalDefaults[i];
        settingsValue = getDataByProperty(defaultDependency.source, updatedSettings);
        settingsValue = settingsValue.filter((val) => {
          var valVal = val[defaultDependency.filter_by.field];
          var requiredVal = defaultDependency.filter_by.value;
          return valVal === requiredVal;
        });
        settingsValue = flattenByProperty(defaultDependency.flatten_by, settingsValue);
        if (Array.isArray(settingsValue) && Array.isArray(defaultOption.allowed_value)) {
          if (overlappingArrays(
              arrayToLower(settingsValue),
              arrayToLower(defaultOption.allowed_value))) {
            defaultOption.enabled_by = 'filtered';
            found = true;
            return defaultOption.default_sort;
          }
        } else if (Array.isArray(settingsValue)) {
          if (arrayToLower(settingsValue).indexOf(
              defaultOption.allowed_value.toLowerCase()) > -1) {
            defaultOption.enabled_by = 'filtered';
            found = true;
            return defaultOption.default_sort;
          }
        } else if (Array.isArray(defaultOption.allowed_value)) {
          if (arrayToLower(defaultOption.allowed_value).indexOf(
              settingsValue.toLowerCase()) > -1) {
            defaultOption.enabled_by = 'filtered';
            found = true;
            return defaultOption.default_sort;
          }
        } else {
          if (defaultOption.allowed_value.toLowerCase() ===
              settingsValue.toLowerCase()) {
            defaultOption.enabled_by = 'filtered';
            found = true;
            return defaultOption.default_sort;
          }
        }
      }
    }
    return '';
  },

  getDefault: function getDefault(arr) {
    return arr.filter(v => v.default)[0];
  },

  applySort: function applySort(entities, sortOptions) {
    if (!entities) return [];
    if (!sortOptions.sortBy) return entities;

    function sortByProp(opts) {
      // returns a function that sorts items on the sort field
      // then by the firstTieBreaker tiebreaker
      // then by the secondTieBreaker tiebreaker
      // as defined in the config file
      return function sort(a, b) {
        var aSortVal;
        var bSortVal;
        var aPrimTieBreak;
        var bPrimTieBreak;
        var aSecTieBreak;
        var bSecTieBreak;

        aSortVal = getDataByProperty(opts.sortBy.field, a);
        try {
          aSortVal = aSortVal.toLowerCase();
        } catch (e) {
          // do nothing
        }
        aSortVal = convertToNumberIfPossible(aSortVal);

        bSortVal = getDataByProperty(opts.sortBy.field, b);
        try {
          bSortVal = bSortVal.toLowerCase();
        } catch (e) {
          // do nothing
        }
        bSortVal = convertToNumberIfPossible(bSortVal);

        // if the values at sort field are equal
        if (aSortVal === bSortVal) {
          //
          // if there is no firstTieBreaker tiebreaker, do not change order
          if (!opts.firstTieBreaker) return 0;

          // get the tiebreaker
          aPrimTieBreak = getDataByProperty(opts.firstTieBreaker.field, a);
          aPrimTieBreak = convertToNumberIfPossible(aPrimTieBreak);

          bPrimTieBreak = getDataByProperty(opts.firstTieBreaker.field, b);
          bPrimTieBreak = convertToNumberIfPossible(bPrimTieBreak);
          //
          // if the values at the tiebreaker field are equal
          if (aPrimTieBreak === bPrimTieBreak) {
            //
            // if there is no secondTieBreaker tiebreaker, do not change order
            if (!opts.secondTieBreaker) return 0;
            //
            // get the secondTieBreaker tiebreaker
            aSecTieBreak = getDataByProperty(opts.secondTieBreaker.field, a);
            aSecTieBreak = convertToNumberIfPossible(aSecTieBreak);

            bSecTieBreak = getDataByProperty(opts.secondTieBreaker.field, b);
            bSecTieBreak = convertToNumberIfPossible(bSecTieBreak);

            if (aSecTieBreak === bSecTieBreak) return 0;

            // always move null or missing data to the end of the list
            if (!aSecTieBreak && !isZero(aSecTieBreak)) return 1;
            if (!bSecTieBreak && !isZero(bSecTieBreak)) return -1;

            // if a custom sort order is supplied, use the index to sort
            if (opts.secondTieBreaker.custom_sort_order) {
              aSecTieBreak = arrayToLower(opts.sortBy.custom_sort_order).indexOf(aSecTieBreak.toLowerCase()); // eslint-disable-line
              bSecTieBreak = arrayToLower(opts.sortBy.custom_sort_order).indexOf(bSecTieBreak.toLowerCase()); // eslint-disable-line
            }

            if (aSecTieBreak < bSecTieBreak) return opts.secondTieBreaker.direction * -1;
            return opts.secondTieBreaker.direction * 1;
          }
          // always move null or missing data to the end of the list
          if (!aPrimTieBreak && !isZero(aPrimTieBreak)) return 1;
          if (!bPrimTieBreak && !isZero(bPrimTieBreak)) return -1;

          // if a custom sort order is supplied, use the index to sort
          if (opts.firstTieBreaker.custom_sort_order) {
            aPrimTieBreak = arrayToLower(opts.sortBy.custom_sort_order).indexOf(aPrimTieBreak.toLowerCase()); // eslint-disable-line
            bPrimTieBreak = arrayToLower(opts.sortBy.custom_sort_order).indexOf(bPrimTieBreak.toLowerCase()); // eslint-disable-line
          }
          if (aPrimTieBreak < bPrimTieBreak) return opts.firstTieBreaker.direction * -1;
          return opts.firstTieBreaker.direction * 1;
        }
        // always move null or missing data to the end of the list
        if (!aSortVal && !isZero(aSortVal)) return 1;
        if (!bSortVal && !isZero(bSortVal)) return -1;

        // if a custom sort order is supplied, use the index to sort
        if (opts.sortBy.custom_sort_order) {
          aSortVal = arrayToLower(opts.sortBy.custom_sort_order).indexOf(aSortVal.toLowerCase()); // eslint-disable-line
          bSortVal = arrayToLower(opts.sortBy.custom_sort_order).indexOf(bSortVal.toLowerCase()); // eslint-disable-line
        }

        if (aSortVal < bSortVal) {
          return opts.sortBy.direction * -1;
        }
        return opts.sortBy.direction * 1;
      };
    }

    return entities.sort(sortByProp(sortOptions)); // eslint-disable-line
  }
};
