<template>
  <div>
    <p>{{wizardSettings.labels.location_text}}</p>
    <address-alert-modal :show="addressAlert"
      :address-data="modalData"
      :settings="settings"
      ></address-alert-modal>
    <address-resolve-modal :show="addressResolve"
      :settings="settings"
      :address-data="modalData"
      :address-search-text="addressSearchText"
      ></address-resolve-modal>
    <search-input
      :placeholder="settings.search_placeholder_text"
      :data-input="addressSearchText"
      v-on:keyup="handleKeyup($event, addressSearchText)">
    </search-input>
    <button v-on:click="handleClick" :disabled="nextDisabled">{{currentScreen.nextText}}</button>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');
  var AddressGoogleMixin = require('../../mixins/AddressGoogleMixin');
  module.exports = {
    mixins: [WizardMixin, AddressGoogleMixin],
    props: [
      'currentScreen',
      'index',
      'previousScreens',
      'wizardSettings',
      'home',
      'address',
      'settings',
      'sorts'
    ],
    data: function data() {
      return {
        addressSearchText: this.getSearchText(),
        addressUpdated: false,
        alert: false
      };
    },
    computed: {
      nextDisabled: function nextDisabled() {
        if (!this.addressSearchText && !this.address.resolved) return true;
        return false;
      }
    },
    methods: {
      handleKeyup: function handleKeyup(ev, text) {
        var enterKey = 13;
        if (ev.keyCode === enterKey) {
          this.handleaddressSearchText(text);
        } else {
          if (this.address[0].resolved) {
            this.address[0].resolved = false;
          }
        }
      },
      next: function next() {
        var current;

        // data for updating previous screens
        current = this.copy(this.currentScreen);

        // this screen does not get a breadcrumb

        // update sorting, if relevant
        this.updateSort();

        // update previous screens with current
        if (this.previousScreens.length - 1 < this.index) {
          this.previousScreens.push(current);
        } else {
          this.previousScreens[this.index] = current;
        }
        this.advance();
      },
      handleClick: function handleClick() {
        // check for an updated address
        if (!this.address[0].resolved) {
          this.handleaddressSearchText(this.addressSearchText)
          .then(function success(res) {
            if (res && Array.isArray(res)) {
              try {
                if (res[0].resolved) this.next();
              } catch (e) {
                // do nothing
              }
            }
          });
        } else {
          this.next();
        }
      }
    },
    events: {
      resetAddress: function resetAddress() {
        this.updateAddress();
      },
      handleResolvedAddress: function handleResolvedAddress(address) {
        this.handleChildAddress(address);
      },
      handleModalAddress: function handleModalAddress(address) {
        this.handleaddressSearchText(address);
      },
      handleSearchClick: function handleSearchClick() {
        this.handleaddressSearchText(this.addressSearchText);
      }
    },
    watch: {
      address: {
        handler: function addressHandler() {
          if (this.address[0].resolved) {
            this.addressUpdated = true;
          }
        },
        deep: true
      }
    }
  };
</script>