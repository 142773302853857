<template>
  <!-- TODO: update for vue2 -->
  <modal-component :show="show" @update:show="val => { show = val }" :click-away="false">
    <div class="modal-search-wrapper">
      <search-input
        :placeholder="settings.search_placeholder_text"
        :data-input="addressSearchText"
        v-on:keyup.13="handleModalAddressSearchText(addressSearchText)">
      </search-input>
    </div>
    <div v-if="addressData">
      <!-- ERROR MESSAGE -->
      <div class="no-address-alert" v-if="addressData.errorMessage">
      <img :src="settings.address_failure_image" alt="">
        <p>{{settings.noresults_text.split(linkedText)[0]}}<span class="a" v-on:click="resetAddress">{{linkedText}}</span> {{settings.noresults_text.split(linkedText)[1]}}</p>
      </div>

      <!-- ADDRESS OPTIONS -->
      <div class="multi-address-alert" v-if="addressData.length > 0">
        <h3>{{settings.multiple_results_text}}</h3>
        <div v-for="address in addressData" v-on:click="chooseAddress(address)" class="possible-address">{{address.formatted_address}}</div>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  var AddressGoogleMixin = require('../../mixins/AddressGoogleMixin');

  module.exports = {
    components: { ModalComponent },
    mixins: [AddressGoogleMixin],
    props: ['show', 'addressData', 'settings', 'addressSearchText'],
    methods: {
      chooseAddress: function chooseAddress(address) {
        this.$dispatch('handleResolvedAddress', address);
      },
      resetAddress: function resetAddress() {
        this.$dispatch('resetAddress');
        this.closeModal();
      },
      closeModal: function closeModal() {
        this.$emit('update:show', false);
      },
      handleModalAddressSearchText: function handleModalAddressSearchText(address) {
        this.$dispatch('handleModalAddress', address);
      }
    },
    computed: {
      linkedText: function linkedText() {
        var noresults = this.settings.noresults_text.toLowerCase();
        var linkedSearch = this.settings.noresults_linked_text.toLowerCase();
        var idx = noresults.indexOf(linkedSearch);
        var l = linkedSearch.length;
        var linked = this.settings.noresults_text.slice(idx, idx + l);
        return linked;
      }
    }
  };
</script>