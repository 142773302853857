// TODO:
// * separation of concerns
const Promise = require('bluebird');
import getDataByProperty from 'tembo-js/getDataByProperty';

function clearAddress() {
  var address;
  address = this.settings.default_address;
  this.updateAddress();
  return address;
}

function requestGoogleAddress(addressString) {
  //
  // accepts string (user input)
  // hits google maps api
  // sends address or list of addresses to be handled
  //
  var address;
  var reqUrl;
  var bounds;
  var bbox;

  // handle enter on empty search box
  if (!addressString || addressString === '') {
    address = this.settings.default_address;
    this.updateAddress();
    return address;
  }
  address = addressString.split(' ').join('+');
  bbox = this.settings.metadata.bbox;
  bounds = [
    [bbox.southWest.lat, bbox.southWest.lng].join(','),
    [bbox.northEast.lat, bbox.northEast.lng].join(',')
  ].join('|');

  reqUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&bounds=${bounds}&region=${this.settings.metadata.region}`;
  return this.$http.get(reqUrl)
  .then(res => this.handleAddressResults(res.body.results))
  .then((resultsArrayWithBounds) => {
    var results = this.filterAddressResults(resultsArrayWithBounds);
    return this.sendAddressResults(results);
  })
  .then(null, (err) => {
    throw new Error(err);
  });
}

function handleAddressResults(addressArray) {
  //
  // returns a promise for the array of addresses
  // with boundaries appended as appropriate
  //
  var addressesWithBoundaries = [];
  var i;
  var address;
  var oneAddressWithBounds;

  if (addressArray) {
    for (i = 0; i < addressArray.length; i ++) {
      address = addressArray[i];
      oneAddressWithBounds = this.appendBoundaries(address);
      addressesWithBoundaries.push(oneAddressWithBounds);
    }
  }
  return Promise.all(addressesWithBoundaries);
}

function appendBoundaries(address) {
  var updatedAddress = address;
  var boundaryFn = this.settings.metadata.check_boundaries;
  var backupFn = this.settings.metadata.check_boundaries_2;

  return this[boundaryFn](address.geometry.location)
  .then((boundaries) => {
    updatedAddress.boundaries = boundaries;
    return updatedAddress;
  }, () => this[backupFn](address.geometry.location));
}

function checkBoundaryBox(loc) {
  //
  // returns a promise for array of results
  // based on bounding box defined in config
  //
  var southWest = this.settings.metadata.bbox.southWest;
  var northEast = this.settings.metadata.bbox.northEast;
  var name = this.settings.metadata.bbox.name;
  var results;
  if (!loc || !loc.lat || !loc.lng) results = [];
  else if (loc.lat > northEast.lat || loc.lat < southWest.lat) results = [];
  else if (loc.lng > northEast.lng || loc.lng < southWest.lng) results = [];
  else results = [name];
  return Promise.all(results);
}

function convertStrToArray(str) {
  if (str.indexOf(', ') > -1) {
    return str.split(', ');
  }
  return str.split(',');
}

function checkBoundaryAPI(loc) {
  //
  // returns a promise for array of results
  // based on boundary api
  //
  var url;
  var results;
  var backupFn = this.settings.metadata.check_boundaries_2;
  if (loc) {
    url = `/api/boundary?lat=${loc.lat}&long=${loc.lng}`;
    return this.$http.get(url)
    .then((res) => {
      var json;
      results = res.body.replace(/'\s*'/g, ' ');
      results = results.replace(/'/g, '"');
      json = results;
      json.map((entity) => {
        var updated = entity;
        if (updated.properties.BOUND_GR) {
          updated.properties.BOUND_GR = convertStrToArray(updated.properties.BOUND_GR);
        }
        return updated;
      });
      return json;
    })
    .catch(() => this[backupFn](loc));
  }
  results = [];
  return Promise.all(results);
}

function noBoundary() {
  return Promise.all(['ok']);
}

function filterAddressResults(arrayOfAddresses) {
  return arrayOfAddresses.filter(address => address.boundaries && address.boundaries.length > 0);
}

function sendAddressResults(arrayOfAddresses) {
  //
  // depending on the number of results,
  // sends new or previous address to the address search bar
  // and/or opens a modal alerting user to problems
  //
  var results;
  var address;
  if (!arrayOfAddresses || !arrayOfAddresses.length > 0) {
    results = { errorMessage: true };
  } else {
    results = arrayOfAddresses;
  }

  if (results.length === 1) {
    address = results[0];
    address.resolved = true; // eslint-disable-line
    this.updateAddress(address);
    this.closeModals();
    if (this.settings.metadata.address_types) {
      return this.handleAddressCategories(address);
    }
  } else {
    // this.updateAddress(this.settings.value);
    return this.openResolveModal(results);
  }

  return results;
}

function updateAddress(address) {
  if (!address || !address.formatted_address) {
    if (this.settings.metadata.reset_on_empty) {
      this.addressSearchText = '';
      this.address = [{ user: false, address: this.settings.default_address }];
    } else {
      this.addressSearchText = this.home.address.formatted_address;
    }
  } else if (address.formatted_address) {
    //
    // update text in address search bar
    //
    this.addressSearchText = address.formatted_address; // updates text in search box
    //
    // update global address
    // used to calcluate home/update map
    //
    this.address = [{ user: true, address: address }];
  }
}

function handleAddressCategories(address) {
  //
  // handles two types of address behavior:
  // * list of bounds that includes default: default behavior
  // * list of bounds that doesn't include default: alternate behavior
  //
  var i;
  var bound;
  var behavior;
  var data;
  var includesDefault = false;
  var addressTypes = this.settings.metadata.address_types;
  var addressBoundaries = address.boundaries;
  for (i = 0; i < addressBoundaries.length; i ++) {
    bound = addressBoundaries[i];
    data = getDataByProperty(addressTypes.default.prop, bound);
    if (data === null || data === addressTypes.default.includes) includesDefault = true;
  }
  if (includesDefault) {
    behavior = this[addressTypes.default.behavior];
  } else {
    behavior = this[addressTypes.alternate.behavior];
  }
  behavior(address.boundaries);
  return [address];
}

function sendNeighborhood(boundaries) {
  this.settings.neighborhood_bounds = boundaries;
}

function alertWebsite(boundaries) {
  this.settings.neighborhood_bounds = [];
  if (boundaries.length === 1) {
    return this.openAlertModal(boundaries[0]);
  }
  return null;
}

function handleChildAddress(address) {
  this.sendAddressResults([address]);
  this.addressResolve = false;
}

function openResolveModal(data) {
  this.addressResolve = true;
  this.modalData = data;
  return data;
}

function openAlertModal(data) {
  this.addressAlert = true;
  this.modalData = data;
  return null;
}

function closeModals() {
  this.addressResolve = false;
  this.addressAlert = false;
}

function handleaddressSearchText(addressSearchText) {
  return this.requestGoogleAddress(addressSearchText);
}

function getSearchText() {
  var text;
  var home = this.$store.state.home;
  if (this.dataInput) { // for non-typeahead component
    text = this.dataInput;
  } else if (!home || !home.address.formatted_address) { //
    text = '';
  } else if (!home.user && !this.settings.metadata.show_default_address) {
    text = '';
  } else if (home.address.formatted_address) {
    text = home.address.formatted_address;
  }
  return text;
}

module.exports = {
  data: function data() {
    return {
      addressAlert: false,
      addressResolve: false,
      modalData: null
    };
  },
  methods: {
    clearAddress: clearAddress,
    requestGoogleAddress: requestGoogleAddress,
    handleAddressResults: handleAddressResults,
    appendBoundaries: appendBoundaries,
    filterAddressResults: filterAddressResults,
    sendAddressResults: sendAddressResults,
    checkBoundaryBox: checkBoundaryBox,
    checkBoundaryAPI: checkBoundaryAPI,
    noBoundary: noBoundary,
    updateAddress: updateAddress,
    handleAddressCategories: handleAddressCategories,
    sendNeighborhood: sendNeighborhood,
    alertWebsite: alertWebsite,
    handleChildAddress: handleChildAddress,
    openResolveModal: openResolveModal,
    openAlertModal: openAlertModal,
    closeModals: closeModals,
    handleaddressSearchText: handleaddressSearchText,
    getSearchText: getSearchText
  },
  events: {
    ClearAddress: function ClearAddress() {
      this.clearAddress();
    }
  },
  watch: {
    home: {
      handler: function watchHome() {
        // updates address bar when address change does not come
        // directly from this component
        this.addressSearchText = this.getSearchText();
      },
      deep: true
    }
  }
};
