<template>
  <modal-component v-if="settings.dynamic" :show="show" :click-away="true" class='match-modal' :containerClass="'p-0'">

    <!-- close button -->

    <!-- header -->
    <div class="modal-heading c-modal__head p-3">
        <span class="heading-target c-modal__title">
          <h2>{{settings.dynamic.computed.match_rate}} {{settings.match_connect_text}} {{relaxedFilterCount}}</h2>
          <div class='filter-match c-list__item--active filter-match--legend filter-match--is-match d-flex align-items-center'>
            <i class="c-list__bullet p-0 mr-2 fa-lg fas fa-check-circle"></i>
          <p>{{ settings.match_singular_text }}</p></div>
        </span>

       <button v-on:click="closeModal" v-on:keyup.enter="closeModal" class="c-modal__close modal_close c-button--mobile "><i class="fal fa-times" aria-hidden="true"></i><span class="sr-only">Close</span></button>
    </div>

    <!-- entity data -->
    <div v-if="settings.dynamic.computed.match_list" class="match-list  sp-v-3 p-3">
      <div class="result-group" v-for="filterGroup in  settings.dynamic.computed.match_list">
        <div class="result-group_title" :class="getTitleClass(filterGroup)">
          <h3 class="mb-1" v-if="filterGroup.show_title">
            {{filterGroup.parent_title ? filterGroup.parent_title : filterGroup.title}}
          </h3>
          <h2 v-if="filterGroup.parent_title">
            {{'(' + filterGroup.title + ')'}}
          </h2>
        </div>
        <ul class="c-list sp-v-2 filter-match-results">
          <li
            v-for="req in displayRequirements(filterGroup)"
            class="c-list__item mb-0 filter-match"
            :class="getRequirementClass(req)"><!--
             -->
             <div class="c-list__bullet p-0 mr-2">
               <i class="fa-fw" :class="req.match ? 'fas fa-check-circle': 'fal fa-times'"></i>
             </div>
             <div class="c-list__text">{{req.text}}</div>
          </li>
        </ul>
      </div>
    </div> <!-- closes entity -->
  </modal-component>
</template>
<script type="text/javascript">
  var filters = require('../../store/filters');
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';

  module.exports = {
    components: { ModalComponent },
    mixins: [ModalMixin],
    props: [
      'settings',
      'show',
    ],
    computed: {
      localFilters: function localFilters() {
        return this.$store.getters.filterSet;
      },
      relaxedFilterCount: function relaxedFilterCount() {
        return this.$store.getters.relaxedFilterCount;
      }
    },
    methods: {
      displayRequirements: function displayRequirements(filterGroup) {
        if (filterGroup.show_requirements === 'all') {
          //
          // return all requirements, matched & unmatched
          //
          return filterGroup.requirements;
        } else if (filterGroup.show_requirements === 'match') {
          //
          return filterGroup.requirements.filter(req => req.match === true || req.match === null);
        } else if (filterGroup.show_requirements === 'categorical') {
          return filterGroup.requirements;
        }
        return filterGroup.requirements.filter(req => req.match === null);
      },
      getRequirementClass: function getRequirementClass(req) {
        if (req.match === null) return 'no-req';
        if (req.match) return 'filter-match--is-match c-list__item--active';
        return 'filter-match--is-not-match c-list__item--missing-data';
      },
      getTitleClass: function getTitleClass(filterGroup) {
        if (filterGroup.show_requirements === 'none') {
          if (filterGroup.filter_met === null) return 'no-req';
          if (filterGroup.filter_met) return 'filter-match--is-match';
          return 'filter-match--is-not-match';
        }
        return '';
      }
    },
    mounted: function mounted() { // MAY NEED TO USE NEXTTICK
      var updatedEntity = this.settings.dynamic;
      //
      // when the modal opens
      // get the entity's match data: which preferences are selected,
      // whether the entity matches and (depending on the filter type) how they differ
      //
      updatedEntity.computed.match_list = filters.getAllMatches(
                                              this.localFilters,
                                              this.settings.dynamic,
                                              this.settings);
      //
      // update modal's entity data to include detailed match information
      //
      this.settings.dynamic = updatedEntity;
      // }
    }
  };
</script>
<style scoped>
  .match-list{
    overflow-y: auto;
  }
</style>
