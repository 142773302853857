// TODO: deep matching of objects is not supported:
// var obj1 = {test: 'im me'};
// var arrObj3 = [obj1];
// var arrObj4 = [obj1];
// var match = arraysMatch(arrObj3, arrObj4);
// match = false

function arraysMatch(arr1, arr2) {
  const arr1Copy = JSON.parse(JSON.stringify(arr1));
  const arr2Copy = JSON.parse(JSON.stringify(arr2));

  if (arr1Copy.length !== arr2Copy.length) return false;

  while (arr1Copy.length) {
    const searchTerm = arr1Copy[arr1Copy.length - 1];
    const idx = arr2Copy.findIndex(item => item === searchTerm);
    if (idx > -1) {
      arr1Copy.pop();
      arr2Copy.splice(idx, 1);
    } else {
      return false;
    }
  }
  return true;
}

export default arraysMatch;
