<template>
    <div>
        <svg width="34px" height="34px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Screen-15" transform="translate(-927.000000, -42.000000)">
                    <g id="Group-2" transform="translate(460.000000, 30.000000)">
                        <g id="Group-3" transform="translate(468.000000, 13.000000)">
                            <polygon id="Shape" :fill="fill" points="17 6.20857143 15.7914286 5 11 9.79142857 6.20857143 5 5 6.20857143 9.79142857 11 5 15.7914286 6.20857143 17 11 12.2085714 15.7914286 17 17 15.7914286 12.2085714 11"></polygon>
                            <circle id="Oval-3"  :stroke="fill" cx="11" cy="11" r="11"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>
<script type="text/javascript">
module.exports = {
  props: {
    fill: {
      default: '#71777A'
    }
  }
};
</script>