<template>
  <modal-component :show="show" :click-away="true" class='compare-modal modal-drawer'>
    <div class="modal-heading c-modal__head o-modal__head">
      <h1 class="c-modal__heading-target o-modal__heading-target">{{settings.compare_header}}</h1>
      <button v-on:click="closeModal" class="modal_close modal_close--ui c-button--mobile"><i class="fal fa-times" aria-hidden="true"></i><span class="sr-only">Close</span></button>
    </div>
    <div v-if="compares" class="my-2 mx-3">
      <p class="text-small" v-if="!compares || compares.length === 0">
        {{settings.no_comparison_text}}
      </p>
      <div v-for="c in sortByName(compares)" class="d-flex justify-content-between align-items-center mt-3">
        <div class="favorite-list__school-name">{{c.name}}</div>
        <button class=" c-button c-button--error c-button--outline favorite-list__remove-toggle delete" v-on:click="removeCompare(c.id)">{{settings.remove_text}}</button>
      </div>
      <p class="text-small" v-if="compares.length > 0 && compares.length < minimum">
      {{settings.not_enough_comparison_text}}</p>

    </div>
    <div class="modal_footer c-modal__footer mt-auto mb-3 mx-3">

        <button :disabled="!compares || compares.length < minimum" class="c-button button button--active pull-right" v-on:click="redirect">{{settings.goto_compare_button_text}}</button>

    </div>

  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  var ModalMixin = require('../../mixins/ModalMixin');
  import getQueryString from 'tembo-js/getQueryString';

  module.exports = {
    props: ['show', 'settings'],
    mixins: [ModalMixin],
    components: { ModalComponent, },
    computed: {
      compares: function compares() {
        return this.$store.state.compares;
      },
      minimum: function minimum() {
        if (this.settings.minimum_compare) {
          return this.settings.minimum_compare;
        }
        return 2;
      }
    },
    methods: {
      sortByName: function sortByName(rows) {
        return rows.slice().sort((a, b) => {
          if (a.name < b.name) return -1;
          if (b.name < a.name) return 1;
          return 0;
        });
      },
      redirect: function redirect() {
        location.href = this.settings.compare_url + getQueryString(this.$route.query);
      },
      removeCompare: function removeCompare(id) {
        var sessionItem = { id: id, compare: false };
        this.$store.dispatch('updateCompare', sessionItem);
      }
    }
  };
</script>
<style scoped>
  .modal-note{
    color:  #71777A;
    font-size: 13px;
  }
</style>
