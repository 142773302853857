<!--
* Do we want to adjust the browser window title? We could.

 -->
<template>
  <div class="container-fluid" id="compare" v-if="settings && isLoaded">
    <div class="row" style="margin-bottom: 130px;">
      <div class="col-12">
        <h1 class="mb-1">{{settings.title}}</h1>
        <p>{{settings.description}}</p>
      </div>
    </div>
    <!-- Entity Header -->
    <div class="row">
      <div class="col-3"></div>
      <component
        v-for="entity in compareEntities"
        :key="entity.id"
        :is="layout.header.tag"
        :entity="entity"
        :entity-count="compareEntities.length"
        :settings="layout.header.data">
      </component>
    </div>
    <!-- Compare header - why is this after? -->
    <div class="row my-4">
      <div class="col-12">
        <h1 v-if="settings.compare_header" class="sr-only">{{settings.compare_header}}</h1>
      </div>
    </div>
    <!-- Loop through categories -->
      <compare-category
        v-for="(category, index) in settings.categories"
        :key="category.id"
        :category="category"
        :compare-entities="compareEntities"
      ></compare-category>

    <hr v-if="layout.footer && layout.footer.data">

    <!-- FOOTER -->
    <component
      v-if="layout.footer && layout.footer.data"
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      :footer-type="containerWidth"
    ></component>
  </div>
  <div v-else class="main-body">
    <!-- <loading-spinner></loading-spinner> -->
  </div>
</template>
<script type="text/javascript">
  import CompareCategory from './CompareCategory.vue';

  module.exports = {
    props: ['settings', 'layout'],
    components: { CompareCategory },
    computed: {
      categories() {
        return this.settings.categories;
      },
      compareEntities() {
        if (!this.$store.state.compares) return [];
        if (!this.$store.getters.entitySet) return [];
        const compareIds = this.$store.state.compares
          .map(c => c.id);
        return this.$store.getters.entitySet
          .filter(entity => compareIds.indexOf(entity.id) > -1);
      },
      isLoaded() {
        return this.$store.state.isLoaded;
      },
    },
    methods: {
      applyCompare(rows) {
        return rows.map((r) => {
          const newR = r;
          const compare = this.$store.state.compare.compares.find(c => (
              r.id.toString() === c.id.toString()
            )
          );
          newR.computed.compare = !!compare;
          return newR;
        }, this);
      }
    },
  };
</script>
