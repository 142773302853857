<template>
  <select-component
    :options="updatedOptions"
    :selected="$store.state.sort"
    @update:selected="val => {handleChange(val)}"
    :enablers="gradeDependentEnablers">
    <h2 slot="select-title" class="c-select__title">{{settings.title_text}}</h2>
  </select-component>
</template>
<script type="text/javascript">
  import SelectComponent from './Select.vue';
  import sendEvent from 'tembo-js/sendEvent';
  const EnableDisableSortMixin = require('../mixins/EnableDisableSortMixin');

  module.exports = {
    props: [
      'settings',
      'enablers'
    ],
    components: { SelectComponent },
    mixins: [EnableDisableSortMixin],
    data() {
      return {
        gradeDependentEnablers: null,
        enablerProp: 'gradeDependentEnablers',
      };
    },
    created() {
      this.gradeDependentEnablers = this.getGradeDependentEnablers();
    },
    computed: {
      gradeFilter: function gradeFilter() {
        if (!this.$store.getters.filterSet.grade_levels) return null;
        return this.$store.getters.filterSet.grade_levels;
      },
    },
    methods: {
      handleChange: function handleChange(newSort) {
        this.$store.dispatch('updateSort', newSort);
        sendEvent({
          category: 'list',
          action: 'sort',
          label: this.settings.value
        });
      },
      getGradeDependentEnablers() {
        if (!this.gradeFilter) return this.enablers;
        let gradeSelection;
        const gradeValues = this.gradeFilter.value;
        let i = 0;
        const l = gradeValues.length;
        let usePreK;
        while (!gradeSelection && i < l) {
          if (gradeValues[i].required) gradeSelection = gradeValues[i].value;
          i += 1;
        }
        if (this.settings.use_pre_k.indexOf(gradeSelection) > -1) {
          usePreK = true;
        } else {
          usePreK = false;
        }

        const useK12 = !usePreK;

        const newEnablers = JSON.parse(JSON.stringify(this.enablers));
        newEnablers.useK12 = useK12;
        newEnablers.usePreK = usePreK;
        return newEnablers;
      },
    },
    watch: {
      gradeFilter: {
        handler() {
          this.gradeDependentEnablers = this.getGradeDependentEnablers();
        },
        deep: true
      },
    },
  };

</script>