<template>
  <p v-if="entityData || entityData === 0"><span class="d-md-none d-inline">{{this.labelWithSeparator}}</span>{{this.entityData}}</p>
</template>
<script type="text/javascript">
  module.exports = {
    props: {
      entityData: {
        type: [String, Number],
        required: false
      },
      universe: {
        validator: function validator(obj) {
          return (typeof obj.separator === 'string' && typeof obj.label === 'string');
        }
      }
    },
    computed: {
      labelWithSeparator: function labeledText() {
        return this.universe.label.concat('', this.universe.separator);
      }
    }
  };
</script>
