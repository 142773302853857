/* eslint-disable global-require, no-unused-vars, no-undef */
var Vue = require('vue');

function vueCase(string) {
  const words = string.split('-');
  const upperWords = words.map(w => w[0].toUpperCase() + w.slice(1, w.length).toLowerCase());
  return upperWords.join('');
}

function registerComponents(list) {
  for (let i = 0, len = list.length; i < len; i ++) {
    const item = list[i];
    // const component = require('../src/vue/' + item.path + '.vue');
    try {
      Vue.component(item.name, item.component);
    } catch (e) {
      console.error(`Could not load ${item.name}`); // eslint-disable-line no-console
    }
  }
}

function alternateLoader(entireList) {
  const laNames = laComponents.map(vueCase);
  for (let i = 0, l = entireList.length; i < l; i ++) {
    const item = entireList[i];
    // if (laNames.indexOf(item.name) > -1) {
      // const component = require('../src/vue/' + item.path + '.vue');
    try {
      Vue.component(item.name, item.component);
    } catch (e) {
      console.error(`Could not load ${item.name}`); // eslint-disable-line no-console
    }
    // } else {
    //   console.log('not loading', item.name);
    // }
  }
}

module.exports = {
  registerComponents,
  alternateLoader,
};
/* eslint-enable */
