<!--  -->
<template>
  <div :class="'col-sm-' + Math.floor(9 / entityCount)" class="d-flex justify-content-center">
    <div>
      <img v-if="entityImage"
           :src="entityImage" alt=""
           class="img--responsive img--compare mb-gutter">
      <a :href="entityUrl">{{entity.name}}</a>
      <ul class="compare-data mt-2">
        <template v-for="(item, index) in entityInfo">
          <li v-if="item.value" :key="index">
            <i :class="'fas fa-fw ' + item.classes"></i>
            {{item.label}}{{item.separator}}{{ item.list_format === 'hyphenated' ? hyphenate(item.value) : item.value}} {{item.suffix}}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import { getEntityUrl } from '../../store/utils';
  const utils = require('../../store/utils');
  module.exports = {
    props: ['entity', 'settings', 'entityCount'],
    computed: {
      entityUrl() {
        const template = this.settings.url_template || null;
        return getEntityUrl(this.entity, this, template);
      },
      entityImage() {
        return getDataByProperty(this.settings.image_prop, this.entity);
      },
      entityInfo() {
        return this.settings.sub_header.map(subHeader => {
          subHeader.value = getDataByProperty(subHeader.entity_prop, this.entity); // eslint-disable-line no-param-reassign, max-len
          return subHeader;
        });
      },
    },
    methods: {
      hyphenate(list) {
        return utils.displayListAsRange(list);
      },
    },
  };
</script>
