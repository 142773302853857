<template>
  <!-- ENROLLMENT: requires enrollment or missing data rule -->
  <div class="entity-attributes__enrollment" v-if="entityData && (entityData.value || entityData.value === 0 || entityData.exception_code)">
    <div class="d-inline-flex flex-column align-items-start align-items-md-center">
      <div class="p-entity__attributes__title mb-1"><i class="fal fa-file-alt mr-1" aria-hidden="true"></i>{{settings.enrollment_header_text}}</div>
      <div v-if="entityData.value || entityData.value === 0" class="">{{entityData.value}} {{settings.enrollment_units_text}}</div>
      <!-- Missing data text for enrollment -->
      <div v-else class="">{{settings.exception_codes[entityData.exception_code].short_description}}</div>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'entityData']
  };
</script>
