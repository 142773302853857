<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <fieldset :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" :settings="settings" :selection-tally="selectionTally" class="categorical-filter filter-group o-two-column-layout__filter-list__group" v-show="!!!settings.metadata.hide_disabled || enabled">

    <!-- FILTER TITLE SLOT -->
    <h2 slot="filter-title">{{settings.group_title_text}}</h2>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" v-if="settings.description_text">{{settings.description_text}}</div>

    <!-- FILTER PROMPT SLOT -->
    <div slot="filter-prompt" v-if="settings.prompt_text" class="filter-prompt">{{settings.prompt_text}}</div>
    <div slot="filter-alt-prompt" v-if="settings.alt_prompt_text" class="filter-prompt">{{settings.alt_prompt_text}}</div>
    <a class="filter-description" href='#' v-if="settings.metadata.descriptions" v-on:click="handleShowDescriptions">{{settings.options_description_text}}</a>
    <!-- FILTER CONTENT SLOT -->
    <ul slot="filter-content" class="d-flex sp-h-2 mt-2" v-if="values">

      <li class="filter-item" v-for="(value, index) in filteredValues">
        <!-- Filter control -->
        <span
          :is="layout.option_tag.tag"
          :value="value"
          :selected="requiredValue"
          @update:selected="val => { handleChange(val) }"
          :disabled="!enabled"
          :name="settings.metadata.label"
        ></span>
      </li>
    </ul>
  </fieldset>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  const FilterGroupMixin = require('../../mixins/FilterGroupMixin');


  // TODO: combine with WrappedSelectFilter
  module.exports = {
    mixins: [FilterGroupMixin],
    computed: {
      requiredValue: function requiredValue() {
        var reqVal;
        var i = 0;
        var l = this.values.length;
        while (!reqVal && i < l) {
          if (this.values[i].required) {
            reqVal = this.values[i].value;
          }
          i += 1;
        }
        return reqVal;
      }
    },
    methods: {
      getItemByValue: function getItemByValue(val) {
        var selectedValue;
        var i = 0;
        var l = this.values.length;
        while (!selectedValue && i < l) {
          if (this.values[i].value === val) {
            selectedValue = this.values[i];
          }
          i += 1;
        }
        return selectedValue;
      },
      handleChange: function handleChange(newval) {
        var prev = this.requiredValue;
        var curr;
        if (prev) {
          prev = this.getItemByValue(prev);
          prev = JSON.parse(JSON.stringify(prev));
          prev.required = false;
          this.$store.dispatch('updateFilterValue', { filterValue: prev, updateEntities: true });
        }
        curr = this.getItemByValue(newval);
        curr = JSON.parse(JSON.stringify(curr));
        curr.required = true;
        this.$store.dispatch('updateFilterValue', { filterValue: curr, updateEntities: true });
        sendEvent({
          category: 'filter',
          action: 'change',
          label: newval
        });
      }
    }
  };
</script>
