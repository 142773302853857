<template>
  <th
    v-if="settings.is_row_header"
    >
    <div v-if="settings.link"
      :is="settings.tag"
      :entity-data="entityData"
      :entity-link="entityLink"
      :universe="settings.universe"
      :entity-id="entity.id"
    ></div>
    <div v-else
      :is="settings.tag"
      :entity-data="entityData"
      :universe="settings.universe"></div>
  </th>
  <td v-else>
    <div v-if="settings.link" :is="settings.tag" :entity-data="entityData" :entity-link="entityLink" :universe="settings.universe"></div>
    <div v-else :is="settings.tag" :entity-data="entityData" :universe="settings.universe"></div>
  </td>
</template>
<script type="text/javascript">
  var utils = require('../store/utils');
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import getQueryString from 'tembo-js/getQueryString';

  module.exports = {
    props: ['settings', 'entity'],
    computed: {
      entityData: function entityData() {
        const data = getDataByProperty(this.settings.entity_prop, this.entity);
        if (this.settings.display && this.settings.display === 'range') {
          return utils.displayListAsRange(data, this.universe);
        }
        return data;
      },
      entityLink: function entityLink() {
        return this.getEntityLink(this.settings.link);
      },
    },
    methods: {
      getEntityLink: function getEntityLink(link) {
        if (!link) return null;
        const entity = this.entity;
        let href = '';
        if (link.base_url) {
          href += link.base_url;
        }
        if (link.entity_prop) {
          if (typeof link.entity_prop === 'object' && !Array.isArray(link.entity_prop)) {
            const keys = Object.keys(link.entity_prop);
            for (let i = 0, l = keys.length; i < l; i ++) {
              const linkKey = keys[i];
              const entityKey = link.entity_prop[linkKey];
              const entityLinkData = getDataByProperty(entityKey, entity);
              if (entityLinkData || entityLinkData === 0) {
                href = href.replace(`[${linkKey}]`, entityLinkData);
              }
            }
          } else if (typeof link.entity_prop === 'string') {
            const entityLinkData = getDataByProperty(link.entity_prop, entity);
            if (entityLinkData || entityLinkData === 0) {
              href = href.replace('[entity_id]', entityLinkData);
            }
          } else {
            console.error('link.entity_prop must be a single entity property as a string or a set of key-value pairs'); // eslint-disable-line no-console, max-len
            href = '';
          }
        }

        return href + getQueryString(this.$route.query);
      },
    }
  };
</script>
