<template>
  <div>
    <!-- header -->
    <div class="container" id="search">
      <div class="row mt-5 mt-md-0">
        <div class="col-12 col-lg-11 offset-md-1 col-md-7">
          <h1 class="text-center text-md-left">{{settings.title_text}}</h1>
        </div>
      </div>
    </div>

    <!-- main content -->
    <div class="container mt-4 mt-md-5">

      <!-- input -->
      <div class="row">
        <div class="col-12 col-md-7 offset-md-1">

          <!-- search input -->
          <div class="c-input__search-wrapper w-100 c-input" v-on:click="setFocus" v-on:keydown.40.prevent="highlightNext" v-on:keydown.38.prevent="highlightPrev" v-on:keydown.13="gotoActive">
            <div class="c-input__value" v-on:click="setFocus">
              <i class="c-input__search-icon c-input__icon--search fas fa-search"></i>
              <input class="c-input__search c-input__input" v-model="inputTarget" type="text"
                :id="id"
                :placeholder="settings.search_placeholder_text"
                v-on:input="handleInput"
                v-on:focus="handleInput" />
              <i class="fas fa-times c-input__icon--clear "
                :class="input ? 'd-inline-block' : 'd-none'"
                v-on:click="clearInput"></i>
            </div>
          </div>

          <!-- search filters -->
          <div class="sp-h-3 align-items-center d-flex pl-2 mt-3" v-if="settings.sections && settings.sections.length > 1">

            <!-- filter - all -->
            <label class="c-radio__label align-items-center d-inline-flex">
              <input class="c-radio c-radio--lg" type="radio" id="filter" value="all" v-model="searchFilter" name="searchFilter" @change="handleFilterSelect()">
              <span class="c-radio__visual"></span>
              <span class="c-radio__text">{{settings.all_text}}</span>
            </label>

            <!-- filters -->
            <label class="c-radio__label align-items-center d-inline-flex" v-for="(filter, idx) in settings.sections" :key="idx">
              <input class="c-radio c-radio--lg" type="radio"  :value="filter.filter_value" v-model="searchFilter" @change="handleFilterSelect()" name="searchFilter">
              <span class="c-radio__visual"></span>
              <span class="c-radio__text">{{filter.display_text}}</span>
            </label>
          </div>

        </div>
      </div>

      <!-- no results state -->
      <div v-if="input.length > 2 && !hasFilteredResults" class="text-center row mt-80">
        <div class="col-12 col-md-5 col-lg-4 mx-auto">
          <h3>{{ settings.no_results_title_text }}</h3>
          <p>{{ settings.no_results_body_text }}</p>
        </div>
      </div>

      <!-- results -->
      <div class="row mt-20">
        <div class="col-12 offset-md-1 col-md-11 p-screen-overlay__results-wrapper">
          <template v-for="section in filteredSectionsList">
            <template v-if="searchFilter === 'all' || searchFilter === section.filterValue">
              <h3 class="search-section-heading w-100 mb-2" v-if="section.filteredResults.length > 0">{{section.sectionHeader}}</h3>
              <div class="d-md-flex flex-md-wrap">
                <entity-card
                  :key='entity.id'
                  v-for="(entity) in section.filteredResults"
                  :entity="entity"
                  :settings="settings"
                  :card-type="'entity_search'">
                </entity-card>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import debounce from 'lodash/debounce';
  var $ = require('jquery');
  import sendEvent from 'tembo-js/sendEvent';
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import handleDataMap from 'tembo-js/handleDataMap';
  import getQueryString from 'tembo-js/getQueryString';
  import replaceSpecialCharacters from 'tembo-js/replaceSpecialCharacters';
  import EntityCard from './EntityCard.vue';

  module.exports = {
    components: { EntityCard },
    props: ['settings'],
    data: function data() {
      return {
        inputTarget: '',
        input: '',
        menuVisible: false,
        highlightIdx: 0,
        focus: false,
        timeout: null,
        id: null,
        searchFilter: 'all'
      };
    },
    mounted: function mounted() {
      this.id = this._uid; // eslint-disable-line no-underscore-dangle
    },
    computed: {
      /**
       * returns object array; object props:
       * sectionHeader - display name for section in search overlay
       * filteredResults - filtered Array of entities
       */
      filteredSectionsList: function filteredSectionsList() {
        if (!this.settings.sections || !this.settings.sections.length) {
          return [{ filteredResults: this.filteredResults, sectionHeader: '' }];
        }
        const filteredArr = [];

        for (let i = 0, l = this.settings.sections.length; i < l; i ++) {
          const section = this.settings.sections[i];
          const filteredObj = {};
          filteredObj.filteredResults = this.filteredResults.filter((entity) => {
            if (entity[section.filter_prop] === section.filter_value) return true;
            return false;
          }, this);
          filteredObj.sectionHeader = section.display_text || '';
          filteredObj.filterValue = section.filter_value || '';
          filteredArr.push(filteredObj);
        }
        return filteredArr;
      },
      filteredResults() {
        const input = replaceSpecialCharacters(this.input.toLowerCase());
        const searchFields = this.settings.search_fields;
        const entities = this.settings.entities;

        if (!input) return [];
        if (!entities || !entities.length) return [];

        const result = entities.filter((entity) => {
          for (let i = 0, l = searchFields.length; i < l; i ++) {
            const field = searchFields[i];
            let entityData = getDataByProperty(field, entity);
            entityData = replaceSpecialCharacters(entityData.toLowerCase());
            if (entityData && entityData.indexOf(input) > -1) return true;
          }
          return false;
        }, this);
        return result;
      },
      hasFilteredResults: function hasFilteredResults() {
        if (this.input === '') return false;
        if (this.searchFilter === 'all') {
          return this.filteredSectionsList.some(ele => ele.filteredResults.length > 0);
        }
        return this.filteredSectionsList.some(ele => ele.filterValue === this.searchFilter && ele.filteredResults.length > 0); // eslint-disable-line max-len
      }
    },
    methods: {
      showInput: function showInput() {
        this.inputVisible = true;
      },
      clearInput: function clearInput() {
        this.input = '';
        this.inputTarget = '';
      },
      handleInput: debounce(function handleInput(ev) {
        var input;
        var menuVisible;
        if (ev.target.value.length >= 3) {
          input = ev.target.value;
          menuVisible = true;
          const findView = this.$route.params.findView;
          const category = findView ? `${findView}_find_header` : 'find_header';
          sendEvent({
            category: category,
            action: 'name_search',
            label: input
          });
        } else {
          input = '';
          menuVisible = false;
        }
        this.input = input;
        this.menuVisible = menuVisible;
        //
        // send GA event
        //
        return input;
      }, 300),
      handleFilterSelect() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_find_header` : 'find_header';
        sendEvent({
          category: category,
          action: 'search_filter',
          label: this.searchFilter,
        });
      },
      getDisplayName: function getDisplayName(entity, fieldSet) {
        if (Object.hasOwnProperty.call(this.settings, fieldSet) && Array.isArray(this.settings[fieldSet])) { // eslint-disable-line max-len
          let displayNames = this.settings[fieldSet]
            .map(field => getDataByProperty(field, entity));
          // remove duplicate results
          displayNames = displayNames
            .filter((item, idx) => item && displayNames.indexOf(item) === idx);
          return displayNames.join('/');
        } else if (Object.hasOwnProperty.call(this.settings, fieldSet)) {
          // allow more complex logic for joining names
          if (this.settings[fieldSet].template) {
            let display = handleDataMap(this.settings[fieldSet].template, entity);
            const replaceArr = display.match(/\[(.*?)\]/g).map(str => str.replace(/[\[\]]/g, ''));
            for (let i = 0, l = replaceArr.length; i < l; i ++) {
              display = display.replace(`[${replaceArr[i]}]`, entity[replaceArr[i]]);
            }
            return display;
          }
        }
        return '';
      },
      getTemplatedEntityUrl: function getTemplatedEntityUrl(entity) {
        const replaceMap = {
          id: '[entity_id]',
          parent_id: '[parent_id]'
        };
        let url = handleDataMap(this.settings.url_templates, entity);
        if (!url) return url;
        const replaceKeys = Object.keys(replaceMap);
        for (let i = 0, l = replaceKeys.length; i < l; i ++) {
          const key = replaceKeys[i];
          const replaceThis = replaceMap[key];
          url = url.replace(replaceThis, entity[key]);
        }
        return url + getQueryString(this.$route.query);
      },
      getEntityUrl: function getEntityUrl(entity) {
        if (this.settings.url_templates) return this.getTemplatedEntityUrl(entity);
        if (!this.$route.query.lang || this.$route.query.lang === 'en') {
          return '/schools/' + entity.id;
        }
        return '/schools/' + entity.id + getQueryString(this.$route.query);
      },
      hideMenu: function hideMenu() {
        $('input.search-input').blur();
        this.highlightIdx = 0;
        this.menuVisible = false;
        this.focus = false;
      },
      setFocus: function setFocus() {
        $('input.c-input__search').focus();
      },
      scrollToHighlighted: function scrollToHighlighted() {
        // if the item to be highlighted next is past the bottom of the menu
        // scroll it into place
        const nextItem = $('.menu').find('.option').eq(this.highlightIdx);
        const nextItemTop = nextItem.position().top;
        const nextItemHeight = nextItem.height();
        const nextItemBottom = nextItemTop + nextItemHeight;
        const menuHeight = $('.menu').height();
        const menuScroll = $('.menu').scrollTop();
        const itemMargin = parseInt(nextItem.css('marginTop'), 10);
        const itemPadding = parseInt(nextItem.css('paddingTop'), 10);

        const itemBelow = menuHeight <= nextItemBottom + itemMargin + itemPadding;
        const itemAbove = nextItemTop < 0;

        const scrollTop = menuScroll + nextItemTop;

        if (itemBelow || itemAbove) {
          $('.menu').animate({ scrollTop: scrollTop }, 200);
        }
      },
      highlightNext: function highlightNext() {
        if (this.highlightIdx < this.filteredList.length - 1) {
          this.highlightIdx = this.highlightIdx + 1;
        }
      },
      highlightPrev: function highlightPrev() {
        if (this.highlightIdx > 0) {
          this.highlightIdx = this.highlightIdx - 1;
        }
      },
      gotoActive: function gotoActive() {
        var url;
        if (this.settings.url_templates) {
          url = handleDataMap(this.settings.url_templates,
                                    this.filteredList[this.highlightIdx]);
          url = url.replace('[entity_id]', this.filteredList[this.highlightIdx].id);
        } else {
          url = '/schools/' + this.filteredList[this.highlightIdx].id;
        }
        location.href = url + getQueryString(this.$route.query);
      }
    },
    watch: {
      highlightIdx: function highlightIdx() {
        this.scrollToHighlighted();
      },
      menuVisible: function menuVisible(curr) {
        var el;
        var height;
        if (curr) {
          el = $(this.$el).closest('.modal-container');
          height = el.height();
          $(el).animate({ scrollTop: height }, 300);
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  .option--info {
    /* float: right; */
    text-transform: capitalize;
  }
</style>
