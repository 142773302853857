<template>
  <div v-if="settings.tembo" class="d-flex align-items-end">
  	<p style="white-space: nowrap" class="p-footer__logo-text d-flex align-items-center text-small mr-2 lh-1">
  	<span class="mr-2">{{settings.powered_by_text}}</span>
  	<a class="d-inline-flex" href="https://www.hoonuit.com" target="_blank">
  	<hoonuit-logo-svg />
  	</a>
  	</p>
  </div>
</template>
<script type="text/javascript">
  import HoonuitLogoSvg from '../assets/HoonuitLogoSvg.vue';
  module.exports = {
    components: {
      HoonuitLogoSvg,
    },
    props: ['settings']
  };
</script>

