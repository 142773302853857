<!--
settings:
  search_placeholder_text
  info_fields
  not_ready_text
  no_results_text

 -->
<template>
  <div v-on:keydown.40.prevent="highlightNext" v-on:keydown.38.prevent="highlightPrev" v-on:keydown.13="gotoActive" class="linked-dropdown-menu position-relative">
    <!-- v-on:mouseenter="cancelTimeout" v-on:mouseleave="hideMenuTimeout" -->
    <!-- search input -->
    <input-light :id="id"  :class="(menuVisible ? 'c-input--flat-bottom' : '') + ' ' + inputSize">
      <input slot="input" type="text" :id="id" class="search-input c-input__input" :placeholder="settings.search_placeholder_text" v-on:input="handleInput" v-on:focus="handleInput"></input>
      <i slot="icon" class="fas fa-search"></i>
      <!-- <i slot="close" class="fa fa-close"></i> -->
    </input-light>

    <!-- dropdown menu -->
    <div class="menu c-input__list" :class="menuVisible ? 'c-input__list--visible c-input__list--round-bottom' : 'c-input__list--hidden'">
      <div v-for="(entity, index) in filteredList" :class="index === highlightIdx ? 'option--highlight c-input__list__item--highlight' : ''" class="option c-input__list__item p-2 ">
        <a :href="getEntityUrl(entity)" class="not-a d-flex justify-content-between align-items-center"><span>{{getDisplayName(entity, 'display_fields')}}</span>
        <span class="option--info" :class="getDisplayName(entity, 'info_fields') === 'district' ? 'option--district' : 'option--campus' "  v-if="settings.info_fields">{{getDisplayName(entity, 'info_fields')}}</span>
        </a>
      </div>
      <div v-if="filteredList.length === 0 && (entitiesLoaded || !settings.not_ready_text)"  class="option disabled c-input__list__item c-input__list__item--disabled text-center py-3">{{settings.no_results_text}}</div>
      <div v-if="!entitiesLoaded && settings.not_ready_text"  class="option disabled">{{settings.not_ready_text}}</div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import debounce from 'lodash/debounce';
  var $ = require('jquery');
  import InputLight from './InputLight.vue';
  import getQueryString from 'tembo-js/getQueryString';
  import sendEvent from 'tembo-js/sendEvent';
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import handleDataMap from 'tembo-js/handleDataMap';
  import { getEntityUrl } from '../store/utils';

  module.exports = {
    props: ['settings', 'inputSize', 'entitiesList'],
    components: { InputLight },
    data: function data() {
      return {
        input: '',
        menuVisible: false,
        highlightIdx: 0,
        focus: false,
        timeout: null,
        id: null
      };
    },
    mounted: function mounted() {
      this.id = this._uid; // eslint-disable-line no-underscore-dangle
    },
    computed: {
      entities: function entities() {
        if (this.entitiesList) return this.entitiesList;
        return this.$store.getters.entitySet;
      },
      entitiesLoaded: function entitiesLoaded() {
        return (this.entities && this.entities.length) || this.$store.state.entitiesLoaded;
      },
      filteredList: function filteredList() {
        function sortByField(a, b, sortField, customSortOrder) {
          // function to return sort value by field
          // uses custom sort order if available
          const dataA = getDataByProperty(sortField, a);
          const dataB = getDataByProperty(sortField, b);
          if (customSortOrder) {
            const idxA = customSortOrder.indexOf(dataA);
            const idxB = customSortOrder.indexOf(dataB);
            return idxA - idxB;
          }
          if (dataA < dataB) return -1;
          if (dataA > dataB) return 1;
          return 0;
        }

        const input = this.input.toLowerCase();
        if (!input) return '';
        if (!this.entities || !this.entities.length) return '';
        return this.entities.filter(function filter(item) {
          for (let i = 0, l = this.settings.search_fields.length; i < l; i ++) {
            const field = this.settings.search_fields[i];
            if (item[field] && item[field].toLowerCase().indexOf(input) > -1) return true;
          }
          return false;
        }, this)
        .sort((a, b) => {
          if (this.settings.sort && Array.isArray(this.settings.sort)) {
            // debugger;
            let result = 0;
            const len = this.settings.sort.length;
            let i = 0;
            while (result === 0 && i < len) {
              const sortItem = this.settings.sort[i];
              result = sortByField(a, b, sortItem.sort_field, sortItem.custom_sort_order);
              i += 1;
            }
            return result;
          } else if (this.settings.sort && this.settings.sort.sort_field) {
            const sortBy = this.settings.sort;
            return sortByField(a, b, sortBy.sort_field, sortBy.custom_sort_order);
          }
          return 0;
        });
      }
    },
    methods: {
      showInput: function showInput() {
        this.inputVisible = true;
      },
      handleInput: debounce(function handleInput(ev) {
        var input;
        var menuVisible;
        if (ev.target.value.length >= 3) {
          input = ev.target.value;
          menuVisible = true;
          sendEvent({
            category: 'intro',
            action: 'name_search',
            label: input
          });
        } else {
          input = '';
          menuVisible = false;
        }
        this.input = input;
        this.menuVisible = menuVisible;
        //
        // send GA event
        //
        return input;
      }, 300),
      getDisplayName: function getDisplayName(entity, fieldSet) {
        if (Object.hasOwnProperty.call(this.settings, fieldSet) && Array.isArray(this.settings[fieldSet])) { // eslint-disable-line max-len
          let displayNames = this.settings[fieldSet]
            .map(field => getDataByProperty(field, entity));
          // remove duplicate results
          displayNames = displayNames
            .filter((item, idx) => item && displayNames.indexOf(item) === idx);
          return displayNames.join('/');
        } else if (Object.hasOwnProperty.call(this.settings, fieldSet)) {
          // allow more complex logic for joining names
          if (this.settings[fieldSet].template) {
            let display = handleDataMap(this.settings[fieldSet].template, entity);
            const replaceArr = display.match(/\[(.*?)\]/g).map(str => str.replace(/[\[\]]/g, ''));
            for (let i = 0, l = replaceArr.length; i < l; i ++) {
              const replaceThis = `[${replaceArr[i]}]`;
              const replaceWith = getDataByProperty(replaceArr[i], entity);
              display = display.replace(replaceThis, replaceWith);
            }
            return display;
          }
        }
        return '';
      },
      getEntityUrl(entity) {
        const templates = this.settings.url_templates || null;
        return getEntityUrl(entity, this, templates);
      },
      hideMenu: function hideMenu() {
        $('input.search-input').blur();
        this.highlightIdx = 0;
        this.menuVisible = false;
        this.focus = false;
      },
      setFocus: function setFocus() {
        $('input.search').focus();
      },
      cancelTimeout: function cancelTimeout() {
        clearTimeout(this.timeout);
      },
      hideMenuTimeout: function hideMenuTimeout() {
        var timeout = setTimeout(this.hideMenu, 800);
        this.timeout = timeout;
      },
      scrollToHighlighted: function scrollToHighlighted() {
        // if the item to be highlighted next is past the bottom of the menu
        // scroll it into place
        const nextItem = $('.menu').find('.option').eq(this.highlightIdx);
        const nextItemTop = nextItem.position().top;
        const nextItemHeight = nextItem.height();
        const nextItemBottom = nextItemTop + nextItemHeight;
        const menuHeight = $('.menu').height();
        const menuScroll = $('.menu').scrollTop();
        const itemMargin = parseInt(nextItem.css('marginTop'), 10);
        const itemPadding = parseInt(nextItem.css('paddingTop'), 10);

        const itemBelow = menuHeight <= nextItemBottom + itemMargin + itemPadding;
        const itemAbove = nextItemTop < 0;

        const scrollTop = menuScroll + nextItemTop;

        if (itemBelow || itemAbove) {
          $('.menu').animate({ scrollTop: scrollTop }, 200);
        }
      },
      highlightNext: function highlightNext() {
        if (this.highlightIdx < this.filteredList.length - 1) {
          this.highlightIdx = this.highlightIdx + 1;
        }
      },
      highlightPrev: function highlightPrev() {
        if (this.highlightIdx > 0) {
          this.highlightIdx = this.highlightIdx - 1;
        }
      },
      highlightFirst: function highlightFirst() {
        this.highlightIdx = 0;
      },
      setAsActive: function setAsActive(index) {
        this.highlightIdx = index;
      },
      gotoActive: function gotoActive() {
        var url;
        if (this.settings.url_templates) {
          url = handleDataMap(this.settings.url_templates,
                                    this.filteredList[this.highlightIdx]);
          url = url.replace('[entity_id]', this.filteredList[this.highlightIdx].id);
        } else {
          url = '/schools/' + this.filteredList[this.highlightIdx].id;
        }
        location.href = url + getQueryString(this.$route.query);
      }
    },
    watch: {
      entitiesLoaded: function entitiesLoaded() {
        const input = $(this.$el).find('input').val();
        this.input = input;
      },
      highlightIdx: function highlightIdx() {
        this.scrollToHighlighted();
      },
      menuVisible: function menuVisible(curr) {
        var el;
        var height;
        if (curr) {
          el = $(this.$el).closest('.modal-container:not(.c-modal--cta)');
          height = el.height();
          $(el).animate({ scrollTop: height }, 300);
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  .option--info {
    /* float: right; */
    text-transform: capitalize;
  }
</style>
