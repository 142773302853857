
function overlappingArrays(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    throw new Error('overlappingArrays expects two arrays');
  }
  if (!arr1 || !arr2) return false;
  if (arr1.length === 0 && arr2.length === 0) return true;
  for (let i = 0, len = arr1.length; i < len; i++) {
    if (arr2.indexOf(arr1[i]) > -1) return true;
  }
  return false;
}

export default overlappingArrays;
