<template>
  <div>
    <div class="wizard__input__prompt">{{currentScreen.prompt}}</div>
    <check-box v-for='option in options' :key="option.value" :value='option' :checked='option.required'></check-box>
    <div class="wizard-button-container">
      <button  v-on:click='next' :disabled="nextDisabled">{{currentScreen.submitText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');

  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen',
      'index',
      'previousScreens',
      'role',
      'options',
      'wizardSettings',
      'sorts'
    ],
    data: function data() {
      return {
        selected: null
      };
    },
    computed: {
      nextDisabled: function nextDisabled() {
        // check for all required information
        var req = this.options.filter(option => option.required);
        var l = req.length;
        if (!l) return true;
        return false;
      }
    },
    methods: {
      next: function next() {
        var current;
        // data for updating previous screens
        current = this.copy(this.currentScreen);

        // this screen does not get a breadcrumb

        // update sorting, if relevant
        this.updateSort();

        // update previous screens with current
        if (this.previousScreens.length - 1 < this.index) {
          this.previousScreens.push(current);
        } else {
          this.previousScreens[this.index] = current;
        }
        this.advance();
      }
    }
  };
</script>