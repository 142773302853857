import overlappingArrays from './overlappingArrays';

/*  eslint-disable no-else-return */
function arraysIncludeOrOverlap(item1, item2) {
  if (Array.isArray(item1) && Array.isArray(item2)) {
    if (overlappingArrays(item1, item2)) return true;
    return false;
  } else if (Array.isArray(item1)) {
    if (item1.indexOf(item2) > -1) return true;
    return false;
  } else if (Array.isArray(item2)) {
    if (item2.indexOf(item1) > -1) return true;
    return false;
  } else if (item2 === item1) {
    return true;
  }
  return false;
}
/*  eslint-enable */

export default arraysIncludeOrOverlap;
