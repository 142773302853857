<template>
  <div>
    <component
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
      class="position-relative"
    ></component>

    <div class="p-hero pb-60 pb-md-96 pt-60 pt-md-80">
      <div class="container h-100">
        <div class="row h-100">

          <div class="col-12 px-md-gutter d-flex flex-column justify-content-center">
            <p class="p-hero__p mb-3" v-html="getTextToRender('hero_text')"></p>
            <h1 class="p-hero__h1 mb-5">{{ getTextToRender('hero_h1', 'What’s your story?') }}</h1>
            <h2 class="p-hero__h2 mb-3">{{ getTextToRender('hero_h2', 'Learn all about your school.') }}</h2>
            <div class="d-flex justify-content-center" v-if="settings.search">
              <typeahead-dropdown-link class="mw-100 w-600"
                :placeholder="settings.search.placeholder"
                :options="settings.search.options"
                :link-template="settings.entity_url_template"
                :no-results="settings.search.no_results"
                :hero="true"
              ></typeahead-dropdown-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FIND ROUTES -->
    <div class="p-hero-links">
      <div class="container-fluid">
        <div class="row" v-if="settings.find_routes">
          <div class="col-12 py-5  d-flex flex-column flex-md-row justify-content-around align-items-center sp-v-gutter sp-v-md-0">
            <div v-for="route in settings.find_routes" class="d-flex align-items-center">

              <img class="d-none d-md-block" :srcset="route.img_srcset"
                :src="route.img_src"
                :alt="route.img_alt_text" width="110px" height="110px" style="margin-right: 40px;">

              <div class="text-center text-md-left">
                <p class="p-hero-links__title">{{route.prompt_text}}</p>
                <a class="p-hero-links__link" :href="route.href + getQueryString($route.query)" v-html="route.link_text"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div style="background-image: linear-gradient(to bottom, #fff3e0, #fff7ea 10%, #fffaf3 20%, #ffffff 29%);">


    <div class="container pt-60" >
      <section class="row pb-96">
        <!-- intro text -->
        <article class="col-12 col-md-7 mb-5 mb-md-0 d-flex flex-column">
          <div class="d-flex flex-column flex-column-reverse mb-4">
            <h1 class="h1--hero">{{ getTextToRender("welcome_title") }}</h1>
            <h2 class="h2--hero mb-2">{{ getTextToRender("welcome_header") }}</h2>
          </div>
          <p v-for="(paragraph, idx) in settings.welcome_paragraphs" :class="idx !== settings.welcome_paragraphs.length - 1 ? 'mb-4' : ''">{{ paragraph }}</p>
        </article>
        <!-- datapoints -->
        <article class="col-12 col-md-5">
          <div class="row">
            <div v-for="column in settings.datapoint_columns" class="d-flex flex-column align-items-center text-center" :class="'col-' + 12/settings.datapoint_columns.length">
              <h1 class="h1--hero--small" :class="column.header_data.subtext ? 'mb-12' : ''" style="font-size: 28px; line-height: 40px;">{{column.header_data.label}}</h1>
              <span style="font-size: 22px; line-height: 32px;">{{column.header_data.subtext}}</span>
              <!-- data separator -->
              <div class="data-separator">
                <span v-for="i in 3" class="data-separator__dot"></span>
              </div>
              <template v-for="(datapoint, idx) in column.data">
                <h2 style="color: #ff4747; font-size: 36px; line-height: 40px; font-family: Metropolis; font-weight: 600;">{{datapoint.value}}</h2>
                <span class="h2--hero--smallest">{{datapoint.label}}</span>
                <!-- data separator -->
                <div class="data-separator" v-if="idx < column.data.length - 1">
                  <span v-for="i in 3" class="data-separator__dot"></span>
                </div>
              </template>
            </div>
          </div>
        </article>
      </section>
    </div>
    </div>
    <div class="home-images container p-0">
      <!-- images -->
      <section class="row no-gutters mx-0 ">
        <div v-for="image in settings.separator_images" class="col-4">
          <img :src="image" class="w-100" />
        </div>
      </section>
    </div>
    <div class="mb-n80 pb-100" style="background-image: linear-gradient(to bottom, #ffffff 65%, #fff9ef 88%, #fff6e7);">
    <div class="container" >
      <!-- more text -->
      <section class="row d-flex justify-content-around mt-36">
        <article v-for="item in settings.secondary_text" class="col-12 col-md-4 d-flex flex-column mb-gutter mb-md-0">
          <div class="d-flex flex-column flex-column-reverse mb-12">
            <h1 class="h1--hero h1--hero--smallest">{{item.h1}}</h1>
            <h2 class="h2--hero h2--hero--smallest mb-2">{{item.h2}}</h2>
          </div>
          <p class="">{{item.paragraph}}</p>
        </article>
      </section>
      <div class="row mt-md-100 text-md-center">
        <div class="col-12 col-md-6 mx-auto">
          <div class="d-flex flex-column flex-column-reverse mb-12">
            <h1 class="h1--hero  h1--hero--smallest">{{settings.last_text.h1}}</h1>
            <h2 class="h2--hero h2--hero--smallest mb-2">{{settings.last_text.h2}}</h2>
          </div>
          <p class=""><a :href="settings.last_text.href">{{settings.last_text.paragraph}}</a></p>
        </div>
      </div>
    </div>
    </div>
    <component
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class=" p-footer-has-before-image"
    ></component>
  </div>
</template>
<script type="text/javascript">

  import TypeaheadDropdownLink from '../TypeaheadDropdownLink.vue';
  import staticData from '../../store/data';
  import utils from '../../store/utils';
  import getQueryStringLibrary from 'tembo-js/getQueryString';

  // console.log(staticData)
  module.exports = {
    components: { TypeaheadDropdownLink },
    props: {
      settings: {
        type: Object,
        default: () => ({}),
        validator: obj => {
          // Props mapped from implementation mapping and config file (/assets/config/Finder.yml).
          const expectedProps = ['page_title', 'hero_text', 'hero_h1', 'hero_h2', 'hero_img_src',
            'hero_img_mobile_src', 'hero_img_alt'];
          const missingProps = expectedProps.filter(prop => !obj.hasOwnProperty(prop));
          if (staticData.homepage.config.defaults.debugger) {
            console.log(`Missing props from HomeHeroDetails: ${missingProps}`); // eslint-disable-line no-console, max-len
          }
          return true;
        }
      },
      layout: {
        type: Object
      },
    },
    data() {
      return {
        selectedEntity: null,
        debug: this.$store.state.debug,
      };
    },
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    },
    computed: {
      entityUrl() {
        if (!this.selectedEntity) return '';
        return utils.getEntityUrl(this.selectedEntity, this, this.settings.entity_url_template); // eslint-disable-line max-len
      },
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      lineBreakJoin: utils.lineBreakJoin,
      getTextToRender: function getTextToRender(prop, placeholder) {
        const textToRender = (this.settings[prop]) || (this.debug ? `[${prop}]` : placeholder);
        return Array.isArray(textToRender) ? this.lineBreakJoin(textToRender) : textToRender;
      }
    }
  };
</script>
<style type="text/css" lang="scss">
  $dark-brown: #4c4141;
  $scarlet: #c22b2c;
  $bright-orange: #ff4747;
  $light-peach: #ffd89f;
  .home-img {
    width: 33.33%;
    & img{
      width: 100%;
    }
  }
  .data-separator{
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    width: 24px;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    &__dot{
      width: 4px;
      height: 4px;
      background-color: #bbbbbb;
      border-radius: 50%;
    }
  }
  .home-images{
    border-top: solid 7px;
    border-image: linear-gradient(to right, $dark-brown 25%, $scarlet 25%, $scarlet 50%, $bright-orange 50%, $bright-orange 75%, $light-peach 75%) 5;
  }
</style>