<template>
  <modal-component class="p-0 intro-modal modal--fullscreen c-modal--full-screen c-modal__mask" :show="show" :click-away="false" :class="settings.show_header_in_intro_modal  ? 'intro-modal--has-header' : ''" :container-class="'c-modal--cta'">
  <header class="header p-header--static p-0 p-header" v-if="settings.show_header_in_intro_modal">
    <div
      :is="layout.header.tag"
      :settings="layout.header.data"
      :layout="layout.header.layout"
      :class="layout.header.tag"
    ></div>
  </header>
    <div class="container" style="margin-bottom: 150px;" :class="nameSectionVisible ? 'unscroll' : ''">
      <div class="row my-5">
        <!-- WELCOME -->
        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3"><img :src="settings.welcome_image_url" :alt="settings.welcome_image_alt_text || ''" class="mb-4 mx-auto d-block" style="max-height: 300px"></div>
        <div class="col-lg-12 ">

          <p class="text-center mb-3 c-modal--cta__p">{{settings.short_text}}</p>
          <h1 class="text-center c-modal--cta__title">{{settings.long_text}}</h1>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6">
          <div class="intro-modal__section-close" v-show="addressSectionVisible" v-on:click="closeSection('address')"><modal-close-svg></modal-close-svg></div>
          <div class="c-card h-100" v-on:click="showSection('address')" :class="addressSectionVisible ? '' : 'intro-modal__hide-contents'">
            <h2 class="text-center ">{{settings.main_prompt_text}}</h2>
            <div :class="addressSectionVisible ? '' : 'mt-3 d-none d-sm-block intro-modal__hide-contents'">
              <div
                v-if="layout.address_entry"
                :is="layout.address_entry.tag"
                :settings="layout.address_entry.data"
                :layout="layout.address_entry.layout"
                class="mb-4 mt-3"
              ></div>

                <div
                  v-if="layout.grade_filter"
                  :is="layout.grade_filter.tag"
                  :settings="layout.grade_filter.data"
                  :layout="layout.grade_filter.layout"
                  class="mb-sm-4"
                ></div>

            </div>
            <div class="mobile-cta" :class="mobileSlideClass" v-if="!settings.address_entry_submit">
              <button v-on:keyup.13="handleViewSchoolsClick" class="c-button w-100" :class="btnActive" v-on:click="handleViewSchoolsClick">{{settings.view_schools_text}}</button>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <div class="col-sm-6 mt-gutter mt-sm-0" :class="nameSectionVisible ? 'mobile-fullscreen' :''">
          <div class="intro-modal__section-close" v-show="nameSectionVisible" v-on:click="closeSection('name')"><modal-close-svg></modal-close-svg></div>
          <div class="c-card h-100" v-on:click="showSection('name')">
            <h2 class="text-center">{{settings.school_lookup_text}}</h2>
             <div
              v-if="layout.school_search"
              :is="layout.school_search.tag"
              :settings="layout.school_search.data"
              :layout="layout.school_search.layout"
              :entities-list="entities"
              :class="nameSectionVisible ? 'mt-3 ' : 'mt-3 d-none d-sm-block intro-modal__hide-contents'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import ModalCloseSvg from '../../assets/ModalCloseSvg.vue';
  import ModalComponent from './Modal.vue';
  var ModalMixin = require('../../mixins/ModalMixin');
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: ['settings', 'layout', 'show'],
    components: { ModalCloseSvg, ModalComponent },
    mixins: [ModalMixin],
    mounted: function mounted() {
      //
      // hide this modal automatically if the requirements are already satisfied
      //
      var show;
      var bothSatisfied = this.gradeIsSatisfied && this.addressIsSatisfied;
      var oneSatisfied = this.gradeIsSatisfied || this.addressIsSatisfied;
      if (bothSatisfied || (this.settings.address_grade_optional && oneSatisfied)) {
        show = false;
      } else {
        show = true;
      }
      if (show !== this.show) {
        this.$emit('update:show', show);
      }
    },
    data: function data() {
      return {
        addressSectionVisible: false,
        nameSectionVisible: false
      };
    },
    computed: {
      home: function home() {
        return this.$store.state.home;
      },
      entities: function entities() {
        if (this.layout.school_search.data.entities) return this.layout.school_search.data.entities;
        if (!this.$store.getters.entitySet) return [];
        return this.$store.getters.entitySet;
      },
      gradeFilter: function gradeFilter() {
        return this.$store.getters.filterSet.grade_levels;
      },
      gradeIsSatisfied: function gradeIsSatisfied() {
        var gradeSatisfied = this.settings.grade_required;
        var i;
        var l;
        var gradeFilter;

        gradeSatisfied = gradeSatisfied !== undefined && !gradeSatisfied;

        if (gradeSatisfied === true) {
          return gradeSatisfied;
        }

        gradeFilter = this.gradeFilter;
        for (i = 0, l = gradeFilter.value.length; i < l; i ++) {
          if (gradeFilter.value[i].required) {
            gradeSatisfied = true;
          }
        }
        return gradeSatisfied;
      },
      addressIsSatisfied: function addressIsSatisfied() {
        var addressSatisfied = this.settings.address_required;
        addressSatisfied = addressSatisfied !== undefined && !addressSatisfied;

        if (this.home && this.home.user) addressSatisfied = true;
        return addressSatisfied;
      },
      mobileSlideClass: function mobileSlideClass() {
        if (this.addressSectionVisible && this.settings.allow_default_submit) {
          return 'mobile-slide-up';
        }
        if (this.settings.address_grade_optional) {
          return this.gradeIsSatisfied || this.addressIsSatisfied ? 'mobile-slide-up' : '';
        }
        return this.gradeIsSatisfied && this.addressIsSatisfied ? 'mobile-slide-up' : '';
      },
      btnActive: function btnActive() {
        if (this.settings.allow_default_submit) {
          return '';
        }
        if (this.settings.address_grade_optional) {
          return this.gradeIsSatisfied || this.addressIsSatisfied ? '' : 'c-button--disabled'; // eslint-disable-line
        }
        return this.gradeIsSatisfied && this.addressIsSatisfied
         ? '' : 'c-button--disabled';
      }
    },
    methods: {
      showSection: function showSection(section) {
        var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content').replace(/"/g, '');
        if (breakpoint === 'smartphone') {
          if (section === 'name') {
            this.addressSectionVisible = false;
            this.nameSectionVisible = true;
            document.body.classList.add('unscroll');
          }
          if (section === 'address') {
            this.addressSectionVisible = true;
            this.nameSectionVisible = false;
          }
        }
      },
      closeSection: function closeSection(section) {
        if (section === 'name') {
          this.nameSectionVisible = false;
          document.body.classList.remove('unscroll');
        }
        if (section === 'address') {
          this.addressSectionVisible = false;
        }
      },
      handleViewSchoolsClick: function handleViewSchoolsClick() {
        var addressSatisfied = this.addressIsSatisfied;
        var gradeSatisfied = this.gradeIsSatisfied;

        if ((this.settings.address_grade_optional) && (gradeSatisfied || addressSatisfied)) {
          this.closeModal();
          sendEvent({
            category: 'intro',
            action: 'view_schools'
          });
        } else if (gradeSatisfied && addressSatisfied) {
          this.closeModal();
          sendEvent({
            category: 'intro',
            action: 'view_schools'
          });
        } else if (this.settings.allow_default_submit) {
          this.closeModal();
          sendEvent({
            category: 'intro',
            action: 'view_schools'
          });
        }
      },
    },
    watch: {
      home(curr, prev) {
        if (this.settings.address_entry_submit) {
          if (!prev.user && curr.user) {
            this.closeModal();
            sendEvent({
              category: 'intro',
              action: 'view_schools',
            });
          }
        }
      },
    },
  };
</script>
<style type="text/css" scoped>
.mobile-cta{

}
.intro-modal__section-close{
  position:  absolute;
      right: 25px;
    top: 0;
    transform: translateY(-50%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,.1), 0 4px 8px rgba(0,0,0,.1);
}
.intro-modal__section-close div{
  display: block;
      width: 34px;
    height: 34px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

</style>
<style>
  .intro-modal__section-close div circle{
  display: none;
}
</style>
