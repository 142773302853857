<!-- This component displays two rows of data below the school name -->
<!-- with up to four badges: distance -->
<template>
  <div v-if="entity" :id="id ? id : entity.id" class="p-0 c-card entity p-entity flex-shrink-0" v-bind:class="entity.computed.neighborhood ? 'entity--is-neighborhood-entity p-entity--is-neighborhood-entity' : ''">
    <div class="entity_order p-entity__order p-2 text-center"><span style="width: 3ch">{{idx}}</span></div>

    <!-- BUTTON TO CLOSE DIV -->
 <!--    <div v-if="x" v-on:click="closeItem" class="modal_close modal_close--ui"><modal-close-svg></modal-close-svg></div> -->

    <!-- SCHOOL INFO -->
    <div class="p-entity__information entity_information px-3 pb-2 pb-md-3 pt-2">
      <!-- SCHOOL NAME -->
      <div class="d-flex align-items-center">
      <h3 class="entity_name flex-grow-1"><a class="a a-ui-grow" v-bind:href="entityUrl" v-on:click="handleNameClick(entity)">{{entity.name}}</a></h3>

            <!-- FAVORITE HEART & COMPARE TOGGLE -->
      <div class="favorite-container" v-if="settings.compare || settings.favorite">
        <compare-toggle v-if="settings.compare" :checked="entity.computed.compare" :value="entity.id" text-saved="Added!" text-add="Save to comparison" text-remove="Remove from comparison"></compare-toggle>
        <favorite-heart-no-text
          v-if="settings.favorite"
          :checked="entity.computed.favorite"
          :value="entity.id"
          :disabled="favDisabled"
          @update:favorite="val => { handleHeartClick(val) }"
          :text-saved="settings.fav_success_text"
          :text-add="settings.fav_save_text"
          :text-remove="settings.fav_remove_text"
        ></favorite-heart-no-text>
      </div>


      </div>

      <!-- STREET ADDRESS -->
      <p>
        <street-address
          v-if="entity.address"
          class="entity-info"
          :entity-data="entity.address"
        ></street-address></p>

        <p class="neighborhood-tag" v-if="entity.computed.neighborhood">{{settings.neighborhood_text}}</p>

      <!-- GRADES -->
        <p class="entity-info p-entity__about sp-h-2 d-flex">

          <range-datum
            :entity-data="entity.grades"
            :universe="settings.grades_universe"
          ></range-datum>
          <text-span-datum
            :entity-data="entity.entity_type"
          ></text-span-datum>
        </p>

      <!-- BADGES -->
        <div class="entity-attributes p-entity__attributes mt-2 flex-column flex-md-row sp-v-3 sp-v-md-0">

          <distance-badge
            :settings="settings"
            :entity-data="entity.computed.distance_from_home"
          ></distance-badge>

          <div
            v-for="score in sortReportCardsByGrade(entity.report_cards)"

            :is="layout && layout.letter_grade ? layout.letter_grade.tag : 'letter-grade-badge'"
            :settings="settings"
            :entity="entity"
            :entity-data="score"
            :strong-text="settings.report_card_strong_text"
            :sub-text="entity.report_cards && entity.report_cards.length > 1 && score.grades && score.grades.length ? `(${settings.grades_text} ${displayListAsRange(score.grades)})` : ''"
          ></div>

          <!-- TODO: this doesn't do anything as a placeholder without a class @scott -->
          <div v-for="x in 2 - ((entity.report_cards && entity.report_cards.length) ? entity.report_cards.length : 0)"></div>

          <preferences-badge
            :settings="settings"
            :entity="entity"
            :entity-data="entity.computed.match_rate"
          ></preferences-badge>

        </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  var utils = require('../../store/utils');
  var ResultItemMixin = require('../../mixins/ResultItemMixin');
  var ReactiveFilterMixin = require('../../mixins/ReactiveFilterMixin');
  import CompareToggle from '../compare/CompareToggle.vue';
  import FavoriteHeartNoText from '../FavoriteHeartNoText.vue';
  import StreetAddress from '../StreetAddress.vue';
  import RangeDatum from '../RangeDatum.vue';
  import TextSpanDatum from '../TextSpanDatum.vue';
  import DistanceBadge from '../DistanceBadge.vue';
  import PreferencesBadge from '../PreferencesBadge.vue';

  module.exports = {
    components: {
      CompareToggle,
      FavoriteHeartNoText,
      StreetAddress,
      RangeDatum,
      TextSpanDatum,
      DistanceBadge,
      PreferencesBadge,
    },
    mixins: [ResultItemMixin, ReactiveFilterMixin],
    methods: {
      sortReportCardsByGrade: function sortReportCardsByGrade(reportCards) {
        //
        // CURRENTLY INDY-SPECIFIC
        // for entities that have multiple report cards
        // order report cards by relevant grade level(s)
        // i.e. K-5 report card should be listed before 6-8 report card
        //
        function sort(a, b) {
          var valA = a.grades[0];
          var valB = b.grades[0];
          if (valA[0] === 'P') return -1;
          if (valB[0] === 'P') return 1;
          if (valA[0] === 'K') return -1;
          if (valB[0] === 'K') return 1;
          if (parseInt(valA, 10) < parseInt(valB, 10)) return -1;
          if (parseInt(valA, 10) > parseInt(valB, 10)) return 1;
          return 0;
        }
        if (!reportCards) return [];
        return Array.prototype.slice.call(reportCards).sort(sort);
      },
      displayListAsRange: utils.displayListAsRange
    }
  };
</script>
<style type="text/css" scoped>
  /**{text-align: center;}*/
/*
.flex{

  display: flex;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  flex-basis: 20%;
}

.flex > div{
  flex-basis: 20%;
} */

/* .entity-info > * {
  display: inline-block;
  border-right: 1px solid grey;
  padding-right: 5px;
  padding-left: 5px;
} */
/* .entity-info > *:first-child {
  padding-left: 0;
}
.entity-info > *:last-child {
  border-right: 0;
} */

</style>