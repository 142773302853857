<template>
  <modal-component :show="show" :click-away="true" class='match-modal'>
    <div class="modal-heading c-modal__head">
      <span class="heading-target">{{settings.header_text}}</span>
      <div v-on:click="handleCancelClick" class="modal_close c-button--mobile"><i class="fal fa-times" aria-hidden="true"></i><span class="sr-only">Close</span></div>
    </div>
    <div v-if="!favorites || favorites.length === 0" class="m-3">
      <p class="text-small">{{settings.none_text}}</p>
    </div>
    <div v-if="favorites && favorites.length > 0" class="my-3 mx-3 sp-v-3">
      <div v-for="fav in favorites" class="d-flex justify-content-between align-items-center ">
        <span class="favorite-list__school-name"><a v-bind:href="entityUrl(fav)">{{fav.name}}</a></span>
        <button class="c-button  c-button--outline favorite-list__remove-toggle favorite-list__remove-toggle" v-bind:class="getDeleteClass(fav.id)" v-on:click="updateDeleteList(fav.id)"><i class='fal fa-lg fa-times'></i>{{getDeleteText(fav.id)}}</button>
      </div>
    </div>
    <div class="c-modal__footer mt-auto mb-3 mx-3">
      <div class="row">
        <div class="col-auto ml-auto">
          <button class="c-button c-button--outline" v-on:click="handleCancelClick">{{settings.cancel_text}}</button>
        </div>
        <div class="col-auto">
          <button class="c-button " v-bind:disabled="pendingDelete.length < 1" v-on:click="handleDeleteClick">{{settings.remove_text}}</button>
        </div>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  var Promise = require('bluebird');
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';
  import sendEvent from 'tembo-js/sendEvent';
  import getQueryString from 'tembo-js/getQueryString';

  module.exports = {
    components: { ModalComponent },
    mixins: [ModalMixin],
    data: function data() {
      return {
        pendingDelete: [] // array of entity ids
      };
    },
    props: ['settings', 'show'],
    computed: {
      favorites: function favorites() {
        return this.$store.state.favorites;
      }
    },
    methods: {
      entityUrl: function entityUrl(entity) {
        var url = '/schools/' + entity.id;
        if (this.$route.query.lang && this.$route.query.lang !== 'en') {
          url += getQueryString(this.$route.query);
        }
        if (entity.framework) {
          if (url.indexOf('?') > -1) {
            url += '&' + entity.framework;
          } else {
            url += '?' + entity.framework;
          }
        }
        return url;
      },
      updateDeleteList: function updateDeleteList(id) {
        var pending = this.pendingDelete;
        var idx = pending.indexOf(id);
        if (idx > -1) {
          pending.splice(idx, 1);
        } else {
          pending.push(id);
        }
        this.pendingDelete = pending;
      },
      getDeleteClass: function getDeleteClass(id) {
        var pending = this.pendingDelete;
        var idx = pending.indexOf(id);
        if (idx > -1) {
          return 'undo';
        }
        return 'delete no-border';
      },
      getDeleteText: function getDeleteText(id) {
        var pending = this.pendingDelete;
        var idx = pending.indexOf(id);
        if (idx > -1) {
          return this.settings.undo_text;
        }
        return '';
      },
      handleCancelClick: function handleCancelClick() {
        this.pendingDelete = [];
        this.closeModal();
      },
      handleDeleteClick: function handleDeleteClick() {
        var self = this;
        var boundSuccessHandler;
        function successHandler(id) {
          sendEvent({
            category: 'list',
            action: 'deleted_favorite',
            label: id
          });
        }
        boundSuccessHandler = successHandler.bind(this);
        function map(i) {
          var sessionItem = { id: i, favorite: false };
          return self.$store.dispatch('updateFavorite', sessionItem, () => {
            boundSuccessHandler(i);
          });
        }
        //
        // promise.each returns promises in sequence
        //
        return Promise.each(this.pendingDelete, map)
        .then(() => {
          self.pendingDelete = [];
        });
      }
    }
  };
</script>
<style scoped>
  .modal-note{
    color:  #71777A;
    font-size: 13px;
  }

  .undo i{
    display: none;
  }
  .no-border{
    border: 0;
  }
</style>
