var BoundaryMixin = require('./BoundaryMixin');

module.exports = {
  mixins: [BoundaryMixin],
  computed: {
    home: function home() {
      return this.$store.state.home;
    }
  }
};
