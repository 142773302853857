<template>
  <modal-component class="intro-modal modal--fullscreen p-0 c-modal--full-screen c-modal__mask" :show="show" :click-away="false" :class="settings.show_header_in_intro_modal  ? 'intro-modal--has-header' : ''" :container-class="'c-modal--cta'">

    <div class="mobile-background-wrapper flex-grow-1 d-flex flex-column">
    <header class="header mb-1 mb-md-4 header p-header--static p-0 p-header" v-if="settings.show_header_in_intro_modal">
      <div
        :is="layout.header.tag"
        :settings="layout.header.data"
        :layout="layout.header.layout"
        :class="layout.header.tag"
        :headerType="'containerWidth'"
      ></div>
    </header>
    <div class="container">
      <div class="row">
        <!-- WELCOME -->

        <div class="col-12 col-md-6 col-sm-pull-6 d-flex flex-column px-4 px-sm-0">
          <div class="flex-order-1 d-flex align-items-center">
            <h1 class="flex-grow-1 my-0 my-md-5 c-modal--cta__title">{{settings.welcome_text}}</h1> <img class="d-md-none m-0 mobile-image-clip " style="max-height: 30vw !important;" :src="settings.welcome_image_url_mobile" :alt="settings.welcome_image_alt_text || ''">
          </div>

          <p class="flex-order-3 flex-md-order-2 mt-3 mt-md-0 c-modal--cta__p">{{settings.long_text}}</p>
          <div class="row flex-order-2 flex-md-order-3 mt-4">
            <div class="col-md-9 mx-md-auto col-md-offset-1">
              <div style="position: relative; height: 39px;">
                <div
                v-if="layout.name_search"
                :is="layout.name_search.tag"
                :settings="layout.name_search.data"
                :layout="layout.name_search.layout"
                class="highlight-container"
                ></div>
              </div>
              <strong class="text-center d-block mt-3">{{settings.or_text}}</strong>
              <p class="text-center mt-3">{{settings.main_prompt_text}}</p>
              <div class="row mt-3  d-flex d-flex--row flex-md-row flex-column">
                <div class="col-12 col-sm">
                  <div
                  v-if="layout.entity_filter"
                  :is="layout.entity_filter.tag"
                  :settings="layout.entity_filter.data"
                  :layout="layout.entity_filter.layout"
                  class="h-100"

                ></div></div>
                <div class="col-12 mt-3 mt-sm-0 col-sm-auto"><button class="c-button w-100" :class="btnActive" v-on:click="handleViewSchoolsClick">{{settings.view_schools_text}}</button></div>


              </div>
            </div>
          </div>
        </div>
        <div class="col-8 offset-2 offset-sm-0 col-md-6 col-sm-push-6 img-container d-none d-md-block">
          <img class="img--responsive" :src="settings.welcome_image_url_mobile" :alt="settings.welcome_image_alt_text || ''">
        </div>

      </div>

      <div class="row row-eq-height--above-sm d-none">
        <div class="col-md-6 col-md-offset-3 center-contents">
          <div class="mobile-cta mobile-only d-sm-none">
            <div tabindex="0" v-on:keyup.13="handleViewSchoolsClick" class="button c-button" :class="btnActive" v-on:click="handleViewSchoolsClick">{{settings.view_schools_text}}</div>
          </div>
        </div>
      </div>
    </div>

      <div
        v-if="layout.footer"
        :is="layout.footer.tag"
        :settings="layout.footer.data"
        :layout="layout.footer.layout"
        :class="layout.footer.tag"
        :footerType="'containerWidth'"
        class="mt-auto"
      ></div>

    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  import ModalComponent from './Modal.vue';
  var ModalMixin = require('../../mixins/ModalMixin');


  module.exports = {
    props: ['settings', 'layout', 'show'],
    components: { ModalComponent },
    mixins: [ModalMixin],
    mounted: function mounted() {
      //
      // hide this modal automatically if the requirements are already satisfied
      //
      var show;
      if (this.filterIsSatisfied) {
        show = false;
      } else {
        document.body.className += 'unscroll';
        show = true;
      }
      if (show !== this.show) {
        this.$emit('update:show', show);
      }
    },
    computed: {
      entities: function entities() {
        if (!this.$store.getters.entitySet) return [];
        return this.$store.getters.entitySet;
      },
      filter: function filter() {
        return this.$store.getters.filterSet[this.settings.filter_key];
      },
      filterIsSatisfied: function filterIsSatisfied() {
        var filterSatisfied = false;
        var i;
        var l;
        var filter;

        if (filterSatisfied === true) {
          return filterSatisfied;
        }

        filter = this.filter;
        for (i = 0, l = filter.value.length; i < l; i ++) {
          if (filter.value[i].required) {
            filterSatisfied = true;
          }
        }
        return filterSatisfied;
      },
      btnActive: function btnActive() {
        return this.filterIsSatisfied ? '' : 'c-button--disabled c-button--outline';
      }
    },
    methods: {
      showSection: function showSection(section) {
        var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content').replace(/"/g, '');
        if (breakpoint === 'smartphone') {
          if (section === 'name') {
            this.addressSectionVisible = false;
            this.nameSectionVisible = true;
          }
          if (section === 'address') {
            this.addressSectionVisible = true;
            this.nameSectionVisible = false;
          }
        }
      },
      closeSection: function closeSection(section) {
        if (section === 'name') {
          this.nameSectionVisible = false;
        }
        if (section === 'address') {
          this.addressSectionVisible = false;
        }
      },
      handleViewSchoolsClick: function handleViewSchoolsClick() {
        if (this.filterIsSatisfied) {
          document.body.classList.remove('unscroll');
          this.closeModal();
          sendEvent({
            category: 'intro',
            action: 'view_schools'
          });
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  .alert{
    color: red;
  }
  .row + .row{
    /* margin-top: 20px; */
  }
  .linked-dropdown-menu.highlight-container{

/*     background: white;
    border:  none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.1); */
  }
  .linked-dropdown-menu.highlight-container .search-wrapper.search-container-light{
    /* border:  none; */


  }
/*   .button-padding{
    margin-left:  20px;
    padding: 10px 40px ;
    white-space: nowrap;
    display: inline-block;
        text-align: center;
        border-radius: 8px;

  } */
  strong,
  strong + div{
    /* margin-top:  1em; */
  }

</style>
