<template>


  <fieldset v-if="!!!settings.metadata.hide_disabled || enabled">
      <legend class="mb-12" v-if="settings.group_title_text">{{settings.group_title_text}}</legend>
      <rich-text
        v-if="settings.description_text"
        :text="settings.description_text"
        :tag="'p'"
      ></rich-text>
      <select-with-menu
        :options="values"
        :selected="pendingValue || requiredValue"
        :placeholder-value="settings.prompt_text"
        :disabled="!enabled"
        @update:selected="val => { handleChange(val) }"
      >
      </select-with-menu>

  </fieldset>
</template>
<script type="text/javascript">
  import SelectWithMenu from '../SelectWithMenu.vue';
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  var WrappedSelectFilterMixin = require('../../mixins/WrappedSelectFilterMixin');

  module.exports = {
    mixins: [FilterGroupMixin, WrappedSelectFilterMixin],
    components: { SelectWithMenu },
    data() {
      return {
        optionsVisible: false
      };
    }
  };
</script>
<style type="text/css" scoped>
p{
  margin-bottom:  12px;
}
</style>
