import staticData from '../store/data';
import getDataByProperty from 'tembo-js/getDataByProperty';

module.exports = {
  data() {
    return {
      staticData: staticData,
    };
  },
  computed: {
    defaultSortOption: function defaultSortOption() {
      return this.$store.getters.defaultSortOption;
    },
    selectionPermitted: function selectionPermitted() {
      var selected = this.$store.state.sort;
      var active;
      var activeEnabled;
      var i = 0;
      var l = this.settings.options.length;
      while (!active && i < l) {
        if (this.settings.options[i].value === selected) {
          active = this.settings.options[i];
        }
        i += 1;
      }
      if (active) {
        activeEnabled = this.optionEnabled(active);
      }
      return activeEnabled;
    },
    updatedOptions: function updatedOptions() {
      const enablerProp = this.enablerProp || 'enablers';
      if (this[enablerProp]) {
        return this.settings.options.map(opt => {
          const updatedOpt = JSON.parse(JSON.stringify(opt));
          updatedOpt.disabled = !!!this.optionEnabled(updatedOpt);
          return updatedOpt;
        }, this);
      }
      return this.settings.options;
    }
  },
  methods: {
    setEnablersAll: function setEnablersAll() {
      var optionsCt;
      var optionsAllCt;
      optionsCt = this.options.length;
      optionsAllCt = this.options.filter(opt => opt.enabled_by === 'all').length;
      if (optionsAllCt < optionsCt) {
        this.options = this.options.map((opt) => {
          var updatedOpt = opt;
          updatedOpt.enabled_by = 'all';
          return updatedOpt;
        });
      }
    },
    optionEnabled: function optionEnabled(option) {
      var valAtEnabler;
      const enablerProp = this.enablerProp || 'enablers';
      if (!this[enablerProp]) return true;
      if (option.enabled_by) {
        valAtEnabler = getDataByProperty(option.enabled_by, this[enablerProp]);
      }
      if (valAtEnabler) return true;
      return false;
    },
  },
  watch: {
    selectionPermitted: function watchSelectionPermitted() {
      if (!this.selectionPermitted) {
        const defaultSortOption = this.defaultSortOption;
        this.$store.dispatch('updateSort', defaultSortOption);
      }
    }
  },
};
