<template>
  <main id="main-content">
    <component
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
      class="position-relative"
      style="z-index: 1;"
    ></component>


    <!-- hero -->
    <div class="p-hero py-60 py-md-80 mb-5" >
      <div class="container h-100">
        <!-- HEADER -->
        <div class="row">
          <div class="col-12">
            <h1 class="p-hero__h1 mb-5 text-center">{{ getTextToRender('hero_h1', 'What’s your story?') }}</h1>
          </div>
        </div>

        <!-- FIND ROUTES -->
        <div class="row" v-if="settings.find_routes">
          <div class="col-12 p-hero-links d-flex flex-column flex-md-row justify-content-around  my-5 sp-v-60 sp-v-md-0">

            <div v-for="route in settings.find_routes" class="d-flex align-items-center flex-column text-center">
              <h2 class="p-hero__h2 mb-40" v-html="lineBreakJoin(route.prompt_text)"></h2>
              <a class="p-hero__button mt-auto" :href="route.href + getQueryString($route.query)"><i v-if="route.icon" class="mr-2 fas" :class="'fa-' + route.icon"></i>{{lineBreakJoin(route.link_text)}}</a>
            </div>

          </div>
        </div>

      </div>
    </div>

    <!-- everything else -->
    <div class="container mt-5">

      <!-- search prompt -->
      <div class="row">
        <div class="col-12 text-center d-flex flex-column flex-column-reverse">
          <h3 class="p-hero__h3">{{settings.search_h1}}</h3>
          <p class="p-hero__h3__tagline">{{settings.search_h2}}</p>
        </div>
      </div>

      <!-- search -->
      <div class="row mt-20 mb-md-60">
        <div class="col-12 col-md-6 offset-md-3 mb-60">
          <div class="d-flex" v-if="settings.search">
            <typeahead-dropdown-link class="flex-grow-1"
              :placeholder="settings.search.placeholder"
              :options="settings.search.options"
              :link-template="settings.entity_url_template"
              :no-results="settings.search.no_results"
              :hero="true"
              :dropdown-type="'home_school_search'"
            ></typeahead-dropdown-link>
          </div>
        </div>
      </div>

      <!-- content -->
      <section class="row mt-3 mt-md-80" >
        <article class="col-12 col-md-7">

          <div class="d-flex flex-column flex-column-reverse mb-4">
            <h1 class="h1--hero">{{settings.content_h1}}</h1>
            <h2 class="h2--hero mb-2">{{settings.content_h2}}</h2>
          </div>
          <p v-for="(paragraph, index) in settings.content_paragraphs" :class="index > 0 ? 'mt-2' : ''">{{paragraph}}</p>
        </article>

        <div class="col-12  col-md-5" v-if="settings.content_image">
          <picture class="m-20 d-block c-image--branding">
            <img :src="settings.content_image" :alt="settings.content_image_alt" class="img-fluid">
          </picture>
        </div>

      </section>
    </div>


    <component
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class="mt-5 mt-md-80"
    ></component>

  </main>
</template>

<script type="text/javascript">
  import TypeaheadDropdownLink from '../TypeaheadDropdownLink.vue';
  import staticData from '../../store/data';
  import utils from '../../store/utils';
  import getQueryStringLibrary from 'tembo-js/getQueryString';


  // console.log(staticData)
  module.exports = {
    components: { TypeaheadDropdownLink },
    props: {
      settings: {
        type: Object,
        default: () => ({}),
        validator: obj => {
          // Props mapped from implementation mapping and config file (/assets/config/Finder.yml).
          const expectedProps = [
            'page_title',
            'hero_text',
            'hero_h1',
            'hero_img_mobile_src',
            'hero_img_alt'
          ];
          const missingProps = expectedProps.filter(prop => !obj.hasOwnProperty(prop));
          if (staticData.homepage.config.defaults.debugger) {
            console.log(`Missing props from HomeHeroDetails: ${missingProps}`); // eslint-disable-line no-console, max-len
          }
          return true;
        }
      },
      layout: {
        type: Object
      },
    },
    data() {
      return {
        selectedEntity: null,
        debug: this.$store.state.debug,
      };
    },
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    },
    computed: {
      entityUrl() {
        if (!this.selectedEntity) return '';
        return utils.getEntityUrl(this.selectedEntity, this, this.settings.entity_url_template); // eslint-disable-line max-len
      },
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      lineBreakJoin: utils.lineBreakJoin,
      getTextToRender: function getTextToRender(prop, placeholder) {
        const textToRender = (this.settings[prop]) || (this.debug ? `[${prop}]` : placeholder);
        return Array.isArray(textToRender) ? this.lineBreakJoin(textToRender) : textToRender;
      }
    }
  };
</script>
