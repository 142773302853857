<template>
  <!-- TODO: add back v-touch:swipeleft="advanceCard" v-touch:swiperight="regressCard"
    vue-touch does not support vue 2.x
  -->
  <modal-component :show="show" :click-away="false" >
    <div tabindex="0" class="clickable modal_close_intro my-5 mx-4" v-if="pagenum < settings.intro_cards.length - 1" v-on:click="skipIntro" v-on:keyup.enter="skipIntro">
      <a class="a--arrow pull-right">{{settings.skip_text}}</a>
    </div>
    <div class="mt-4 slideshow d-flex flex-column flex-grow-1 justify-content-between">
      <div class="slideshow_deck">
        <div
          v-for="(card, index) in sortedCards"
          :id="'card-' + (index + 1)"
          class="card f-flex flex-column text-center px-4"
          v-bind:class="pagenum === index ? 'card--is-active' : 'd-none'">
          <img v-bind:src="card.image" class="mb-2">
          <h1 class="my-4">{{card.short_text}}</h1>
          <p>{{card.long_text}}</p>

          <div v-if="index===3" class="my-4">
             <check-box
              v-if="index===3"
              :value="{value: 'hideIntro', text: settings.off_text}"
              :checked="hideThisCard"
              @update:checked="val => { hideShow(val) }"
            ></check-box>
          </div>
        </div>
      </div>

      <div class="mt-5 row row-eq-height button-controls" v-if="pagenum < settings.intro_cards.length - 1">
        <div class="hidden-xs d-none d-sm-block col-sm-4 text-center align-self-center">
          <a v-if="pagenum > 0" v-on:click="regressCard" class="a--arrow-left">{{settings.previous_text}}</a>
        </div>
        <div class="col-12 col-sm-4"><button class="c-button button button--active button--full w-100" v-on:click="advanceCard">{{settings.next_text}}</button></div>
      </div>


      <div class="row button-controls mt-5" v-if="pagenum===settings.intro_cards.length-1">

        <div class="col-12 col-sm-6 offset-sm-3"><button class="c-button button--active w-100" v-on:click="handleOkClick">{{settings.start_text}}</button></div>
      </div>



      <div class="control mx-auto d-inline sp-h-4 my-4">
        <i
          v-for="(card, index) in settings.intro_cards"
          v-on:click="goToCard(index)"
          class="fas fa-circle fa-sm control_item"
          v-bind:class="pagenum === index ? 'color--brand' : 'color--disabled'">
        </i>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';


  module.exports = {
    components: { ModalComponent },
    mixins: [ModalMixin],
    props: ['show', 'settings'],
    data: function data() {
      return {
        pagenum: 0,
        hideThisCard: null
      };
    },
    computed: {
      sortedCards: function sortedCards() {
        if (!this.settings.intro_cards) return [];
        return this.settings.intro_cards.sort((a, b) => a.idx - b.idx);
      }
    },
    methods: {
      goToCard: function goToCard(idx) {
        this.pagenum = idx;
        return idx;
      },
      advanceCard: function advanceCard() {
        if (this.pagenum < this.settings.intro_cards.length - 1) {
          this.goToCard(this.pagenum + 1);
        }
      },
      regressCard: function regressCard() {
        if (this.pagenum > 0) {
          this.goToCard(this.pagenum - 1);
        }
      },
      skipIntro: function skipIntro() {
        this.hideThisCard = true;
        this.closeIntroCards();
        sendEvent({
          category: 'intro',
          action: 'skip_intro',
          label: this.pagenum + 1
        });
      },
      closeIntroCards: function closeIntroCards() {
        var showThisCard = !this.hideThisCard;
        this.$store.dispatch('saveShowIntroToSession', showThisCard);
        this.pagenum = 0;
        this.closeModal();
      },
      handleOkClick: function handleOkClick() {
        sendEvent({
          category: 'intro',
          action: 'view_schools'
        });
        this.closeIntroCards();
      },
      hideShow: function hideShow(hideMe) {
        var label;
        this.hideThisCard = hideMe;
        if (this.hideThisCard) label = 'hide_next_time';
        else label = 'show_next_time';
        sendEvent({
          category: 'intro',
          action: 'hide_show',
          label: label
        });
      }
    },
    mounted: function mounted() {
      this.hideThisCard = !this.$store.state.showIntro;
      function changeCard(ev) {
        var backArrowKey = 37;
        var fwdArrowKey = 39;
        if (this.show && ev.keyCode === backArrowKey) {
          this.regressCard();
        } else if (this.show && ev.keyCode === fwdArrowKey) {
          this.advanceCard();
        }
      }
      document.addEventListener('keyup', changeCard.bind(this));
    },
    watch: {
      show: function watchShow() {
        if (!this.show) this.pagenum = 0;
      }
    }
  };
</script>
<style scoped>
.card h1{
    font-family: brandon-grotesque,sans-serif;
    color: #90949c;
    font-weight: 200;
    text-transform: none;
    font-size: 21px;
    line-height: 32px;

}
.card p{
  color: #90949c;
}
.card img{
  width: auto;
    height: 20vmin;
    max-height: 150px;
}
</style>