<template>
  <modal-component :show="show" :click-away="true">
     <button v-on:click="closeModal" class="align-self-end c-modal__close modal_close c-button--mobile"><i class="fal fa-times" aria-hidden="true"></i></button>


      <!-- BOUNDARY ALERT -->
    <div class="text-center" v-if="settings.dynamic && settings.dynamic.type === 'Feature'">
      <h1 class="modal-h1 mb-4">{{settings.alert_header_text}} {{settings.dynamic.properties.CORPORATIO}}</h1>
      <p class="modal-p mb-4">{{settings.alert_body_1_text}}</p>
      <p class="modal-p mb-1">{{settings.alert_body_2_text}}
      <a :href="settings.dynamic.properties.WEBADDRESS" target="_blank">{{settings.dynamic.properties.WEBADDRESS}}</a></p>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  var ModalMixin = require('../../mixins/ModalMixin');
  module.exports = {
    components: { ModalComponent },
    mixins: [ModalMixin],
    props: ['show', 'settings']
  };
</script>