<template>
  <table class="result-table table--results">
    <!-- header -->
    <thead>
      <tr>
        <th v-for="col in filteredColumns" scope="col" style="cursor: default;">{{col.col_header}}</th>
      </tr>
    </thead>
    <!-- body -->
    <tbody>
      <!-- results -->
      <template v-if="entitiesLoaded && pageXEntities.length">
        <tr v-for="entity in pageXEntities">
          <th v-if="settings.include_compare_column && entity.entity_type === entity.entity_compare_prop" class="d-flex justify-content-center align-items-center table--results__compare ">
            <compare-checkbox
              :checked="entity.computed.compare"
              :value="entity.id"
              @update:compare="val => { handleCompareToggle(val, entity) }"
              text-saved="Saved"
              text-add="Add"
              text-remove="Remove"
              class="d-md-inline-block"
            ></compare-checkbox>
          </th>
          <template v-for="col in filteredColumns">
            <result-datum-simple
              v-if="!col.is_compare_column"
              :key="col.id"
              :settings="col"
              :entity="entity"
            ></result-datum-simple>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script type="text/javascript">
  import flattenDeep from 'lodash/flattenDeep';
  const PaginationMixin = require('../mixins/PaginationMixin');
  import compares from '../mixins/ResultItemMixin';
  import arraysIncludeOrOverlap from 'tembo-js/arraysIncludeOrOverlap';
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import flattenByProperty from 'tembo-js/flattenByProperty';
  import CompareCheckbox from './compare/CompareCheckbox.vue';
  import ResultDatumSimple from './ResultDatumSimple.vue';

  module.exports = {
    components: {
      CompareCheckbox,
      ResultDatumSimple,
    },
    props: ['settings', 'layout', 'sorts'],
    mixins: [PaginationMixin, compares],
    data: function data() {
      const dataObj = {};
      const columns = this.settings.columns;
      if (columns) {
        dataObj.dependentColumns = columns.filter(col => col.conditional_column);
      }
      return dataObj;
    },
    computed: {
      breakpoint: function breakpoint() {
        return this.$store.state.breakpoint;
      },
      filters: function filters() {
        return this.$store.getters.filterSet;
      },
      entitiesLoaded: function entitiesLoaded() {
        return this.$store.state.entitiesLoaded;
      },
      columnDependencies: function columnDependencies() {
        if (!this.settings.columns || !this.settings.columns.length) {
          return [];
        }
        const dependentColumns = this.dependentColumns;
        const dependentColumnsHash = {};
        for (let i = 0, l = dependentColumns.length; i < l; i ++) {
          const col = dependentColumns[i];
          const condition = col.conditional_column;
          let dependency = getDataByProperty(condition.source, this);
          dependency = dependency.filter(d => d[condition.filter_by.field] === condition.filter_by.value); // eslint-disable-line max-len
          dependency = flattenByProperty(condition.flatten_by, dependency);
          dependency = flattenDeep(dependency);
          dependentColumnsHash[col.id] = dependency;
        }
        return dependentColumnsHash;
      },
      filteredColumns: function filteredColumns() {
        let filtered;
        const sort = this.$store.state.sort;
        const columns = this.settings.columns;
        if (!columns || !columns.length) filtered = [];
        else {
          const columnDependencies = this.columnDependencies;
          filtered = columns.filter(col => {
            if (col.conditional_column) {
              const condition = col.conditional_column;
              return arraysIncludeOrOverlap(columnDependencies[col.id], condition.allowed_value); // eslint-disable-line max-len
            }
            return true;
          }).map(col => {
            if (sort) {
              const appSort = sort;
              const newCol = col;
              if (col.entity_prop === appSort.field) {
                newCol.sort_direction = appSort.direction;
              }
              return newCol;
            }
            return col;
          }, this);
        }
        return filtered;
      }
    },
    methods: {
      handleCompareToggle: function handleCompareToggle(compared, entity) {
        //
        // currently assumes compare will never be auth-based
        //
        let limitReached = false;
        let maximum;
        let sessionItem;
        //
        // set default for compare maximum
        //
        if (this.settings.compare_maximum) {
          maximum = this.settings.compare_maximum;
        } else {
          maximum = 3;
        }
        if (this.compares.length >= maximum) {
          limitReached = true;
        }

        if (compared && limitReached) {
          //
          // if entity has been added and limit has been reached
          // reset compare property on entity & show ribbon with limit state
          //
          this.$store.dispatch('updateShowRibbon', true);
          this.$store.dispatch('updateRibbonError', true);
          sessionItem = { id: entity.id, compare: true, name: entity.name };
          this.$store.dispatch('updateCompare', sessionItem)
          .then(() => {
            setTimeout(() => {
              sessionItem.compare = false;
              //
              // remove item from compare list
              //
              this.$store.dispatch('updateCompare', sessionItem);
            }, 300);
          });
        } else {
          sessionItem = { id: entity.id, compare: compared, name: entity.name };
          if (compared) {
            //
            // if entity has been saved and is not past the limit, add to compares & show ribbon
            //
            this.$store.dispatch('updateShowRibbon', true);
          } else {
            //
            // if removing an entity from the compare list, hide the ribbon (if not hidden already)
            //
            this.$store.dispatch('updateShowRibbon', false);
          }
          this.$store.dispatch('updateCompare', sessionItem);
        }
      },
      colVisible: function colVisible(col) {
        if (this.breakpoint === 'smartphone') {
          if (col.is_row_header || col.visible_mobile) {
            return true;
          }
          return false;
        }
        return true;
      },
    }
  };
</script>
