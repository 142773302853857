import arraysIncludeOrOverlap from 'tembo-js/arraysIncludeOrOverlap';
import getDataByProperty from 'tembo-js/getDataByProperty';
import flattenByProperty from 'tembo-js/flattenByProperty';

module.exports = {
  computed: {
    filters: function filters() {
      return this.$store.getters.filterSet;
    },
    superFilters: function superFilters() {
      var superReqs = {};
      var i;
      var l;
      var key;
      var filter;
      var keys;
      var filters = this.filters;
      if (!filters) return null;
      keys = Object.keys(filters);
      for (i = 0, l = keys.length; i < l; i ++) {
        key = keys[i];
        filter = filters[key];
        if (filter.metadata.super_filter) {
          superReqs[key] = filters[key];
        }
      }
      return superReqs;
    },
    superFilterSelections: function superFilterSelections() {
      var superFilterReqs;
      if (!this.superFilters) return null;
      superFilterReqs = this.getSuperFilterReqs(this.superFilters);
      return superFilterReqs;
    },
    superFiltersRelevant: function superFiltersRelevant() {
      const keys = this.superFilterKeys;
      for (let i = 0, l = keys.length; i < l; i ++) {
        const key = keys[i];
        if (this.settings[key]) return true;
        for (let j = 0, k = this.settings.value.length; j < k; j ++) {
          const val = this.settings.value[j];
          if (val[key]) return true;
        }
      }
      return false;
    },
    superFilterKeys: function superFilterKeys() {
      if (!this.superFilterSelections) return [];
      return Object.keys(this.superFilterSelections);
    },
    enabled: function enabled() {
      // allows a filter group to be disabled but not removed
      // based on an arbitrary property that it has access to
      var enabler;
      var result;
      if (!this.settings) return null;
      if (!this.settings.enabled_by) return true;
      enabler = this.settings.enabled_by;
      result = !!getDataByProperty(enabler, this);
      return result;
    },
    enabler: function enabler() {
      if (!this.settings) return null;
      if (!this.settings.enabled_by) return null;
      let filterEnabler = this.settings.enabled_by;
      filterEnabler = getDataByProperty(filterEnabler, this);
      return filterEnabler;
    },
  },
  methods: {
    getSuperFilterReqs: function getSuperFilterReqs(filters) {
      const superReqs = {};
      const keys = Object.keys(filters);
      for (let i = 0, l = keys.length; i < l; i ++) {
        let reqs;
        const key = keys[i];
        const filter = filters[key];
        if (filter.metadata.super_filter) {
          if (Array.isArray(filter.value)) {
            reqs = JSON.parse(JSON.stringify(filter.value)).filter(v => v.required);
          } else {
            reqs = filter.value;
          }
          if (reqs && reqs.length) {
            superReqs[key] = JSON.parse(JSON.stringify(filters[key]));
            superReqs[key].value = reqs;
          }
        }
      }
      if (Object.keys(superReqs).length === 0) return null;
      return superReqs;
    },
    filterToRenderable: function filterToRenderable(list) {
      var filteredList;
      filteredList = list.filter(function f(item) {
        return this.isRenderable(item);
      }, this);
      return filteredList;
    },
    isRenderable: function isRenderable(item) {
      var key;
      var superFilter;
      var superFilterValues;
      var i;
      var l;
      for (i = 0, l = this.superFilterKeys.length; i < l; i ++) {
        key = this.superFilterKeys[i];
        superFilter = this.superFilterSelections[key];
        // TODO:
        // assumes superfilter & filter values are arrays
        if (superFilter.value) {
          if (Array.isArray(superFilter.value) && superFilter.value.length === 0) {
            // pass
          } else {
            superFilterValues = flattenByProperty('value', superFilter.value);
            if (superFilterValues.indexOf('all') > -1) return true;
            if (superFilterValues.indexOf('any') > -1) return true;
            if (item[key]) {
              if (!arraysIncludeOrOverlap(item[key], superFilterValues)) return false;
            } else if (item.data && item.data[key]) {
              if (!arraysIncludeOrOverlap(item.data[key], superFilterValues)) return false;
            }
          }
        }
      }
      return true;
    }
  },
  mounted: function mounted() {
    if (this.settings !== undefined && !this.enabled) {
      this.$store.dispatch('resetOneFilter', {
        filter: this.settings,
        applyDefaults: false,
        updateEntities: true,
      });
    }
  },
  watch: {
    enabled: function enabled() {
      //
      // when this filter is no longer enabled
      // empty this filter of selections and
      // do not make any default selections
      //
      if (!this.enabled) {
        this.$store.dispatch('resetOneFilter', {
          filter: this.settings,
          applyDefaults: false,
          updateEntities: true,
        });
      } else {
        this.$store.dispatch('resetOneFilter', {
          filter: this.settings,
          applyDefaults: true,
          updateEntities: true,
        });
      }
    },
    enabler: {
      handler() {
        if (this.enabled) {
          this.$store.dispatch('resetOneFilter', {
            filter: this.settings,
            applyDefaults: true,
            updateEntities: true,
          });
        }
      },
      deep: true
    }
  }
};
