<template>
  <div>
    <div
      class="results-status--item"
      :is="layout.results_status.tag"
      :settings="layout.results_status.data"
      :layout="layout.results_status.layout"
    >
    </div>
    <div
      class="results-status--item"
      :is="layout.status_info.tag"
      :settings="layout.status_info.data"
      :layout="layout.status_info.layout"
    >
    </div>
  </div>
</template>

<script>
export default {
  props: ['settings', 'layout']
};
</script>
<style>
  .results-status--wrapper{
    margin: 10px 0;
  }
  .results-status--item{
    display: inline-block;
  }
</style>
