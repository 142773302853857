<template>
  <div class="" v-bind:class="mobileViewClass">
      <header>
        <a href="#main-content" class="sr-only">Skip to main content</a>
        <component
          v-for="(header, index) in renderableLayout.header"
          :key="index"
          :is="header.tag"
          :settings="header.data"
          :layout="header.layout"
        ></component>
      </header>

    <div class="container mt-80 mb-100 pb-100">

      <!-- FIND ROUTES -->
      <div class="row" v-if="settings.find_routes">
        <div class="col-12 p-hero-links d-flex flex-column flex-md-row justify-content-around align-items-center mt-80 mb-100 sp-v-gutter sp-v-md-0">
          <div v-for="route in settings.find_routes" class="d-flex align-items-center">

            <img class="d-none d-md-block" :srcset="route.img_srcset"
              :src="route.img_src"
              :alt="route.img_alt_text" width="110px" height="110px" style="margin-right: 40px;">

            <div class="text-center text-md-left">
              <p class="p-hero-links__title">{{route.prompt_text}}</p>
              <a class="p-hero-links__link" :href="route.href + getQueryString($route.query)" v-html="route.link_text"></a>
            </div>
          </div>

        </div>
      </div>

      <section class="row">
        <article class="col-12 col-md-10 d-flex flex-column offset-md-1">
          <aside class="row mt-100 justify-content-around">
            <div class="col-12 col-md-8">
              <img class="mx-auto d-block" :src="settings.state_page.block_a_img_src" alt="" width="206px" height="160px">
              <h1 class="!h3--hero mt-2 text-center">{{ settings.state_page.block_a_title }}</h1>
              <p class="text-center mt-12">{{ settings.state_page.block_a_description }}</p>
            </div>
          </aside>
          <aside class="row mt-gutter mt-md-80 justify-content-between">
            <div class="col-12 col-md-5 ">
              <img class="mx-auto d-block" :src="settings.state_page.block_b_img_src" alt="" width="188px" height="188px">
              <!-- TODO: make dynamic -->
              <h2 class="text-center mt-3">
                <a :href="settings.state_page.block_b_link" class="!p-hero-links__link text-center">{{ settings.state_page.block_b_title }}</a>
              </h2>
              
              <p class="text-center mt-12">{{ settings.state_page.block_b_description }}</p>
            </div>
            <div class="col-12 mt-5 mt-md-0 col-md-5">
              <img class="mx-auto d-block" :src="settings.state_page.block_c_img_src" alt="" width="188px" height="188px">
              <!-- TODO: make dynamic -->
              <h2 class="text-center mt-3">
                 <a :href="settings.state_page.block_c_link" class="!p-hero-links__link text-center">{{ settings.state_page.block_c_title }}</a>
              </h2>
             
              <p class="text-center mt-12">{{ settings.state_page.block_c_description }}</p>
            </div>
          </aside>

        </article>

      </section>
    </div>
    <component
      :is="renderableLayout.footer.tag"
      :settings="renderableLayout.footer.data"
      class="mt-5 mt-md-80"
    ></component>
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../../mixins/AppMixinBase');

  // console.log(staticData)
  module.exports = {
    mixins: [AppMixinBase],
    props: {
      settings: {
        type: Object,
        default: () => ({}),
      },
      layout: {
        type: Object
      },
    },
    computed: {
      mobileViewClass: function mobileViewClass() {
        //
        // sets view/class for mobile
        //
        var view = this.$store.state.mobileView;
        view = `o-two-column-layout--mobile-${view}`;
        return view;
      },
    },
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    }
  };
</script>
