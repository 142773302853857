<template>
<label>
  <input :disabled="disabled" type="checkbox" class="c-checkbox c-checkbox--lg sr-only" v-model="localChecked">
    <span class="c-checkbox__visual"></span>
</label>

</template>
<script type="text/javascript">
  var $ = require('jquery');
  global.jQuery = $;
  require('bootstrap/js/tooltip');

  module.exports = {
    props: ['value', 'checked', 'disabled', 'textSaved', 'textAdd', 'textRemove'],
    data: function data() {
      return {
        autoHide: true
      };
    },
    computed: {
      localChecked: {
        get: function get() {
          return this.checked;
        },
        set: function set(newval) {
          this.$emit('update:compare', newval);
        }
      }
    },
    methods: {
      toggleCompare: function toggleCompare() {
        this.$emit('update:compare', !this.checked);
      }
    },
    watch: {
      checked: function watchChecked() {
        // this.toggleCompare();
      }
    }
  };
</script>
<style scoped>
  button{
    background: transparent;
  }
  label{
    line-height: 18px;
    vertical-align: middle;
  }
</style>