<template>
  <span>{{streetAddressOneLine}}</span>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['entityData'],
    computed: {
      streetAddressOneLine: function streetAddressOneLine() {
        var firstHalf = [this.entityData.street || null, this.entityData.city || null]
          .filter(item => item !== null);
        var secondHalf = [this.entityData.state || null, this.entityData.postal_code || null]
          .filter(item => item !== null);
        if (firstHalf.length && secondHalf.length) {
          return `${firstHalf.join(' ')}, ${secondHalf.join(' ')}`;
        }
        if (firstHalf.length) {
          return firstHalf.join(' ');
        }
        return secondHalf.join(' ');
      }
    }
  };
</script>