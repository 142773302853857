<template>
<svg width="176px" height="16px" viewBox="0 0 176 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
    <!-- Generator: Sketch 3.3.3 (12081) - http://www.bohemiancoding.com/sketch -->
    <title>greyscale-regular</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="greyscale-regular" sketch:type="MSLayerGroup">
            <g id="Imported-Layers-Copy-3" transform="translate(67.000000, 0.127273)" fill="#757575" sketch:type="MSShapeGroup" class="p-footer__contrast--fill">
                <path d="M62.7893333,4.66666667 C62.0506667,4.66666667 61.0226667,5.18866667 60.7326667,6 L60.6746667,6 L60.6746667,4.77533333 L59.4,4.77533333 L59.4,11.8726667 L60.7326667,11.8726667 L60.7326667,8.05666667 C60.7326667,6.75266667 61.66,5.98533333 62.572,5.98533333 C62.862,5.98533333 63.094,6.014 63.282,6.08666667 L63.7893333,4.84133333 C63.514,4.72533333 63.224,4.66666667 62.7893333,4.66666667" id="Fill-1"></path>
                <path d="M86.8006667,1.33333333 L88.1333333,1.33333333 L88.1333333,11.8726667 L86.8006667,11.8726667 L86.8006667,1.33333333 Z" id="Fill-2"></path>
                <path d="M82.658,4.66666667 C81.2246667,4.66666667 79.8913333,5.46733333 79.8913333,6.71733333 C79.8913333,7.926 81.1,8.47333333 81.732,8.628 L83.164,8.96533333 C83.8946667,9.148 84.146,9.52733333 84.146,9.87866667 C84.146,10.454 83.5286667,10.8306667 82.728,10.8306667 C81.8993333,10.8306667 81.184,10.4266667 80.8193333,9.53733333 L79.6666667,10.0153333 C80.074,11.016 81.0566667,12 82.7146667,12 C84.33,12 85.4666667,11.0586667 85.4666667,9.83666667 C85.4666667,8.99333333 84.892,8.15066667 83.5286667,7.82733333 L82.1253333,7.504 C81.5773333,7.378 81.2126667,7.13933333 81.2126667,6.70333333 C81.2126667,6.14133333 81.8853333,5.836 82.616,5.836 C83.29,5.836 83.9633333,6.10333333 84.2306667,6.74933333 L85.354,6.286 C84.9326667,5.232 83.852,4.66666667 82.658,4.66666667" id="Fill-3"></path>
                <path d="M92.5786667,10.8013333 C91.904,10.8013333 91.0713333,10.454 91.0713333,9.724 C91.0713333,9.092 91.6793333,8.40133333 93.042,8.40133333 C94.138,8.40133333 94.6666667,8.81066667 94.6666667,8.81066667 C94.6666667,9.93466667 93.604,10.8013333 92.5786667,10.8013333 M92.8593333,4.66666667 C91.1313333,4.66666667 90.158,5.67266667 89.9186667,6.30466667 L91.0986667,6.81066667 C91.338,6.16466667 92.1706667,5.86466667 92.8873333,5.86466667 C93.8566667,5.86466667 94.6666667,6.46666667 94.6666667,7.558 L94.6666667,7.75533333 C94.3993333,7.60066667 93.7446667,7.33333333 92.8173333,7.33333333 C91.202,7.33333333 89.7013333,8.13666667 89.7013333,9.696 C89.7013333,11.1286667 90.9626667,12 92.3673333,12 C93.548,12 94.2306667,11.364 94.6106667,10.788 L94.6666667,10.788 L94.6666667,11.8726667 L95.922,11.8726667 L95.922,7.61666667 C95.922,5.678 94.5453333,4.66666667 92.8593333,4.66666667" id="Fill-4"></path>
                <path d="M67.3226667,10.8013333 C66.6486667,10.8013333 65.8153333,10.454 65.8153333,9.724 C65.8153333,9.092 66.424,8.40133333 67.7866667,8.40133333 C68.882,8.40133333 69.4113333,8.81066667 69.4113333,8.81066667 C69.4113333,9.93466667 68.3486667,10.8013333 67.3226667,10.8013333 M67.604,4.66666667 C65.876,4.66666667 64.902,5.67266667 64.6633333,6.30466667 L65.8433333,6.81066667 C66.0826667,6.16466667 66.9153333,5.86466667 67.632,5.86466667 C68.6013333,5.86466667 69.4113333,6.46666667 69.4113333,7.558 L69.4113333,7.75533333 C69.144,7.60066667 68.4886667,7.33333333 67.5613333,7.33333333 C65.946,7.33333333 64.446,8.13666667 64.446,9.696 C64.446,11.1286667 65.7073333,12 67.112,12 C68.292,12 68.9753333,11.364 69.3546667,10.788 L69.4113333,10.788 L69.4113333,11.8726667 L70.6666667,11.8726667 L70.6666667,7.61666667 C70.6666667,5.678 69.29,4.66666667 67.604,4.66666667" id="Fill-5"></path>
                <path d="M103.398,7.496 C103.654667,6.49266667 104.425333,5.86466667 105.396,5.86466667 C106.894,5.86466667 107.407333,6.9 107.45,7.496 L103.398,7.496 M105.424,4.66666667 C103.37,4.66666667 102,6.38 102,8.33333333 C102,10.4126667 103.426667,12 105.566667,12 C107.179333,12 108.206667,11.1006667 108.62,10.1173333 L107.45,9.64 C107.079333,10.5113333 106.308667,10.8013333 105.538,10.8013333 C104.454,10.8013333 103.384,10.0333333 103.312667,8.58 L108.834,8.58 C108.834,8.58 108.848667,8.43133333 108.848667,8.33333333 C108.848667,6.11333333 107.507333,4.66666667 105.424,4.66666667" id="Fill-6"></path>
                <path d="M75.778,4.66666667 C74.8073333,4.66666667 73.9093333,5.23133333 73.5326667,5.88266667 L73.4746667,5.88266667 L73.4746667,4.77533333 L72.2,4.77533333 L72.2,11.8726667 L73.5326667,11.8726667 L73.5326667,8.07 C73.5326667,6.984 74.2426667,5.88266667 75.4013333,5.88266667 C76.4153333,5.88266667 77.154,6.37533333 77.154,7.85266667 L77.154,11.8726667 L78.4866667,11.8726667 L78.4866667,7.65066667 C78.4866667,5.898 77.5886667,4.66666667 75.778,4.66666667" id="Fill-7"></path>
                <path d="M99.3993333,2.66666667 L98.0666667,2.66666667 L98.0666667,4.77533333 L96.8206667,4.77533333 L96.8206667,6.00266667 L98.0666667,6.00266667 L98.0666667,9.726 C98.0666667,11.1746667 98.8633333,12 100.355333,12 C100.804,12 101.122667,11.942 101.427333,11.812 L100.964,10.6673333 C100.775333,10.754 100.601333,10.7833333 100.355333,10.7833333 C99.7326667,10.7833333 99.3993333,10.4213333 99.3993333,9.624 L99.3993333,6.00266667 L101.137333,6.00266667 L101.137333,4.77533333 L99.3993333,4.77533333 L99.3993333,2.66666667" id="Fill-8"></path>
                <path d="M58.95,1.33333333 L51.7333333,1.33333333 L51.7333333,2.7 L54.6666667,2.7 L54.6666667,11.8666667 L56.0166667,11.8666667 L56.0166667,2.7 L58.95,2.7 L58.95,1.33333333" id="Fill-9"></path>
                <path d="M6.16,5.45866667 L6.16,7.09733333 L10.082,7.09733333 C9.96466667,8.01933333 9.65733333,8.69266667 9.18933333,9.16066667 C8.618,9.73133333 7.72533333,10.3606667 6.16,10.3606667 C3.74466667,10.3606667 1.85733333,8.41466667 1.85733333,6 C1.85733333,3.58533333 3.74466667,1.63933333 6.16,1.63933333 C7.462,1.63933333 8.41333333,2.15133333 9.116,2.81 L10.272,1.65333333 C9.29133333,0.717333333 7.98933333,0 6.16,0 C2.85266667,0 0.072,2.69266667 0.072,6 C0.072,9.30733333 2.85266667,12 6.16,12 C7.94466667,12 9.29133333,11.4146667 10.3453333,10.3173333 C11.428,9.234 11.7646667,7.712 11.7646667,6.48266667 C11.7646667,6.10266667 11.7353333,5.75133333 11.6766667,5.45866667 L6.16,5.45866667" id="Fill-10"></path>
                <path d="M16.6666667,4.12733333 C14.5253333,4.12733333 12.7793333,5.756 12.7793333,8 C12.7793333,10.2293333 14.5253333,11.8726667 16.6666667,11.8726667 C18.8086667,11.8726667 20.554,10.2293333 20.554,8 C20.554,5.756 18.8086667,4.12733333 16.6666667,4.12733333 L16.6666667,4.12733333 Z M16.6666667,10.3466667 C15.4933333,10.3466667 14.4806667,9.37866667 14.4806667,8 C14.4806667,6.60666667 15.4933333,5.65266667 16.6666667,5.65266667 C17.84,5.65266667 18.8526667,6.60666667 18.8526667,8 C18.8526667,9.37866667 17.84,10.3466667 16.6666667,10.3466667 L16.6666667,10.3466667 Z" id="Fill-11"></path>
                <path d="M35.72,4.99333333 L35.6613333,4.99333333 C35.2793333,4.538 34.546,4.12733333 33.622,4.12733333 C31.686,4.12733333 30,5.814 30,8 C30,10.1706667 31.686,11.8726667 33.622,11.8726667 C34.546,11.8726667 35.2793333,11.462 35.6613333,10.9926667 L35.72,10.9926667 L35.72,11.534 C35.72,13.0153333 34.928,13.8073333 33.6513333,13.8073333 C32.61,13.8073333 31.9646667,13.0593333 31.7006667,12.4286667 L30.2193333,13.0446667 C30.6446667,14.0713333 31.774,15.3333333 33.6513333,15.3333333 C35.6466667,15.3333333 37.3333333,14.16 37.3333333,11.2993333 L37.3333333,4.32666667 L35.72,4.32666667 L35.72,4.99333333 L35.72,4.99333333 Z M33.7693333,10.3466667 C32.596,10.3466667 31.702,9.34933333 31.702,8 C31.702,6.636 32.596,5.65266667 33.7693333,5.65266667 C34.9286667,5.65266667 35.8373333,6.65066667 35.8373333,8.01466667 C35.8373333,9.364 34.9286667,10.3466667 33.7693333,10.3466667 L33.7693333,10.3466667 Z" id="Fill-12"></path>
                <path d="M25.3333333,4.12733333 C23.192,4.12733333 21.446,5.756 21.446,8 C21.446,10.2293333 23.192,11.8726667 25.3333333,11.8726667 C27.4753333,11.8726667 29.2206667,10.2293333 29.2206667,8 C29.2206667,5.756 27.4753333,4.12733333 25.3333333,4.12733333 L25.3333333,4.12733333 Z M25.3333333,10.3466667 C24.16,10.3466667 23.148,9.37866667 23.148,8 C23.148,6.60666667 24.16,5.65266667 25.3333333,5.65266667 C26.5066667,5.65266667 27.5193333,6.60666667 27.5193333,8 C27.5193333,9.37866667 26.5066667,10.3466667 25.3333333,10.3466667 L25.3333333,10.3466667 Z" id="Fill-13"></path>
                <path d="M38.6666667,0.16 L40.34,0.16 L40.34,11.8726667 L38.6666667,11.8726667 L38.6666667,0.16 Z" id="Fill-14"></path>
                <path d="M45.51,10.3466667 C44.6446667,10.3466667 44.0286667,9.95133333 43.632,9.174 L48.81,7.032 L48.634,6.592 C48.3113333,5.72666667 47.3286667,4.12733333 45.3193333,4.12733333 C43.324,4.12733333 41.6666667,5.69733333 41.6666667,8 C41.6666667,10.1713333 43.3093333,11.8726667 45.51,11.8726667 C47.2846667,11.8726667 48.3113333,10.7873333 48.7366667,10.156 L47.4166667,9.276 C46.9766667,9.922 46.3753333,10.3466667 45.51,10.3466667 L45.51,10.3466667 Z M45.384,5.57866667 C46.0726667,5.57866667 46.656,5.92933333 46.8506667,6.43266667 L43.3533333,7.88266667 C43.3533333,6.25 44.508,5.57866667 45.384,5.57866667 L45.384,5.57866667 Z" id="Fill-15"></path>
            </g>
            <text id="powered-by-copy"  sketch:type="MSTextLayer" font-family="Roboto" font-size="12" font-weight="normal" fill="#000000">
                <tspan x="0" y="12" class="p-footer__contrast--fill p-footer__force-color">powered by</tspan>
            </text>
        </g>
    </g>
</svg>
</template>
<script type="text/javascript">
    module.exports = {};
</script>
