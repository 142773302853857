const path = require('path');
const Vue = require('vue');
const VueResource = require('vue-resource');
Vue.use(VueResource);
const staticData = require('../store/data');

module.exports = {
  applyCompares: function applyCompares(compares, rows) {
    if (!compares) return rows;
    return rows.map((r) => {
      const newR = r;
      const compare = compares.find(c => r.id.toString() === c.id.toString());
      newR.computed.compare = !!compare;
      return newR;
    }, this);
  },
  applyFavorites: function applyFavorites(favorites, rows) {
    if (!favorites) return rows;
    return rows.map((r) => {
      var favorite;
      var newR = r;
      favorite = favorites.find(f => f.id.toString() === r.id.toString());
      newR.computed.favorite = !!favorite;
      return newR;
    }, this);
  },
  applyNeighborhood: function applyNeighborhood(neighborhoodSchoolIds, rows) {
    // apply neighborhood flag to approprate schools
    // TODO: make sf_id configurable
    if (!neighborhoodSchoolIds) return rows;
    return rows.map(r => {
      const newR = r;
      let schoolId;
      try {
        schoolId = r.sf_id.toString();
      } catch (e) {
        schoolId = null;
      }
      const neighborhood = neighborhoodSchoolIds.indexOf(schoolId) > -1;
      newR.computed.neighborhood = neighborhood;
      return newR;
    });
  },
  applyTransportation: function applyTransportation(idsWithTransportation, rows) {
    // TODO: make sf_id configurable
    //
    // apply list of transport options to schools
    //
    if (!idsWithTransportation) return rows;
    return rows.map(r => {
      const newR = r;
      const rowId = r.sf_id;
      if (idsWithTransportation.hasOwnProperty(rowId)) {
        newR.computed.transportation = idsWithTransportation[rowId];
      } else {
        newR.computed.transportation = [];
      }
      return newR;
    });
    // for (let j = 0, k = rows.length; j < k; j ++) {
    //   if (idsWithTransportation.hasOwnProperty(rows[j].sf_id)) {
    //     rows[j].computed.transportation = idsWithTransportation[rows[j].sf_id];
    //   } else {
    //     rows[j].computed.transportation = [];
    //   }
    // }
    // return rows;
  },
  getSessionUrlMap(sessionList) {
    const urlMap = {};
    if (!sessionList) return null;
    for (let i = 0, l = sessionList.length; i < l; i ++) {
      const sessionItem = sessionList[i];
      if (typeof sessionItem === 'string') {
        urlMap[sessionItem] = sessionItem;
      } else {
        urlMap[sessionItem.name] = sessionItem.url;
      }
    }
    return urlMap;
  },
  getSessionUrl(ctx, field, id) {
    let url;
    const findRoute = ctx.state.route.name;
    const config = findRoute === 'find' ? staticData.config : staticData[findRoute].config;
    const urlMap = config.session_url_map;
    if (!urlMap) {
      return null;
    }

    if (typeof field === 'object') {
      try {
        url = field.url;
      } catch (e) {
        console.error('Session items must be configured as either a string (the name of the session data, or as an object with a name & url property. Instead, we got:', field); // eslint-disable-line no-console, max-len
        return null;
      }
    } else {
      url = urlMap[field];
    }
    if (!url) return null;
    if (id) {
      url = url.split('?');
      url[0] = path.join(url[0], id);
      url = url.join('?');
    }
    url = path.join('/sessions/', url);
    return url;
  },
  getFromSession: function getFromSession(ctx, field, handler) {
    var newHandler = handler;
    if (!newHandler) {
      newHandler = function success(res) {
        if (!Array.isArray(res.body)) return JSON.parse(res);
        return res.body.map((i) => {
          try {
            return JSON.parse(i);
          } catch (e) {
            return i;
          }
        });
      };
    }

    const url = this.getSessionUrl(ctx, field);

    if (!url) return new Promise(() => null);
    return Vue.http.get(url)
    .then(newHandler);
  },
  addSessionItem: function addSessionItem(ctx, field, item, success, fail) {
    var strItem = JSON.stringify(item);
    var postData = new FormData();
    var successHandler = success;
    var failHandler = fail;
    var url = this.getSessionUrl(ctx, field);
    if (!successHandler) {
      successHandler = function s(res) {
        try {
          return JSON.parse(res.body);
        } catch (e) {
          return res.body;
        }
      };
    }
    if (!failHandler) {
      failHandler = function f(err) {
        console.error(err); // eslint-disable-line no-console
      };
    }
    postData.append('item', strItem);

    if (!url) return new Promise((resolve) => resolve(item));
    return Vue.http.post(url, postData)
    .then(successHandler, failHandler);
  },
  removeSessionItem: function removeSessionItem(ctx, field, item, handler) {
    var idToRemove = item.id.toString();
    var newHandler = handler;

    if (!newHandler) {
      newHandler = function success(res) {
        try {
          return JSON.parse(res.body);
        } catch (e) {
          return res.body;
        }
      };
    }
    const url = this.getSessionUrl(ctx, field, idToRemove);
    if (!url) return new Promise((resolve) => resolve());
    return Vue.http.delete(url)
    .then(newHandler);
  },
  replaceSessionItem: function replaceSessionItem(ctx, field, data, handler) {
    var postData = new FormData();
    var strData = JSON.stringify(data);
    postData.append('item', strData);

    const url = this.getSessionUrl(ctx, field);
    if (!url) return new Promise((resolve) => resolve(data));
    return Vue.http.put(url, postData)
    .then(handler);
  },
  setPersistItems: function setSessionItems(field, data) {
    this[field] = data;
  }
};
