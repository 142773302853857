<template>
  <div class="o-two-column-layout__nav">
    <div class="" :class="headerType === 'containerWidth' ?  'container' : 'container-fluid'">
      <div class="row align-items-center">
        <div class="align-items-center col-auto d-flex">
          <!-- option to open intro modal -->
          <a v-if="settings.logo_behavior === 'show_intro'" class="d-inline-flex" tabindex="1" v-on:click="showModal('intro')" v-on:keydown.13="showModal('intro')"><img :src="settings.logo_src" :alt="settings.logo_alt" class="o-two-column-layout__nav__logo"><span class="sr-only">{{settings.logo_alt}}</span></a>
          <!-- option for internal link -->
          <a v-else-if="settings.logo_behavior === 'local_url'" class="d-inline-flex" tabindex="1" :href="settings.logo_url + getQueryString(this.$route.query)"><img :src="settings.logo_src" :alt="settings.logo_alt" class="o-two-column-layout__nav__logo"><span class="sr-only">{{settings.logo_alt}}</span></a>
          <!-- option for external link -->
          <a v-else class="d-inline-flex" tabindex="1" target="_blank" :href="settings.logo_url + getQueryString(this.$route.query)"><img :src="settings.logo_src" :alt="settings.logo_alt" class="o-two-column-layout__nav__logo"><span class="sr-only">{{settings.logo_alt}}</span></a>

          <h6 class="o-two-column-layout__nav__logo-text ml-2" v-if="settings.logo_text" v-html="settings.logo_text"></h6>
        </div>
        <div class="col-auto ml-auto">
          <div class="nav-container d-flex sp-h-3">
            <!-- COMPARE COUNTER -->
            <a tabindex="1" class="align-self-center compare-container d-md-inline-block visible-md-inline visible-lg-inline" v-if="settings.compare && !hideCompare" v-on:click="showModal('compares')" v-on:keydown.13="showModal('compares')">
              <span class="compare-text"><span>{{settings.view_compare_text}}</span></span>
              <compare-counter v-if="compares"></compare-counter>
            </a>
            <a tabindex="1" class="view-favorites d-none d-md-inline-block desktop-only" v-if="settings.favorite" v-on:click="showModal('favorites')" v-on:keydown.13="showModal('favorites')"><span class="a-ui-grow">{{settings.view_favorites_text}}</span></a>
            <a v-if="useDropdown" tabindex="1" class="nav_settings not-a p-header__cog" v-on:click="toggleSettings">
              <i class="fa fa-cog fa-1 p-header__cog" aria-hidden="true"></i>
              <i v-bind:class="showSettings ? 'fa fa-caret-up p-header__cog__caret' : 'fa fa-caret-down p-header__cog__caret'"></i>
              <span class="sr-only">Toggle Settings Menu</span>
            </a>

            <!-- for use only when no dropdown menu is wanted -->
            <!-- assumes we only need a way to handle languages, no favorites, compare, or instructions -->
            <div v-if="!settings.disable_languages">
              <a
                v-if="!useDropdown && settings.language_handling === 'toggle'"
                tabindex="1"
                v-on:click="toggleLanguage"
                v-on:keypress.13="toggleLanguage"

              >{{otherLanguage.text}}</a>
              <template v-if="!useDropdown && settings.language_handling === 'toggle_all'">
                <a
                  v-for="lang in settings.language_options.options"
                  tabindex="1"
                  v-on:click="toggleLanguage(lang.value)"
                  v-on:keypress.13="toggleLanguage(lang.value)"
                  class="mr-3"
                  :class="lang.value === $store.state.language ? 'a--disabled' : ''"
                >{{lang.text}}</a>
              </template>
              <template v-if="!useDropdown && settings.language_handling === 'select'">
                <div class="d-flex align-items-center">
                <i class="fas fa-language mr-2" style="font-size: 1.5em;"></i>
                <label class="c-select__wrapper" >
                <select v-model="currentLanguage" class="c-select" >
                <option
                  v-for="lang in settings.language_options.options"
                  :value="lang.value"
                  :selected="lang.value === $store.state.language ? 'selected' : ''"
                >{{lang.text}}</option>
                </select>
                </label>
              </div>
              </template>
              <a
                v-if="!useDropdown && settings.language_handling === 'modal'"
                tabindex="1"
                v-on:click="showModal('lang')"
                v-on:keypress.13="showModal('lang')"

              >{{otherLanguage.text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="useDropdown" v-on:mouseleave="removeSettings" class="o-two-column-layout__nav__dropdown p-2 sp-v-2" v-bind:class="showSettings ? 'o-two-column-layout__nav__dropdown--visible' : 'o-two-column-layout__nav__dropdown--hidden'">
      <div class="nav_settings_menu_section">
        <div v-if="settings.access_intro" class="mb-2">
          <a v-on:click="showModal('intro')">{{settings.view_intro_text}}</a>
        </div>

        <!-- LANGUAGE COMPONENT -->
        <!-- Link for togging: -->
        <div v-if="settings.language_handling === 'toggle'">
          <a v-on:click="toggleLanguage">{{otherLanguage.text}}</a>
        </div>

        <!-- Link to open language modal -->
        <div v-if="settings.language_handling === 'modal'">
          <a v-on:click="showModal('lang')">{{settings.view_languages_text}}</a>
        </div>

      </div>
      <div class="nav_settings_menu_section  visible-xs d-md-none sp-v-2" :class="!settings.auth ? 'mobile-only' : ''" v-if="settings.favorite || settings.compare">
        <div v-if="settings.favorite">
          <a tabindex="1" class="mobile-only " v-on:click="showModal('favorites')" v-on:keypress.13="showModal('favorites')">{{settings.view_favorites_text}}</a>
        </div>
        <div v-if="settings.compare && !hideCompare">
          <a tabindex="1" class="compare-container visible-sm visible-xs d-md-none"  v-on:click="showModal('compares')" v-on:keypress.13="showModal('compares')">
            <span class="compare-text">{{settings.view_compare_text}}</span>
            <compare-counter v-if="compares"></compare-counter>
          </a>
        </div>
      </div>

      <div class="nav_settings_menu_section" v-if="settings.auth">
        <div v-if="settings.auth">
          <a tabindex="1" class="login-link" v-on:click="handleLogOutClick" v-on:keypress.13="handleLogOutClick">{{loggedIn ? settings.logout_text : settings.login_text}}</a>
        </div>
      </div>
    </div>
  </div>

</template>
<script type="text/javascript">
  import CompareCounter from './compare/CompareCounter.vue';
  import sendEvent from 'tembo-js/sendEvent';
  import getQueryStringLibrary from 'tembo-js/getQueryString';

  module.exports = {
    props: [
      'settings',
      'layout',
      'headerType',
      'hideCompare'
    ],
    components: { CompareCounter },
    data: function data() {
      return {
        showSettings: false,
        currentLanguage: this.$store.state.language
      };
    },
    computed: {
      useDropdown: function useDropdown() {
        if (this.settings.hasOwnProperty('use_dropdown')) {
          return this.settings.use_dropdown;
        }
        return true;
      },
      loggedIn: function loggedIn() {
        return this.$store.state.loggedIn;
      },
      compares: function compares() {
        return this.$store.state.compares;
      },
      otherLanguage: function otherLanguage() {
        var nextLang;
        var i = 0;
        var optionCt = this.settings.language_options.options.length;
        while (!nextLang && i < optionCt) {
          if (this.settings.language_options.options[i].value !== this.$store.state.language) {
            nextLang = this.settings.language_options.options[i];
          }
          i += 1;
        }
        return nextLang;
      }
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      toggleLanguage: function toggleLanguage(val) {
        let nextLang;
        if (typeof val === 'string') {
          nextLang = val;
        } else {
          nextLang = this.otherLanguage.value;
        }
        this.updateLanguage(nextLang);
      },
      updateLanguage: function updateLanguage(newLanguage) {
        // let newLanguage = this.currentLanguage;
        let queryObj = this.$route.query;
        queryObj = Object.assign({}, this.$route.query, { lang: newLanguage });
        const isIE = !!navigator.userAgent.match(/Trident/g) ||
          !!navigator.userAgent.match(/MSIE/g) ||
          !!navigator.userAgent.match(/Edge/g);


        sendEvent({
          category: 'languages',
          action: 'update',
          label: newLanguage
        });

        if (isIE) {
          window.location = getQueryStringLibrary(queryObj);
        } else {
          if (newLanguage === this.settings.language_options.default_val) {
            delete queryObj.lang;
          }
          this.$router.push({ name: 'find', query: queryObj });
        }
        return newLanguage;
      },
      toggleSettings: function toggleSettings() {
        this.showSettings = !this.showSettings;
      },
      handleLogOutClick: function handleLogOutClick() {
        if (!this.loggedIn) {
          this.showModal('redirect');
        } else {
          this.$store.dispatch('logOut');
        }
      },
      showModal: function showModal(modal) {
        sendEvent({
          category: 'nav',
          action: 'view',
          label: modal
        });
        this.showSettings = false;
        this.$store.dispatch('updateShowModal', { modal: modal });
      },
      removeSettings: function removeSettings() {
        this.showSettings = false;
      }
    },
    watch: {
      currentLanguage: function currentLanguage(current, previous) {
        if (current !== previous) {
          this.updateLanguage(current);
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  a.disabled{
    cursor: not-allowed;
  }
</style>
