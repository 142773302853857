<template>
  <label class="favorite_label" v-on:mouseover="handleMouseOver" v-on:mouseleave="handleMouseLeave">
    <span class="button-status">
      <span class="button-status__status" :class="!checked ? '' : 'hide'">{{textAdd}}</span>
      <span class="button-status__status" :class="checked ? '' : 'hide'">{{textSaved}}</span>
    </span>
    <input class="favorite" type="checkbox" v-model="localChecked" v-bind:disabled="disabled" v-on:focus="handleMouseOver" v-on:blur="handleMouseLeave">
  </label>
</template>
<script type="text/javascript">
  var FavoriteHeartMixin = require('../mixins/FavoriteHeartMixin');

  module.exports = {
    mixins: [FavoriteHeartMixin]
  };
</script>