<template>
  <div v-if="entity" :id="id ? id : entity.id" class="entity" :class="entity.computed.neighborhood ? 'entity--is-neighborhood-entity' : ''">
    <div class="entity_order"><span>{{idx}}</span></div>

    <!-- BUTTON TO CLOSE DIV -->
    <div v-if="x" v-on:click="closeItem" class="modal_close modal_close--ui"><modal-close-svg></modal-close-svg></div>

    <!-- SCHOOL INFO -->
    <div class="entity_information">
      <!-- SCHOOL NAME -->
      <h3 class="entity_name"><a class="a a-ui-grow" :href="entityUrl" v-on:click="handleNameClick(entity)">{{entity.name}}</a></h3>
      <!-- NEIGHBORHOOD BADGE -->
      <div v-if="entity.computed.neighborhood" class="entity__neighborhood-badge">{{settings.neighborhood_text}}</div>
      <!-- SCHOOL ADDRESS -->
      <div class="entity_address">
        <i class="fa fa-map-marker"></i>
        <street-address :entity-data="entity.address"></street-address>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="row entity-type-grades">
              <div class="col-md-12">{{entity.entity_type}}</div>
              <div v-if="entity.grades" class="col-md-12">{{entityGrades}}</div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="row distance-filter-count-container">
              <div class="col-6 ">
                <span v-if="home && entity.computed.distance_from_home !== null"><span class="entity-distance">{{padNumberString(entity.computed.distance_from_home, this.settings.metadata.round_to_decimals)}}</span> {{settings.distance_units_text}}</span>
              </div>
              <div class="col-6">
                <div v-if="relaxedFilterCount" v-on:click="handleMatchClick(entity)" class="filters-met">
                  <span>{{entity.computed.match_rate}} {{settings.match_connect_text}} {{relaxedFilterCount}} </span><span>{{relaxedFilterCount > 1 ? settings.match_plural_text : settings.match_singular_text}}</span>
                </div>
              </div>
            </div>

            <div class="row report-card-container">

              <!-- SCHOOL REPORT SCORE -->
              <div class="col-12 col-md-6" v-for="score in entity.report_cards">
                <span :class="'report-card-letter report-card-letter--' + settings.letter_grade_universe.indexOf(score.letter_grade)">{{score.letter_grade}} </span>
                <span>{{settings.report_card_text}}</span>
                <span v-if="entity.report_cards.length && entity.report_cards.length > 1">{{displayGradeStringAsRangeOrList(score.grades)}}</span>
              </div>

              <!-- SCHOOL RATING -->
              <div class="col-12 col-md-6" v-if="entity.rating">
                <i v-for="i in settings.metadata.max_stars" :class="i + 1 <= entity.rating ? 'fa-star' : filled ? 'fa-star' : 'fa-star-o'" class="fa"></i>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- COMPARE -->
      <div v-if="compares" class="compare-container">
        <compare-toggle v-if="settings.compare" :checked="entity.computed.compare" :value="entity.id" text-saved="Added!" text-add="Save to comparison" text-remove="Remove from comparison"></compare-toggle>
      </div>

      <!-- FAVORITE HEART -->
      <div class="favorite-container">
        <favorite-heart
          v-if="settings.favorite"
          :checked="entity.computed.favorite"
          :value="entity.id"
          :disabled="favDisabled"
          @update:favorite="val => { handleHeartClick(val) }"
          :text-add="settings.fav_save_text"
          :text-saved="settings.fav_success_text"
        ></favorite-heart>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import ModalCloseSvg from '../../assets/ModalCloseSvg.vue';
  import FavoriteHeart from '../FavoriteHeart.vue';
  import CompareToggle from '../compare/CompareToggle.vue';
  import StreetAddress from '../StreetAddress.vue';
  var ResultItemMixin = require('../../mixins/ResultItemMixin');
  module.exports = {
    mixins: [ResultItemMixin],
    components: {
      ModalCloseSvg,
      FavoriteHeart,
      CompareToggle,
      StreetAddress,
    }
  };
</script>
<style type="text/css" scoped>
.flex{
/*   width: 100%; */
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  flex-basis: 20%;
}

.flex > div{
  flex-basis: 20%;
}

.entity-info > * {
  display: inline-block;
  border-right: 1px solid grey;
  padding-right: 5px;
  padding-left: 5px;
}
.entity-info > *:first-child {
  padding-left: 0;
}
.entity-info > *:last-child {
  border-right: 0;
}
</style>