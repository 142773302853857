<!-- Currently set up to work with ResultsStatusCountyFilter.vue as the wrapper for this component -->
<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <div :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" :settings="layout.wrapper.data" :selection-tally="selectionTally" class="deselect-filter">

    <!-- NO FILTER TITLE SLOT -->
    <span slot="filter-title" v-if="settings.group_title_text">{{settings.group_title_text}}</span>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" v-if="settings.description_text">{{settings.description_text}}</div>

    <!-- FILTER CONTENT SLOT -->
    <template slot="filter-content" class="filter-content">
        <div v-for="req in requirements" class="req">
          {{req.text}}
          <button class="req__unset" v-on:click.prevent.stop="unsetAsRequirement(req)" v-on:keydown.stop>
            <i class="fa fa-close"></i>
          </button>
        </div>
    </template>
      <!-- Clear button -->
    <!-- <div slot="filter-clear" class="filter-clear">
      <a href="#" class="a filter__clear" :class="requirements.length ? '' : 'a__is-disabled'" v-on:click.prevent.stop="clearThisFilter(false)">{{settings.clear_one_filter_text}}</a>
    </div> -->
  </div>
</template>
<script type="">
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  import isEqual from 'lodash/isEqual';

  module.exports = {
    mixins: [FilterGroupMixin],
    computed: {
      requirements: function requirements() {
        return this.filteredValues.filter(v => v.required);
      }
    },
    methods: {
      unsetAsRequirement: function unsetAsRequirement(option) {
        var i;
        var l = this.values.length;

        // set requirements properties
        for (i = 0; i < l; i ++) {
          const updatedValue = JSON.parse(JSON.stringify(this.values[i]));
          if (option.value === 'all') {
            updatedValue.required = false;
          } else if (updatedValue.value === option.value) {
            updatedValue.required = false; // eslint-disable-line no-param-reassign
          }
          if (!isEqual(updatedValue, this.values[i])) {
            this.$store.dispatch('updateFilterValue', {
              filterValue: updatedValue,
              updateEntities: true
            });
          }
        }
      }
    }
  };
</script>
<style scoped>
  .deselect-filter-contents {
    position: relative;
  }
  .req{
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 0 4px;
    margin: 3px 5px;
    display: inline-block;
  }
  button.req__unset{
    padding: inherit;
    border: none;
  }
</style>