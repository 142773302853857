<template>
  <div class="entity entity--placeholder p-entity p-entity--placeholder p-0 c-card my-1 flex-shrink-0">
    <div class="entity_order p-entity--placeholder__element p-entity__order p-3"></div>
    <!-- SCHOOL INFO -->
    <div class="entity_information p-entity__information p-3">
      <!-- SCHOOL NAME -->
      <h3 class="entity_name"><span class="p-entity--placeholder__element loading"  v-bind:style="{ width: getWidth(40,85) }"></span></h3>

      <!-- GRADES & DISTRICT -->
      <div class="mt-4"><span class="loading p-entity--placeholder__element" v-bind:style="{ width: getWidth(30,65) }"></span></div>
      <div class="mt-2"><span class="loading p-entity--placeholder__element" v-bind:style="{ width: getWidth(30,75) }"></span></div>
      <div class="mt-2"><span class="loading p-entity--placeholder__element" v-bind:style="{ width: getWidth(30,75) }"></span></div>
      <div class="mt-2"><span class="loading p-entity--placeholder__element" v-bind:style="{ width: getWidth(30,75) }"></span></div>

      <!-- FAVORITE HEART & COMPARE TOGGLE -->
    </div>
  </div>
</template>
<script type="text/javascript">
module.exports = {
  methods: {
    getWidth: function getWidth(min, max) {
      return `${Math.floor(Math.random() * (max - min)) + min}%`;
    }
  }
};
</script>


<style scoped> 
.loading{
  height:  0.8em;
  display: inline-block; 
}
  
</style>