var PaginationMixin = require('../mixins/PaginationMixin');
import getDataByProperty from 'tembo-js/getDataByProperty';

module.exports = {
  props: ['settings'],
  mixins: [PaginationMixin],
  computed: {
    entitiesLoaded: function entitiesLoaded() {
      return this.$store.state.entitiesLoaded;
    },
    filters: function filters() {
      return this.$store.getters.filterSet;
    },
    home: function home() {
      return this.$store.state.home;
    },
    distanceIsRelevant: function distanceIsRelevant() {
      if (this.filterRequirement('distance')) return true;
      return false;
    },
    countMessage: function countMessage() {
      var message;
      var range;
      var currentEntities;
      if (this.pageCount > 1) {
        message = this.settings.display_pagination;
      } else if (this.$store.state.filtered.length === 1) {
        message = this.settings.display_singular;
      } else {
        message = this.settings.display_plural;
      }
      currentEntities = this.pageXEntities;
      if (this.$store.state.filtered.length > 0) {
        range = `${this.getEntityIndex(0)}-${this.getEntityIndex(currentEntities.length - 1)}`;
      } else {
        range = '0';
      }
      message = message.replace('[ZZ]', range);
      message = message.replace('[XX]', this.$store.state.filtered.length);
      return message;
    },
    distanceMessage: function distanceMessage() {
      var message = this.settings.within_distance_text || '';
      message = message.replace('[YY]', this.filterRequirement('distance').toLowerCase());
      return message;
    }
  },
  methods: {
    filterRequirement: function filterRequirement(key) {
      var filter;
      var filterValues;
      var filterRequirements;
      if (!this.filters) return false;
      filter = this.filters[key];
      if (!filter) return false;

      if (filter.enabled_by) {
        const enabler = getDataByProperty(filter.enabled_by, this);
        if (!enabler) return null;
      }
      filterValues = filter.value;
      filterRequirements = filterValues.filter(val => val.required);
      if (filterRequirements.length >= 1) {
        return filterRequirements[0].text;
      }
      return null;
    },
    showFilters: function showFilters() {
      this.$store.dispatch('updateMobileView', 'filter'); // set mobile view
      this.$store.dispatch('updateMoreFiltersExpanded', true); // expand more filters (for desktop)
    }
  },
  events: {
    SchoolsLoaded: function SchoolsLoaded() {
      this.entitiesLoaded = true;
    }
  }
};
