module.exports = {
  gradesServed: function gradesServed(row) {
    var str = '<div class="map-popup">';
    var first;
    var last;
    var len;
    if (row.name) {
      str += '<h4>' + row.name + '</h4>';
    }
    if (row.grades) {
      //
      // handle arrays
      //
      if (Array.isArray(row.grades)) {
        len = row.grades.length;
        //
        // handle empty array
        //
        if (len === 0) {
          str += '</div>';
          return str;
        }
        str += '<div>';
        first = row.grades[0];
        if (typeof(first) === 'object' && first.name !== undefined) {
          first = first.name;
        }
        //
        // handle array of 1
        //
        if (len === 1) {
          if (first) {
            str += first +
              '</div>' +
            '</div>';
            return str;
          }
          str += '</div>';
          return str;
        }
        last = row.grades[len - 1];
        if (typeof(last) === 'object' && last.name !== undefined) {
          last = last.name;
        }
        //
        // handle longer arrays
        //
        if (first) {
          str += first;
          if (last) {
            str += ' - ' + last;
          }
        } else if (last) {
          str += last;
        }
        str += '</div></div>';
        return str;
      }
      //
      // handle strings
      //
      if (typeof row.grades === 'string') {
        str += '<div>' +
                    row.grades +
                '</div>' +
              '</div>';
        return str;
      }
    }
    //
    // everything else
    //
    str += '</div>';
    return str;
  },
  addressString: function addressString(address) {
    if (!address.formatted_address) return null;
    return '<div class="map-popup map-popup__current-address">' +
              '<h4>' + address.formatted_address + '</h4>' +
            '</div>';
  }
};
