<template>
  <nav class="button--control-container button-group--horizontal sp-h-2" aria-label="Results Pagination Navigation" v-if="pageCount > 1">
    <button class="button--control c-button--control c-button" v-on:click="pageBack" :disabled="page <= 1" :class="page <= 1 ? '' : 'c-button--outline'" :aria-label="'Go to previous page, page ' + (page - 1)"><i class="fa fa-caret-left"></i><span class="hidden-xs">{{settings.previous_text}}</span></button><!--
     --><button class="button--control c-button--control c-button" v-for="(pageItem, index) in pages" :class="pageItem === page ? 'button--control button--active' : 'button--control c-button--outline' " :disabled="pageItem === '...'" :aria-label="(pageItem === page ? 'Current page,' : 'Go to') + ' page '  + pageItem" v-on:click="setPage(pageItem, $event)">{{pageItem}}</button><!--
     --><button class="button--control c-button--control c-button" v-on:click="pageFwd" :disabled="page >= pageCount" :class="page >= pageCount ? '' : 'c-button--outline'" :aria-label="'Go to next page, page ' + (page + 1)"><span class="hidden-xs">{{settings.next_text}}</span><i class="fa fa-caret-right"></i></button>
  </nav>
</template>
<script type="text/javascript">
  var PaginationMixin = require('../mixins/PaginationMixin');
  module.exports = {
    mixins: [PaginationMixin],
    computed: {
      page: function page() {
        return this.$store.state.page;
      },
      pages: function pages() {
        var pageArray = [];
        var i;
        var n = this.pageCount;
        var p = this.$store.state.page;
        var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before')
          .getPropertyValue('content').replace(/"/g, '');

        for (i = 0; i < n + 1; i ++) {
          if (breakpoint === 'smartphone') {
            if (i < 1 || i > n) {
              // do nothing
            } else if (i === 1 || i === p || i === n) {
              pageArray.push(i);
            }
          } else {
            if (i < 1 || i > n) {
              // do nothing
            } else if (i === 1 || i === p - 1 || i === p || i === p + 1 || i === n) {
              pageArray.push(i);
            }
          }
        }
        pageArray = this.ellipsify(pageArray);
        return pageArray;
      }
    },
    methods: {
      ellipsify: function ellipsify(pages) {
        var updated = [];
        var i;
        var l = pages.length;

        for (i = 0; i < l; i ++) {
          if (pages[i + 1] - pages[i] === 2) {
            // if the difference between this & the next is two
            // add this page and the following page
            updated.push(pages[i]);
            updated.push(pages[i] + 1);
          } else if (pages[i + 1] - pages[i] === 1 || i === l - 1) {
            // if this page is followed by page + 1
            // add this page only
            updated.push(pages[i]);
          } else {
            // otherwise, there must be more than one page missing
            // so add this page and an elipsis
            updated.push(pages[i]);
            updated.push('...');
          }
        }
        return updated;
      }
    }
  };
</script>
