<template>
  <div id="entity-list" class="d-flex flex-column flex-grow-1 flex-shrink-0 position-relative" style="overflow-y: auto;">
    <!-- ERROR TEXT -->
    <div
      v-if="$store.state.entitiesLoaded && (!$store.state.filtered || !$store.state.filtered.length || $store.state.filtered.length < 1)"
      :is="layout.no_results.tag"
      :settings="layout.no_results.data"
    ></div>


      <result-item-placeholder v-else-if="!$store.state.entitiesLoaded" v-for="i in (usePagination ? settings.pagination.results_per_page : 20)" :key="i"></result-item-placeholder>

    <!-- LOOP THROUGH LIST OF SCHOOLS -->
    <div
      v-for="(entity, index) in (usePagination) ? pageXEntities : $store.state.filtered"
      :key="entity.id"

      :is="layout.school_item.tag"
      :settings="layout.school_item.data"
      :layout="layout.school_item.layout"

      :entity="entity"
      :idx="getEntityIndex(index)"

      :x="false"
    ></div>
  </div>
</template>
<script type="text/javascript">
  var ModalMixin = require('../mixins/ModalMixin');
  var PaginationMixin = require('../mixins/PaginationMixin');
  import ResultItemPlaceholder from './resultItems/ResultItemPlaceholder.vue';
  import staticData from '../store/data';

  module.exports = {
    mixins: [ModalMixin, PaginationMixin],
    components: { ResultItemPlaceholder, },
    props: [
      'settings',
      'layout'
    ],
    data() {
      return {
        usePagination: staticData.usePagination,
      };
    },
  };
</script>