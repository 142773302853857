<template>
  <fieldset class="collapsable-filter" v-bind:class="show ? 'collapsable-filter--is-open' : 'collapsable-filter--is-closed'">

    <!-- FILTER HEADER -->
    <div class="filter-header" >
      <legend v-bind:class="settings.is_subfilter ? 'filter-subtitle' : 'filter-title'" v-if="settings.group_title_text" v-on:click="handleTitleClick">
        <div class="clickable tmbo" v-bind:class="show ? 'tmbo-minus' : 'tmbo-plus'"></div>
          <slot name="filter-title"></slot>
      </legend>
      <div class="selected-tally" v-if="settings.metadata.show_tally && selectionTally > 0"><slot name="filter-tally"></slot></div>
      <div class="filter-group-description"><slot name="filter-group-description" class="filter-group-description"></slot></div>
    </div>

    <!-- FILTER CONTENT -->
    <slot name="filter-content"></slot>
  </fieldset>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: ['settings', 'selectionTally'],
    data: function data() {
      var show;
      try {
        show = !this.settings.metadata.collapse_on_load;
      } catch (e) {
        show = false;
      }
      return {
        show: show
      };
    },
    methods: {
      handleTitleClick: function handleTitleClick() {
        var action;
        this.show = !this.show;
        if (this.show) {
          action = 'show_group';
          $(this.$el).find('.filter-content').slideDown();
        } else {
          action = 'hide_group';
          $(this.$el).find('.filter-content').slideUp();
        }
        sendEvent({
          category: 'filter',
          action: action,
          label: this.settings.metadata.label
        });
      }
    }
  };
</script>