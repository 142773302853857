<template>
  <div style="overflow-x: hidden;">
    <component
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
      class="position-relative"
      style=""
    ></component>
    <div class="p-hero pt-4 " >
      <div class="container h-100">

        <!-- hero headers & text -->
        <div class="row h-100">
        	<div class="col-12 col-sm-11 col-lg-10 col-xl-8 mx-md-auto">
            <h1 class="p-hero__h1 mb-3 mt-20 mt-md-60 text-center">{{ getTextToRender('hero_h1', 'What’s your story?') }}</h1>
            <rich-text
              class="p-hero__p mb-4 text-center"
              :text="getTextToRender('hero_text', ['Every school', 'has a thousand stories.'])"
              :tag="'p'"
            ></rich-text>
    			</div>
    		</div>

        <!-- search box & image -->
    		<div class="row">
    			<div class="col-auto mx-auto">
    				<div class="position-relative !mb-n80" style="max-width: 870px;">
                  <!-- hero image attached to search box -->
        					<img :src="settings.hero_img" :alt-text="settings.hero_img_alt" class="p-hero-cta-block__img">

                  <!-- search -->
    		        	<div class="p-hero-cta-block py-1">
    		        		<h2 class="p-hero__h2 my-4  text-center">{{ settings.search_h2 }}</h2>
    				        <!-- entity search -->
                    <div class="d-flex" v-if="settings.search">
    				            <typeahead-dropdown-link class="flex-grow-1"
    			                :placeholder="settings.search.placeholder"
    			                :options="settings.search.options"
    			                :link-template="settings.entity_url_template"
    			                :no-results="settings.search.no_results"
    			                :hero="true"
    			              ></typeahead-dropdown-link>
    				        </div>
    				        <h3 class="p-hero__h3 text-center mt-20 mb-4" v-html="settings['search_link']"></h3>
    		        	</div>
          	</div>
	        </div>
        </div>

      </div>
    </div>

    <!-- metrics -->
    <div v-if="settings.metrics" class="p-hero--secondary pb-60" style="padding-top: 171px;">
    	<div class="container">
        <!-- metric title -->
        <div class="row !mt-2" v-if="settings.metrics.title && validMetrics">
    			<div class="col-12">
    				<h2 class="p-hero__h2 text-center">{{settings.metrics.title}}</h2>
    			</div>
    		</div>
        <!-- metric data -->
    		<div class="row mt-gutter" v-if="validMetrics">
    			<div class="col-12">
    				<div class="d-flex justify-content-around flex-column sp-v-4 sp-v-md-0 flex-md-row">
    					<div v-for="item in settings.metrics.data" class="d-inline-block ">
                  <p class="p-hero__h1 mb-12 mb-sm-20 text-center">{{item['value']}}</p>
                  <rich-text
                    v-if="item.label"
                    class="p-hero__p text-center"
                    :text="item.label"
                    :tag="'p'"
                  ></rich-text>
              </div>
    				</div>
    			</div>
    		</div>

    	</div>
    </div>

    <!-- first section below the fold -->
    <div class="d-flex flex-column p-hero--tertiary">
    	<div class="container d-flex flex-column flex-grow-1 h-100">
    		<section class="flex-grow-1 row h-100">
            <!-- image -->
        		<div class="p-hero--tertiary__image-column col-12  col-md-5">
           		<picture class="mx-60 my-gutter my-md-0 mx-md-0">
              		<source :srcset="settings.section_1.img_src" >
              		<img :src="settings.section_1.img_src" :alt="settings.section_1.img_alt_text" class="img-fluid">
            		</picture>
          	</div>

            <!-- header & paragraphs -->
          	<article class="col-12 col-md-7 mb-60">
              	<h2 class="h2--hero pb-4 mt-60">{{settings.section_1.header}}</h2>
              	<rich-text
                  v-for="text in settings.section_1.paragraphs"
                  :key="text"
                  :text="text"
                  :tag="'p'"
                ></rich-text>
          	</article>
      	</section>
    	</div>
    </div>

    <div class="container" v-if="settings.callouts && validCallouts">
      <div class="row mt-60">
      	<div class="col-12 col-md-7">
      		<h2 class="h2--hero">{{settings.callouts.title}}</h2>
      	</div>
      </div>
      <div class="row">
      	<div class="col-12 col-lg-6 ">
      		<div class="row mt-40 justify-content-center">
                <div v-for="callout in settings.callouts.data" class="col-12 col-md-6 text-center px-60 mb-40">
                    <img :src="callout.icon" alt="" aria-hidden>
                    <rich-text
                      class="mt-3"
                      :text="callout.text"
                      :tag="'p'"
                    ></rich-text>
                </div>
      		</div>
      	</div>
      	<div class="col-12 col-lg-6 text-center mt-gutter mt-lg-0">
      		<img :src="settings.callouts.img_src" :alt="settings.callouts.img_alt_text" class="img-fluid">
      	</div>
      </div>
    </div>
    <component
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class="mt-5 mt-md-80"
    ></component>
  </div>
</template>
<script type="text/javascript">
  import staticData from '../../store/data';
  import utils from '../../store/utils';
  import getQueryStringLibrary from 'tembo-js/getQueryString';
  import RichText from '../RichText.vue';

  // console.log(staticData)
  module.exports = {
    components: {
      RichText,
    },
    props: {
      settings: {
        type: Object,
        default: () => ({}),
        validator: obj => {
          // Props mapped from implementation mapping and config file (/assets/config/Finder.yml).
          const expectedProps = ['page_title', 'hero_text', 'hero_h1', 'hero_h2', 'hero_img_src',
            'hero_img_mobile_src', 'hero_img_alt'];
          const missingProps = expectedProps.filter(prop => !obj.hasOwnProperty(prop));
          if (staticData.homepage.config.defaults.debugger) {
            console.log(`Missing props from HomeHeroDetails: ${missingProps}`); // eslint-disable-line no-console, max-len
          }
          return true;
        }
      },
      layout: {
        type: Object
      },
    },
    data() {
      return {
        selectedEntity: null,
        debug: this.$store.state.debug,
      };
    },
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    },
    computed: {
      entityUrl() {
        if (!this.selectedEntity) return '';
        return utils.getEntityUrl(this.selectedEntity, this, this.settings.entity_url_template);
      },
      validMetrics() {
        if (!this.settings.metrics) return false;
        return this.settings.metrics.data.filter(m => m.value || m.value === 0).length;
      },
      validCallouts() {
        if (!this.settings.callouts) return false;
        return this.settings.callouts.data.filter(m => m.text || m.text === 0).length;
      },
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      lineBreakJoin: utils.lineBreakJoin,
      getTextToRender: function getTextToRender(prop, placeholder) {
        const textToRender = (this.settings[prop]) || (this.debug ? `[${prop}]` : placeholder);
        return Array.isArray(textToRender) ? this.lineBreakJoin(textToRender) : textToRender;
      }
    }
  };
</script>
