<!--
  facet_controls:
    facets_prompt:
    clear_prompt:
 -->
<template>
  <div v-if="enabledFacetCount">
    <p class="p--utility d-inline-block">{{settings.facets_prompt || '[facets_prompt]'}}</p>
    <span class="p--utility" :class="facetCount ? 'mx-2 d-inline-block' : 'd-none'">|</span>
    <button
      class="c-link c-link--utility"
      :class="facetCount ? '' : 'd-none c-link--disabled'"
      :disabled="facetCount > 0 ? false : true"
      v-on:click="clearFacets"
    >{{settings.clear_prompt || '[clear_prompt]'}}</button>
  </div>
</template>
<script type="text/javascript">
import sendEvent from 'tembo-js/sendEvent';

module.exports = {
  props: ['settings', 'layout'],
  computed: {
    facetCount() {
      return this.$store.getters.facetCount;
    },
    enabledFacetCount() {
      return this.$store.getters.enabledFacetCount;
    },
  },
  methods: {
    clearFacets() {
      this.$store.dispatch('resetFacets', { forceReset: false, applyDefaults: false, updateEntities: true }); // eslint-disable-line max-len
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_filter` : 'filter';
      sendEvent({
        category: category,
        action: 'clear_facets',
      });
    },
  },
};
</script>
