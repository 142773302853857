function stripHTML(html) {
  try {
    const doc = new DOMParser().parseFromString(html, 'text/html'); // eslint-disable-line no-undef
    const result = doc.body.textContent || '';
    return result;
  } catch (e) {
    // pass
  }
  console.error('stripHTML must be run in the browser'); // eslint-disable-line no-console
  return '';
}

export default stripHTML;
