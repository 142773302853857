<template>
  <!-- DISTANCE: requires computed distance or missing data rule -->
  <div class="entity-attributes__distance" v-if="entityData || entityData === 0 || settings.missing_data_rules && settings.missing_data_rules.distance">
    <div class="d-inline-flex flex-column align-items-start align-items-md-center">
      <div class="p-entity__attributes__title mb-1">
        <!-- <i class="fal fa-route mr-2" aria-hidden="true"></i> looks off at this size -->
        <i class="fas fa-road mr-1" aria-hidden="true"></i>{{settings.distance_header_text}}</div>

      <div v-if="entityData || entityData === 0" class=""><span>{{entityData}}</span> {{settings.distance_units_text}}</div>
      <!-- Missing data text for distance -->
      <div v-else class="">{{settings.missing_data_rules.distance.missing_data_text}}</div>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'entityData']
  };
</script>