<template>
  <div>
    <div class="wizard__input__prompt">{{currentScreen.promptText1}}</div>
    <dropdown-modal :choice='role' :options="roles"></dropdown-modal>
    <div class="wizard__input__prompt">{{currentScreen.promptText2}}</div>
    <dropdown-modal :choice='year' :options="years"></dropdown-modal>
    <!-- <select v-model='year'>
      <option v-for='opt in years' :value='opt'>{{opt.text}}</option>
    </select> -->
    <div class="wizard-button-container">
    <button  v-on:click="next" :disabled="nextDisabled">{{currentScreen.nextText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');

  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen',
      'index',
      'previousScreens',
      'breadCrumbs',
      'roles',
      'role',
      'years',
      'year'
    ],
    computed: {
      nextDisabled: function nextDisabled() {
        // check for all required information
        if (!this.role) return true;
        if (!this.year) return true;
        return false;
      }
    },
    methods: {
      next: function next() {
        var role;
        var year;
        var current;


        // text and goTo for role breadcrumb
        role = this.copy(this.currentScreen);
        role.text = this.role.text;
        role.idx = this.index;

        // text and goTo for year breadcrumb
        year = this.copy(this.currentScreen);
        year.text = this.year.text;
        year.idx = this.index;

        // data for updating previous screens
        current = this.copy(this.currentScreen);
        current.role = this.role;
        current.year = this.year;

        if (this.breadCrumbs.length < 2) {
          this.breadCrumbs.push(role);
          this.breadCrumbs.push(year);
          this.previousScreens.push(current);
        } else {
          this.breadCrumbs[0] = role;
          this.breadCrumbs[1] = year;
          this.previousScreens[0] = current;
        }
        this.advance();
      }
    }
  };
</script>
