<template>
  <!-- Toggle button -->
  <button class="c-button w-100 h-100"  :class="expanded ? 'c-button--outline' : ''" v-on:click="toggleExpanded">{{expanded ? settings.more_filters_close_text : settings.more_filters_show_text}}</button>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'layout', 'filters'],
    computed: {
      expanded: function expanded() {
        return this.$store.state.moreFiltersExpanded;
      }
    },
    methods: {
      toggleExpanded: function toggleExpanded() {
        this.$store.dispatch('updateMoreFiltersExpanded', !this.expanded);
        return !this.expanded;
      }
    }
  };
</script>
<style scoped>

</style>