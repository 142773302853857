import valToLower from './valToLower';

function arrayToLower(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('arrayToLower expects a single array');
  }
  return arr.map(a => valToLower(a));
}

export default arrayToLower;
