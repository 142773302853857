<template>

    <input-light-new classes="c-input--sm" :class="hasFocus ? '!c-input--open' : ''">
      <input slot="input" type="text" class="c-input__input" :title="settings.prompt_text" :placeholder="settings.prompt_text" v-model="localSelection" v-on:focus="hasFocus = true" v-on:blur="hasFocus = false"></input>
      <i slot="icon" class="fa fa-search c-input__icon--search"></i>
      <i v-if="localSelection.length" v-on:click="localSelection = ''" slot="clear" class="fa fa-times c-input__icon--clear"></i>
    </input-light-new>

</template>
<script type="text/javascript">
  import InputLightNew from './InputLightNew.vue';
  const SearchMixin = require('../mixins/SearchMixin');

  module.exports = {
    components: { InputLightNew },
    props: ['settings'],
    mixins: [SearchMixin],
    data() {
      return {
        hasFocus: false
      };
    },
  };
</script>
