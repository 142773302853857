const Vue = require('vue');
const Vuex = require('vuex');
const VueResource = require('vue-resource');

const Promise = require('bluebird');

import handleDataMap from 'tembo-js/handleDataMap';

import staticData from './data';
import utils from './utils';

Vue.use(Vuex);
Vue.use(VueResource);

const state = {
  isReady: false,
  settings: null,
  renderableLayout: null,
};

/* eslint-disable no-param-reassign, no-shadow */
const mutations = {
  setIsReady(state, isReady) {
    state.isReady = isReady;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
  setRenderableLayout(state, renderable) {
    state.renderableLayout = renderable;
  }
};
/* eslint-enable */

const actions = {
  loadAbout: function loadAbout(ctx) {
    ctx.commit('setIsReady', false);
    ctx.dispatch('loadConfig')
      .then(() => ctx.dispatch('initializeLanguage', 'about', {
        root: true
      }))
      .then(() => ctx.dispatch('initializeDatabase', 'home', { root: true }))
      .then(() => ctx.dispatch('loadData'))
      .then(() => ctx.dispatch('getRenderableLayout'))
      .then(() => ctx.commit('setIsReady', true));
  },
  loadConfig: function loadConfig(ctx) {
    const configDataKey = ctx.rootState.route.meta || 'about';
    return Vue.http.get(`/get_config/${configDataKey}`)
      .then((res) => {
        staticData.about = {};
        staticData.about.config = res.body.Finder;
        staticData.about.siteSettings = res.body.SITE_SETTINGS;
      });
  },
  loadData: function loadData(ctx) {
    //
    // load all data required for about page
    //
    var settingsList = staticData.about.config.defaults.api_routes;

    return Promise.map(settingsList, (route) => utils.getFromAPI(ctx, route))
      .then((allResponses) => {
        const unmapped = {};
        for (let i = 0, l = allResponses.length; i < l; i++) {
          unmapped[settingsList[i].name] = allResponses[i].body;
        }
        unmapped.defaults = staticData.about.config.defaults;
        unmapped.site_settings = staticData.about.siteSettings;
        const settings = handleDataMap(staticData.about.config.data_map.settings, unmapped);
        ctx.commit('setSettings', settings);
      });
  },
  getRenderableLayout: function getRenderableLayout(ctx) {
    function getRenderable(lay) {
      var renderable;
      var data;
      var keys;
      var renderableItem;
      var newData;
      var ok = true;
      var layout = JSON.parse(JSON.stringify(lay));
      var settings = JSON.parse(JSON.stringify(ctx.state.settings));
      // Reactive filters:
      // ** ReactiveFilterMixin.vue will determine if they should render
      // ** and filtering functions will determine if they should be used to filter

      // collects data property/properties for each item in the layout
      // skips & logs items with data defined that cannot be found
      if (typeof lay !== 'object') {
        console.error('cannot handle layout item', lay); // eslint-disable-line
      } else if (layout.tag && layout.data) {
        // if layout object requires data, all data must be good
        if (Array.isArray(layout.data)) {
          data = null;
          for (let i = 0, l = layout.data.length; i < l; i++) {
            newData = handleDataMap(layout.data[i], settings);
            if (!newData) {
              ok = false;
              console.error('No data found for key', layout.data[i]); // eslint-disable-line
            }
            data = utils.extend(data, newData);
          }
        } else {
          data = handleDataMap(layout.data, settings);
          if (!data) {
            ok = false;
            console.error('No data found for key', layout.data); // eslint-disable-line
          }
        }
        if (data && ok) {
          renderable = layout;
          renderable.data = data;
        }
      } else if (Array.isArray(layout)) {
        renderable = [];
        for (let i = 0, l = layout.length; i < l; i++) {
          renderableItem = getRenderable(layout[i]);
          if (renderableItem) {
            renderable.push(renderableItem);
          }
        }
      } else if (layout.tag) {
        // assume all tags are renderable - if not vue will throw error
        renderable = layout;
      } else if (layout && typeof layout === 'object') {
        renderable = {};
        keys = Object.keys(layout);
        for (let i = 0, l = keys.length; i < l; i++) {
          renderableItem = getRenderable(layout[keys[i]]);
          if (renderableItem) {
            renderable[keys[i]] = renderableItem;
          } else {
            console.error('Not adding layout item', keys[i], 'with missing data', layout[keys[i]].data, 'to layout'); // eslint-disable-line
          }
        }
      }
      if (layout.layout && renderable) {
        // layout must be renderable itself before checking for renderable sub-layout
        renderable.layout = getRenderable(layout.layout);
      }
      return renderable;
    }
    const renderable = getRenderable(staticData.about.config.layout);
    ctx.commit('setRenderableLayout', renderable);
  },
};

const getters = {

};

module.exports = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
