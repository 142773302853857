<template>
  <div v-if="renderableLayout">

    <!-- HEADERS -->
      <header class="header">
        <a href="#main-content" class="sr-only">Skip to main content</a>
        <div v-for="component in renderableLayout.header"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"
          :class="component.tag">
        </div>
      </header>

    <!-- CONTENTS -->
      <div class="content-container" id="main-content">
        <div v-for="component in renderableLayout.content"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"

          :class="component.tag">
        </div>
      </div>
    <!-- FOOTER -->
    <div
      :is="renderableLayout.footer.tag"
      :settings="renderableLayout.footer.data"
    ></div>
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  module.exports = {
    mixins: [AppMixinBase]
  };
</script>
<style type="text/css" scoped>
  .content-container{
    margin: 10px 30px;
  }
</style>