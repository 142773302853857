<template>
  <div class="d-flex flex-column hanging-indent">
    <template v-for="(data, index) in entityData">
        <a v-if="data && (data.href || data.text)" :href="data.href" :target="data.href && data.href.indexOf('http') > -1 ? '_blank' : ''" v-on:click="sendLinkClickEvent">{{data.text || data.href}}</a>
        <div v-else>{{data}}</div>
    </template>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  module.exports = {
    props: ['entityData', 'entityLink', 'entityId'],
    methods: {
      sendLinkClickEvent() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_list` : 'list';
        sendEvent({
          category: category,
          action: 'report_link',
          label: this.entityId
        });
      }
    }
  };
</script>
<style type="text/css" lang="scss">
    .hanging-indent {
        padding-left: .5em;
        text-indent: -.5em;
    }

</style>