<template>
  <div class="collapsable-filter" v-bind:class="show ? 'collapsable-filter--is-open' : 'collapsable-filter--is-closed'">

    <!-- FILTER HEADER -->
    <div class="filter-header" >
      <button :class="settings.is_subfilter ? 'filter-subtitle' : 'filter-title'" v-if="settings.group_title_text" v-on:click="handleTitleClick" class="o-slide-toggle__handle filter-toggle-handle">
        <i class="fal mr-2 o-slide-toggle__handle__icon" :class="show ? 'fa-minus-circle' : 'fa-plus-circle' "></i>
        <slot name="filter-prompt" v-if="!show" class="filter-prompt"></slot>
        <slot name="filter-alt-prompt" v-else class="filter-prompt"></slot>
      </button>
    </div>

    <!-- FILTER CONTENT -->
    <fieldset class="filter-content mt-2" style="display: none;">
      <legend class="filter-title" v-if="settings.group_title_text"><slot name="filter-title"></slot></legend>
      <p class="filter-group-description text-small" v-if="settings.description_text"><slot name="filter-group-description"></slot></p>
      <div class="selected-tally" v-if="settings.metadata.show_tally && selectionTally > 0"><slot name="filter-tally"></slot></div>
      <slot name="filter-content"></slot>
      <p class="filter-group-link text-small mt-2" v-if="settings.link_text && settings.link_url && settings.link_description_text"><slot name="filter-group-link"></slot></p>
    </fieldset>
  </div>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: ['settings', 'selectionTally'],
    data: function data() {
      var show;
      const collapse = this.settings.metadata.collapse_on_load;
      if (collapse === undefined || collapse === null) {
        show = false;
      } else {
        show = !collapse;
      }
      return {
        show: show
      };
    },
    methods: {
      handleTitleClick: function handleTitleClick() {
        var action;
        this.show = !this.show;
        if (this.show) {
          action = 'show_group';
          $(this.$el).find('.filter-content').slideDown();
        } else {
          action = 'hide_group';
          $(this.$el).find('.filter-content').slideUp();
        }
        sendEvent({
          category: 'filter',
          action: action,
          label: this.settings.metadata.label
        });
      }
    }
  };
</script>
<style scoped>
.o-slide-toggle__handle{
  background:  transparent;
  padding: 0;
}
/* .filter-toggle-handle{
  display:  flex;
  padding-bottom:  0;
  align-items: center;
} */
</style>