<template>
  <modal-component :show="show" @update:show="val => { show = val }" :click-away="true" class="language-toggle">
    <div>{{settings.group_title_text}}</div>
    <radio-button
      v-for="option in settings.options"
      :key="option.value"
      :value="option"
      :selected="settings.value"
      :name="settings.metadata.label">
    </radio-button>
    <button class="button button--cta" v-on:click="handleOK" v-on:keyup.enter="handleOK">{{settings.confirmation_text}}</button>
  </modal-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  import ModalComponent from './Modal.vue';
  import RadioButton from '../RadioButton.vue';

  module.exports = {
    props: ['show', 'value', 'settings'],
    components: { ModalComponent, RadioButton },
    data: function data() {
      var previous = this.settings.value;
      return {
        previous: previous
      };
    },
    methods: {
      handleOK: function handleOK() {
        var language = this.settings.value;

        // send language change event
        if (language !== this.previous) {
          sendEvent({
            category: 'languages',
            action: 'update',
            label: language
          });
          if (language === this.settings.default_val) {
            this.$route.router.push({ name: 'find' });
          } else {
            this.$route.router.push({ name: 'find', query: { lang: language } });
          }
        }
        this.previous = language;
        // this.show = false;
        this.$emit('update:show', false);
      }
    }
  };
</script>
