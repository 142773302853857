<template>
  <div>
    <input-light>
      <input slot="input" type="text" class="search-input c-input__input" :title="settings.prompt_text" :placeholder="settings.prompt_text" v-model="localSelection"></input>
      <i slot="icon" class="fa fa-search c-input__icon--search"></i>
    </input-light>
  </div>
</template>
<script type="text/javascript">
  import InputLight from './InputLight.vue';
  var SearchMixin = require('../mixins/SearchMixin');

  module.exports = {
    props: ['settings'],
    components: { InputLight },
    mixins: [SearchMixin]
  };
</script>
