<template>
  <div>
    <h3>{{currentScreen.promptText1[role.value]}}</h3>
    <div class="wizard-button-container">
      <button class="button button--full-width" v-on:click='nice'>{{currentScreen.niceExitText}}</button>
      <button class="button button--full-width" v-if='getRepeats(this.index) < wizardSettings.max_categories' v-on:click='next'>{{currentScreen.nextText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');
  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen',
      'index',
      'previousScreens',
      'role',
      'wizardSettings'
    ],
    methods: {
      next: function next() {
        var current;
        // data for updating previous screens
        current = this.copy(this.currentScreen);

        // this screen does not get a breadcrumb
        if (this.previousScreens.length - 1 < this.index) {
          this.previousScreens.push(current);
        } else {
          this.previousScreens[this.index] = current;
        }
        this.advance();
      },
      nice: function nice() {
        var current;
        // data for updating previous screens
        current = this.copy(this.currentScreen);

        // this screen does not get a breadcrumb
        if (this.previousScreens.length - 1 < this.index) {
          this.previousScreens.push(current);
        } else {
          this.previousScreens[this.index] = current;
        }
        this.niceExit();
      }
    }
  };
</script>