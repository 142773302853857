<template>
  <label class="radio_label">
    <input
      v-bind:disabled="disabled"
      class="radio"
      type="radio"
      :name="name"
      :value="value.value"
      v-model="localSelected">
    <!-- <span class="radio_visual"></span> -->
    <span class="radio_value">{{value.text}}</span>
  </label>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['value', 'selected', 'name', 'disabled'],
    computed: {
      localSelected: {
        get() {
          return this.selected;
        },
        set(val) {
          this.$emit('update:selected', val);
        }
      },
    },
  };
</script>