import range from 'lodash/range';
import sendEvent from 'tembo-js/sendEvent';

module.exports = {
  data: function data() {
    return {
      noresults: false,
      filled: [],
      hovered: [],
      focused: []
    };
  },
  computed: {
    master: function master() {
      return this.values.find(v => v.master);
    },
    masterIdx: function masterIdx() {
      var i;
      var l = this.values.length;
      if (!this.master) return -1;
      for (i = 0; i < l; i ++) {
        if (this.values[i].value === this.master.value) return i;
      }
      return -1;
    }
  },
  methods: {
    handleFills: function handleFills(state, masterIdx) {
      if (state === true) {
        if (this.settings.metadata.visual_fill === 'left') {
          this.filled = range(masterIdx + 1);
        } else if (this.settings.metadata.visual_fill === 'right') {
          this.filled = range(masterIdx, this.values.length);
        } else {
          this.filled = [masterIdx];
        }
      } else {
        this.filled = [];
      }
      return this.filled;
    },
    handleHovers: function handleHovers(state, hoveredIdx) {
      //
      // classes values above or below master (or neither) as 'filled'
      //
      if (state === true) {
        if (this.settings.metadata.visual_fill === 'left') {
          this.hovered = range(hoveredIdx + 1);
        } else if (this.settings.metadata.visual_fill === 'right') {
          this.hovered = range(hoveredIdx, this.values.length);
        } else {
          this.hovered = [hoveredIdx];
        }
      } else {
        this.hovered = [];
      }
      return this.hovered;
    },
    handleFocus: function handleFocus(state, focusedIdx) {
      //
      // classes values above or below master (or neither) as 'filled'
      //
      if (state === true) {
        if (this.settings.metadata.visual_fill === 'left') {
          this.focused = range(focusedIdx + 1);
        } else if (this.settings.metadata.visual_fill === 'right') {
          this.focused = range(focusedIdx, this.values.length);
        } else {
          this.focused = [focusedIdx];
        }
      } else {
        this.focused = [];
      }
      return this.focused;
    },
    handleReqs: function handleReqs(value, i, masterIdx) {
      const updatedValue = JSON.parse(JSON.stringify(value));
      //
      // sets values above or below master (or neither) as requirements
      //
      if (masterIdx === -1) {
        updatedValue.required = false;
      } else if (this.settings.metadata.requirement_fill === 'left') {
        if (i <= masterIdx) {
          updatedValue.required = true;
        } else {
          updatedValue.required = false;
        }
      } else if (this.settings.metadata.requirement_fill === 'right') {
        if (i >= masterIdx) {
          updatedValue.required = true;
        } else {
          updatedValue.required = false;
        }
      } else {
        if (i === masterIdx) {
          updatedValue.required = true;
        } else {
          updatedValue.required = false;
        }
      }
      return updatedValue;
    },
    handleMaster: function handleMaster(value, i, masterIdx) {
      //
      // sets the controlling option (actual selection by user)
      //
      const updatedValue = JSON.parse(JSON.stringify(value));
      if (i === masterIdx) updatedValue.master = true;
      else updatedValue.master = false;
      return updatedValue;
    },
    updateAllValues: function updateAllValues(masterIdx) {
      for (let i = 0, l = this.values.length; i < l; i ++) {
        let updatedValue = JSON.parse(JSON.stringify(this.values[i]));
        //
        // handle master property
        //
        updatedValue = this.handleMaster(updatedValue, i, masterIdx);
        //
        // handle required property
        //
        updatedValue = this.handleReqs(updatedValue, i, masterIdx);
        this.$store.dispatch('updateFilterValue', {
          filterValue: updatedValue,
          updateEntities: true
        });
      }
      //
      // handle filled property
      //
      this.handleFills(masterIdx > -1, masterIdx);
    },
    handleFilterClick: function handleFilterClick(checked, value, clickedIdx) {
      let action;
      let masterIdx;
      if (!value.master) {
        //
        // if the value clicked isn't the master already, make it so
        //
        action = 'select';
        masterIdx = clickedIdx;
      } else {
        //
        // if the value clicked is the master, clear all selections & fills
        //
        action = 'deselct';
        masterIdx = -1;
      }
      this.updateAllValues(masterIdx);
      sendEvent({
        category: 'filter',
        action: action + '_' + this.settings.metadata.label,
        label: value.value
      });
    }
  },
  mounted: function mounted() { // MAY NEED TO USE NEXTTICK
    var mIdx = this.masterIdx;
    if (mIdx > -1) {
      this.updateAllValues(mIdx);
    }
  },
  watch: {
    masterIdx: function watchMaster() {
      var mIdx = this.masterIdx;
      this.updateAllValues(mIdx);
    }
  }
};
