<!--
  mobile_filters_facets:
    mobile_filter_overlay_heading:
    mobile_filter_overlay_paragraph:
 -->
<template>
  <screen-overlay
    :show-overlay="show"
    v-on:closeOverlay="$emit('close')">
    <div class="container">
      <div class="row mb-20">
        <div class="col-12 mb-3">
          <h1 v-if="settings.mobile_filter_overlay_heading" class="p-screen-overlay__mobile-h1">{{settings.mobile_filter_overlay_heading}}</h1>
          <p v-if="settings.mobile_filter_overlay_paragraph">{{settings.mobile_filter_overlay_paragraph}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div style="margin-bottom: 60px;">
            <!-- address, filters, etc -->
            <component
              v-for="item, index in layout"
              :key="index"
              @close="$emit('close')"
              :is="item.tag"
              :settings="item.data"
              :layout="item.layout"
              class="mb-4"
            ></component>
          </div>

          <!-- "apply/close" mobile filters -->
          <button
            v-if="settings.close_mobile_filters_text"
            class="c-button font-weight-medium"
            v-on:click="$emit('close')"
          >
            <i class="far fa-sliders-h mr-2 d-none d-md-inline-flex"></i>
            <span>{{settings.close_mobile_filters_text}}</span>
          </button>

          <!-- apply filters, clear filters, etc -->
          <component
            v-for="item, index in layoutControls"
            :key="index"
            @close="$emit('close')"
            :is="item.tag"
            :settings="item.data"
            :layout="item.layout"
            class="d-inline-block"
          ></component>


        </div>
      </div>
    </div>
  </screen-overlay>
</template>
<script type="text/javascript">
  import ScreenOverlay from './ScreenOverlay.vue';
  module.exports = {
    components: { ScreenOverlay },
    props: ['settings', 'layout', 'layoutControls', 'show'],
  };
</script>