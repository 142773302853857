<template>
  <div class="findlite-heading">
    <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-sm-9 text-center">
            <h1>{{settings.header_text}}</h1>
          </div>
          <div class="col-12 col-sm-3">
            <a class="c-button c-button--cta w-100" :href="settings.button_url">{{settings.button_text}}</a>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['settings']
  };
</script>
<style>
  .findlite-heading{
    padding-top:  10px;
    padding-bottom:  10px;
  }
  .findlite-heading h1{
  
    font-size: 21px;
    font-weight: bold;
    line-height:  32px;
  /*   position: relative;
    top: 50%;
    transform: translateY(-50%); */
  }
</style>
