<template>
  <screen-overlay :show-overlay="showOverlay" @closeOverlay="$emit('close')" :close-text="settings.close_text">
    <compare
      :settings="settings"
      :layout="layout"
    ></compare>
  </screen-overlay>
</template>
<script type="text/javascript">
  import ScreenOverlay from './ScreenOverlay.vue';
  import Compare from '../compare/Compare.vue';

  module.exports = {
    components: { ScreenOverlay, Compare, },
    props: ['showOverlay', 'settings', 'layout'],
  };
</script>
