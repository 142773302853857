<template>
  <div class="entity-attributes__pre-k-rating">
    <div class="d-inline-flex flex-column align-items-start align-items-md-center">
      <div class="align-items-start align-items-md-center d-inline-flex flex-column mb-1 p-entity__attributes__title"><strong>{{strongText}}</strong> <span>{{subText}}</span></div>
      <!-- SCHOOL REPORT SCORE -->
      <div v-if="!missing">
        <span :class="'letter-grade__' + settings.early_ed_score_universe.indexOf(entityData.rating_text)">{{entityData.rating_text}} </span>
        <span v-if="entityData.score">/ {{entityData.score}}</span>
      </div>
      <!-- missing data -->
      <div v-else>
        <span class="report-card-letter letter-grade__">{{entityData}}</span>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'entityData', 'missing', 'strongText', 'subText']
  };
</script>