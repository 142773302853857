<!-- TODO: update for vue2 -->
<template>
  <div>
    <h3>{{currentScreen.shortText}}</h3>
    <p>{{currentScreen.longText}}</p>
    <div class="wizard-button-container">
      <button class="button button--full-width" v-on:click='enterWizard'>{{currentScreen.nextText}}</button>
      <button v-on:click='close(false)' class="button button--full-width">{{currentScreen.exitText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');
  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen'
    ],
    methods: {
      enterWizard: function enterWizard() {
        this.$dispatch('ResetAllFilters', true); // true = apply default selections to filters
        this.$dispatch('ClearAddress');
        this.advance();
      }
    }
  };
</script>