<template>
  <div v-if="settings.google" class="d-flex align-items-end p-footer__logo-text d-flex align-items-center text-small mr-2 lh-1" style="white-space: nowrap">
    <a class="d-inline-flex" href="http://translate.google.com" target="_blank">
    <attribution-google-color-svg :settings="settings" />
    </a>
  </div>
</template>
<script type="text/javascript">
  import AttributionGoogleColorSvg from '../assets/AttributionGoogleColorSvg.vue';
  module.exports = {
    components: {
      AttributionGoogleColorSvg,
    },
    props: ['settings']
  };
</script>

