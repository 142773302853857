// TODO:
// auth module
// session module

// dispatch actions (async)
  // commit mutations
    // update state

const Vue = require('vue');
const Vuex = require('vuex');
const VueResource = require('vue-resource');

const Promise = require('bluebird');
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';
import padStart from 'lodash/padStart';
import keyBy from 'lodash/keyBy';

import sendEvent from 'tembo-js/sendEvent';
import handleDataMap from 'tembo-js/handleDataMap';
import getDataByProperty from 'tembo-js/getDataByProperty';

const utils = require('./utils');
const filter = require('./filters');
const session = require('./session');
const distance = require('./distance');
const sort = require('./sort');
const filters = require('./filters');
const auth = require('./auth');

import { router } from '../../main';
import homepage from './homepage';
import about from './about';

import staticData from './data';

Vue.use(Vuex);
Vue.use(VueResource);


const state = {
  // once
  isLoaded: false,
  entitiesLoaded: false,
  loggedIn: false,
  // debugger
  debug: false,
  // features to use
  compareConfig: false,
  //
  // unmappedSettings: null, moved to staticData!
  //
  // config: null, moved to staticData!
  showIntro: null,
  entities: null,
  entityList: null,
  // settings: null, moved to staticData!
  sort: null,
  renderableLayout: null,
  // breakpoint
  breakpoint: null,
  database: null,
  // language
  language: null,
  // filters
  filters: null,
  filterIdList: null,
  filterValues: {},
  filterValueIdList: null,
  // from session or authdata
  home: null,
  about: null,
  favorites: [],
  compares: [],
  address: [],
  saveAfterLogin: null,
  userSort: null,
  userFilters: null,
  userShowIntro: null,
  // related
  favoritesDisabled: [],
  comparesDisabled: [],
  // boundary-related
  neighborhoodSchoolIds: [],
  transportSchoolIds: {},
  // filter visual
  moreFiltersExpanded: false,
  // pagination
  page: 1,
  resultsPerPage: null,
  // list of pending filters - for any filter
  pendingFilters: [],
  // address that is pending
  pendingAddress: [],
  // pending filter - for WrappedSelectAlertFilter
  pendingFilterAction: null,
  // modal related
  showModal: null,
  modalTag: null,
  modalData: null,
  modalLayout: null,
  // compare ribbon
  showRibbon: false,
  ribbonError: false,
  // maps
  mapsApiLoaded: false,
  // mobile
  mobileView: null,
  // filtered entities
  filtered: [],
  baseFilteredEntities: [],
};

/* eslint-disable no-shadow, no-param-reassign */
const mutations = {
  // setConfig: function setConfig(state, config) {
  //   staticData.config = config;
  // },
  // setUnmappedSettings: function setUnmappedSettings(state, settings) {
  //   state.unmappedSettings = settings;
  // },
  setAbout: function setAbout(state, about) {
    state.about = about;
  },
  setCompareConfig: function setcompareConfig(state, compareConfig) {
    state.compareConfig = compareConfig;
  },
  setLoginStatus: function setLoginStatus(state, loggedIn) {
    state.loggedIn = loggedIn;
  },
  setDebug: function setDebug(state, debug) {
    state.debug = debug;
  },
  setDatabase: function setDatabase(state, database) {
    state.database = database;
  },
  setLanguage: function setLanguage(state, lang) {
    state.language = lang;
  },
  setEntityList: function setEntityList(state, entityList) {
    state.entityList = entityList;
  },
  setEntities: function setEntities(state, entities) {
    state.entities = entities;
  },
  setRenderableLayout: function setRenderableLayout(state, renderable) {
    state.renderableLayout = renderable;
  },
  setIsLoaded: function setIsLoaded(state, isLoaded) {
    state.isLoaded = isLoaded;
  },
  setEntitiesLoaded: function setEntitiesLoaded(state, entitiesLoaded) {
    state.entitiesLoaded = entitiesLoaded;
  },
  setAddress: function setAddress(state, address) {
    state.address = address;
  },
  setFavorites: function setUserFavorites(state, favorites) {
    state.favorites = favorites;
  },
  setFavoritesDisabled: function setFavoritesDisabled(state, favoritesDisabled) {
    state.favoritesDisabled = favoritesDisabled;
  },
  setComparesDisabled: function setComparesDisabled(state, comparesDisabled) {
    state.comparesDisabled = comparesDisabled;
  },
  setHome: function setHome(state, home) {
    state.home = home;
  },
  setCompares: function setCompares(state, compares) {
    state.compares = compares;
  },
  setUserFilters: function setUserFilters(state, filters) {
    state.userFilters = filters;
  },
  setUserSort: function setUserSort(state, sort) {
    state.userSort = sort;
  },
  setUserShowIntro: function setUserShowIntro(state, showIntro) {
    state.userShowIntro = showIntro;
  },
  setSaveAfterLogin: function setSaveAfterLogin(state, saveAfterLogin) {
    state.saveAfterLogin = saveAfterLogin;
  },
  setShowIntro: function setShowIntro(state, showIntro) {
    state.showIntro = showIntro;
  },
  setMoreFiltersExpanded: function setMoreFiltersExpanded(state, moreFiltersExpanded) {
    state.moreFiltersExpanded = moreFiltersExpanded;
  },
  setPage: function setPage(state, page) {
    state.page = page;
  },
  setMobileView: function setMobileView(state, mobileView) {
    state.mobileView = mobileView;
  },
  setSort: function setSort(state, sort) {
    state.sort = sort;
  },
  setFilters: function setFilters(state, filters) {
    state.filters = filters;
  },
  setFilterIdList: function setFilterIdList(state, filterIdList) {
    state.filterIdList = filterIdList;
  },
  setFilterValues: function setFilterValues(state, filterValues) {
    state.filterValues = filterValues;
  },
  setFilterValueIdList: function setFilterValueIdList(state, filterValueIdList) {
    state.filterValueIdList = filterValueIdList;
  },
  setFilterValue: function setFilterValue(state, filterValue) {
    var filterValueId;
    if (filterValue.id) filterValueId = filterValue.id;
    else return;
    Vue.set(state.filterValues, filterValueId, filterValue);
  },
  setFilterValueProps: function setFilterValueProps(state, filterValue) {
    if (!filterValue.id) return;
    const filterValueId = filterValue.id;
    const updatedFilterValue = JSON.parse(JSON.stringify(state.filterValues[filterValueId]));
    const props = Object.keys(filterValue);
    for (let i = 0, l = props.length; i < l; i ++) {
      const prop = props[i];
      if (prop !== 'applyPending') {
        updatedFilterValue[prop] = filterValue[prop];
      }
    }
    Vue.set(state.filterValues, filterValueId, updatedFilterValue);
  },
  setPendingFilters: function setPendingFilters(state, pendingFilters) {
    state.pendingFilters = pendingFilters;
  },
  updatePendingFilters: function updatePendingFilters(state, newFilter) {
    const l = state.pendingFilters.length;
    Vue.set(state.pendingFilters, l, newFilter);
  },
  setPendingAddress: function setPendingAddress(state, pendingAddress) {
    state.pendingAddress = pendingAddress;
  },
  setModalInformation: function setModalInformation(state, { field, data }) {
    state[field] = data;
  },
  setShowRibbon: function setShowRibbon(state, show) {
    state.showRibbon = show;
  },
  setRibbonError: function setRibbonError(state, error) {
    state.ribbonError = error;
  },
  setMapsApiLoaded: function setMapsApiLoaded(state, loaded) {
    state.mapsApiLoaded = loaded;
  },
  setPendingFilterAction: function setPendingFilterAction(state, action) {
    state.pendingFilterAction = action;
  },
  setNeighborhoodSchoolIds: function setNeighborhoodSchoolIds(state, ids) {
    state.neighborhoodSchoolIds = ids;
  },
  setTransportSchoolIds: function setTransportSchoolIds(state, ids) {
    state.transportSchoolIds = ids;
  },
  setFilteredEntities: function setFilteredEntities(state, filteredEntities) {
    state.filtered = filteredEntities;
  },
  setBaseFilteredEntitiesList: function setBaseFilteredEntitiesList(state, baseFilteredEntities) {
    state.baseFilteredEntities = baseFilteredEntities;
  },
  setResultsPerPage: function setResultsPerPage(state, perPage) {
    state.resultsPerPage = perPage;
  },
  setBreakpoint: function setBreakpoint(state, breakpoint) {
    state.breakpoint = breakpoint;
  },
};
/* eslint-enable */

const actions = {
  loadFinder: function loadFinder(ctx, findView) {
    //
    // prevent rendering early
    //
    ctx.commit('setIsLoaded', false);
    ctx.dispatch('initializeBreakpoint');
    //
    // append script with appropriate key
    // could probably be done in App.vue still
    //
    return ctx.dispatch('getConfigs', findView)
    .then(() => ctx.dispatch('initializeLanguage'))
    .then(() => ctx.dispatch('initializeDatabase'))
    .then(() => ctx.dispatch('initializePagination'))
    .then(() => ctx.dispatch('getLoginStatus'))
    .then(() => ctx.dispatch('getAuthData'))
    .then(() => ctx.dispatch('getSessionData'))
    .then(() => ctx.dispatch('getSettings')) // collect data & run through datamap
    .then(() => ctx.dispatch('getHome'))
    .then(() => ctx.dispatch('getAbout'))
    .then(() => ctx.dispatch('initializeShowIntro'))
    .then(() => ctx.dispatch('initializeFilters'))
    // for this ^^ to happen after normalizing, filters.js would need more updates
    // TODO: fix, so that the initialize will work to make updates, too
    .then(() => ctx.dispatch('normalizeFilters', ctx.state.filters))
    .then(() => ctx.dispatch('mergeUserData'))
    .then(() => ctx.dispatch('applyUrlFilters'))
    .then(() => ctx.dispatch('initializeSort'))
    .then(() => ctx.dispatch('cleanUrl'))
    // must happen after filters in case of
    // filter-dependent sorts
    .then(() => ctx.dispatch('mergeUserSort'))
    .then(() => ctx.dispatch('getRenderableLayout'))
    .then(() => {
      ctx.commit('setIsLoaded', true);
    })
    .then(() => {
      if (ctx.state.showIntro) {
        ctx.dispatch('updateShowModal', { modal: 'intro' });
      }
    })
    .then(() => ctx.dispatch('getEntities'))
    .then(() => ctx.dispatch('applyUrlCompare'))
    .then(() => ctx.dispatch('saveAfterLogin'))
    // initial filtering
    .then(() => ctx.dispatch('initializeFilteredEntities'))
    .then(() => ctx.dispatch('initializeBaseFilteredEntities'))
    .then(() => ctx.dispatch('initializeFacetEntities'));
    // ctx.dispatch('updateFacetEntityCount')
    // .then(() => ctx.dispatch('applyDistanceToEntities'));
  },
  initializeBreakpoint: function initializeBreakpoint(ctx) {
    function getNewBreakpoint() {
      const breakpointValue = window.getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content').replace(/"/g, '');
      return breakpointValue;
    }

    function updateBreakpoint() {
      const breakpointValue = getNewBreakpoint();
      if (this.state.breakpoint !== breakpointValue) {
        // this.store.breakpoint = breakpointValue;
        this.dispatch('updateBreakpoint', breakpointValue);
      }
    }

    const initialBreakpoint = getNewBreakpoint();
    ctx.commit('setBreakpoint', initialBreakpoint);
    window.onresize = updateBreakpoint.bind(ctx);
  },
  initializeLanguage: function initializeLanguage(ctx, dataSource) {
    let lang;
    let config;
    if (dataSource) {
      config = staticData[dataSource].config;
    } else {
      config = staticData.config;
    }
    if (!config) {
      lang = 'en';
      console.error('Please make sure that you are loading Finder.yaml'); // eslint-disable-line no-console, max-len
    } else {
      const languageOptions = config.defaults.language_options;
      if (!state.route.query) {
        lang = languageOptions.default_value;
      } else if (!state.route.query.lang) {
        lang = languageOptions.default_value;
      } else if (languageOptions.options.indexOf(state.route.query.lang) === -1) {
        lang = languageOptions.default_value;
      } else {
        lang = state.route.query.lang;
      }
    }
    ctx.commit('setLanguage', lang);
  },
  initializeDatabase: function intitializeDatabase(ctx) {
    var db;
    if (state.route.query.db) {
      db = state.route.query.db;
      ctx.commit('setDatabase', db);
    }
  },
  initializePagination: function initializePagination(ctx) {
    let usePagination;
    if (!staticData.config || !staticData.config.defaults) {
      usePagination = false;
    } else {
      if (staticData.config.defaults.pagination
          && !staticData.config.defaults.pagination.results_per_page) {
        console.error('It looks like you are trying to paginate the results list. To paginate the results list, set defaults.pagination.results_per_page = <number> in Finder.yaml.'); // eslint-disable-line no-console, max-len
      }
      usePagination = staticData.config.defaults.pagination &&
        staticData.config.defaults.pagination.include
        && staticData.config.defaults.pagination.results_per_page;
    }

    staticData.usePagination = usePagination;

    if (usePagination) {
      ctx.commit('setResultsPerPage', staticData.config.defaults.pagination.results_per_page);
    }
  },
  getAuthData: function getAuthData(ctx) {
    var authList;
    var authGetter;
    var mutationName;
    //
    // if not logged in, we won't be able to get auth data
    //
    if (!ctx.state.loggedIn) return null;

    try {
      authList = staticData.config.defaults.persist.auth;
    } catch (e) {
      if (!authList.length) {
        authList = [];
      }
    }
    if (staticData.config.defaults.auth_prefix) {
      authGetter = utils.getAuthMethod(ctx, 'GetAuthItems');
    } else if (staticData.config.auth === 'true' ||
      (authList && authList.length && authList.length > 0)) {
      console.error('To use auth-based persistance, Finder.yaml must include valid defaults.auth_prefix'); // eslint-disable-line
      authList = [];
    }
    if (authGetter) {
      authGetter = auth[authGetter];
    }
    if (!authGetter) return null;

    return Promise.map(authList, item => authGetter(item))
    .then((authResults) => {
      for (let i = 0, l = authResults.length; i < l; i ++) {
        // results[authList[i]] = authResults[i];
        mutationName = `set_${authList[i]}`;
        mutationName = camelCase(mutationName);
        ctx.commit(mutationName, authResults[i]);
      }
    });
  },
  getLoginStatus: function getLoginStatus(ctx) {
    function parseCookies() {
      var cookieArr;
      var cookies = {};
      var cookie;
      var cookieKey;
      var cookieVal;
      if (!document.cookie) return cookies;
      cookieArr = document.cookie.split('; ');
      for (let i = 0, l = cookieArr.length; i < l; i ++) {
        cookie = cookieArr[i].split('=');
        cookieKey = cookie[0];
        cookieVal = cookie[1];
        cookieVal = cookieVal.replace(/\\(?=[^\\(\\*?)])/g, '');
        cookieVal = cookieVal.replace(/"/g, '');
        cookieVal = utils.convertBooleanStrings(cookieVal);
        cookieVal = utils.convertArrayStrings(cookieVal);
        cookies[cookieKey] = cookieVal;
      }
      return cookies;
    }
    const cookies = parseCookies();
    let loggedIn;
    if (cookies && cookies.hasOwnProperty('logged_in')) {
      loggedIn = cookies.logged_in;
    } else {
      loggedIn = false;
    }
    ctx.commit('setLoginStatus', loggedIn);
  },
  getSessionData: function getSessionData(ctx, dataSource) {
    //
    // request all fields listed in
    // Finder.yaml Finder.persist.session
    // and apply the data retrieved to the state
    //
    let sessionList;
    let mutationName;
    let config;
    if (dataSource) {
      config = staticData[dataSource].config;
    } else {
      config = staticData.config;
    }
    try {
      sessionList = config.defaults.persist.session;
    } catch (e) {
      if (!sessionList) sessionList = [];
    }
    return Promise.map(sessionList, item => session.getFromSession(ctx, item))
    .then((sessionResults) => {
      for (let i = 0, l = sessionResults.length; i < l; i ++) {
        const sessionListItem = sessionList[i];
        let sessionListName;
        if (typeof sessionListItem === 'object') {
          sessionListName = sessionListItem.name;
        } else {
          sessionListName = sessionListItem;
        }
        mutationName = `set_${sessionListName}`;
        mutationName = camelCase(mutationName);
        ctx.commit(mutationName, sessionResults[i]);
      }
    });
  },
  getSettings: function getSettings(ctx) {
    //
    // get all data from api that isn't entities
    //
    var settingsList = staticData.config.defaults.api_routes
      .filter(route => route.name !== 'entities');

    return Promise.map(settingsList, (route) => utils.getFromAPI(ctx, route))
    .then((allResponses) => {
      const unmappedSettings = {};
      for (let i = 0, l = allResponses.length; i < l; i ++) {
        unmappedSettings[settingsList[i].name] = allResponses[i].body;
      }
      unmappedSettings.defaults = staticData.config.defaults;
      unmappedSettings.site_settings = staticData.config.site_settings;
      staticData.unmappedSettings = unmappedSettings;
      const settings = handleDataMap(staticData.config.data_map.settings, unmappedSettings);
      if (settings.nav_mobile && settings.nav_mobile.value) {
        ctx.commit('setMobileView', settings.nav_mobile.value); // set default mobile view
      }
      staticData.settings = settings;
      // ctx.commit('setSettings', settings);
    })
    .then(null, (err) => {
      console.error('failed to load', err); // eslint-disable-line no-console
    });
  },
  getHome: function getHome(ctx, dataSource) {
    let settings;
    if (dataSource) {
      settings = staticData[dataSource].settings;
    } else {
      settings = staticData.settings;
    }
    let newHome = null;
    if (!settings) {
      // pass;
    } else if (
      //
      // if configs say plot a default and no user-supplied address exists
      // plot the default location with default address text
      //
      settings &&
      settings.address_entry &&
      (!state.address || !state.address.length)) {
      newHome = { user: false, address: settings.address_entry.default_address };
    } else if (!state.address || !state.address.length) {
      newHome = null;
    } else {
      newHome = state.address[0];
    }
    ctx.commit('setHome', newHome);
    return newHome;
  },
  getAbout: function getAbout(ctx) {
    let newAbout = null;
    if (!staticData.settings) {
      // pass;
    } else if (
      //
      // if configs say plot a default and no user-supplied address exists
      // plot the default location with default address text
      //
      staticData.settings &&
      staticData.settings.map_component &&
      (!state.address || !state.address.length)) {
      newAbout = {
        user: false,
        address: staticData.settings.address_entry.default_address
      };
    } else if (!state.address || !state.address.length) {
      newAbout = null;
    } else {
      newAbout = state.address[0];
    }
    ctx.commit('setAbout', newAbout);
    return newAbout;
  },
  initializeShowIntro: function initializeShowIntro(ctx) {
    if (staticData.settings.intro_card) {
      try {
        ctx.commit('setShowIntro', staticData.settings.intro_card.show_intro_on_load);
      } catch (e) {
        console.error('Set settings.intro_card.show_intro_on_load in Finder.yaml'); // eslint-disable-line max-len, no-console
      }
    }
  },
  initializeSort: function initializeSort(ctx) {
    let initialSort;
    const query = ctx.state.route.query;
    const queryKeys = Object.keys(query);
    const urlSort = queryKeys.filter(s => s.indexOf('sort') > -1);
    if (urlSort.length) {
      const initSort = urlSort[0];
      initialSort = {
        field: initSort.replace('sort_', '').replace('-', '.').toLowerCase(),
        direction: query[initSort],
      };
    } else if (!staticData.settings.sort_options) {
      initialSort = '';
    } else if (staticData.settings.sort_options.conditional_defaults) {
      initialSort = sort.getConditionalDefaultSort(ctx.getters, staticData.settings);
    } else {
      initialSort = staticData.settings.sort_options.value;
    }
    ctx.commit('setSort', initialSort);
  },
  normalizeFilters: function normalizeFilters(ctx, filtersFromSettings) {
    if (!filtersFromSettings) return;
    function collectAllFilterIds(filtersWithSubfiltersMaybe) {
      var filterKeys = Object.keys(filtersWithSubfiltersMaybe);
      var allFilterKeys = filterKeys;

      for (let i = 0, l = filterKeys.length; i < l; i ++) {
        const filterKey = filterKeys[i];
        const oneFilter = filtersWithSubfiltersMaybe[filterKey];
        if (oneFilter.subfilters) {
          allFilterKeys = allFilterKeys.concat(collectAllFilterIds(oneFilter.subfilters));
        }
      }
      return allFilterKeys;
    }

    function collectAllFilters(filtersWithSubfiltersMaybe) {
      var filterKeys = Object.keys(filtersWithSubfiltersMaybe);
      var allFilters = {};

      for (let i = 0, l = filterKeys.length; i < l; i ++) {
        const filterKey = filterKeys[i];
        const oneFilter = filtersWithSubfiltersMaybe[filterKey];
        allFilters[filterKey] = oneFilter;
        if (oneFilter.subfilters) {
          let allSubfilters = collectAllFilters(oneFilter.subfilters);
          allSubfilters = JSON.parse(JSON.stringify(allSubfilters));
          Object.assign(allFilters, allSubfilters);
        }
      }
      return allFilters;
    }

    //
    // only collect ids of top-level filters
    //
    const topLevelFilterIds = Object.keys(filtersFromSettings);
    //
    // collect ids of all fitlers, including subfilters
    //
    const allFilterIds = collectAllFilterIds(filtersFromSettings);
    //
    // collect all filters, including subfilters
    //
    const allFilters = collectAllFilters(filtersFromSettings);
    const valueIds = [];
    //
    // collect all values & valueIds
    //
    for (let i = 0, l = allFilterIds.length; i < l; i ++) {
      const filterId = allFilterIds[i];
      // if(filterId === 'name_search') debugger;
      const oneFilter = allFilters[filterId];
      if (Array.isArray(oneFilter.value)) {
        for (let j = 0, k = oneFilter.value.length; j < k; j ++) {
          //
          // create id: <filter_key>_<padded_index>
          // if we have more than 1000 filter values,
          // we have other problems
          //
          const filterValueId = `${filterId}_${padStart(String(j), 3, '0')}`;
          //
          // add value id to list
          //
          valueIds.push(filterValueId);

          const filterValue = oneFilter.value[j];
          //
          // overwrite or add value.id
          //
          filterValue.id = filterValueId;
          //
          // replace value on the filter with value id
          //
          oneFilter.value[j] = filterValueId;
          //
          // add value to state
          //
          ctx.commit('setFilterValue', filterValue);
        }
      } else if (oneFilter.hasOwnProperty('value')) {
        valueIds.push(filterId);
        const filterValue = {
          id: filterId,
          value: oneFilter.value,
          type: oneFilter.metadata.default_type,
          facet: oneFilter.metadata.facet,
        };
        oneFilter.value = filterId;
        ctx.commit('setFilterValue', filterValue);
      }
      if (oneFilter.subfilters) {
        //
        // replace subfilters with their keys
        //
        const subfilterKeys = Object.keys(oneFilter.subfilters);
        for (let j = 0, k = subfilterKeys.length; j < k; j ++) {
          oneFilter.subfilters[subfilterKeys[j]] = subfilterKeys[j];
        }
      }
    }
    //
    // values are already committed in the loops
    //

    ctx.commit('setFilters', allFilters);
    ctx.commit('setFilterIdList', topLevelFilterIds);
    ctx.commit('setFilterValueIdList', valueIds);
  },
  initializeFilters: function initializeFilters(ctx) {
    if (!staticData.settings.filters) return;
    const filtersFromSettings = filter.initializeAllFilters(staticData.settings.filters);
    ctx.commit('setFilters', filtersFromSettings);
  },
  mergeUserData: function mergeUserData(ctx) {
    var settings = staticData.settings;
    var userShowIntro = ctx.state.userShowIntro;
    var userFilters = ctx.state.userFilters;
    //
    // apply user's preference for showing intro card
    //
    if (userShowIntro && userShowIntro.length > 0) {
      settings.intro_card.show_intro_on_load = userShowIntro[0];
      ctx.commit('setShowIntro', userShowIntro[0]);
    }
    //
    // apply user's filter selections:
    // parse minified filters from session object
    // apply to global settings for use in app
    //
    if (userFilters) {
      // for each filter group
      for (let i = 0, l = ctx.state.filterIdList.length; i < l; i++) {
        const filterId = ctx.state.filterIdList[i];
        const filterGroupValueIds = ctx.state.filters[filterId].value;
        if (filterGroupValueIds) { // not all filters will have values.
                                   // they may only have subfilters
          filters.mergeUserFilterData(ctx, userFilters, filterGroupValueIds);
        } else if (ctx.state.filters[filterId].subfilters) {
          const subfilterKeys = Object.keys(ctx.state.filters[filterId].subfilters);
          for (let j = 0, k = subfilterKeys.length; j < k; j ++) {
            const key = subfilterKeys[j];
            const subfilterValueIds = ctx.state.filters[key].value;
            if (subfilterValueIds) {
              filters.mergeUserFilterData(ctx, userFilters, subfilterValueIds);
            }
          }
        }
      }
    }
    staticData.settings = settings;
    // ctx.commit('setSettings', settings);
  },
  applyUrlFilters: function applyUrlFilters(ctx) {
    const query = ctx.state.route.query;
    const queryKeys = Object.keys(query);
    for (let i = 0, l = queryKeys.length; i < l; i ++) {
      const key = queryKeys[i];
      if (key.toLowerCase().indexOf('filter') > -1) {
        const filterId = key.replace('filter_', '').toLowerCase();
        const value = query[key].toLowerCase().split(',');

        // check that the filter key exists
        if (!ctx.state.filters[filterId]) {
          // eslint-disable-next-line no-console
          console.error(`no such filter ${filterId}`);
          return null;
        }
        const queryFilter = ctx.state.filters[filterId];
        // check that the filter should accept url params
        if (!queryFilter.metadata.url_filter) {
          // eslint-disable-next-line no-console
          console.error(`cannot load url filters for ${filterId}. add url_filter to config`);
          return null;
        }

        // get keys for the filter's value ids
        const filterValueKeys = queryFilter.value;
        if (!Array.isArray(filterValueKeys)) {
          // eslint-disable-next-line no-console
          console.error(`can only currently handle list filters. ${filterId} is not`);
          return null;
        }
        const filterValues = filterValueKeys.map(k => ctx.state.filterValues[k]);

        // allow applying the default filter value
        if (value.indexOf('default') > -1) {
          const applyDefaults = true;
          const updateEntities = true;
          return ctx.dispatch('resetOneFilter', { filter: queryFilter, applyDefaults, updateEntities }); // eslint-disable-line max-len
        }

        // otherwise:
        // map filter value ids to actual objects
        let queryFilterValue = value
        .map(v => filterValues.find(fv => fv.value.toLowerCase() === v))
        .filter(v => v || v === 0);
        if (!queryFilterValue.length) {
          // eslint-disable-next-line no-console
          console.error(`no ${filterId} value found matching ${value}`);
          return null;
        }

        queryFilterValue = JSON.parse(JSON.stringify(queryFilterValue));
        queryFilterValue = queryFilterValue.map(v => {
          const newV = v;
          newV.required = true;
          return newV;
        }).slice(0, 1); // change this if we want to allow more values

        const applyDefaults = false;
        const updateEntities = true;
        return ctx.dispatch('resetOneFilter', { filter: queryFilter, applyDefaults, updateEntities }) // eslint-disable-line max-len
        .then(Promise.map(queryFilterValue, qfv => {
          ctx.dispatch('updateFilterValue', { filterValue: qfv, updateEntities });
        }));
      }
    }
    return null;
  },
  cleanUrl: function cleanUrl(ctx) {
    const query = JSON.parse(JSON.stringify(ctx.state.route.query));
    if (query.hasOwnProperty('temp_params')) {
      const tempParams = query.temp_params.split(',');
      for (let i = 0, l = tempParams.length; i < l; i++) {
        if (query[tempParams[i]]) delete query[tempParams[i]];
      }
      delete query.temp_params;
      router.push({ query: query });
    }
  },
  applyUrlCompare: function applyUrlCompare(ctx) {
    const query = ctx.state.route.query;
    if (query && query.compareids) {
      const compareIds = query.compareids.split(',');
      const compares = compareIds
        .filter(id => ctx.state.entities[id])
        .map(id => ({ id: id, name: ctx.state.entities[id].name }));

      // if there aren't valid ids in the url, don't overwrite what's already in the session
      if (!compares.length) return null;

      // if compares are valid, empty compares in session & overwrite
      ctx.commit('setCompares', compares);
      return session.replaceSessionItem(ctx, 'compares', [])
      .then(() => { // eslint-disable-line arrow-body-style
        return Promise.map(compares, c => session.addSessionItem(ctx, 'compares', c));
      });
    }
    return null;
  },
  mergeUserSort: function mergeUserSort(ctx) {
    //
    // apply user's sort selection
    //
    const userSort = ctx.state.userSort;
    if (userSort && userSort.length > 0) {
      ctx.commit('setSort', userSort[0]);
    }
  },
  getEntities: function getEntities(ctx) {
    const entitiesRoute = staticData.config.defaults.api_routes
      .find(route => route.name === 'entities');
    return utils.getFromAPI(ctx, entitiesRoute)
    .then(res => {
      const unmappedEntities = { entities: res.body };
      const settingsKeys = Object.keys(staticData.unmappedSettings);
      for (let i = 0, l = settingsKeys.length; i < l; i ++) {
        const key = settingsKeys[i];
        unmappedEntities[key] = staticData.unmappedSettings[key];
      }
      const entityList = handleDataMap(staticData.config.data_map.entities, unmappedEntities);
      const entities = {};
      entityList.forEach(e => {
        entities[e.id] = e;
      });

      ctx.commit('setEntityList', entityList.map(e => e.id));
      ctx.commit('setEntities', entities);
      // ctx.commit('setEntitiesLoaded', true);
      // schools loaded event
    });
  },
  getConfigs: function getConfigs(ctx, findView) {
    let configDataKey = ctx.rootState.route.meta;
    if (typeof configDataKey === 'string') {
      configDataKey = configDataKey === 'dynamic' ? findView : configDataKey;
    } else {
      configDataKey = '';
    }
    let url;
    if (configDataKey) {
      url = `/get_config/${configDataKey}`;
    } else {
      url = '/get_config';
    }

    //
    // parse all top-level config data
    //
    return Vue.http.get(url)
    .then((res) => {
      const suiteConfig = res.data;
      const siteSettings = suiteConfig.SITE_SETTINGS; // eslint-disable-line
      const findConfig = suiteConfig.Finder;
      const layout = findConfig.layout;
      const datamap = findConfig.data_map;
      const defaults = findConfig.defaults;
      let sessionItems = null;
      if (defaults && defaults.persist) {
        sessionItems = defaults.persist.session || null;
      }
      const config = {
        suite_config: suiteConfig,
        site_settings: siteSettings,
        auth: siteSettings.auth.toString().toLowerCase(),
        findConfig: findConfig,
        layout: layout,
        data_map: datamap,
        defaults: defaults,
        session_url_map: session.getSessionUrlMap(sessionItems)
      };
      staticData.config = config;
      ctx.commit('setCompareConfig', {
        use: siteSettings.include_compare || false,
        min: siteSettings.compare_minimum || 2,
        max: siteSettings.compare_maximum || 3,
        routes: siteSettings.compare_routes || null,
      });
      const isDebug = staticData.config.defaults.env_vars.find_debug === 'true';
      ctx.commit('setDebug', isDebug, { root: true });
    });
  },
  getRenderableLayout: function getRenderableLayout(ctx) {
    function getRenderable(lay) {
      var renderable;
      var data;
      var keys;
      var renderableItem;
      var newData;
      var ok = true;
      var layout = JSON.parse(JSON.stringify(lay));
      var settings = JSON.parse(JSON.stringify(staticData.settings));
      settings.filters = JSON.parse(JSON.stringify(ctx.getters.filtersWithSubfilters));
      // Reactive filters:
      // ** ReactiveFilterMixin.vue will determine if they should render
      // ** and filtering functions will determine if they should be used to filter

      // collects data property/properties for each item in the layout
      // skips & logs items with data defined that cannot be found
      if (typeof lay !== 'object') {
        console.error('cannot handle layout item', lay); // eslint-disable-line
      } else if (layout.tag && layout.data) {
        // if layout object requires data, all data must be good
        if (Array.isArray(layout.data)) {
          data = null;
          for (let i = 0, l = layout.data.length; i < l; i ++) {
            newData = handleDataMap(layout.data[i], settings);
            if (!newData) {
              ok = false;
              console.error('No data found for key', layout.data[i]); // eslint-disable-line
            }
            data = utils.extend(data, newData);
          }
        } else {
          data = handleDataMap(layout.data, settings);
          if (!data) {
            ok = false;
            console.error('No data found for key', layout.data); // eslint-disable-line
          }
        }
        if (data && ok) {
          renderable = layout;
          renderable.data = data;
        }
      } else if (Array.isArray(layout)) {
        renderable = [];
        for (let i = 0, l = layout.length; i < l; i ++) {
          renderableItem = getRenderable(layout[i]);
          if (renderableItem) {
            renderable.push(renderableItem);
          }
        }
      } else if (layout.tag) {
        // assume all tags are renderable - if not vue will throw error
        renderable = layout;
      } else if (layout && typeof layout === 'object') {
        renderable = {};
        keys = Object.keys(layout);
        for (let i = 0, l = keys.length; i < l; i ++) {
          renderableItem = getRenderable(layout[keys[i]]);
          if (renderableItem) {
            renderable[keys[i]] = renderableItem;
          } else {
            console.error('Not adding layout item', keys[i], 'with missing data', layout[keys[i]].data, 'to layout'); // eslint-disable-line
          }
        }
      }
      if (layout.layout && renderable) {
        // layout must be renderable itself before checking for renderable sub-layout
        renderable.layout = getRenderable(layout.layout);
      }
      return renderable;
    }
    const renderable = getRenderable(staticData.config.layout);
    ctx.commit('setRenderableLayout', renderable);
  },
  updateMoreFiltersExpanded: function updateMoreFiltersExpanded(ctx, moreFiltersExpanded) {
    ctx.commit('setMoreFiltersExpanded', moreFiltersExpanded);
  },
  updatePage: function updatePage(ctx, page) {
    ctx.commit('setPage', page);
  },
  updateMobileView: function updateMobileView(ctx, mobileView) {
    ctx.commit('setMobileView', mobileView);
  },
  updateAddress: function updateAddress(ctx, address) {
    ctx.commit('setAddress', address);
    let dataSource = null;
    if (ctx.state.route.name !== 'find') {
      dataSource = ctx.state.route.name;
    }
    ctx.dispatch('getHome', dataSource);
    //
    // update entities with distance
    //
    return ctx.dispatch('updateSessionItem', { field: 'address', data: address })
    .then(() => ctx.dispatch('updateAllEntities', ['distance']))
    .then(() => ctx.dispatch('updateFilteredEntities'));
  },
  saveAfterLogin: function saveAfterLogin(ctx) {
    if (ctx.state.loggedIn && ctx.state.saveAfterLogin && ctx.state.saveAfterLogin.length > 0) {
      const favoriteIds = ctx.state.saveAfterLogin
                              .filter(s => s.field === 'favorites')
                              .map(s => s.data.id);
      return Promise.map(favoriteIds, id => { // eslint-disable-line arrow-body-style
        const newFavorite = { id, favorite: true, name: ctx.state.entities[id].name };
        return ctx.dispatch('updateFavorite', newFavorite);
      })
      .then(() => ctx.dispatch('updateSessionItem', { field: 'save_after_login', data: [] }));
    }
    return null;
  },
  updateAllEntities: function updateAllEntities(ctx, include) {
    let entities = ctx.getters.entitySet;
    const filtersSet = ctx.getters.filterSet;
    if (!ctx.getters.entitySet || !ctx.getters.filterSet) return;
    if (!include || include.indexOf('filter-dependent-fields') > -1) {
      entities = filters.applyFilterDependentFields(staticData.settings, filtersSet, entities);
    }
    if (!include || include.indexOf('relaxed') > -1) {
      const resetMatchCt = true;
      entities = filters.applyFilters(filtersSet, entities, 'relaxed', resetMatchCt);
    }
    if (!include || include.indexOf('favorites') > -1) {
      entities = session.applyFavorites(state.favorites, entities);
    }
    if (!include || include.indexOf('compares') > -1) {
      entities = session.applyCompares(state.compares, entities);
    }
    if (!include || include.indexOf('distance') > -1) {
      entities = distance.applyDistanceToEntities(ctx, entities);
    }

    if (ctx.getters.home && ctx.getters.home.user ||
      (staticData.settings.boundaries && staticData.settings.boundaries.neighborhood_on_default)) {
      if (!include || include.indexOf('neighborhood') > -1) {
        entities = session.applyNeighborhood(ctx.state.neighborhoodSchoolIds, entities);
      }
    }
    if (Object.keys(ctx.state.transportSchoolIds).length > 0) {
      if (!include || include.indexOf('transport') > -1) {
        entities = session.applyTransportation(ctx.state.transportSchoolIds, entities);
      }
    }

    //
    // set all entities
    //
    //
    const entityDict = keyBy(entities, 'id');
    ctx.commit('setEntities', entityDict);
  },
  updateFilteredEntities: function updateFilteredEntities(ctx, include) {
    let filtered = ctx.getters.entitySet;
    if (!filtered) return;
    if (!ctx.getters.filterSet) {
      ctx.commit('setFilteredEntities', filtered);
      return;
    }
    const setBase = include && include.indexOf('base') > -1;

    if (!include || include.indexOf('strict') > -1) {
      filtered = filters.applyFilters(ctx.getters.filterSet, ctx.getters.entitySet, 'strict');
    } else if (setBase && ctx.getters.useFacets) {
      filtered = filters.applyFilters(ctx.getters.filterSet, ctx.getters.entitySet, 'strict', null, true); // eslint-disable-line max-len
    } else {
      filtered = JSON.parse(JSON.stringify(ctx.state.filtered));
    }
    if (!include || include.indexOf('relaxed') > -1) {
      const resetMatchCt = true;
      filtered = filters.applyFilters(ctx.getters.filterSet, filtered, 'relaxed', resetMatchCt);
    }
    if (!include || include.indexOf('sort') > -1) {
      filtered = sort.applySort(filtered, ctx.getters.sortOptions);
    }

    //
    // set base filtered list for facet calculations, if applicable
    //
    if (setBase && ctx.getters.useFacets) {
      ctx.commit('setBaseFilteredEntitiesList', filtered.map(f => f.id));
    }
    //
    // set filtered
    //
    ctx.commit('setFilteredEntities', filtered);
  },
  initializeFilteredEntities: function initializeFilteredEntities(ctx) {
    return ctx.dispatch('updateAllEntities')
    .then(() => { ctx.dispatch('updateFilteredEntities', ['strict', 'relaxed', 'sort']); })
    .then(() => { ctx.commit('setEntitiesLoaded', true); });
  },
  initializeBaseFilteredEntities: function initializeBaseFilteredEntities(ctx) {
    if (!ctx.getters.useFacets) return;
    let filtered = ctx.getters.entitySet;
    if (!filtered) return;
    if (! ctx.getters.filterSet) {
      ctx.commit('setBaseFilteredEntitiesList', filtered.map(item => item.id));
      return;
    }

    const excludeFacets = true;
    filtered = filters.applyFilters(ctx.getters.filterSet, ctx.getters.entitySet, 'strict', null, excludeFacets); // eslint-disable-line max-len

    //
    // set base filtered list for facet calculations
    //
    ctx.commit('setBaseFilteredEntitiesList', filtered.map(item => item.id));
  },
  initializeFacetEntities: function initializeFacetEntities(ctx) {
    if (!ctx.getters.useFacets) return;
    const filterValues = ctx.state.filterValues;
    const filterValueIdList = ctx.state.filterValueIdList;
    for (let i = 0, l = filterValueIdList.length; i < l; i ++) {
      const key = filterValueIdList[i];
      const filterValue = filterValues[key];
      if (filterValue.facet) {
        let filterGroupSlug = filterValue.id;
        if (ctx.state.filterIdList.indexOf(filterGroupSlug) === -1) {
          filterGroupSlug = filterGroupSlug.split('_');
          filterGroupSlug = filterGroupSlug.slice(0, filterGroupSlug.length - 1).join('_');
        }
        const filterGroupMetadata = JSON.parse(JSON.stringify(ctx.state.filters[filterGroupSlug].metadata)); // eslint-disable-line max-len
        const allowEntityCounts = !filterGroupMetadata.hide_facet_entity_count || false;
        if (allowEntityCounts) {
          const updatedFacet = JSON.parse(JSON.stringify(filterValue));
          const simpleFacet = {
            metadata: filterGroupMetadata,
          };
          if (Array.isArray(ctx.state.filters[filterGroupSlug].value)) {
            simpleFacet.value = [updatedFacet];
          } else {
            simpleFacet.value = updatedFacet;
          }
          updatedFacet.entities = filters.getFacetEntities(simpleFacet, ctx.getters.entitySet); // eslint-disable-line max-len
          ctx.commit('setFilterValueProps', updatedFacet);
        }
      }
    }
  },
  updateSort: function updateSort(ctx, sort) { // eslint-disable-line no-shadow
    ctx.commit('setSort', sort);
    ctx.dispatch('updateSessionItem', { field: 'user_sort', data: [sort] });
    //
    //
    //
    ctx.dispatch('updateFilteredEntities', ['sort']);
  },
  updateSessionItem: function updateSessionItem(ctx, { field, data }) {
    let config;
    if (ctx.state.route.name === 'find') {
      config = staticData.config;
    } else {
      config = staticData[ctx.state.route.name].config;
    }
    try {
      if (config.session_url_map.hasOwnProperty(field)) {
        return session.replaceSessionItem(ctx, field, data);
      }
    } catch (e) {
      // pass - no sessions available
    }
    return new Promise((resolve) => {
      resolve();
    });
  },
  resetAllFilters: function resetAllFilters(ctx, applyDefaults) {
    const filterKeys = ctx.state.filterIdList;
    for (let i = 0, l = filterKeys.length; i < l; i ++) {
      const key = filterKeys[i];
      const oneFilter = ctx.state.filters[key];
      if (oneFilter.metadata.reset) {
        ctx.dispatch('resetOneFilter', {
          filter: oneFilter,
          applyDefaults: applyDefaults,
          updateEntities: false,
        });
      }
    }
    return ctx.dispatch('updateAllEntities')
    .then(() => ctx.dispatch('updateFilteredEntities', ['strict', 'relaxed', 'sort', 'base']))
    .then(() => ctx.dispatch('saveFiltersToSession'));
  },
  resetFacets: function resetFacets(ctx, { forceReset, applyDefaults, updateEntities, defaultsOnly }) { // eslint-disable-line max-len
    const filterKeys = ctx.state.filterIdList;
    for (let i = 0, l = filterKeys.length; i < l; i ++) {
      const key = filterKeys[i];
      const oneFilter = ctx.state.filters[key];
      let resetMe = oneFilter.metadata.facet_reset;
      resetMe = resetMe === undefined || resetMe;
      if (oneFilter.metadata.facet && (resetMe || forceReset)) {
        ctx.dispatch('resetOneFilter', {
          filter: oneFilter,
          updateEntities: false,
          applyDefaults: applyDefaults,
        });
      } else if (oneFilter.metadata.facet && defaultsOnly && oneFilter.metadata.default_requirements) { // eslint-disable-line max-len
        ctx.dispatch('resetOneFilter', {
          filter: oneFilter,
          updateEntities: false,
          applyDefaults: applyDefaults,
        });
      }
    }
    if (updateEntities) {
      ctx.dispatch('updateAllEntities')
      .then(() => ctx.dispatch('updateFilteredEntities', ['strict', 'relaxed', 'sort']));
    }
    ctx.dispatch('saveFiltersToSession');
  },
  resetFiltersByBreakpoint: function resetFiltersByBreakpoint(ctx, breakpoint) {
    //
    // this allowed TN's FIND to hide the map
    // filter entirely on smaller breakpoints
    // and clear the county filter at the same time
    //
    if (!ctx.state.filterIdList || !ctx.state.filterIdList.length) return;
    const filterKeys = ctx.state.filterIdList;
    let filtersUpdated = false;
    const itemsToUpdate = ['strict', 'relaxed', 'sort'];
    for (let i = 0, l = filterKeys.length; i < l; i ++) {
      const key = filterKeys[i];
      const oneFilter = ctx.state.filters[key];
      if (oneFilter.metadata.reset_size &&
        (oneFilter.metadata.reset_size === breakpoint || oneFilter.metadata.reset_size.indexOf(breakpoint) > -1)) { // eslint-disable-line max-len
        filtersUpdated = true;
        ctx.dispatch('resetOneFilter', {
          filter: oneFilter,
          applyDefaults: false,
          updateEntities: false,
        });
      }
      if (ctx.getters.useFacets && oneFilter.metadata.type === 'strict' && !oneFilter.metadata.facet) { // eslint-disable-line max-len
        itemsToUpdate.push('base');
      }
    }
    if (filtersUpdated) {
      ctx.dispatch('updateAllEntities')
      .then(() => { ctx.dispatch('updateFilteredEntities', itemsToUpdate); });
      ctx.dispatch('saveFiltersToSession');
    }
  },
  resetOneFilter: function resetOneFilter(ctx, { filter, applyDefaults, updateEntities }) { // eslint-disable-line no-shadow, max-len
    const oneFilter = filter;
    const valueKeys = oneFilter.value;
    const filterDefaults = oneFilter.metadata.default_requirements;
    let enabled = true;
    if (oneFilter.enabled_by && !getDataByProperty(oneFilter.enabled_by, ctx.state)) {
      enabled = false;
    }
    if (Array.isArray(valueKeys)) {
      // TODO: may be a race condition here where we're calling this on every filter option
      // for each value, listed by id in the filter object
      for (let i = 0, l = valueKeys.length; i < l; i ++) {
        //
        // get the actual filter value from state.filterValues
        //
        const key = valueKeys[i];
        const originalValue = Object.assign({}, ctx.state.filterValues[key]);
        let updatedValue = Object.assign({}, ctx.state.filterValues[key]);
        //
        // make a copy, to update
        //
        updatedValue = JSON.parse(JSON.stringify(updatedValue));
        //
        // update the `master` property, if necessary, to the default
        //
        if (oneFilter.metadata.hasOwnProperty('default_master')) {
          updatedValue.master = oneFilter.metadata.default_master;
        }
        //
        // update the `required` & `type` fields to the defaults
        //
        updatedValue.required = oneFilter.metadata.default_val;
        updatedValue.type = oneFilter.metadata.default_type;
        //
        // reset the sticky attribute
        //
        if (updatedValue.hasOwnProperty('sticky')) {
          updatedValue.sticky = false;
        }
        //
        // apply default selections, when requested & configured
        //
        if (applyDefaults && filterDefaults && filterDefaults.length > 0 && enabled) {
          // apply default to the current filter value (updatedValue)
          for (let j = 0, k = filterDefaults.length; j < k; j++) {
            // for each item in defaults
            const selectField = filterDefaults[j].where.field;
            const selectValue = filterDefaults[j].where.value;
            const defaultFieldList = Object.keys(filterDefaults[j]);
            if (updatedValue[selectField] === selectValue) {
              for (let m = 0, n = defaultFieldList.length; m < n; m ++) {
                const defaultField = defaultFieldList[m];
                if (defaultField !== 'where') {
                  updatedValue[defaultField] = filterDefaults[j][defaultField];
                }
              }
            }
          }
        }
        if (!isEqual(originalValue, updatedValue)) {
          ctx.commit('setFilterValueProps', updatedValue);
        }
      }
    } else if (valueKeys) {
      const originalValue = Object.assign({}, ctx.state.filterValues[valueKeys]);
      let updatedValue = Object.assign({}, ctx.state.filterValues[valueKeys]);
      updatedValue = JSON.parse(JSON.stringify(updatedValue));
      updatedValue.value = oneFilter.metadata.default_val;

      if (!isEqual(originalValue, updatedValue)) {
        ctx.commit('setFilterValueProps', updatedValue);
      }
    }
    if (filter.subfilters) {
      const subfilterKeys = Object.keys(filter.subfilters);
      for (let i = 0, l = subfilterKeys.length; i < l; i ++) {
        const key = subfilterKeys[i];
        const subfilter = ctx.state.filters[key];
        if (filter.metadata.reset) {
          ctx.dispatch('resetOneFilter', {
            filter: subfilter,
            applyDefaults: applyDefaults,
            updateEntities: false
          });
        }
      }
    }
    if (updateEntities) {
      let itemsToUpdate = null;
      if (ctx.getters.useFacets && oneFilter.metadata.type === 'strict' && !oneFilter.metadata.facet) { // eslint-disable-line max-len
        itemsToUpdate = ['strict', 'relaxed', 'sort', 'base'];
      }
      ctx.dispatch('updateAllEntities')
      .then(() => { ctx.dispatch('updateFilteredEntities', itemsToUpdate); });
      ctx.dispatch('saveFiltersToSession');
    }
  },
  applyPendingFilters: function applyPendingFilters(ctx) {
    // TODO/FYI
    // facets reset explicitly (alone or with all filters) and when pending filters are applied
    // they do not reset any other time
    const forceReset = true;
    const updateFilteredEntities = ['sort', 'base'];
    ctx.dispatch('resetFacets', { forceReset, applyDefaults: false, updateEntities: false });
    for (let i = 0, l = ctx.state.pendingFilters.length; i < l; i ++) {
      const newValue = JSON.parse(JSON.stringify(ctx.state.pendingFilters[i]));
      if (updateFilteredEntities.indexOf(newValue.type) === -1) {
        updateFilteredEntities.push(newValue.type);
      }
      newValue.applyPending = true;
      ctx.dispatch('updateFilterValue', { filterValue: newValue, updateEntities: false });
    }
    ctx.dispatch('saveFiltersToSession');
    ctx.dispatch('updateAllEntities')
    .then(() => ctx.dispatch('updateFilteredEntities', updateFilteredEntities))
    // apply defaults after base entity list has been calculated in the line above
    .then(() => ctx.dispatch('resetFacets', { applyDefaults: true, defaultsOnly: true, updateEntities: true })); // eslint-disable-line max-len

    ctx.commit('setPendingFilters', []);
  },
  applyPendingAddress: function applyPenndingAddress(ctx) {
    if (ctx.state.pendingAddress && ctx.state.pendingAddress.length) {
      return ctx.dispatch('updateAddress', ctx.state.pendingAddress)
      .then(() => ctx.commit('setPendingAddress', []));
    }
    return null;
  },
  updateFilterValue: function updateFilterValue(ctx, { filterValue, updateEntities }) {
    // get filter metadata
    const updatedFilterValue = JSON.parse(JSON.stringify(filterValue));
    let filterGroupSlug = updatedFilterValue.id;
    if (ctx.state.filterIdList.indexOf(filterGroupSlug) === -1) {
      filterGroupSlug = filterGroupSlug.split('_');
      filterGroupSlug = filterGroupSlug.slice(0, filterGroupSlug.length - 1).join('_');
    }
    const filterGroupMetadata = ctx.state.filters[filterGroupSlug].metadata;

    if (filterGroupMetadata.url_filter === true) {
      // handle url updates for filters where
      // filter.metadata.url_filter == true
      const querySlug = `filter_${filterGroupSlug}`;
      const query = JSON.parse(JSON.stringify(ctx.state.route.query));
      const val = filterValue.value.toLowerCase();
      query[querySlug] = val;
      if (val === 'any' || val === 'all') {
        delete query[querySlug];
      }
      router.push({ query: query });
    }

    if (!updatedFilterValue.applyPending && filterGroupMetadata.explicit_apply) {
      const pendingVal = JSON.parse(JSON.stringify(updatedFilterValue));
      pendingVal.pending = pendingVal.required;
      pendingVal.required = !pendingVal.required;
      ctx.commit('updatePendingFilters', updatedFilterValue);
      ctx.commit('setFilterValueProps', pendingVal);
      return;
    }
    updatedFilterValue.pending = false;
    ctx.commit('setFilterValueProps', updatedFilterValue);
    //
    // update filtered, as needed
    //
    if (!updateEntities) {
      return;
    } else if (!ctx.getters.entitySet) { // pass
    } else if (!ctx.getters.filterSet) { // pass
    } else if (updatedFilterValue.type === 'strict') {
      // apply filter-dependent fields
      let itemsToUpdate = null;
      if (ctx.getters.useFacets && updatedFilterValue.type === 'strict' && !updatedFilterValue.facet) { // eslint-disable-line max-len
        itemsToUpdate = ['strict', 'relaxed', 'sort', 'base'];
      }
      ctx.dispatch('updateAllEntities', ['filter-dependent-fields'])
      .then(() => ctx.dispatch('updateFilteredEntities', itemsToUpdate))
      .then(() => {
        //
        // update facet counts, if needed
        //
        if (ctx.getters.useFacets && !updatedFilterValue.facet && updatedFilterValue.type === 'strict') { // eslint-disable-line max-len
          // ctx.dispatch('updateFacetEntityCount');
        }
      });
    } else if (updatedFilterValue.type === 'relaxed') {
      //
      //
      const filteredUpdates = ['relaxed'];
      // sort if necessary
      if (ctx.getters.sortOptions.sortBy.field === 'computed.match_rate') {
        filteredUpdates.push('sort');
      }
      // relaxed filters should never control filter-dependent fields
      ctx.dispatch('updateAllEntities', ['relaxed'])
      .then(() => { ctx.dispatch('updateFilteredEntities', filteredUpdates); });
    }
    ctx.dispatch('saveFiltersToSession');
  },
  saveFiltersToSession: function saveFiltersToSession(ctx) {
    const filtersToSave = [];
    //
    // 20180206 start here
    //
    for (let i = 0, l = ctx.state.filterValueIdList.length; i < l; i ++) {
      const id = ctx.state.filterValueIdList[i];
      const filterVal = ctx.state.filterValues[id];
      if (filterVal.value &&
        ((filterVal.hasOwnProperty('required') && filterVal.required) ||
          !filterVal.hasOwnProperty('required'))) {
        const saveVal = {};
        saveVal.value = filterVal.value;
        saveVal.required = filterVal.required;
        // used for distinguishing between "Yes" values, or similar
        if (filterVal.hasOwnProperty('id')) saveVal.id = filterVal.id;
        // type = strict or relaxed
        if (filterVal.hasOwnProperty('type')) saveVal.type = filterVal.type;
        // master = selected item in range-select (stars, etc)
        if (filterVal.hasOwnProperty('master')) saveVal.master = filterVal.master;
        // sticky = must show in facet list, whether or not selected
        if (filterVal.hasOwnProperty('sticky')) saveVal.sticky = filterVal.sticky;

        filtersToSave.push(saveVal);
      }
    }
    return ctx.dispatch('updateSessionItem', { field: 'user_filters', data: filtersToSave });
  },
  saveShowIntroToSession: function saveShowIntroToSession(ctx, showIntro) {
    ctx.commit('setShowIntro', showIntro);
    ctx.dispatch('updateSessionItem', { field: 'user_show_intro', data: [showIntro] });
  },
  logOut: function logOut(ctx) {
    var logOutMethod = utils.getAuthMethod(ctx, 'LogOut');
    auth[logOutMethod](ctx)
    .then(() => {
      let authList;
      try {
        authList = staticData.config.defaults.persist.auth;
      } catch (e) {
        if (!authList.length) {
          authList = [];
        }
      }
      for (let i = 0, l = authList.length; i < l; i ++) {
        let mutationName = `set_${authList[i]}`;
        mutationName = camelCase(mutationName);
        ctx.commit(mutationName, []);
        ctx.dispatch('updateAllEntities', authList)
        .then(() => { ctx.dispatch('updateFilteredEntities'); });
      }
    });
  },
  updateShowModal: function updateShowModal(ctx, { modal, data }) {
    var modalTag;
    var modalData;
    var modalLayout;
    var getMethod;
    var showModal;
    var favoritesModule;
    if (!ctx.state.renderableLayout.modals) return null;
    if (!modal && !data) {
      ctx.commit('setModalInformation', { field: 'modalTag', data: null });
      ctx.commit('setModalInformation', { field: 'modalData', data: null });
      ctx.commit('setModalInformation', { field: 'modalLayout', data: null });
      ctx.commit('setModalInformation', { field: 'showModal', data: false });
      return false;
    }
    // if (!this.renderable.modals) return null;
    if (modal === 'favorites' && staticData.settings.favorite_options.include) {
      //
      // handle favorites modal separately
      //
      // TODO:
      // currently assumes that if favorites are enabled and auth is not required
      // favorites are saved to the session, regardless of defaults.persist.session
      //
      if (staticData.config.auth === 'true' && ctx.state.loggedIn || staticData.config.auth === 'false') { // eslint-disable-line max-len
        // needs updating for SF
        if (staticData.config.auth === 'true') {
          favoritesModule = auth;
          getMethod = utils.getAuthMethod(ctx, 'GetAuthItems');
        } else {
          favoritesModule = session;
          getMethod = 'getFromSession';
        }

        return favoritesModule[getMethod](ctx, 'favorites')
        .then((res) => {
          ctx.commit('setFavorites', res);

          modalTag = ctx.state.renderableLayout.modals.favorites.tag;
          modalData = ctx.state.renderableLayout.modals.favorites.data;
          if (modalTag) {
            ctx.commit('setModalInformation', { field: 'modalTag', data: modalTag });
          }
          if (modalData) {
            ctx.commit('setModalInformation', { field: 'modalData', data: modalData });
          }
          if (modalLayout) {
            ctx.commit('setModalInformation', { field: 'modalLayout', data: modalLayout });
          }
          if (modalTag && modalData) {
            ctx.commit('setModalInformation', { field: 'showModal', data: true });
          }
        });
      }
      //
      // if not allowed to see favorites, redirect
      //
      ctx.dispatch('updateShowModal', { modal: 'redirect' });
      return 'redirect';
    }

    //
    // for non-favorites modals
    //
    modalTag = ctx.state.renderableLayout.modals[modal].tag;
    modalData = ctx.state.renderableLayout.modals[modal].data;
    modalLayout = ctx.state.renderableLayout.modals[modal].layout;
    if (modalTag && modalData) showModal = true;
    else showModal = false;
    if (data) {
      modalData.dynamic = data;
    }
    ctx.commit('setModalInformation', { field: 'modalTag', data: modalTag });
    ctx.commit('setModalInformation', { field: 'modalData', data: modalData });
    ctx.commit('setModalInformation', { field: 'modalLayout', data: modalLayout });
    ctx.commit('setModalInformation', { field: 'showModal', data: showModal });
    return modal;
  },
  hideModal: function hideModal(ctx, modalsToHide) {
    const currentModalTag = ctx.state.modalTag;
    const modalTagsToHide = modalsToHide.map(m => ctx.state.renderableLayout.modals[m].tag);
    if (modalTagsToHide.indexOf(currentModalTag) > -1) {
      ctx.dispatch('updateShowModal', false);
    }
  },
  updateCompare: function updateCompare(ctx, { id, compare, name }) { // eslint-disable-line
    var action;
    var sessionItem;
    var compares = ctx.state.compares;
    var comparesDisabled = ctx.state.comparesDisabled;

    // add to disabled compares list
    // prevents many clicks
    comparesDisabled.push(id);
    ctx.commit('setComparesDisabled', comparesDisabled);

    if (compare) { // esline-disable-line
      // save to compares
      action = 'add_compare';
      sessionItem = { id: id, name: name };
      // add to session
      return session.addSessionItem(ctx, 'compares', sessionItem)
      .then(added => {
        //
        // on success update state.compares
        //
        compares.push(added);
        ctx.commit('setCompares', compares);
        //
        // update entities with compares
        //
        ctx.dispatch('updateAllEntities', ['compares'])
        .then(() => { ctx.dispatch('updateFilteredEntities'); });

        // remove from state.comparesDisabled
        const idx = comparesDisabled.indexOf(id);
        comparesDisabled.splice(idx, 1);
        ctx.commit('setComparesDisabled', comparesDisabled);
        // send google analytics event
        const findView = ctx.state.route.params.findView;
        const category = findView ? `${findView}_list` : 'list';
        sendEvent({
          category: category,
          action: action,
          label: id
        });
      }, () => {
        // remove from state.comparesDisabled
        const idx = comparesDisabled.indexOf(id);
        comparesDisabled.splice(idx, 1);
        ctx.dispatch('updateAllEntities', ['compares'])
        .then(() => { ctx.dispatch('updateFilteredEntities'); });
        ctx.commit('setComparesDisabled', comparesDisabled);
      });
    }

    // remove from compares
    action = 'remove_compare';
    return session.removeSessionItem(ctx, 'compares', { id: id })
    .then(() => {
      // on success update state.compares
      let idx = -1;
      let i = 0;
      const l = compares.length;
      while (idx === -1 && i < l) {
        if (compares[i].id === id) {
          idx = i;
        }
        i += 1;
      }
      if (idx !== -1) {
        compares.splice(idx, 1);
      }
      ctx.commit('setCompares', compares);
      //
      // update entities with compares
      //
      ctx.dispatch('updateAllEntities', ['compares'])
      .then(() => { ctx.dispatch('updateFilteredEntities'); });

      // remove from state.comparesDisabled
      idx = comparesDisabled.indexOf(id);
      comparesDisabled.splice(idx, 1);
      ctx.commit('setComparesDisabled', comparesDisabled);
      // send google analytics event
      const findView = ctx.state.route.params.findView;
      const category = findView ? `${findView}_list` : 'list';
      sendEvent({
        category: category,
        action: action,
        label: id
      });
    }, () => {
      // remove from state.comparesDisabled
      const idx = comparesDisabled.indexOf(id);
      comparesDisabled.splice(idx, 1);
      ctx.commit('setComparesDisabled', comparesDisabled);
    });
  },
  removeAllCompares: function removeAllCompares(ctx) {
    ctx.commit('setCompares', []);
    ctx.dispatch('updateAllEntities', ['compares'])
    .then(() => ctx.dispatch('updateFilteredEntities'));
    session.replaceSessionItem(ctx, 'compares', []);
  },
  updateFavorite: function updateFavorite(ctx, { id, favorite, name, framework }) {
    var action;
    var favorites = ctx.state.favorites;
    var favoritesDisabled = ctx.state.favoritesDisabled;
    var favoritesModule;
    var favoritesMethod;
    var favoritesData;
    favoritesDisabled.push(id);
    // add to disabled favorites list
    ctx.commit('setFavoritesDisabled', favoritesDisabled);

    //
    // get the correct module & method for updating the favorite
    //
    if (staticData.config.defaults.persist.auth &&
      staticData.config.defaults.persist.auth.indexOf('favorites') > -1) {
      if (ctx.state.loggedIn) {
        //
        // auth-based favoriting
        //
        favoritesModule = auth;
        if (!staticData.config.defaults.auth_prefix) {
          console.error('To use auth-based favorites, Finder.yaml must include valid defaults.auth_prefix'); // eslint-disable-line
          return null;
        } else if (favorite) {
          favoritesMethod = utils.getAuthMethod(ctx, 'AddAuthItem');
        } else {
          favoritesMethod = utils.getAuthMethod(ctx, 'RemoveAuthItem');
        }
      } else {
        //
        // re-enable favorite heart, save for later & show redirect modal
        //
        const idx = favoritesDisabled.indexOf(id);
        favoritesDisabled.splice(idx, 1);

        const sessionItem = {
          field: 'save_after_login',
          data: [{ field: 'favorites', data: { id: id } }]
        };
        return ctx.dispatch('updateSessionItem', sessionItem)
        .then(() => ctx.dispatch('updateShowModal', { modal: 'redirect' }));
      }
    } else if (staticData.config.defaults.persist &&
      staticData.config.defaults.persist.session &&
      staticData.config.defaults.persist.session.indexOf('favorites') > -1) {
      //
      // session-based favoriting
      //
      favoritesModule = session;
      if (favorite) {
        favoritesMethod = 'addSessionItem';
      } else {
        favoritesMethod = 'removeSessionItem';
      }
    }

    //
    // actually do the updating
    //
    if (favorite) {
      //
      // save favorite
      //
      action = 'saved_favorite';
      favoritesData = { id: id, name: name };
      if (framework) favoritesData.framework = framework;
      return favoritesModule[favoritesMethod](ctx, 'favorites', favoritesData)
      .then(added => {
        // on success update state.favorites
        favorites.push(added);
        ctx.commit('setFavorites', favorites);
        //
        // update entities with favorites
        //
        ctx.dispatch('updateAllEntities', ['favorites'])
        .then(() => { ctx.dispatch('updateFilteredEntities'); });

        // remove from state.favoritesDisabled
        const idx = favoritesDisabled.indexOf(id);
        favoritesDisabled.splice(idx, 1);
        ctx.commit('setFavoritesDisabled', favoritesDisabled);
        // send google analytics event
        sendEvent({
          category: 'list',
          action: action,
          label: id
        });
      }, () => {
        // remove from state.favoritesDisabled
        const idx = favoritesDisabled.indexOf(id);
        favoritesDisabled.splice(idx, 1);
        ctx.commit('setFavoritesDisabled', favoritesDisabled);
      });
    }
    //
    // delete favorite
    //
    action = 'deleted_favorite';
    favoritesData = ctx.state.favorites.find(f => f.id === id);
    return favoritesModule[favoritesMethod]('favorites', favoritesData)
    .then(() => {
      // on success update state.favorites
      let idx = -1;
      let i = 0;
      const l = favorites.length;
      while (idx === -1 && i < l) {
        if (favorites[i].id === id) {
          idx = i;
        }
        i += 1;
      }
      if (idx !== -1) {
        favorites.splice(idx, 1);
      }
      ctx.commit('setFavorites', favorites);
      //
      // update entities with favorites
      //
      ctx.dispatch('updateAllEntities', ['favorites'])
      .then(() => { ctx.dispatch('updateFilteredEntities'); });

      // remove from state.favoritesDisabled
      idx = favoritesDisabled.indexOf(id);
      favoritesDisabled.splice(idx, 1);
      ctx.commit('setFavoritesDisabled', favoritesDisabled);
      // send google analytics event
      sendEvent({
        category: 'list',
        action: action,
        label: id
      });
    }, () => {
      // remove from state.favoritesDisabled
      const idx = favoritesDisabled.indexOf(id);
      favoritesDisabled.splice(idx, 1);
      ctx.commit('setFavoritesDisabled', favoritesDisabled);
    });
  },
  updateShowRibbon: function updateShowRibbon(ctx, show) {
    ctx.commit('setShowRibbon', show);
  },
  updateRibbonError: function updateRibbonError(ctx, error) {
    ctx.commit('setRibbonError', error);
  },
  updateMapsApiLoaded: function updateMapsApiLoaded(ctx, loaded) {
    //
    // might need to trigger google distance updates
    //
    ctx.commit('setMapsApiLoaded', loaded);
  },
  updatePendingFilterAction: function updatePendingFilterAction(ctx, action) {
    ctx.commit('setPendingFilterAction', action);
  },
  updateNeighborhoodSchoolIds: function updateNeighborhoodSchoolIds(ctx, ids) {
    ctx.commit('setNeighborhoodSchoolIds', ids);
    //
    // update entities with neighborhoods
    //
    ctx.dispatch('updateAllEntities', ['neighborhood'])
    .then(() => { ctx.dispatch('updateFilteredEntities'); });
  },
  updateTransportSchoolIds: function updateTransportSchoolIds(ctx, ids) {
    ctx.commit('setTransportSchoolIds', ids);
    //
    // update entities with transport
    //
    ctx.dispatch('updateAllEntities', ['transport'])
    .then(() => { ctx.dispatch('updateFilteredEntities'); });
  },
  updateResultsPerPage: function updateResultsPerPage(ctx, perPage) {
    ctx.commit('setResultsPerPage', perPage);
  },
  updateBreakpoint: function updateBreakpoint(ctx, breakpoint) {
    ctx.commit('setBreakpoint', breakpoint);
  }
};

/* eslint-disable no-shadow */
const getters = {
  language: function language(state) {
    var languageOptions;
    var lang;
    if (!staticData.config) return null;
    languageOptions = staticData.config.defaults.language_options;
    if (!state.route.query) {
      lang = languageOptions.default_value;
    } else if (!state.route.query.lang) {
      lang = languageOptions.default_value;
    } else if (languageOptions.options.indexOf(state.route.query.lang) === -1) {
      lang = languageOptions.default_value;
    } else {
      lang = state.route.query.lang;
    }
    return lang;
  },
  entitySet: function entitySet(state) {
    if (!state.entityList || !state.entities) return null;
    return state.entityList.map(e => state.entities[e]);
  },
  sortOptions: function sortOptions(state) {
    var selection = state.sort;
    var options;
    var firstTieBreaker;
    var secondTieBreaker;
    var activeSort;
    if (staticData.settings && staticData.settings.sort_options
      && staticData.settings.sort_options.options) {
      options = staticData.settings.sort_options.options;
      firstTieBreaker = options.filter(v => v.first_tiebreaker)[0];
      secondTieBreaker = options.filter(v => v.second_tiebreaker)[0];
      activeSort = options.filter(v => v.value === selection)[0];
    } else {
      activeSort = selection;
    }
    if (activeSort && activeSort.related_sort) {
      const relatedSort = JSON.parse(JSON.stringify(activeSort.related_sort));

      if (Object.hasOwnProperty.call(activeSort.related_sort, 'correlation')) {
        relatedSort.direction = activeSort.related_sort.correlation * activeSort.direction;
      } else if (Object.hasOwnProperty.call(activeSort.related_sort, 'direction')) {
        relatedSort.direction = activeSort.related_sort.direction;
      } else {
        console.error('related sorts must have either correlation or direction defined. Valid values 1, -1'); // eslint-disable-line max-len, no-console
      }

      try {
        secondTieBreaker = JSON.parse(JSON.stringify(firstTieBreaker));
      } catch (e) {
        // pass
      }
      try {
        firstTieBreaker = JSON.parse(JSON.stringify(relatedSort));
      } catch (e) {
        // pass
      }
    }
    return {
      sortBy: activeSort,
      firstTieBreaker: firstTieBreaker,
      secondTieBreaker: secondTieBreaker
    };
  },
  defaultSortOption: function defaultSortOption(state, getters) {
    var defaultSort;
    if (!staticData.settings || !staticData.settings.sort_options) {
      defaultSort = null;
    } else if (staticData.settings.sort_options.conditional_defaults) {
      defaultSort = sort.getConditionalDefaultSort(getters, staticData.settings);
    } else {
      defaultSort = staticData.settings.sort_options.value;
    }
    if (!defaultSort && !(!staticData.settings || !staticData.settings.sort_options)) {
      defaultSort = staticData.settings.sort_options.value;
    }
    return defaultSort;
  },
  filtersWithSubfilters: function filtersWithSubfilters(state) {
    //
    // used for getting renderable layout
    //
    if (!state.filterIdList || !state.filters) {
      return null;
    }

    function buildFilterSet(filterIds, collectedFilters) {
      var allFilters = {};
      for (let i = 0, l = filterIds.length; i < l; i ++) {
        const filterId = filterIds[i];
        const oneFilter = collectedFilters[filterId];
        allFilters[filterId] = oneFilter;
        if (allFilters[filterId].subfilters) {
          allFilters[filterId].subfilters = buildFilterSet(Object.keys(allFilters[filterId].subfilters), collectedFilters); // eslint-disable-line max-len
        }
      }
      return allFilters;
    }

    const topLevelFilterIds = state.filterIdList;
    const stateFilters = JSON.parse(JSON.stringify(state.filters));
    const collectedFilters = buildFilterSet(topLevelFilterIds, stateFilters);
    return collectedFilters;
  },
  filterSet: function filterSet(state) {
    //
    // used for actually filtering entities, until we re-write the filtering functions
    // and for components that need access to some or all filters:
    // MatchModal.vue, ResultsStatus.vue, GradeDependentMixin.vue maybe others
    //
    if (!state.filterIdList || !state.filters || !state.filterValueIdList || !state.filterValues) {
      return null;
    }

    function buildFilterSet(filterIds, collectedFilters, filterValues) {
      var allFilters = {};
      for (let i = 0, l = filterIds.length; i < l; i ++) {
        const filterId = filterIds[i];
        const oneFilter = collectedFilters[filterId];
        allFilters[filterId] = oneFilter;
        if (Array.isArray(oneFilter.value)) {
          allFilters[filterId].value = allFilters[filterId].value.map(valId => filterValues[valId]);
        } else if (allFilters[filterId].hasOwnProperty('value')) {
          allFilters[filterId].value = filterValues[allFilters[filterId].value];
        }
        if (allFilters[filterId].subfilters) {
          allFilters[filterId].subfilters = buildFilterSet(Object.keys(allFilters[filterId].subfilters), collectedFilters, filterValues); // eslint-disable-line max-len
        }
      }
      return allFilters;
    }

    const topLevelFilterIds = state.filterIdList;
    const filterValues = JSON.parse(JSON.stringify(state.filterValues));
    const stateFilters = JSON.parse(JSON.stringify(state.filters));
    const collectedFilters = buildFilterSet(topLevelFilterIds, stateFilters, filterValues);
    return collectedFilters;
  },
  filterCount: function filterCount(state, getters) {
    var filterSet = getters.filterSet;
    return filters.countFilters(filterSet);
  },
  relaxedFilterCount: function relaxedFilterCount(state, getters) {
    if (!getters.filterCount) return 0;
    return getters.filterCount.relaxed.reset + getters.filterCount.relaxed.noReset;
  },
  strictFilterCount: function strictFilterCount(state, getters) {
    if (!getters.filterCount) return 0;
    return getters.filterCount.strict.reset + getters.filterCount.strict.noReset;
  },
  resettableFilterCount: function resettableFilterCount(state, getters) {
    // TODO: is this actually needed?
    if (!getters.filterCount) return 0;
    return getters.filterCount.strict.reset + getters.filterCount.relaxed.reset;
  },
  useFacets: function useFacets(state) {
    if (!state.filterValues) return null;
    for (const key in state.filterValues) { // eslint-disable-line
      const filter = state.filterValues[key];
      if (filter.facet && filter.facet === true) return true;
    }
    return false;
  },
  facetCount: function facetCount(state, getters) {
    if (!getters.filterCount) return 0;
    return getters.filterCount.facet;
  },
  enabledFacetCount(state, getters) {
    //
    // this getter relies on filter enablers being available to
    // the store's state or getters in exactly the same way that
    // it is avilable to the filter component
    // current values in use: home, home.user
    //
    // TODO: this does NOT take filters that react to a super-filter into account
    // if, for example, selecting Kindergarten will remove all Sports facets
    // that is not captured here.
    //
    let count = 0;
    const filterKeys = Object.keys(getters.filterSet);
    for (let i = 0, l = filterKeys.length; i < l; i ++) {
      const key = filterKeys[i];
      const filter = getters.filterSet[key];

      // only count facets
      if (filter.metadata.facet) {
        if (!filter.enabled_by) {
          count += 1;
        } else {
          const enablerKey = filter.enabled_by;
          // try finding the data on the state
          let enabler = getDataByProperty(enablerKey, state);
          // if not there, it may be in a getter
          if (!enabler) {
            enabler = getDataByProperty(enablerKey, getters);
          }
          const enabled = !!enabler;
          if (enabled) {
            count += 1;
          }
        }
      }
    }
    return count;
  },
  allStrictFilterCount(state, getters) {
    if (!getters.strictFilterCount && !getters.facetCount) return 0;
    const strict = getters.strictFilterCount || 0;
    const facet = getters.facetCount || 0;
    return strict + facet;
  },
  useCompare(state) {
    const compareConfig = state.compareConfig;
    const findView = state.route.params.findView;
    if (!compareConfig.use) return false;
    if (compareConfig.routes && findView) {
      return compareConfig.routes.indexOf(findView) > -1;
    }
    return true;
  },
};

const modules = {
  homepage,
  about
};

module.exports = new Vuex.Store({
  modules,
  state,
  mutations,
  actions,
  getters
});
