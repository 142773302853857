<template>
  <label class="checkbox_label c-checkbox__label">
    <input class="checkbox c-checkbox sr-only" type="checkbox" :name="value.value" :value="value.value" :disabled="disabled" v-model="localChecked">
    <span class="checkbox_visual c-checkbox__visual"></span><!--
     --><span class="checkbox_value c-checkbox__value">{{value.text}}{{useEntityCount ? (' (' + entityCount + ')') : ''}}</span>
  </label>
</template>
<script type="text/javascript">
  import intersection from 'lodash/intersection';
  module.exports = {
    props: ['value', 'checked', 'disabled'],
    computed: {
      localChecked: {
        get: function localChecked() {
          return this.checked;
        },
        set: function localChecked(newval) {
          this.$emit('update:checked', newval);
        }
      },
      useEntityCount() {
        return this.$store.getters.useFacets && this.value.facet && this.value.entities;
      },
      entityCount() {
        if (!this.$store.getters.useFacets) return null;
        return intersection(this.value.entities, this.$store.state.baseFilteredEntities).length;
      }
    }
  };
</script>
<style type="text/css" scoped>
	.checkbox {
		display: inherit !important;
	}
</style>
