<template>
  <div class="search-wrapper">
      <search-input
      v-on:keyup="handleInputEvent($event)"
      :data-input="settings.value"
      :placeholder="settings.prompt_text"
    ></search-input>
  </div>
</template>
<script type="text/javascript">
  const SearchMixin = require('../mixins/SearchMixin');
  import SearchInput from './SearchInput.vue';

  module.exports = {
    components: { SearchInput },
    props: ['settings'],
    mixins: [SearchMixin]
  };
</script>