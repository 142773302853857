<template>
  <div>
    <p class="p-footer__p">{{settings.name}}
      <template v-if="settings.address">
        <br>
        {{settings.address.street}}<br>
        {{settings.address.city}}, {{settings.address.state}} {{settings.address.postal_code}}
      </template>
    </p>

    <p class="p-footer__p mt-2" v-if="settings.phone">{{settings.phone_prefix}} {{settings.phone}} <br>
      {{settings.phone_secondary_prefix}} {{settings.phone_secondary}}</p>
    <p class="p-footer__p mt-2" v-if="settings.website_url && !settings.hide_website_url">
      <a  :href="settings.website_url" class="p-footer__a">{{settings.website_text ? settings.website_text : settings.website_url}}</a>
    </p>
    <p class="p-footer__p mt-2" v-if="settings.data_center_url">
        <a  :href="settings.data_center_url" class="p-footer__a">{{settings.data_center_text ? settings.data_center_text : settings.data_center_url}}</a>
    </p>

    <div class="social mt-3 d-flex sp-h-3">

      <span v-if="settings.fb_link" class="social_icon social_icon--facebook">
        <a :href="settings.fb_link" target="_blank" class="p-footer__a">
            <span class="sr-only">Facebook</span>
            <i class="fab fa-lg fa-facebook"></i>
        </a>
      </span>


      <span v-if="settings.twitter_link" class="social_icon social_icon--twitter">
        <a :href="settings.twitter_link" target="_blank" class="p-footer__a">
          <span class="sr-only">Twitter</span>
          <i class="fab fa-lg fa-twitter"></i>
        </a>
      </span>


      <span v-if="settings.youtube_link" class="social_icon social_icon--youtube">
        <a :href="settings.youtube_link" target="_blank" class="p-footer__a">
          <span class="sr-only">Youtube</span>
          <i class="fab fa-lg fa-youtube"></i>
        </a>
      </span>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings']
  };
</script>
