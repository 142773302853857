<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <fieldset :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" :settings="settings" :selection-tally="selectionTally" class="categorical-filter">

    <!-- FILTER TITLE SLOT -->
    <div slot="filter-title">{{settings.group_title_text}}</div>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" v-if="settings.description_text">{{settings.description_text}}</div>

    <!-- FILTER CONTENT SLOT -->
    <ul slot="filter-content" class="filter-content filter-content--vertical-group d-inline-flex flex-column sp-v-2 mt-2" v-if="settings.value">
      <a class="filter-description" href='#' v-if="settings.metadata.descriptions" v-on:click="handleShowDescriptions">{{settings.options_description_text}}</a>
      <li class="filter-item filter-item-vertical"
        v-for="(value, index) in filteredValues"
        v-on:mouseover="handleHovers(true, index)"
        v-on:mouseleave="handleHovers(false, index)"
      >
        <!-- Filter control -->
        <div
          :is="layout.option_tag.tag"
          :value="value"
          :checked="value.master"
          :filled="filled.indexOf(index) > -1"
          :hovered="hovered.indexOf(index) > -1"
          :focused="focused.indexOf(index) > -1"
          :group="settings.metadata.label"
          :idx="index"
          @update:checked="checked => { handleFilterClick(checked, value, index) }"
          @update:focus="(focusState, index) => { handleFocus(focusState, index) }"
        ></div>
      </li>
    </ul>
  </fieldset>
</template>
<script type="text/javascript">
  var CategoricalFilterMixin = require('../../mixins/CategoricalFilterMixin');
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  module.exports = {
    mixins: [CategoricalFilterMixin, FilterGroupMixin]
  };
</script>
<style type="text/css">
/*   .filter-content--vertical-group{
    margin-top: 10px;
    display: inline-block;
  }
  .filter-item-vertical{
    width:  inherit;
  }
  .filter-item-vertical span{
    width:  100%;
    padding: 5px 15px;
  }
  .categorical-filter .filter-content .filter-item.filter-item-vertical {
    display: block;
    margin-left: 0px;
  }
  .categorical-filter .filter-content .filter-item.filter-item-vertical+.filter-item.filter-item-vertical {
    margin-top: 10px;
  } */
</style>