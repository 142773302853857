// TODO: update for vue2
module.exports = {
  methods: {
    close: function close(completed) {
      this.$dispatch('Close', completed);
    },
    advance: function advance() {
      this.$dispatch('GoTo', this.currentScreen.goTo);
    },
    advanceTo: function advanceTo(screen) {
      this.$dispatch('GoTo', screen);
    },
    goToCrumb: function goToCrumb(idx) {
      this.$dispatch('GoToCrumb', idx);
    },
    niceExit: function niceExit() {
      this.$dispatch('GoTo', 'niceExit');
    },
    getRepeats: function getRepeats(idx) {
      var reps = ((idx - this.wizardSettings.repeat_start_index)
        / this.wizardSettings.repeat_cards) + 1;
      reps = Math.floor(reps);
      return reps;
    },
    updateSort: function updateSort() {
      // if this is the most important category (first one selected)
      // set the sort order of find accordingly
      if (this.getRepeats(this.index) === 1) {
        this.sorts.value = this.currentScreen.sortby;
      }
    },
    resetThisAndFollowingFilters: function resetThisAndFollowingFilters() {
      var i;
      var l = this.previousScreens.length;
      var screen;
      for (i = this.index; i < l; i ++) {
        screen = this.previousScreens[i];
        if (screen.selected && screen.selected.category_type === 'filters') {
          this.$dispatch('ClearFilter', screen.selected.reset);
        } else if (screen.selected && screen.selected.category_type === 'address') {
          this.$dispatch('ClearAddress');
        }
      }
    }
  }
};
