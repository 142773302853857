import sanitizeHtml from 'sanitize-html';

function cleanHTML(text, { overrideTags, additionalTags, allowStyles }) {
  const alwaysAllowedTags = ['strong', 'li', 'ul', 'ol', 'a', 'p', 'div', 'br'];
  const maybeAllowed = ['a', 'address', 'b', 'blockquote', 'br', 'div', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'li', 'ol', 'p', 'pre', 'small', 'span', 'strong', 'sub', 'sup', 'ul']; // eslint-disable-line max-len

  let allowedTags = alwaysAllowedTags;

  if (additionalTags && Array.isArray(additionalTags)) {
    allowedTags = allowedTags.concat(additionalTags.filter(tag => (maybeAllowed.indexOf(tag) > -1))); // eslint-disable-line max-len
  }
  if (overrideTags && Array.isArray(overrideTags)) {
    allowedTags = overrideTags.filter(tag => (maybeAllowed.indexOf(tag) > -1));
  }

  const options = {
    allowedTags,
    allowedAttributes: {
      a: ['href', 'target'],
    },
  };

  if (allowStyles) {
    options.allowedAttributes['*'] = ['style'];
  }

  return sanitizeHtml(text, options);
}

export default cleanHTML;
