<!-- THIS COMPONENT DOES NOT ALLOW RESETTING ADDRESS TO A DEFAULT OR CLEARING THE ADDRESS -->
<!-- TODO:
  * allow clearing address (?)
  * add google analytics
 -->
<template>
  <div class="search-wrapper search-container-light c-input">
    <label :for="id"><span class="sr-only">{{settings.search_placeholder_text}}</span>  </label>
    <input  :id="id" slot="input" type="text" class="c-input__input auto-complete search-input truncate-ellipsis flex-grow-1" :placeholder="settings.search_placeholder_text" v-model="addressSearchText" v-on:focus="initInput" />
    <div class="search-icon c-input__icon"><i slot="icon" class="fas fa-map-pin"></i></div>
  </div>
</template>
<script type="text/javascript">
  var AddressGoogleMixin = require('../mixins/AddressGoogleMixin');
  var $ = require('jquery');

  /* eslint-disable no-undef, no-new */
  module.exports = {
    props: [
      'settings',
      'layout',
      'dataInput'
    ],
    mixins: [AddressGoogleMixin],
    data: function data() {
      return {
        initialized: false,
        id: null
      };
    },
    mounted: function mounted() { // MAY NEED TO USE NEXTTICK
      if (this.$store.state.mapsLoaded && !this.initialized) {
        this.initInput();
      }
      this.id = this._uid; // eslint-disable-line no-underscore-dangle
    },
    computed: {
      mapsLoaded: function mapsLoaded() {
        return this.$store.state.mapsLoaded;
      },
      addressSearchText: {
        get: function addressSearchText() {
          //
          // this is a computed property to make sure all instances of
          // this component within the app show the same text
          //
          return this.getSearchText();
        },
        set: function addressSearchText() {
          // don't actually need to do anything when this computed prop is updated
        }
      }
    },
    methods: {
      initInput: function initInput() {
        var input;
        var autocomplete;
        var options;
        var bbox;
        var bounds;
        var boundSetNewAddress;
        if (this.initialized) {
          return true;
        }
        // callback passed to google event listener on 'place_changed event'
        // updates address, which is synced with app, triggers re-drawing home & panning map
        function setNewAddress() {
          var place = autocomplete.getPlace();
          var updatedPlace;
          if (place.formatted_address && place.geometry) {
            updatedPlace = {
              formatted_address: place.formatted_address,
              geometry: {
                location: {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng()
                }
              }
            };
          }
          $(this.$el).find('input').blur();
          if (updatedPlace) {
            if (this.settings.metadata.explicit_apply) {
              this.$store.commit('setPendingAddress', [{ user: true, address: updatedPlace }]);
            } else {
              this.$store.dispatch('updateAddress', [{ user: true, address: updatedPlace }]);
            }
          }
        }

        // bind setNewAddress to 'this' context
        boundSetNewAddress = setNewAddress.bind(this);

        // input element
        input = $(this.$el).find('.auto-complete').get(0);

        if (!google) {
          // eslint-disable-next-line no-console
          console.error('the scripts required to run the address search have failed to load');
          return this.initialized;
        }

        // create google bounds from bbox in settings, set in config
        bbox = this.settings.metadata.bbox;
        bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(bbox.southWest.lat, bbox.southWest.lng),
          new google.maps.LatLng(bbox.northEast.lat, bbox.northEast.lng));

        // set options for google maps search
        options = {
          bounds: bounds,
          componentRestrictions: { country: 'us' },
          strictBounds: true
        };

        // initialize autocomplete class
        autocomplete = new google.maps.places.Autocomplete(input, options);

        // add event listener for user selection
        autocomplete.addListener('place_changed', boundSetNewAddress);
        this.initialized = true;
        return this.initialized;
      },
    },
    watch: {
      mapsLoaded: function mapsLoaded() {
        if (!this.initialized) {
          this.initInput();
        }
      }
    }
  };
  /* eslint-enable no-undef, no-new */
</script>

