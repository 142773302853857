<template>
  <nav class="p-pagination this!" aria-label="Results Pagination Navigation" v-if="pageCount > 1">
    <button class="c-link c-link--utility p-pagination__page" v-on:click="setPage(1, $event)" :disabled="page == 1" :class="page != 1 ? '' : 'c-button--outline'" :aria-label="'Go to the first, page 1'"><span class="d-sm-inline">{{settings.first_text}}</span></button>
    <button class="c-link c-link--utility p-pagination__previous" v-on:click="pageBack" :disabled="page <= 1" :class="page <= 1 ? '' : 'c-button--outline'" :aria-label="'Go to previous page, page ' + (page - 1)"><i class="fa fa-chevron-left"></i><span class="d-none d-sm-inline ml-2">{{settings.previous_text}}</span></button><!--
 --><button class="c-link c-link--utility p-pagination__page" v-for="(pageItem, index) in pages" :class="pageItem === page ? 'c-link--utility--current' : 'button--control c-button--outline' " :disabled="pageItem === '...'" :aria-label="(pageItem === page ? 'Current page,' : 'Go to') + ' page '  + pageItem" v-on:click="setPage(pageItem, $event)">{{pageItem}}</button><!--
 --><button class="c-link c-link--utility p-pagination__next" v-on:click="pageFwd" :disabled="page >= pageCount" :class="page >= pageCount ? '' : 'c-button--outline'" :aria-label="'Go to next page, page ' + (page + 1)"><span class="d-none d-sm-inline mr-2">{{settings.next_text}}</span><i class="fa fa-chevron-right"></i></button><!--
 --><button class="c-link c-link--utility p-pagination__page" v-on:click="setPage(pageCount, $event)" :disabled="page == pageCount" :class="page != pageCount ? '' : 'c-button--outline'" :aria-label="'Go to the last, page ' + pageCount"><span class="d-sm-inline">{{settings.last_text}}</span></button>
  </nav>
</template>
<script type="text/javascript">
  var PaginationMixin = require('../mixins/PaginationMixin');
  module.exports = {
    mixins: [PaginationMixin],
    computed: {
      page: function page() {
        return this.$store.state.page;
      },
      pages: function pages() {
        var pageArray = [];
        var i;
        var n = this.pageCount;
        var p = this.$store.state.page;
        var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before')
          .getPropertyValue('content').replace(/"/g, '');

        // i = page number
        // n = page count
        // p = current page
        for (i = 0; i < n + 1; i ++) {
          if (breakpoint === 'smartphone') {
            if (i < 1 || i > n) {
              // do nothing
            } else if (i === p || i === n) {
              // if current page or last page
              pageArray.push(i);
            } else if (p >= n - 1 && i >= n - 2) {
              // TMBOSR-1240
              // At the 2nd to last page
              //  display the third from last item
              pageArray.push(i);
            }
          } else {
            if (i < 1 || i > n) {
              // do nothing
            } else if (i === p || i === n) {
              // always add current page & last page
              pageArray.push(i);
            } else if (i <= 5 && p < 5) {
              // if the c
              pageArray.push(i);
            } else if (p >= n - 3 && i >= n - 6) {
              // if current page is within top 4
              // show the last 6 pages
              pageArray.push(i);
            } else if (p >= 5 && p < (n - 3)) {
              if (i === p - 3 || i === p - 2 || i === p - 1 || i === p + 1) {
              // these are the inbetweens
              // when not near the front or the back of the list
              // display:
              // three previous pages
              // current page
              // next page
              // last page

                pageArray.push(i);
              }
            }
          }
        }
        pageArray = this.ellipsify(pageArray);
        return pageArray;
      }
    },
    methods: {
      ellipsify: function ellipsify(pages) {
        var updated = [];
        var i;
        var l = pages.length;
        for (i = 0; i < l; i ++) {
          if (pages[i + 1] - pages[i] === 2) {
            // if the difference between this & the next is two
            // add this page and the following page
            updated.push(pages[i]);
            updated.push(pages[i] + 1);
          } else if (pages[i + 1] - pages[i] === 1 || i === l - 1) {
            // if this page is followed by page + 1
            // add this page only
            updated.push(pages[i]);
          } else {
            // otherwise, there must be more than one page missing
            // so add this page and an elipsis
            updated.push(pages[i]);
            updated.push('...');
          }
        }
        return updated;
      }
    }
  };
</script>
