<template>
  <a v-if="settings.google" :href="settings.google_translate_link" target="_blank">
  	<attribution-google-svg /></a>
</template>
<script type="text/javascript">
  import AttributionGoogleSvg from '../assets/AttributionGoogleSvg.vue';
  module.exports = {
    props: ['settings'],
    components: { AttributionGoogleSvg, },
  };
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Roboto&text=powerdby');
</style>

