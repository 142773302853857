<template>
  <div v-if="category.category_type === 'section' && category.categories && category.categories.length" class="mb-100">
    <div class="row">
      <div class="col-12">
        <h1 class="h1--compare" v-if="category.title">{{category.title}}</h1>
        <p class="p--compare" v-if="category.description">{{category.description}}</p>
      </div>
    </div>
    <compare-category
      v-for="(cat, idx) in category.categories"
      :is-last="idx === category.categories.length - 1"
      :category="cat"
      :compare-entities="compareEntities"
      :key='cat.id'
    ></compare-category>
  </div>
  <compare-data-row
    v-else-if="category.category_type === 'data'"
    :is-last="isLast"
    :settings="category"
    :compare-entities="compareEntities"
  ></compare-data-row>
</template>
<script type="text/javascript">
  import CompareCategory from './CompareCategory.vue';
  import CompareDataRow from './CompareDataRow.vue';

  module.exports = {
    props: ['category', 'compareEntities', 'isLast'],
    components: { CompareCategory, CompareDataRow },
  };
</script>
