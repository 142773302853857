import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
const VueResource = require('vue-resource');
const VueRouter = require('vue-router');
const Vuex = require('vuex');
const sync = require('vuex-router-sync').sync;

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const Root = require('./vue/Root.vue').default;
const App = require('./vue/App.vue').default;
const Home = require('./vue/Home.vue').default;
const About = require('./vue/About.vue').default;
// const ErrorPage = require('./vue/components/ErrorPage.vue');

const registerComponents = require('./component-loader').registerComponents;
const componentList = require('./component-list');
const alternateLoader = require('./component-loader').alternateLoader; // eslint-disable-line no-unused-vars, max-len
const store = require('./vue/store/store');


if (useSentry.toLowerCase() === 'true' || useSentry === true) { // eslint-disable-line no-undef
  Sentry.init({
    dsn: 'https://6677d2dcbc5f4cad8156daaafd5d8221@sentry.tembo.dev/6',
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    environment: sentryEnv, // eslint-disable-line no-undef
  });
}

Vue.use(VueResource);
Vue.use(Vuex);
Vue.use(Vue2TouchEvents);
Vue.use(VueRouter);

registerComponents(componentList);
// alternateLoader(componentList);

//
// maps component strings from Finder.yaml
// to actual vue components
//
const componentMapping = {
  app: {
    component: App,
    name: 'find'
  },
  home: {
    component: Home,
    name: 'homepage'
  },
  about: {
    component: About,
    name: 'about'
  }
};


const routes = [];
if (!v2Routing || v2Routing.toLowerCase() === 'false' || !routeList || !routeList.length) { // eslint-disable-line no-undef, max-len
  routes.push({ path: '/', component: App, name: 'find' });
} else {
  for (let i = 0, l = routeList.length; i < l; i ++) { // eslint-disable-line no-undef
    const route = routeList[i]; // eslint-disable-line no-undef
    routes.push({
      path: route.path,
      component: componentMapping[route.component].component,
      name: componentMapping[route.component].name,
      meta: route.data_key,
      alias: route.alias,
    });
  }
}

export const router = new VueRouter({
  mode: 'history',
  routes: routes,
});


sync(store, router);

new Vue({
  router: router,
  store: store,
  render: h => h(Root)
}).$mount('#app');

