import flattenDeep from 'lodash/flattenDeep';

function getEventIsValid(session) {
  if (!session) return false;
  //
  // no longer limiting events to those with start & end dates
  // if (!session.start_date || !session.end_date || !session.day) return false;
  //
  if (!session.day) return false;
  const hasValidDays = session.day
    //
    // no longer limiting days to those with start & end times
    // .filter(day => day && day.day && day.start_time && day.end_time).length > 0;
    //
    .filter(day => day && day.day).length > 0;
  return hasValidDays;
}

function getDays(calendarEvents) {
  //
  // for each calendar event with a start & end date
  // and at least one day with a start & end time
  // collect all the days of the week with a start & end time
  //
  // returns a de-duped list of days of the week
  //
  let validDays = [];
  if (!calendarEvents) return validDays;
  const validEvents = calendarEvents.filter(getEventIsValid);
  for (let i = 0, l = validEvents.length; i < l; i++) {
    const event = calendarEvents[i];
    const eventDays = event.day
      // no longer limiting days to those with start & end times
      // .filter(e => (e.day && e.start_time && e.end_time))
      .filter(e => (e.day))
      .map(e => e.day);
    validDays = validDays.concat(eventDays);
  }
  validDays = validDays.reduce((acc, day) => {
    if (acc.indexOf(day) === -1) {
      acc.push(day);
    }
    return acc;
  }, []);
  return validDays;
}


function getEventTimeOfDay(event) {
  //
  // given a start & end time, determine if the event occurs during
  // morning, afternoon and/or evening
  //
  // Morning = 00:00 - 11:59 (TBD)
  // Afternoon = 12:00 - 16:59 (TBD)
  // Evening = 17:00 - 23:59 (TBD)
  // TBD; does this include any programs that have a start or end time
  // in the range, or, do both times have to be in the range?
  //

  if (!event || !event.start_time || !event.end_time) return [];
  const start = Number(event.start_time.replace(':', ''));
  const end = Number(event.end_time.replace(':', ''));
  const times = [];

  if (start > end && end !== 0) {
    console.error(`start time (${event.start_time}) cannot be after end time (${event.end_time})`); // eslint-disable-line no-console, max-len
    return [];
  }

  if (start < 1200) {
    times.push('morning');
  }
  if (start < 1700 && (end > 1200)) {
    times.push('afternoon');
  }
  if (end > 1700 || end === 0) {
    times.push('evening');
  }

  return times;
}

function getTimes(calendarEvents) {
  //
  // for each calendar event with a start & end date
  // and at least one day with a start & end time
  // collect all the days that have a start & end time
  //
  // determine which times of day are applicable to each day of each event
  //
  // return a de-duped list of those times of day
  //

  let validTimes = [];
  if (!calendarEvents) return validTimes;
  const validEvents = calendarEvents.filter(getEventIsValid);
  for (let i = 0, l = validEvents.length; i < l; i++) {
    const event = validEvents[i];
    let eventTimes = event.day
      .filter(e => (e.day && e.start_time && e.end_time))
      .map(getEventTimeOfDay);
    eventTimes = flattenDeep(eventTimes);
    validTimes = validTimes.concat(eventTimes);
  }
  validTimes = validTimes.reduce((acc, time) => {
    if (acc.indexOf(time) === -1) {
      acc.push(time);
    }
    return acc;
  }, []);
  return validTimes;
}


function getSessionTimeOfYear(event) {
  //
  // summer = 6/1 - 8/31 (TBD)
  // school = 9/1 - 5/31 (TBD)
  //
  if (!event || !event.start_date || !event.end_date) return null;
  const start = event.start_date.split('-');
  const startMonth = start[1];
  const startDate = new Date(event.start_date);
  const end = event.end_date.split('-');
  const endMonth = end[1];
  const endDate = new Date(event.end_date);
  const seasons = [];

  if (startDate > endDate) {
    console.error(`cannot understand an event with a start (${event.start_date}) after the end (${event.end_date})`); // eslint-disable-line no-console, max-len
  } else {
    if ((startMonth > 5 && startMonth < 9) || (endMonth > 5 && endMonth < 9)) {
      seasons.push('summer');
    }
    if ((endMonth > 8 || endMonth < 6) || (startMonth > 8 || startMonth < 6)) {
      seasons.push('school');
    }
  }
  return seasons;
}


function getSeasons(calendarEvents) {
  //
  // for each calendar event with a start & end date
  // and at least one day with a start & end time
  // determine the seasons during which it is offered
  //

  let seasons = [];
  if (!calendarEvents) return seasons;
  const validEvents = calendarEvents.filter(getEventIsValid);
  for (let i = 0, l = validEvents.length; i < l; i++) {
    const event = calendarEvents[i];
    const eventSeasons = getSessionTimeOfYear(event);
    seasons = seasons.concat(eventSeasons);
  }

  seasons = seasons.reduce((acc, season) => {
    if (acc.indexOf(season) === -1) {
      acc.push(season);
    }
    return acc;
  }, []);

  return seasons;
}

export {
  getEventTimeOfDay,
  getSessionTimeOfYear,

  getDays,
  getTimes,
  getSeasons,
};
