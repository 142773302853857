<template>
  <div id="filter-controls" class="container-fluid">
    <div class="row py-2">
      <div class="col-6 col-xs-6 col-sm-auto ml-sm-auto ">
        <button v-on:click="resetAllFilters" id="filter-controls_clear" class="c-button c-button--outline button--full w-100">{{settings.clear_filters_text}}</button>
      </div>
      <div class="col-6 col-xs-6 col-sm-auto">
        <button v-on:click="viewResults" id="filter-controls_apply" class="button--active button--full c-button w-100">{{settings.view_results_text}}</button>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: [
      'settings',
      'layout'
    ],
    methods: {
      resetAllFilters: function resetAllFilters() {
        sendEvent({
          category: 'filter',
          action: 'clear_all'
        });
        // false = do not apply default selections to filters
        this.$store.dispatch('resetAllFilters', false);
      },
      viewResults: function viewResults() {
        this.$store.dispatch('updateMobileView', 'list');
        this.$store.dispatch('updateMoreFiltersExpanded', false);
      }
    }
  };
</script>