import sendEvent from 'tembo-js/sendEvent';

module.exports = {
  props: [
    'settings',
    'layout',
    'entity',
    'idx',
    'id',
    'x'
  ],
  computed: {
    relaxedFilterCount: function relaxedFilterCount() {
      return this.$store.getters.relaxedFilterCount;
    },
    entityGrades: function entityGrades() {
      var grades = this.entity.grades;
      if (typeof grades === 'string') {
        return grades;
      }
      if (Array.isArray(grades) && grades.length > 0) {
        if (grades.length > 1) {
          return grades[0] + ' - ' + grades[grades.length - 1];
        }
        return grades[0];
      }
      return grades;
    },
    entityUrl: function entityUrl() {
      var queryStringParams = ['db', 'lang'];
      var queryString = '?';
      for (const param of queryStringParams) {
        if (this.$route.query[param]) {
          queryString += param + '=' + this.$route.query[param] + '&';
        }
      }
      queryString = queryString.slice(0, -1);
      return '/schools/' + this.entity.id + queryString;
    },
    favDisabled: function favDisabled() {
      return this.$store.state.favoritesDisabled.indexOf(this.entity.id) > -1;
    },
    compares: function compares() {
      return this.$store.state.compares;
    }
  },
  methods: {
    padNumberString: function padNumberString(number, decimals) {
      var integer = Math.floor(number).toString();
      var fraction = (number + '').split('.')[1];
      if (!fraction) fraction = '0';
      while (fraction.length < decimals) {
        fraction += '0';
      }
      return integer + '.' + fraction;
    },
    handleHeartClick: function handleHeartClick(favorited) {
      var sessionItem = { id: this.entity.id, favorite: favorited, name: this.entity.name };
      if (this.framework) sessionItem.framework = this.framework;
      this.$store.dispatch('updateFavorite', sessionItem);
    },
    handleCompareClick: function handleCompareClick(compared) {
      //
      // currently assumes compare will never be auth-based
      //
      let limitReached = false;
      let maximum;
      let sessionItem;
      //
      // set default for compare maximum
      //
      if (this.settings.compare_maximum) {
        maximum = this.settings.compare_maximum;
      } else {
        maximum = 3;
      }
      if (this.compares.length >= maximum) {
        limitReached = true;
      }

      if (compared && limitReached) {
        //
        // if entity has been added and limit has been reached
        // reset compare property on entity & show ribbon with limit state
        //
        this.$store.dispatch('updateShowRibbon', true);
        this.$store.dispatch('updateRibbonError', true);
        //
        // TODO: may be a better way to handle this
        // this is a temporary fix: a brief save & then removing from compares
        // to prevent a flash when selecting a compare button shouldn't be allowed
        // may need additional UX input
        //
        // temporarily save
        //
        sessionItem = { id: this.entity.id, compare: true, name: this.entity.name };
        this.$store.dispatch('updateCompare', sessionItem)
        .then(() => {
          setTimeout(() => {
            sessionItem.compare = false;
            //
            // remove item from compare list
            //
            this.$store.dispatch('updateCompare', sessionItem);
          }, 300);
        });
      } else {
        sessionItem = { id: this.entity.id, compare: compared, name: this.entity.name };
        if (compared) {
          //
          // if entity has been saved and is not past the limit, add to compares & show ribbon
          //
          this.$store.dispatch('updateShowRibbon', true);
        } else {
          //
          // if removing an entity from the compare list, hide the ribbon (if not hidden already)
          //
          this.$store.dispatch('updateShowRibbon', false);
        }
        this.$store.dispatch('updateCompare', sessionItem);
      }
    },
    handleNameClick: function handleNameClick(entity) {
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_list` : 'list ';
      sendEvent({
        category: category,
        action: 'report_link',
        label: entity.id
      });
    },
    closeItem: function closeItem() {
      this.$emit('update:entity', null);
    }
  }
};
