<template>
  <div class="search-wrapper">
    <address-alert-modal :show="addressAlert"
      :address-data="modalData"
      :settings="settings"
      ></address-alert-modal>
    <address-resolve-modal :show="addressResolve"
      :settings="settings"
      :address-data="modalData"
      :address-search-text="addressSearchText"
      ></address-resolve-modal>
    <search-input
      :placeholder="settings.search_placeholder_text"
      :data-input="addressSearchText"
      v-on:keyup.13="handleaddressSearchText(addressSearchText)">
    </search-input>
  </div>
</template>
<script type="text/javascript">
  import AddressAlertModal from './overlays/AddressAlertModal.vue';
  import AddressResolveModal from './overlays/AddressResolveModal.vue';
  import SearchInput from './SearchInput.vue';
  const AddressGoogleMixin = require('../mixins/AddressGoogleMixin');

  module.exports = {
    components: {
      AddressAlertModal,
      AddressResolveModal,
      SearchInput
    },
    props: [
      'settings',
      'layout',
      'dataInput'
    ],
    mixins: [AddressGoogleMixin],
    data: function data() {
      return {
        addressSearchText: this.getSearchText()
      };
    },
    events: {
      resetAddress: function resetAddress() {
        this.updateAddress();
      },
      handleResolvedAddress: function handleResolvedAddress(address) {
        this.handleChildAddress(address);
      },
      handleModalAddress: function handleModalAddress(address) {
        this.handleaddressSearchText(address);
      },
      handleSearchClick: function handleSearchClick() {
        this.handleaddressSearchText(this.addressSearchText);
      }
    }
  };
</script>
