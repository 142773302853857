<template>
<div v-on:mouseleave="startTimeout" v-on:mouseenter="stopTimeout">
    <div class="button-mobile-dropdown visible-xs-inline-block visible-sm-inline-block" v-on:click="toggle" data-mobile="trigger"><span class='ellipsis-group'><i class="fa fa-circle" aria-hidden="true"></i><i class="fa fa-circle" aria-hidden="true"></i><i class="fa fa-circle" aria-hidden="true"></i></span></div>
    <div class="favorite-compare-button-container__mobile-content " :class="state === 'open' ? '' : 'hidden-xs hidden-sm'" data-mobile="target">
        <slot></slot>
    </div>
</div>
</template>
<script type="text/javascript">

module.exports = {
  methods: {
    toggle: function toggle() {
      if (this.state === 'open') {
        this.state = 'closed';
      } else {
        this.state = 'open';
      }
    },
    openTarget: function openTarge() {
      // this.target.addClass('target-display');
      this.state = 'open';
    },
    closeTarget: function closeTarget() {
      // this.target.removeClass('target-display');
      this.state = 'closed';
    },
    startTimeout: function startTimeout() {
      if (this.state === 'open') {
        this.timeout = setTimeout(this.closeTarget, 800);
      }
    },
    stopTimeout: function stopTimeout() {
      if (this.state === 'open') {
        clearTimeout(this.timeout);
      }
    }
  },
  data: function data() {
    return {
      state: 'closed',
      timeout: ''
    };
  }
};
</script>