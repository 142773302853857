<template>
  <!-- PREFERENCES MET -->
  <div class="entity-attributes__preferences-met" v-if="relaxedFilterCount > 0" >
    <div class="d-inline-flex flex-column align-items-start align-items-md-center" v-on:click="handleMatchClick(entity)">
      <div class="p-entity__attributes__title mb-1">
        <i class="fal mr-1" aria-hidden="true" :class="entityData > 0 ? 'fa-check-square' : 'fa-square' "></i>{{relaxedFilterCount > 1 ? settings.match_plural_text : settings.match_singular_text}}</div>
      <div class="a">{{entityData}} {{settings.match_connect_text}} {{relaxedFilterCount}}</div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: [
      'settings',
      'entityData',
      'entity'
    ],
    computed: {
      relaxedFilterCount: function relaxedFilterCount() {
        return this.$store.getters.relaxedFilterCount;
      }
    },
    methods: {
      handleMatchClick: function handleMatchClick(entity) {
        this.$store.dispatch('updateShowModal', { modal: 'preferences', data: entity });
        sendEvent({
          category: 'list',
          action: 'inspect_matches',
          label: entity.id
        });
      }
    }
  };
</script>