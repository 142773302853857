<template>
  <div class="d-flex align-items-center">
    <p class="flex-grow-1">
    <span v-if="entitiesLoaded">{{countMessage}}&nbsp</span><!--
     --><span v-if="entitiesLoaded && settings.change_filters && distanceIsRelevant">{{distanceMessage}}&nbsp</span><!--
     --><span v-if="entitiesLoaded && settings.change_filters && !districtIsRelevant">{{settings.within_district_text}}&nbsp</span>
    </span>
  </p>
  <a v-on:click="showFilters" class="flex-shrink-0 ml-2" v-if="entitiesLoaded && settings.change_filters">{{settings.linked_text}}</a>
  </div>

</template>
<script type="text/javascript">
  var ResultStatusMixin = require('../mixins/ResultStatusMixin');
  module.exports = {
    mixins: [ResultStatusMixin],
    computed: {
      districtIsRelevant: function districtIsRelevant() {
        if (this.filterRequirement('district')) return true;
        return false;
      }
    }
  };
</script>