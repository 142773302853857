import staticData from '../store/data';
module.exports = {
  computed: {
    staticData() {
      return staticData;
    },
  },
  watch: {
    $route: function $route(to, from) {
      const configDataKey = this.$route.name;
      let config;
      if (configDataKey !== 'find') {
        config = this.staticData[configDataKey].config;
      } else {
        config = this.staticData.config;
      }
      let lang;
      // get the language of the new route
      if (to.query && to.query.lang) {
        lang = to.query.lang;
        // if the new language isn't supported, use the default value
        if (config.defaults.language_options.options.indexOf(lang) === -1) {
          lang = config.defaults.language_options.default_value;
        }
      } else {
        lang = config.defaults.language_options.default_value;
      }

      if (lang !== from.query.lang && (
        (config.defaults.language_options.default_value !== lang)
          || (from.query.lang !== undefined))) {
        this.$nextTick(window.location.reload.bind(window.location));
      }
    },
  },
};
