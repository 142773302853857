<template>
  <div class="p-footer py-gutter">
    <div class="container">
      <div class="row mt-auto text-left text-sm-center">
        <!-- LOGO -->
        <div v-if="settings.logo" class="col-12 col-sm-3 col-lg-2 offset-lg-1">
          <a v-if="settings.logo.href" :href="settings.logo.href" target="_blank"><img :src="settings.logo.src" :alt-text="settings.logo.alt_text" class="footer-logo"></a>
          <img v-else :src="settings.logo.src" :alt-text="settings.logo.alt_text" class="footer-logo">
        </div>
        <div class="col-12 col-sm-9 col-lg-8">
          <div class="row">
            <div v-for="section, idx in settings.sections" class="col-12 col-sm-6 col-md-3 mt-4 d-flex mt-md-0 flex-column align-items-start text-left" :class="{'mt-sm-0': idx < 2 } ">
              <template v-for="line, i in section">
                <a v-if="line.href" :href="line.href" class="p-footer__p" :class="(line.class_name ? line.class_name : '') + ' ' + (i > 0 ? 'mt-1' : '')" target="_blank">{{line.text}}</a>
                <div v-else-if="line.list" :class="i > 0 ? 'mt-1' : ''">
                  <template v-for="item in line.list">
                    <a v-if="item.href" :href="item.href" class="p-footer__p p-footer__list-item" :class="(line.class_name ? line.class_name : '')" target="_blank">{{item.text}}</a>
                    <span v-else-if="item.text" class="p-footer__p p-footer__list-item" :class="(line.class_name ? line.class_name : '')">{{item.text}}</span>
                  </template>
                </div>
                <p v-else-if="line.text" class="p-footer__p" :class="(line.class_name ? line.class_name : '') + ' ' + (i > 0 ? 'mt-1' : '')">{{line.text}}</p>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-lg-10 offset-lg-1 text-center">
          <hr style="border-top: 1px solid white !important;">
          <p class="p-footer__p mt-1">© {{settings.copyright_text}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">

  import AttributionInline from './AttributionInline.vue';
  import AttributionGoogle from './AttributionGoogle.vue';
  module.exports = {
    components: { AttributionInline, AttributionGoogle },
    props: ['settings']
  };
</script>
