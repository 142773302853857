<template>
  <div id="footer-wrapper" class="wrap flex-shrink-0">
      <div class="container">
          <div class="row">
              <div class="col-sm-6 col-md-4">
                      <p v-if="settings.organization_name">{{settings.organization_name}}<br/>

                      <template v-if="settings.address">
                          {{settings.address.street}}<br/>
                          {{settings.address.city}}, {{settings.address.state}} {{settings.address.postal_code}}
                      </template>
                      </p>

                    <p v-if="settings.phone">{{ settings.phone.prefix}} {{settings.phone.text}}
                      <template v-if="settings.secondary_phone">
                        <br>{{ settings.secondary_phone.prefix}} {{settings.secondary_phone.text}}
                      </template>
                    </p>


                  <a v-if="settings.website && settings.website.url" :href="settings.website.url"  style="display: block; margin: 30px 0;" class="p-footer__a">{{settings.website.text}}</a>
                  <div v-if="settings.facebook || settings.twitter" class="social">
                      <div v-if="settings.facebook" class="social_icon social_icon--facebook">
                        <a :href="settings.facebook" target="_blank" class="p-footer__a"><i class="fa fa-facebook"></i></a>
                      </div>
                      <div v-if="settings.twitter" class="social_icon social_icon--twitter">
                          <a :href="settings.twitter" target="_blank" class="p-footer__a"><i class="fa fa-twitter"></i></a>
                      </div>
                  </div>

                  <a href="//translate.google.com" target="_blank">
                    <img src="/assets/img/google-attribution.png" :alt="settings.google_attribution_alt_text" class="google-attribution" style="margin-bottom: 5px;"/>
                  </a>
              </div>
              <div class="col-sm-6 col-md-3 col-md-offset-5">
                <template v-if="settings.show_tembo_logo">
                  <p>{{ settings.powered_by }}
                  <br />
                  <a href="//www.hoonuit.com" target="_blank">
                    <hoonuit-logo-svg></hoonuit-logo-svg>
                  </a>
                  </p>
                </template>
                  <div v-if="settings.more_info_text" class="t-heading-4">{{settings.more_info_text}}</div>
                  <a class="p-footer__a" v-if="settings.district_detail" :href="settings.district_detail.url" style="display: block; margin-top: 10px; margin-bottom: 21px;">{{settings.district_detail.display}}</a>
                  <a class="p-footer__a" v-if="settings.data_download" :href="settings.data_download.url" style="display: block; margin-top: 10px; margin-bottom: 21px;">{{settings.data_download.display}}</a>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import HoonuitLogoSvg from '../assets/HoonuitLogoSvg.vue';
export default {
  props: ['settings', 'layout'],
  components: { HoonuitLogoSvg },
};
</script>
