<template>
  <nav class="p-nav__wrapper" id="vue-site-nav">
    <div v-if="settings.banner && settings.banner != null" class="p-nav__banner container-fluid py-3" :class="settings.banner_class ? settings.banner_class : 'bg-warning'">
      <div class="row">
        <div class="col-12">
          <p class="text-center" v-html="settings.banner"></p>
        </div>
      </div>
    </div>
    <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="p-nav">
          <!-- mobile logo/icon -->
          <a :href="settings.image_url + getQueryString()" class="d-md-none"><img :src="settings.image_mobile" :srcset="settings.image_mobile_srcset" :alt="settings.image_text" class="p-nav__logo d-md-none"></a>
          <!-- desktop logo -->
          <a :href="settings.image_url + getQueryString()" class="d-none d-md-inline"><img :src="settings.image" :srcset="settings.image_srcset" :alt="settings.image_text" class="p-nav__logo d-none d-md-block"></a>

          <!-- entity search modal -->
          <template v-if="layout && layout.entity_search">
            <screen-overlay :show-overlay="showSearch" v-on:closeOverlay="showSearch = false" :close-text="settings.modal_close_text">
              <component
                :is="layout.entity_search.tag"
                :settings="layout.entity_search.data">
              </component>
            </screen-overlay>
          </template>

          <!-- desktop container for nav -->
          <div class="d-none ml-auto d-md-flex align-items-center p-nav__lists">

            <!-- primary nav links -->
            <ul class="p-nav__list--primary sp-h-3 sp-h-lg-60 sp-h-xl-80">
              <li v-for="link in settings.links" :class="$route.path === link.url ? 'p-nav__item--active' : ''" class="d-flex flex-column justify-content-center"><a :href="link.url + getQueryString()">{{link.display_text}}</a></li>
            </ul>

            <!-- nav separator -->
            <div class="p-nav__seperator">
              <span class="p-nav__seperator__dot"></span>
              <span class="p-nav__seperator__dot"></span>
              <span class="p-nav__seperator__dot"></span>
              <span class="p-nav__seperator__dot"></span>
              <span class="p-nav__seperator__dot"></span>
            </div>

            <ul class="p-nav__list--secondary sp-h-40 sp-h-lg-60">

              <!-- entity search launcher -->
              <li v-if="layout && layout.entity_search" class="d-flex align-items-center">


                    <a class="align-items-center d-inline-flex" tabindex="0" v-on:keyup.enter="showSearch = true" v-on:click="showSearch = true"><i class="fas fa-fw fa-search"></i><span class="c-link c-link--utility">{{settings.search_button_text}}</span></a>

              </li>

              <!-- links to language(s) not currently in use -->
              <template v-if="showLanguages && otherLanguage.length < 2">
                <li v-for="lang in otherLanguage" class="d-flex align-items-center">
                  <a :href="getQueryString({ lang: lang.value })" class="align-items-center d-inline-flex" @click="handleLangClick(lang.value)">
                    <i class="fas fa-language fa-fw"></i><span class="c-link c-link--utility">{{lang.text}}</span>
                  </a>
                </li>
              </template>
              <li v-else-if="showLanguages" class="d-flex align-items-center">
                <select-with-menu
                  :options="settings.language_options.options"
                  :selected="$store.state.language"
                  @update:selected="updateLang"
                  :icon-class="'fas fa-language'"
                ></select-with-menu>
              </li>
            </ul>
          </div>

          <!-- mobile menu launcher -->
          <button class="d-md-none p-nav__mobile"  v-on:click="showMobileMenu = true">
            <i class="fas fa-bars  p-nav__mobile__icon"></i>
          </button>

          <!-- mobile menu -->
          <screen-overlay
            :show-overlay="showMobileMenu"
            :secondary-action="showMobileSearch ? settings.back_to_mobile_text : null"
            v-on:secondaryAction="backToMobileMenu"
            v-on:closeOverlay="closeMobileMenu">
            <!-- mobile menu -->
            <template v-if="!showMobileSearch">
              <!-- primary nav links -->
              <ul class="p-nav__list--primary d-block mt-60">
                <li v-for="link in settings.links" class="d-block" :class="$route.path == link.url ? 'p-nav__item--active' : ''"><a :href="link.url + getQueryString()">{{link.display_text}}</a></li>
              </ul>

              <ul class="p-nav__list--secondary d-block sp-v-gutter" style="margin-top: 80px;">
                <!-- entity search launcher -->
                <li v-if="layout && layout.entity_search" class="d-block">

                  <a class="align-items-center d-inline-flex" tabindex="0" v-on:keyup.enter="showMobileSearch = true;" v-on:click="showMobileSearch = true;"><i class="fa-fw fas fa-search"></i><span class="c-link">{{settings.search_button_text}}</span></a>
                </li>
                <!-- links to language(s) not currently in use -->
                <template v-if="showLanguages && otherLanguage.length < 2">
                  <li class="d-block" v-for="lang in otherLanguage">
                    <a :href="getQueryString({ lang: lang.value })" class="align-items-center d-inline-flex" @click="handleLangClick(lang.value)">
                      <i class="fas fa-language"></i><span class="c-link">{{lang.text}}</span>
                    </a>
                  </li>
                </template>
                <li v-else-if="showLanguages">
                  <select-with-menu
                    :options="settings.language_options.options"
                    :selected="$store.state.language"
                    @update:selected="updateLang"
                    :icon-class="'fas fa-language'"
                  ></select-with-menu>
                </li>
              </ul>
            </template>

            <!-- entity search -->
            <template v-if="layout && layout.entity_search">
              <div v-if="showMobileSearch">
                <component
                  :is="layout.entity_search.tag"
                  :settings="layout.entity_search.data">
                </component>
              </div>
            </template>
          </screen-overlay>

        </div>
      </div>
    </div>
    </div>
   </nav>
</template>
<script>
import ScreenOverlay from './overlays/ScreenOverlay.vue';
import SelectWithMenu from './SelectWithMenu.vue';
import sendEvent from 'tembo-js/sendEvent';
import { setupLinkClickEvents } from '../store/utils';

export default {
  props: ['settings', 'layout'],
  components: { ScreenOverlay, SelectWithMenu },
  data: function data() {
    return {
      showSearch: false,
      showMobileMenu: false,
      showMobileSearch: false
    };
  },
  mounted() {
    const category = 'find_site_nav';
    setupLinkClickEvents(this, category, '.ga-link-site-nav');
  },
  computed: {
    otherLanguage: function otherLanguage() {
      const nextLang = [];
      const optionCt = this.settings.language_options.options.length;

      const currentLang = this.$store.state.language;
      let i = 0;
      while (i < optionCt) {
        if (this.settings.language_options.options[i].value !== currentLang) {
          nextLang.push(this.settings.language_options.options[i]);
        }
        i += 1;
      }
      return nextLang;
    },
    showLanguages: function showLanguages() {
      if (this.settings.disable_languages === true) {
        return false;
      }
      return true;
    }
  },
  methods: {
    backToMobileMenu() {
      this.showMobileSearch = false;
    },
    closeMobileMenu() {
      this.showMobileMenu = false;
      this.showMobileSearch = false;
    },
    getQueryString(options) {
      let newQuery = Object.assign({}, this.$route.query);
      if (options) {
        newQuery = Object.assign(newQuery, options);
      }
      if (Object.keys(newQuery).length === 0 && newQuery.constructor === Object) return '';
      let queryStr = '?';
      Object.keys(newQuery).forEach((key) => {
        if (newQuery[key]) {
          queryStr += `${key}=${newQuery[key]}&`;
        }
      });
      return queryStr.slice(0, -1);
    },
    updateLang(lang) {
      this.handleLangClick(lang);
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.lang = lang;
      this.$router.push({ query: query });
    },
    handleLangClick(newVal) {
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_find_languages` : 'find_languages';
      sendEvent({
        category: category,
        action: 'update',
        label: newVal || this.otherLanguage[0].value,
      });
    },
  }
};
</script>
