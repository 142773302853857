<!-- THIS COMPONENT DOES NOT ALLOW RESETTING ADDRESS TO A DEFAULT OR CLEARING THE ADDRESS -->
<!-- TODO:
  * allow clearing address (?)
  * add google analytics
  * allow to load without the map? (for address component in general - maps are the only thing that load the google scripts)
 -->
<template>
  <div>
  <label class="c-input c-input--long" :class="optionsVisible ? 'c-input--open' : ''" data-has-options="true" :title="settings.search_placeholder_text">
    <!-- placeholder -->
    <span class="sr-only">{{settings.search_placeholder_text}}</span>

    <!-- input -->
    <div class="js-google-menu-target flex-grow-1">
      <div class="c-input__value">
        <i class="fa c-input__icon--search" :class="settings.icon ? settings.icon : (home && home.geolocation ? 'fa-location' : 'fa-search')"></i>
        <input  :id="id" slot="input" type="text" class="c-input__input auto-complete truncate-ellipsis flex-grow-1" :placeholder="settings.search_placeholder_text" v-model="addressSearchText" v-on:focus="inputFocus" v-on:input="placeResults" v-on:blur="removeOpenClass" />
      </div>
    </div>

    <!-- get my current location -->
    <template v-if="settings.current_location && settings.current_location.ui_type === 'button'">
      <button
        class="d-none c-button c-button--icon c-button--geolocation"
        :class="!(home && home.user) && !loading ? 'd-md-block' : ''"
        v-on:mouseover="showCurrentLocationTooltip"
        v-on:focus="showCurrentLocationTooltip"
        v-on:blur="hideCurrentLocationTooltip"
        v-on:mouseleave="hideCurrentLocationTooltip"
        v-on:click="getCurrentLocation"
        v-touch:tap="handleLocationTap"
        v-touch:touchhold="handleLocationTouchhold"
      >
        <i class="fas fa-location"></i>
      </button>
      <!-- current location in progress -->
      <span v-if="loading" class="d-none d-md-block c-button c-button--icon">
        <i class="fas fa-spin fa-spinner"></i>
      </span>
    </template>


    <!-- clear location -->
    <button v-if="home && home.user && (settings.current_location || settings.clear_location)"
      class="c-button c-button--icon"
      v-on:click="clearAddress"
    >
      <i class="fas fa-times"></i>
    </button>
  </label>

  <!-- mobile version -->
  <template v-if="settings.current_location && settings.current_location.ui_type === 'button'">
    <div v-if="geolocationText" class="d-block d-md-none px-3 py-1">
      <button class="c-link c-link--utility" v-on:click="getCurrentLocation">
        <i class="fas mr-2" :class="loading ? 'fa-spinner fa-spin' : 'fa-location'"></i>{{geolocationText}}
      </button>
    </div>

  </template>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  import staticData from '../store/data';
  var AddressGoogleMixin = require('../mixins/AddressGoogleMixin');
  var $ = require('jquery');
  global.jQuery = $;
  require('bootstrap/js/tooltip');


  /* eslint-disable no-undef, no-new, no-console */
  module.exports = {
    props: [
      'settings',
      'layout',
      'dataInput'
    ],
    mixins: [AddressGoogleMixin],
    data: function data() {
      return {
        initialized: false,
        id: null,
        resultsRelocated: false,
        optionsVisible: false,
        errorMessage: null,
        loading: false,
        geolocationStatus: null,
      };
    },
    mounted: function mounted() { // MAY NEED TO USE NEXTTICK
      this.$nextTick(() => {
        try {
          if (google) {
            this.initializeAddressEntry();
          }
        } catch (e) {
          if (window.initializeAddressEntry) {
            window.initializeAddressEntry();
            return;
          }
          const findRoute = this.$route.name;
          const config = findRoute === 'find' ? staticData.config : staticData[findRoute].config;
          // prevent loading script twice if the map component exists
          if (!config.data_map.settings.map_component) {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=' + config.defaults.env_vars.google_api_key + '&libraries=places&callback=initializeAddressEntry';
            document.getElementsByTagName('body')[0].appendChild(script);
            window.initializeAddressEntry = this.initializeAddressEntry;
          } else {
            this.initializeAddressEntry();
          }
        }
      });
    },
    computed: {
      home() {
        if (!this.$store.state.home) return null;
        return this.$store.state.home;
      },
      mapsLoaded: function mapsLoaded() {
        return this.$store.state.mapsLoaded;
      },
      addressSearchText: {
        get: function addressSearchText() {
          //
          // this is a computed property to make sure all instances of
          // this component within the app show the same text
          //
          return this.getSearchText();
        },
        set: function addressSearchText() {
          // don't actually need to do anything when this computed prop is updated
        }
      },
      geolocationText() {
        if (!this.settings.current_location) return null;
        if (this.geolocationStatus === 'prompt') {
          return this.settings.current_location.current_location_prompt_text;
        }
        if (this.geolocationStatus === 'granted') {
          return this.settings.current_location.current_location_prompt_text;
        }
        if (this.geolocationStatus === 'denied') {
          return this.settings.current_location.permission_denied_text;
        }
        if (this.geolocationStatus === 'unavailable') {
          return this.settings.current_location.feature_unavailable_text;
        }
        if (this.geolocationStatus === 'position_unavailable') {
          return this.settings.current_location.position_unavailable_text;
        }
        console.error(`status ${this.geolocationStatus} not handled`); // eslint-disable-line no-console, max-len
        return this.settings.current_location.current_location_prompt_text;
      }
    },
    methods: {
      initializeAddressEntry() {
        if (this.settings.current_location) {
          // set the geolocation permission status for our site
          // granted, denied or prompt
          const self = this;
          if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
              self.geolocationStatus = PermissionStatus.state;
            });
          }
        }

        if (this.settings.current_location && this.settings.current_location.ui_type === 'button') {
          const button = $(this.$el).find('.c-button--geolocation');
          const defaultTooltipText = this.geolocationText || this.settings.current_location.current_location_prompt_text; // eslint-disable-line max-len
          button.tooltip({ title: defaultTooltipText, trigger: 'manual', animation: false });
        }
        if (this.$store.state.mapsLoaded && !this.initialized) {
          this.initInput();
        }
        this.id = this._uid; // eslint-disable-line no-underscore-dangle
      },
      inputFocus() {
        this.initInput();

        // label element
        $(this.$el).find('.c-input').addClass('c-input--shadow');
      },
      initInput: function initInput() {
        var input;
        var autocomplete;
        var options;
        var bbox;
        var bounds;
        var boundSetNewAddress;
        if (this.initialized) {
          return true;
        }
        // callback passed to google event listener on 'place_changed event'
        // updates address, which is synced with app, triggers re-drawing home & panning map
        // when a place is selected from the dropdown and populates the input value
        function setNewAddress() {
          var place = autocomplete.getPlace();
          var updatedPlace;
          if (place.formatted_address && place.geometry) {
            updatedPlace = {
              formatted_address: place.formatted_address,
              geometry: {
                location: {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng()
                }
              }
            };
          }
          $(this.$el).find('input').blur();
          if (updatedPlace) {
            const findView = this.$route.params.findView;
            const category = findView ? `${findView}_map` : 'map';

            let label;
            const filteredZip = place.address_components.filter(component => component.types.includes('postal_code')); // eslint-disable-line max-len
            if (filteredZip.length) {
              try { label = filteredZip[0].short_name; } catch (e) { label = ''; }
            } else {
              // handle the case where we don't have a zip code (big cities)
              try { label = place.formatted_address; } catch (e) { label = ''; }
            }

            if (this.settings.metadata.explicit_apply) {
              this.$store.commit('setPendingAddress', [{ user: true, address: updatedPlace }]);
              sendEvent({
                category: category,
                action: 'address_search',
                label: label
              });
            } else {
              this.$store.dispatch('updateAddress', [{ user: true, address: updatedPlace }]);
              sendEvent({
                category: category,
                action: 'address_update',
                label: label
              });
            }
          }
        }

        // bind setNewAddress to 'this' context
        boundSetNewAddress = setNewAddress.bind(this);

        // input element
        input = $(this.$el).find('.auto-complete').get(0);

        if (!google) {
          // eslint-disable-next-line no-console
          console.error('the scripts required to run the address search have failed to load');
          return this.initialized;
        }

        // create google bounds from bbox in settings, set in config
        bbox = this.settings.metadata.bbox;
        bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(bbox.southWest.lat, bbox.southWest.lng),
          new google.maps.LatLng(bbox.northEast.lat, bbox.northEast.lng));

        // set options for google maps search
        options = {
          bounds: bounds,
          componentRestrictions: { country: 'us' },
          strictBounds: true
        };

        // initialize autocomplete class
        autocomplete = new google.maps.places.Autocomplete(input, options);

        // add event listener for user selection
        autocomplete.addListener('place_changed', boundSetNewAddress);
        this.initialized = true;
        // return this.initialized;

        // // Listen for the event fired when the user selects a prediction and retrieve
        // // more details for that place.
        // console.log('check places');
        // const self = this;
        // autocomplete.addListener('places_changed', function () {
        //   var places = autocomplete.getPlaces();
        //   console.log(places);
        //   if (places.length > 0) {
        //     self.optionsVisible = true;
        //   } else {
        //     self.optionsVisible = false;
        //   }
        // });

        return this.initialized;
      },
      placeResults: function placeResults() {
        // console.log('input interaction');
        if (!this.resultsRelocated) {
          $('.pac-container')
          .appendTo($(this.$el).find('.js-google-menu-target'))
          .addClass('c-input__options');
          this.resultsRelocated = true;
          // console.log('dropdown container created');
        }
      },
      showCurrentLocationTooltip() {
        $(this.$el).find('.c-button--geolocation')
          .attr('data-original-title', this.geolocationText)
          .tooltip('show');
      },
      hideCurrentLocationTooltip() {
        $(this.$el).find('.c-button--geolocation').tooltip('hide');
      },
      blurButton() {
        $(this.$el).find('.c-button--geolocation').blur();
      },
      handleLocationTap() {
        this.showCurrentLocationTooltip();
        setTimeout(() => {
          this.hideCurrentLocationTooltip();
          this.getCurrentLocation();
        }, 500);
      },
      removeOpenClass() {
        // console.log('removeOpenClass');
        $(this.$el).find('.c-input').removeClass('c-input--open c-input--shadow');
      },
      handleLocationTouchhold() {
        this.showCurrentLocationTooltip();
      },
      getCurrentLocation() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_map` : 'map';
        this.hideCurrentLocationTooltip();
        this.blurButton();
        sendEvent({ category, action: 'use_current_location' });

        if (navigator.geolocation) {
          this.loading = true;
          navigator.geolocation.getCurrentPosition((position) => {
            this.errorMessage = null;
            const currentLocation = {
              formatted_address: this.settings.current_location.my_location_text,
              geometry: {
                location: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                }
              }
            };
            this.loading = false;

            if (this.settings.metadata.explicit_apply) {
              this.$store.commit('setPendingAddress', [{
                user: true,
                address: currentLocation,
                geolocation: true
              }]);
            } else {
              this.$store.dispatch('updateAddress', [{
                user: true,
                address: currentLocation,
                geolocation: true
              }])
              .then(() => {
                $(this.$el).find('input').blur();
              });
            }
          }, this.handleLocationError);
        } else {
          $(this.$el).find('input').blur();
          this.loading = false;
          this.geolocationStatus = 'unavailable';
        }
      },
      handleLocationError(error) {
        this.loading = false;
        $(this.$el).find('input').blur();
        switch (error.code) {
          case error.PERMISSION_DENIED:
            this.geolocationStatus = 'denied';
            break;
          case error.POSITION_UNAVAILABLE:
            this.geolocationStatus = 'position_unavailable';
            break;
          case error.TIMEOUT:
            this.geolocationStatus = 'position_unavailable';
            break;
          case error.UNKNOWN_ERROR:
            this.geolocationStatus = 'position_unavailable';
            break;
          default:
            this.geolocationStatus = 'position_unavailable';
            break;
        }
      },
      clearAddress() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_map` : 'map';
        sendEvent({ category, action: 'clear_address' });

        this.blurButton();
        return this.$store.dispatch('updateAddress', []);
      },
    },
    watch: {
      mapsLoaded: function mapsLoaded() {
        if (!this.initialized) {
          this.initInput();
        }
      },
    }
  };
  /* eslint-enable no-undef, no-new */
</script>
<style>
/*.pac-container.pac-logo{
  width: max-content !important;
  min-width: 100% !important;
  left: unset !important;
  border:  0 !important;
  display: inline-block;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  clip: rect(0px, 1000px, 500px, -100px);
  z-index: -1;
}*/

/*.c-input--sm .pac-container.pac-logo{
  border-top-right-radius: 0;
}*/

/*.pac-logo:after {
  display: none;
}*/

/*.pac-container.pac-logo:after {
  display: none;
}*/
/*.pac-container.pac-logo .pac-item {
  font-size: 16px;
  border: 0;
  padding: 5px 12px;
  line-height: 18px;
}*/
/*.pac-container.pac-logo .pac-item:hover {
  background: #f9f5ef;
}*/
/*.pac-container.pac-logo .pac-item .pac-icon.pac-icon-marker {
  display: none;
}*/
/*.pac-container.pac-logo .pac-item .pac-item-query {
  font-size: 16px;
  font-weight: normal;
}*/
/*.pac-container.pac-logo .pac-item .pac-item-query .pac-matched {
  font-weight: normal;
}*/
</style>

<style scoped>
/*  .c-input:focus-within{
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
    position: relative;
    z-index: 9999;
    border: 0;
  }*/

/*    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
       .c-input{border: 1px solid #d1cdc8;}
       .pac-container.pac-logo{
        border: 1px solid #d1cdc8;
        border-top:  none;
       }
    }*/

/*  .c-input--open:focus-within .c-input__value {
    background: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }*/

  .c-button.c-button--icon {
    background: none;
    color: inherit;
    min-width: initial;

  }

</style>


