<!--
  Required props:
    * text: either html string or plain text

  Default behavior:
    * checks whether `text` is html or not.
    * wraps html in `<div>`
    * sanitizes html
      * tags allowed by default: 'strong', 'li', 'ul', 'ol', 'a', 'p', 'div', 'br'
      * inline styles are allowed by default
    * renders html or plain text

  Optional props:
    * tag:
      type: string
      Any valid html tag, replaces the <div> wrapper below ONLY for plain text without html content
    * additionalTags:
      type: array
      default: null
      Tags to allow in html, in addition to those listed above
    * overrideTags:
      type: array
      default: null
      ONLY allow these tags in the html. Do not allow default tags.
    * allowStyles:
      type: boolean
      default: true by default
      When false, will strip inline styles from html content.
    * skipSanitize
      type: boolean
      default: false by default
      When true, will render complete html with no stripping

  To include in a template/parent component:
    1. In the template:
       * `<rich-text :text="textData"></rich-text>`
    2. In the template's script:
       * import RichText component and include in `components` property
-->
<template>
  <!-- HTML -->
    <!-- HTML, wrapped only in this.tag -->
    <div v-if="isHTML && !tag && !skipSanitize" v-html="sanitizedHTML"  class="rich-text" v-bind:data-fallback-tag="tag ? tag : ''"></div>
    <div v-else-if="isHTML && !tag && skipSanitize" v-html="text"  class="rich-text" v-bind:data-fallback-tag="tag ? tag : ''"></div>
    <component v-else-if="isHTML" :is="tag" v-html="sanitizedHTML"  class="rich-text" v-bind:data-fallback-tag="tag ? tag : ''"></component>

  <!-- plain text -->
    <!-- text, wrapped only in this.tag -->
    <component v-else-if="tag" :is="tag" class="rich-text"  v-bind:data-fallback-tag="tag ? tag : ''">{{text}}</component>

    <!-- or wrapped only in div -->
    <div v-else class="rich-text" v-bind:data-fallback-tag="tag ? tag : ''">{{text}}</div>
</template>
<script type="text/javascript">
  import isHTML from 'tembo-js/isHTML';
  import cleanHTML from 'tembo-js/cleanHTML';
  import { setupLinkClickEvents } from '../store/utils';

  module.exports = {
    props: {
      text: {
        type: String,
        required: true,
      },
      additionalTags: {
        type: [Array, String],
        default: null,
      },
      overrideTags: {
        type: [Array, String],
        default: null,
      },
      allowStyles: {
        type: Boolean,
        default: true,
      },
      tag: {
        type: String,
        required: false
      },
      skipSanitize: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isHTML() {
        return isHTML(this.text);
      },
      sanitizedHTML() {
        const overrideTags = Array.isArray(this.overrideTags) || !this.overrideTags ? this.overrideTags : [this.overrideTags]; // eslint-disable-line max-len
        const additionalTags = Array.isArray(this.additionalTags) || !this.additionalTags ? this.additionalTags : [this.additionalTags]; // eslint-disable-line max-len
        const allowStyles = this.allowStyles;
        return cleanHTML(this.text, {
          additionalTags,
          overrideTags,
          allowStyles,
        });
      },
    },
    mounted() {
      setupLinkClickEvents(this, 'rich_text', 'a');
    }
  };
</script>
