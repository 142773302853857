<!-- this component currently supports display/search by for a SINGLE field -->
<!-- can be extended later -->
<!-- each option requires a text attribute, others are optional depending on the needs of the parent -->
<template>
  <typeahead-dropdown :options="options" :placeholder="placeholder" :enter-key-action="'selectResult'">
      <template slot="option-item" slot-scope="slotProps">
        <div
          v-for="(option, index) in slotProps.searchResults"
          tabindex="0"
          class="c-select__option"
          :class="getOptionClass(slotProps, index)"
          v-on:click.prevent="selectResult(option)"
          :hero="hero"
        >{{option.text}}</div>
    </template>
  </typeahead-dropdown>
</template>
<script type="text/javascript">
  // import $ from 'jquery';
  import TypeaheadDropdown from './TypeaheadDropdown.vue';

  module.exports = {
    components: {
      TypeaheadDropdown,
    },
    props: ['placeholder', 'options', 'hero'],
    methods: {
      getOptionClass(slotProps, index) {
        const classList = [];
        if (index === slotProps.highlightIndex) classList.push('c-select__option--current-selection');
        if (this.hero) classList.push('p-hero__input__option');
        return classList.join(' ');
      }
    }
  };
</script>
