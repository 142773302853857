<template>
  <div>
    <component
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
      class="position-relative"
      style="z-index: 1;"
    ></component>
    <div class="p-hero py-4">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-12 col-md-5 order-2 order-md-1 d-flex flex-column justify-content-center">

            <img v-if="settings.hero_img_mobile_src" :src="settings.hero_img_mobile_src" :alt="settings.hero_img_alt" class="d-md-none p-hero__img">
            <p v-else-if="this.debug" class="d-md-none p-hero__img">[hero_img_mobile_src]</p>

            <img v-if="settings.hero_img_src" :src="settings.hero_img_src" :alt="settings.hero_img_alt" class="d-none d-md-block p-hero__img">
            <p v-else-if="this.debug" class="d-none d-md-block p-hero__img">[hero_img_src]</p>

          </div>
          <div class="col-12 col-md-6 order-1 order-md-2 d-flex flex-column justify-content-center">
            <p class="p-hero__p mb-4" v-html="getTextToRender('hero_text', ['Every school', 'has a thousand stories.'])"></p>
            <h1 class="p-hero__h1 mb-5">{{ getTextToRender('hero_h1', 'What’s your story?') }}</h1>
            <h2 class="p-hero__h2 mb-3 d-none d-md-block">{{ getTextToRender('hero_h2', 'Learn all about your school.') }}</h2>
            <div class="d-flex" v-if="settings.search">
              <typeahead-dropdown-link class="flex-grow-1"
                :placeholder="settings.search.placeholder"
                :options="settings.search.options"
                :link-template="settings.entity_url_template"
                :no-results="settings.search.no_results"
                :hero="true"
              ></typeahead-dropdown-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">

      <!-- FIND ROUTES -->
      <div class="row" v-if="settings.find_routes">
        <div class="col-12 p-hero-links d-flex flex-column flex-md-row justify-content-around align-items-center my-5 sp-v-gutter sp-v-md-0">
          <div v-for="route in settings.find_routes" class="d-flex align-items-center">

            <img class="d-none d-md-block" :srcset="route.img_srcset"
              :src="route.img_src"
              :alt="route.img_alt_text" width="114px" height="114px" style="margin-right: 40px;">

            <div class="text-center text-md-left">
              <p class="p-hero-links__title">{{route.prompt_text}}</p>
              <a class="p-hero-links__link" :href="route.href + getQueryString($route.query)" v-html="lineBreakJoin(route.link_text)"></a>
            </div>
          </div>

        </div>
      </div>

      <section class="row">
        <article class="col-12 col-md-7">

          <div class="d-flex flex-column flex-column-reverse mb-4">
            <h1 class="h1--hero">[Consectetuer laoreet ulum.]</h1>
            <h2 class="h2--hero mb-2">[Vestibulum nonummy]</h2>
          </div>
          <rich-text
            v-for="(paragraph, index) in settings.paragraphs"
            :key="index"
            :text="paragraph"
            :class="index > 0 ? 'mt-2' : ''"
          ></rich-text>

          <aside class="row mt-5">
            <div class="col-12 col-md-5">
              <h3>[Viverra aliquam]</h3>
              <p>[Fusce ante sem, iaculis set amet ipsum eu, maximis viverra nibh. Ailquam ut sapien ac leo lobortis imperdiet]</p>
            </div>
            <div class="col-12 mt-5 mt-md-0 col-md-4 offset-md-1">
              <h3>[Viverra aliquam]</h3>
              <p>[Fusce ante sem, iaculis set amet ipsum eu, maximis viverra nibh. Ailquam ut sapien ac leo lobortis imperdiet]</p>
            </div>
          </aside>
        </article>
        <div class="col-12  col-md-4 offset-md-1 d-flex flex-column justify-content-center">
          <picture class="mx-60 my-gutter my-md-0 mx-md-0">
            <source srcset="https://storage.googleapis.com/cep-assets/images/SA_boy_at_desk_mobile.svg" media="(max-width: 799px)">
            <source srcset="https://storage.googleapis.com/cep-assets/images/SA_boy_at_desk_desktop.svg" media="(min-width: 800px)">
            <img src="" :alt="'[alt_text]'" class="img-fluid">
          </picture>
        </div>
      </section>
      <section class="row mt-3 mt-md-5">
        <div class="col-12 col-md-3 order-2 order-md-1 mt-5 mt-md-0">
          <div class="d-flex">
            <img src="https://storage.googleapis.com/cep-assets/icons/Backpack.png" alt="" style="width: 78px; height: 78px; object-position: -19px -12px;">

            <div>
              <h3 class="mb-2">[Tincidunt non elit]</h3>
              <p>[Fusce ante sem, iaculis set amet ipsum eu, maximis viverra nibh. Ailquam ut sapien ac leo lobortis imperdiet]</p>
            </div>
          </div>
          <div class="d-flex mt-4">
            <img src="https://storage.googleapis.com/cep-assets/icons/Vials.png" alt="" style="width: 78px; height: 78px; object-position: -15px -12px;">
            <div>
              <h3 class="mb-2">[Tincidunt non elit]</h3>
              <p>[Fusce ante sem, iaculis set amet ipsum eu, maximis viverra nibh. Ailquam ut sapien ac leo lobortis imperdiet]</p>
            </div>
          </div>
          <div class="d-flex mt-4">
            <img src="https://storage.googleapis.com/cep-assets/icons/Speech%20bubbles.png" alt="" style="width: 78px; height: 78px; object-position: -10px -12px;">
            <div>
              <h3 class="mb-2">[Tincidunt non elit]</h3>
              <p>[Fusce ante sem, iaculis set amet ipsum eu, maximis viverra nibh. Ailquam ut sapien ac leo lobortis imperdiet]</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 offset-md-1 order-1 order-md-2">
          <div class="d-flex flex-column flex-column-reverse mb-4">
            <h1 class="h1--hero">[Consectetuer laoreet ulum.]</h1>
            <h2 class="h2--hero mb-2">[Vestibulum nonummy]</h2>
          </div>
          <p>[Vivamus rutrum tortor nec dolor malesuada elementum. Etiam bibendum eu magna vel venenatis. Nunc eu posuere risus. In eleifend bibendum lacus, eget facilisis tellus dapibus quis. Praesent facilisis odio nec placerat consequat. Nunc sit amet ex sit amet quam tincidunt mollis. Proin tortor diam, tristique vitae imperdiet eu, tempor nec orci. Sed vel elit erat. Vivamus quis auctor ante.]</p>
          <p>[Vivamus rutrum tortor nec dolor malesuada elementum. Etiam bibendum eu magna vel venenatis. Nunc eu posuere risus. In eleifend bibendum lacus, eget facilisis tellus dapibus quis. Praesent facilisis odio nec placerat consequat. Nunc sit amet ex sit amet quam tincidunt mollis. Proin tortor diam, tristique vitae imperdiet eu, tempor nec orci. Sed vel elit erat. Vivamus quis auctor ante.]</p>
          <p>[Donec tristique blandit metus a lobortis. Sed gravida mollis odio et suscipit. Maecenas a justo et mauris condimentum maximus luctus id tellus. Suspendisse pharetra lectus sit amet felis vestibulum, ut facilisis neque semper.]</p>
        </div>
      </section>
    </div>
    <component
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class="mt-5 mt-md-80"
    ></component>
  </div>
</template>
<script type="text/javascript">
  import TypeaheadDropdownLink from '../TypeaheadDropdownLink.vue';
  import staticData from '../../store/data';
  import utils from '../../store/utils';
  import getQueryStringLibrary from 'tembo-js/getQueryString';
  import RichText from '../RichText.vue';

  // console.log(staticData)
  module.exports = {
    props: {
      settings: {
        type: Object,
        default: () => ({}),
        validator: obj => {
          // Props mapped from implementation mapping and config file (/assets/config/Finder.yml).
          const expectedProps = ['page_title', 'hero_text', 'hero_h1', 'hero_h2', 'hero_img_src',
            'hero_img_mobile_src', 'hero_img_alt'];
          const missingProps = expectedProps.filter(prop => !obj.hasOwnProperty(prop));
          if (staticData.homepage.config.defaults.debugger) {
            console.log(`Missing props from HomeHeroDetails: ${missingProps}`); // eslint-disable-line no-console, max-len
          }
          return true;
        }
      },
      layout: {
        type: Object
      },
    },
    components: { TypeaheadDropdownLink, RichText, },
    data() {
      return {
        selectedEntity: null,
        debug: this.$store.state.debug,
      };
    },
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    },
    computed: {
      entityUrl() {
        if (!this.selectedEntity) return '';
        return utils.getEntityUrl(this.selectedEntity, this, this.settings.entity_url_template); // eslint-disable-line max-len
      },
    },
    methods: {
      getQueryString: getQueryStringLibrary,
      lineBreakJoin: utils.lineBreakJoin,
      getTextToRender: function getTextToRender(prop, placeholder) {
        const textToRender = (this.settings[prop]) || (this.debug ? `[${prop}]` : placeholder);
        return Array.isArray(textToRender) ? this.lineBreakJoin(textToRender) : textToRender;
      }
    }
  };
</script>
