import isZero from './isZero';

function getDataByProperty(prop, row, def) {
  let defaultVal;
  let obj = row;

  if (typeof def === 'undefined') {
    defaultVal = null;
  } else {
    defaultVal = def;
  }

  if (!prop) return defaultVal;
  if (typeof prop !== 'string') return defaultVal;

  const props = prop.split('.');

  for (let i = 0, len = props.length; i < len; i++) {
    if ((!obj || !obj[props[i]]) && !isZero(obj[props[i]]) && obj[props[i]] !== false) {
      return defaultVal;
    }
    obj = obj[props[i]];
  }
  return obj;
}

export default getDataByProperty;
