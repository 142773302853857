<template>
  <div class="button--control-container button-group--horizontal sp-h-2" v-if="pageCount > 1">
    <button class="button--control c-button--control c-button" v-on:click="pageBack" :disabled="page <= 1">{{settings.previous_text}}</button>
    <span>{{page}} {{settings.join_text}} {{pageCount}}</span>
    <button class="button--control c-button--control c-button" v-on:click="pageFwd" :disabled="page >= pageCount">{{settings.next_text}}</button>
  </div>
</template>
<script type="text/javascript">
  var PaginationMixin = require('../mixins/PaginationMixin');
  module.exports = {
    mixins: [PaginationMixin]
  };
</script>
