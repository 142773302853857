var filters = require('../store/filters');
import arraysIncludeOrOverlap from 'tembo-js/arraysIncludeOrOverlap';
import getDataByProperty from 'tembo-js/getDataByProperty';
import flattenByProperty from 'tembo-js/flattenByProperty';

module.exports = {
  mounted: function mounted() {
    var lang;
    // MAY NEED TO USE NEXTTICK
    if (this.settings.boundaries && this.settings.boundaries.include && this.home && this.entities && this.entities.length) { // eslint-disable-line max-len
      lang = this.$store.getters.language;
      this.getAllBoundaries(this.home.address.geometry.location, lang)
      .then(this.parseBoundaries)
      .then(function success(boundaries) {
        this.boundaries = boundaries;
        this.handleNewBoundaries(boundaries, 'load');
      });
    }
  },
  computed: {
    entities: function entities() {
      return this.$store.getters.entitySet;
    },
    entitiesLoaded: function entitiesLoaded() {
      return this.$store.state.entitiesLoaded;
    },
    filters: function filters() { // eslint-disable-line no-shadow
      return this.$store.getters.filterSet;
    },
    boundaryRelatedFilters: function boundaryRelatedFilters() {
      var i;
      var l;
      var type;
      var result = {};
      var filter;
      var j;
      var k;
      if (!this.settings.boundaries || !this.settings.boundaries.include) return null;
      // for each boundary type
      for (i = 0, l = this.settings.boundaries.boundary_types.length; i < l; i ++) {
        type = this.settings.boundaries.boundary_types[i];
        // get any filters that it depends on
        if (type.related_filters && type.related_filters.length) {
          for (j = 0, k = type.related_filters.length; j < k; j ++) {
            filter = type.related_filters[j];
            // if the filter hasn't already been collected, collect it
            if (!result[filter]) {
              result[filter] = this.filters[filter];
            }
          }
        }
      }
      return result;
    }
  },
  data: function data() {
    return {
      boundaries: []
    };
  },
  methods: {
    handleNewBoundaries: function handleNewBoundaries(boundaries, event) {
      var i;
      var l;
      var type;
      var boundsByType;
      if (this.no_bounds) return;
      if (!boundaries || !boundaries.length) {
        this.$store.dispatch('updateNeighborhoodSchoolIds', []);
        this.$store.dispatch('updateTransportSchoolIds', []);
        this[this.settings.boundaries.no_boundaries_behavior](event);
        return;
      }
      this.$store.dispatch('hideModal', ['address_alert', 'address_resolve']);
      for (i = 0, l = this.settings.boundaries.boundary_types.length; i < l; i ++) {
        type = this.settings.boundaries.boundary_types[i];
        // get the collection of boundaries that match each rule
        boundsByType = this.getBoundariesByType(boundaries, type);
        // apply the correct behavior for each rule
        // if the triggering event is one allowed by config
        if (type.events.indexOf(event) > -1) {
          try {
            this[type.boundary_behavior](boundsByType, type);
          } catch (e) {
            console.error('caught in', type.boundary_behavior, e); // eslint-disable-line
          }
        }
      }
    },
    getAllBoundaries: function getAllBoundaries(loc, lang) {
      var url;
      if (!this.settings.boundaries || !this.settings.boundaries.include) return null;
      url = '/api/boundary?lat=' + loc.lat + '&long=' + loc.lng + '&lang=' + lang;
      return this.$http.get(url)
      .then(res => res.text())
      .catch(() => {
        this.no_bounds = true;
        throw new Error('no boundaries');
      });
    },
    parseBoundaries: function parseBoundaries(res) {
      var parsed;
      var json = res.replace(/'\s*'/g, ' ');
      function convertStrToArray(str) {
        if (str.indexOf(', ') > -1) {
          return str.split(', ');
        }
        return str.split(',');
      }
      json = json.replace(/'/g, '"');
      parsed = JSON.parse(json);
      parsed.map((bound) => {
        var updated = bound;
        if (updated.properties && updated.properties.BOUND_GR) {
          if (typeof updated.properties.BOUND_GR === 'string') {
            updated.properties.BOUND_GR = convertStrToArray(updated.properties.BOUND_GR);
          }
        }
        return updated;
      });
      return parsed;
    },
    getBoundariesByType: function getBoundariesByType(boundaries, type) {
      var i;
      var l;
      var data;
      var results = [];
      for (i = 0, l = boundaries.length; i < l; i ++) {
        data = getDataByProperty(type.where, boundaries[i]);
        if (type.rule === 'exists' && data) {
          results.push(boundaries[i]);
        } else if (type.rule === 'NE' && data && data !== type.value) {
          results.push(boundaries[i]);
        } else if (type.rule === 'EQ') {
          if (data &&
          (data === type.value || arraysIncludeOrOverlap(data, type.value))) {
            results.push(boundaries[i]);
          }
        }
      }
      return results;
    },
    filterBoundariesByGrade: function filterBoundariesByGrade(boundaries) {
      var requiredGrades;
      var filteredBounds = [];
      var i;
      var l;
      var bound;
      var data;
      // get required grades
      requiredGrades = filters.getRequiredValues(this.filters.grade_levels);
      requiredGrades = flattenByProperty('value', requiredGrades);
      // filter boundaries
      if (!requiredGrades || requiredGrades.length === 0) {
        filteredBounds = boundaries;
      } else {
        for (i = 0, l = boundaries.length; i < l; i ++) {
          bound = boundaries[i];
          data = getDataByProperty('properties.BOUND_GR', bound);
          if (arraysIncludeOrOverlap(data, requiredGrades)) {
            filteredBounds.push(bound);
          }
        }
      }
      return filteredBounds;
    },
    getGradeLevelNeighborhoodSchools: function getGradeLevelNeighborhoodSchools(boundaries) {
      var filteredIds;
      var filteredBounds = this.filterBoundariesByGrade(boundaries);
      // get neighborhood school ids
      filteredIds = flattenByProperty('properties.SF_ID', filteredBounds);
      this.$store.dispatch('updateNeighborhoodSchoolIds', filteredIds);
      return filteredIds;
    },
    alertOuterTownship: function alertOuterTownship(boundaries) {
      if (boundaries.length && boundaries.length === 1) {
        this.$store.dispatch('updateShowModal', { modal: 'address_alert', data: boundaries[0] });
      }
    },
    forbid: function forbid() {
      this.$store.dispatch('updateShowModal', { modal: 'address_resolve' });
    },
    getGradeLevelTransportSchools: function getGradeLevelTransportSchools(boundaries) {
      var filteredBounds = this.filterBoundariesByGrade(boundaries);
      var idsWithTransportation = {};
      var type;
      var schoolId;
      var i;
      var l;

      // group by school id
      for (i = 0, l = filteredBounds.length; i < l; i ++) {
        type = filteredBounds[i].properties.TYPE;
        schoolId = filteredBounds[i].properties.SF_ID;
        if (!idsWithTransportation.hasOwnProperty(schoolId)) {
          idsWithTransportation[schoolId] = [type];
        } else if (idsWithTransportation[schoolId].indexOf(type) === -1) {
          idsWithTransportation[schoolId].push(type);
        }
      }
      this.$store.dispatch('updateTransportSchoolIds', idsWithTransportation);
      return idsWithTransportation;
    }
  },
  watch: {
    entitiesLoaded: {
      handler: function watchEntities() {
        var lang;
        if (this.settings.boundaries && this.settings.boundaries.include && this.home && this.entities && this.entities.length) { // eslint-disable-line max-len
          lang = this.$store.getters.language;
          this.getAllBoundaries(this.home.address.geometry.location, lang)
          .then(this.parseBoundaries)
          .then(function success(boundaries) {
            this.boundaries = boundaries;
            this.handleNewBoundaries(boundaries, 'load');
          });
        }
      }
    },
    home: {
      handler: function homeHandler() {
        var lang;
        if (!this.settings) return null;
        // if home changes and home exists
        if (this.home) {
          // if config calls for applying neighborhoods
          if (this.settings.boundaries && this.settings.boundaries.include && this.entities && this.entities.length) { // eslint-disable-line max-len
            lang = this.$store.getters.language;
            this.getAllBoundaries(this.home.address.geometry.location, lang)
            .then(this.parseBoundaries)
            .then(function success(boundaries) {
              this.boundaries = boundaries;
              this.handleNewBoundaries(boundaries, 'home');
            });
          }
        }

        return this.home;
      },
      deep: true
    },
    idsWithTransportation: {
      handler: function idsWithTransportation() {
        // if (!this.settings.boundaries || !this.settings.boundaries.include) return;
        // // when schools in transportation bounds change, re-apply to entities
        // this.applyTransportation(this.idsWithTransportation);
        // this.updateFiltered();
      },
      deep: true
    },
    boundaryRelatedFilters: {
      handler: function boundaryRelatedFilters() {
        if (!this.settings.boundaries || !this.settings.boundaries.include) return;
        this.handleNewBoundaries(this.boundaries, 'filter');
      },
      deep: true
    }
  }
};
