<!-- This component responds to grade-level super-filter selections: -->
  <!-- * If early ed filter selected: -->
    <!-- * Show early ed performance data or missing data text (if supplied)-->
    <!-- * Always show K-12 performance data if supplied -->
  <!-- * If k-12 filter selected: -->
    <!-- * Show K-12 perfrormance data or missing data text (if supplied) -->
  <!-- If early ed filter selected AND school includes early-ed & any K-12 grades: -->
    <!-- * Url should direct to early-ed specific explore page -->
<template>
  <div v-if="entity" :id="id ? id : entity.id" class="p-0 c-card entity p-entity flex-shrink-0" v-bind:class="{ 'p-entity--is-neighborhood-entity': entity.computed.neighborhood }">
    <div class="entity_order p-entity__order p-2 text-center"><span style="width: 3ch">{{idx}}</span></div>

    <!-- BUTTON TO CLOSE DIV -->
<!--     <div v-if="x" v-on:click="closeItem" class="modal_close modal_close--ui"><modal-close-svg></modal-close-svg></div> -->

    <!-- SCHOOL INFO -->
    <div class="flex-grow-1">
      <div class="d-flex">
        <div class="p-entity__information entity_information mx-3 mb-3 mb-md-3 mt-2 ">
          <!-- <div class="d-flex"> -->
          <!-- SCHOOL NAME -->
          <div class="d-flex">
            <div class="flex-grow-1">
              <h3 class="entity_name flex-grow-1"><a class="a a-ui-grow" v-bind:href="gradeDependentUrl" v-on:click="handleNameClick(entity)">{{entity.name}}</a></h3>
              <!-- GRADES & DISTRICT -->
              <p class="p-entity__about  sp-h-md-2 d-flex flex-column flex-md-row">
                <range-datum
                  v-if="entity.grades"
                  :entity-data="entity.grades"
                ></range-datum>
                <text-span-datum
                  v-if="entity.district"
                  :entity-data="entity.district"
                ></text-span-datum>
                <text-span-datum
                  v-if="entity.region"
                  :entity-data="entity.region"
                ></text-span-datum>
              </p>
              <!-- Alternative Badge -->
              <p v-if="entity.alternative_school" class="alternative-badge"><i class="fas fa-school"></i>{{ entity.alternative_school }}</p>
            </div>
            <div class="favorite-container d-flex flex-column" v-if=" settings.favorite">
            <!-- <mobile-button-container> -->

              <favorite-heart-no-text
                v-if="settings.favorite"
                :checked="entity.computed.favorite"
                :value="entity.id"
                :disabled="favDisabled"
                @update:favorite="val => { handleHeartClick(val) }"
                :text-saved="settings.fav_success_text"
                :text-add="settings.fav_save_text"
                :text-remove="settings.fav_remove_text"
              ></favorite-heart-no-text>
            <!-- </mobile-button-container> -->
            </div>
          </div>


          <!-- DISTANCE, RATINGS, MATCHES -->
          <div class="entity-attributes p-entity__attributes mt-2 flex-column flex-md-row sp-v-3 sp-v-md-0">

            <!-- DISTANCE: requires computed distance or missing data rule -->
            <div
              v-if="layout && layout.distance.tag && (entity.computed.distance_from_home || entity.computed.distance_from_home === 0 || settings.missing_data_rules && settings.missing_data_rules.distance)"
              :is="layout.distance.tag"
              :settings="settings"
              :entity-data="entity.computed.distance_from_home"
            ></div>
            <distance-badge
              v-if="(!layout || !layout.distance.tag) && (entity.computed.distance_from_home || entity.computed.distance_from_home === 0 || settings.missing_data_rules && settings.missing_data_rules.distance)"
              :settings="settings"
              :entity-data="entity.computed.distance_from_home"
            ></distance-badge>

            <!-- distance placeholder -->
            <div class="entity-attributes__placeholder entity-attributes__placeholder-distance-1" v-if="!entity.computed.distance_from_home && (!settings.missing_data_rules || !settings.missing_data_rules.distance)"></div>


            <!-- PRE-K RATING: requires that pre-k grade is selected and pre-k rating exists -->
            <performance-badge v-if="preKSelected && entity.pre_k_ratings"
              :settings="settings"
              :entity-data="entity.pre_k_score ? {rating_text: entity.pre_k_ratings[0].rating_text, score: entity.pre_k_score} : entity.pre_k_ratings[0]"
              :strong-text="settings.early_education_performance_strong_text"
              :sub-text="settings.early_education_performance_sub_text"
            ></performance-badge>

            <performance-badge v-if="preKSelected && !entity.pre_k_ratings && settings.missing_data_rules && settings.missing_data_rules.pre_k_ratings"
              :settings="settings"
              :entity-data="settings.missing_data_rules.pre_k_ratings.missing_data_text"
              :missing="true"
              :strong-text="settings.early_education_performance_strong_text"
              :sub-text="settings.early_education_performance_sub_text"
            ></performance-badge>

            <!-- Placeholder if pre-k selected but no data & no missing-data rules -->
            <div class="entity-attributes__placeholder entity-attributes__placeholder-pre-k-selected-no-data-no-rules" v-if="preKSelected && (!entity.pre_k_ratings && (!settings.missing_data_rules || !settings.missing_data_rules.pre_k_ratings))"></div>


            <!-- K-12 letter grade: display if available -->
            <letter-grade-badge v-if="entity.report_cards && (entity.report_cards.length && entity.report_cards.length !== 0)"
              :settings="settings"
              :entity-data="entity.sps_rating ? {letter_grade: entity.report_cards[0].letter_grade, score: entity.sps_rating } : entity.report_cards[0]"
              :strong-text="settings.report_card_strong_text"
              :sub-text="settings.report_card_sub_text"
            ></letter-grade-badge>

            <!-- K-12 LETTER GRADE Missing data: Display if pre-k not selected, letter grade not available and missing data rules are supplied -->
            <letter-grade-badge v-if="!preKSelected && (!entity.report_cards || !entity.report_cards.length || entity.report_cards.length === 0) && settings.missing_data_rules && settings.missing_data_rules.report_cards"
              :settings="settings"
              :entity-data="settings.missing_data_rules.report_cards.missing_data_text"
              :strong-text="settings.report_card_strong_text"
              :sub-text="settings.report_card_sub_text"
              :missing="true"
            ></letter-grade-badge>

            <!-- Placeholder, for early ed schools with no k-12 data. Preserves columns -->
            <div class="entity-attributes__placeholder entity-attributes__placeholder-early-ed-w-no-k1-data" v-if="preKSelected && (!entity.report_cards || (entity.report_cards.length && entity.report_cards.length == 0))"></div>


            <!-- PREFERENCES MET -->
            <preferences-badge
              :settings="settings"
              :entity-data="entity.computed.match_rate"
              :entity="entity"
            ></preferences-badge>
            <video-modal-launcher
              v-if="entity.video"
              :settings="settings"
              :entity="entity"
            ></video-modal-launcher>
            <!-- Placeholder, for entities that dont have videos, NMPED-182 Preserves columns -->
            <div class="entity-attributes__placeholder entity-attributes__placeholder--doesnt-have-video" v-if="!entity.video && settings.video_modal != null"></div>

          </div>
        </div>
      </div>
      <div v-if="settings.compare" class="mb-3 mx-3 mt-0">
        <button-compare

            :checked="entity.computed.compare"
            :value="entity.id"
            @update:compare="val => { handleCompareClick(val) }"
            :text-saved="settings.compare_success_text"
            :text-add="settings.compare_save_text"
            :text-remove="settings.compare_remove_text"
          ></button-compare>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  var ResultItemMixin = require('../../mixins/ResultItemMixin');
  var GradeDependentMixin = require('../../mixins/GradeDependentMixin');
  var ReactiveFilterMixin = require('../../mixins/ReactiveFilterMixin');

  import ButtonCompare from '../compare/ButtonCompare.vue';
  import TextSpanDatum from '../TextSpanDatum.vue';
  import RangeDatum from '../RangeDatum.vue';
  import FavoriteHeartNoText from '../FavoriteHeartNoText.vue';
  import DistanceBadge from '../DistanceBadge.vue';
  import PerformanceBadge from '../PerformanceBadge.vue';
  import LetterGradeBadge from '../LetterGradeBadge.vue';
  import PreferencesBadge from '../PreferencesBadge.vue';
  import VideoModalLauncher from '../VideoModalLauncher.vue';

  module.exports = {
    components: {
      ButtonCompare,
      TextSpanDatum,
      RangeDatum,
      FavoriteHeartNoText,
      DistanceBadge,
      PerformanceBadge,
      LetterGradeBadge,
      PreferencesBadge,
      VideoModalLauncher,
    },
    mixins: [ResultItemMixin, GradeDependentMixin, ReactiveFilterMixin],
  };

</script>
<style type="text/css" scoped>
/* .flex{

  display: flex;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  flex-basis: 20%;
}

.flex > div{
  flex-basis: 20%;
}

.entity-info > * {
  display: inline-block;
  border-right: 1px solid grey;
  padding-right: 5px;
  padding-left: 5px;
}
.entity-info > *:first-child {
  padding-left: 0;
}
.entity-info > *:last-child {
  border-right: 0;
} */
</style>