<!-- TODO: update for vue2 -->
<template>
  <div class="wizard-menu">
    <div class="wizard-menu__toggle" v-if="!focused"
        v-on:click="focused = true">
      <div class="fa fa-ellipsis-v wizard-menu__toggle__icon"></div>
    </div>
    <div class="wizard-menu__options" tabindex="0" v-if="focused"
        v-on:click="exitMenu"
        v-focus="focused"
        @focused="focused = true"
        @blur="focused = false"
      >
         {{ wizardSettings.labels.exit_menu_text }}
      </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');
  var FocusMixin = require('vue-focus').mixin;
  module.exports = {
    mixins: [WizardMixin, FocusMixin],
    props: [
      'breadCrumbs',
      'categories',
      'home',
      'address',
      'currentScreen',
      'role',
      'show',
      'options',
      'year',
      'roles',
      'years',
      'repeats',
      'wizardSettings',
      'previousScreens',
      'index'
    ],
    data: function data() {
      return {
        focused: false
      };
    },
    methods: {
      exitMenu: function exitMenu() {
        this.$dispatch('Close');
      }
    }
  };
</script>