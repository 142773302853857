<template>
  <div
    v-if="isReady && settings"
    :is="layout.tag"
    :settings="layout.data"
    :layout="layout.layout"
  ></div>
</template>
<script type="text/javascript">
  import PageMixin from './mixins/PageMixin';

  module.exports = {
    mixins: [PageMixin],
    mounted() {
      this.$store.dispatch('about/loadAbout');
    },
    computed: {
      isReady() {
        return this.$store.state.about.isReady;
      },
      settings() {
        return this.$store.state.about.settings;
      },
      layout() {
        return this.$store.state.about.renderableLayout;
      },
    }
  };
</script>
