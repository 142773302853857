<!-- Currently, used only for grade selections -->
<template>
  <div>
    <div class="wizard__input__prompt">{{currentScreen.promptText1[prompt1Key]}}</div>
    <dropdown-modal :options='options' :choice='selected'></dropdown-modal>
    <div class="wizard__input__prompt">{{currentScreen.promptText2[prompt2Key]}}</div>
    <div class="wizard-button-container">
      <button v-on:click='next' :disabled="nextDisabled">{{currentScreen.nextText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');

  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen',
      'index',
      'previousScreens',
      'breadCrumbs',
      'role',
      'year',
      'options',
      'wizardSettings'
    ],
    data: function data() {
      var selected = null;
      if (this.currentScreen.selected) selected = this.currentScreen.selected;
      return {
        selected: selected
      };
    },
    computed: {
      prompt1Key: function prompt1Key() {
        var key = `${this.role.value}_${this.year.value}`;
        return key;
      },
      prompt2Key: function prompt2Key() {
        var key = this.year.value;
        return key;
      },
      nextDisabled: function nextDisabled() {
        // check for all required information
        if (!this.selected) return true;
        return false;
      }
    },
    methods: {
      updateFilter: function updateFilter() {
        var updatedOptions = this.options;
        updatedOptions.map(function m(opt) {
          var updatedOpt = opt;
          if (opt.value === this.selected.value) updatedOpt.required = true;
          else updatedOpt.required = false;
          return updatedOpt;
        }, this);
        this.options = updatedOptions;
      },
      next: function next() {
        var current;
        var crumb;
        var crumbIdx;

        // data for updating previous screens
        current = this.copy(this.currentScreen);
        current.selected = this.copy(this.selected);

        // text and goto for bread crumbs
        crumb = this.copy(this.currentScreen);
        crumb.text = this.selected.text;
        crumb.idx = this.index;

        crumbIdx = (this.index - this.wizardSettings.repeat_start_index)
          / this.wizardSettings.repeat_cards +
          this.wizardSettings.repeat_start_crumb;
        if (this.index < this.previousScreens.length) {
          // if user changed the selection, delete all following breadcrumbs & previous screens
          if (this.previousScreens[this.index].selected.value !== this.selected.value) {
            this.previousScreens.splice(this.index);
            this.previousScreens.push(current);

            this.breadCrumbs.splice(crumbIdx);
            this.breadCrumbs.push(crumb);
            // POSSIBLE TODO:
            // MAY also need to clear all other filter selections
          } else {
            this.breadCrumbs[this.index + 1] = crumb;
            this.previousScreens[this.index] = current;
          }
        } else {
          this.breadCrumbs.push(crumb);
          this.previousScreens.push(current);
        }
        this.advance();
      }
    },
    watch: {
      selected: function watchSelected() {
        this.updateFilter();
      }
    }
  };
</script>