<template>
  <td :scope="settings.is_row_header ? 'row': null" :class="settings.is_row_header ? 'c-table__mobile-card__header': 'c-table__mobile-card__list-item'">
    <component
      v-if="settings.link && entityData"
      :is="settings.tag || 'text-span-datum-link'"
      :entity-data="entityData"
      :entity-link="entityLink"
      :universe="settings.universe"
      :entity-id="entity.id"
      :list-separator="settings.list_separator"


    ></component>
    <component
      v-else-if="settings.tag"
      :is="settings.tag"
      :entity-data="entityData"
      :entity-link="entityLink"
      :universe="settings.universe"
      :entity-id="entity.id"
      :list-separator="settings.list_separator"

    ></component>
    <template v-else-if="entityData"><p>{{entityData}}</p></template>
  </td>
</template>
<script type="text/javascript">
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import { getEntityUrl } from '../store/utils';
  import TextSpanDatumLink from './TextSpanDatumLink.vue';

  module.exports = {
    props: ['settings', 'entity'],
    components: {
      TextSpanDatumLink,
    },
    computed: {
      entityData() {
        if (Array.isArray(this.settings.entity_prop)) {
          return this.settings.entity_prop
            .map(prop => getDataByProperty(prop, this.entity))
            .filter(data => data || data === 0);
        }

        if ('suffix' in this.settings) {
          const data = getDataByProperty(this.settings.entity_prop, this.entity);
          if (data) {
            return data + this.settings.suffix;
          }
        }

        return getDataByProperty(this.settings.entity_prop, this.entity);
      },
      entityLink() {
        if (!this.settings.link) return '';
        if (this.settings.link.url_prop) {
          const url = getDataByProperty(this.settings.link.url_prop, this.entity);
          if (url) return url.toString();
          return '';
        }
        return getEntityUrl(this.entity, this, this.settings.link.url_template);
      }
    }
  };
</script>
