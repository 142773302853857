<template>
  <p v-if="settings.tembo">{{settings.powered_by_text}}<br>
    <a :href="settings.tembo_logo_link" target="_blank"><hoonuit-logo-svg /></a>
  </p>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings']
  };
</script>
<style scoped>
	img{
		width: 100px;
	}
</style>
