<template>
      <select-component
        :options="updatedOptions"
        :selected="$store.state.sort"
        @update:selected="val => {handleChange(val)}"
        :enablers="enablers">
        <h2 slot="select-title" class="c-select__title">{{settings.title_text}}</h2>
        </select-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  const EnableDisableSortMixin = require('../mixins/EnableDisableSortMixin');
  import SelectComponent from './Select.vue';

  module.exports = {
    components: { SelectComponent },
    props: [
      'settings',
      'enablers'
    ],
    mixins: [EnableDisableSortMixin],
    methods: {
      handleChange: function handleChange(newSort) {
        this.$store.dispatch('updateSort', newSort);
        sendEvent({
          category: 'list',
          action: 'sort',
          label: newSort
        });
      },
    },
  };

</script>