<template>
  <select-with-menu
    class="flex-grow-1"
    :placeholder-value="placeholder"
    :options="options"
    :selected="selected"
    @update:selected="value => updateSort(value)"
  ></select-with-menu>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  import SelectWithMenu from './SelectWithMenu.vue';

  export default {
    components: { SelectWithMenu },
    props: ['options', 'placeholder'],
    computed: {
      selected() {
        return this.$store.state.sort.value;
      },
    },
    methods: {
      updateSort(value) {
        const newSortOption = this.options.find(opt => opt.value === value);
        this.$store.dispatch('updateSort', newSortOption);
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_list` : 'list';
        sendEvent({
          category: category,
          action: 'mobile_sort',
          label: value
        });
      },
    },
  };
</script>
