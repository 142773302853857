var ReactiveFilterMixin = require('./ReactiveFilterMixin');
var PaginationMixin = require('./PaginationMixin');
import arraysMatchInOrder from 'tembo-js/arraysMatchInOrder';

module.exports = {
  mixins: [
    ReactiveFilterMixin,
    PaginationMixin
  ],
  computed: {
    breakpoint: function breakpoint() {
      return this.$store.state.breakpoint;
    },
    filteredIds: function filteredIds() {
      //
      // array of ids of all schools in filtered list
      // watcher triggers re-drawing map,
      // re-setting page
      //
      var ids;
      if (!this.$store.state.filtered || !this.$store.state.filtered.length) return [];
      ids = this.$store.state.filtered.map(entity => entity.id);
      return ids;
    },
    relaxedFilterCount: function relaxedFilterCount() {
      return this.$store.getters.relaxedFilterCount;
    }
  },
  watch: {
    filteredIds: {
      handler: function watchOrder(newList, oldList) {
        // when sort or length changes, reset page = 1
        if (!arraysMatchInOrder(newList, oldList)) {
          if (this.$store.state.page === 1) {
            const scrollAllLayouts = false;
            this.scrollToTop(null, scrollAllLayouts);
          } else {
            // trigger on text tick to allow the current state change in progress to complete
            this.$nextTick(function next() {
              this.$store.dispatch('updatePage', 1);
            });
          }
        }
      },
      deep: true
    },
    breakpoint: function breakpoint(curr, prev) {
      if (curr !== prev) {
        this.$store.dispatch('resetFiltersByBreakpoint', this.breakpoint);
      }
    }
  }
};
