<template>
  <footer id="footer-wrapper" class="p-footer px-4 d-flex justify-content-between flex-shrink-0" :class="[leftAlignedContact ? 'flex-row-reverse' : '', footerType === 'containerWidth' ?  'container' : 'container-fluid']">

      <div>
        <div class="d-flex flex-column justify-content-between h-100">
          <attribution-google :settings="settings"></attribution-google>
          <attribution :settings="settings"></attribution>
        </div>
      </div>
      <div class="d-inline-flex">
        <div v-if="settings.logo" class="col-4">
          <a :href="settings.website_url"><img :src="settings.logo.img" :alt="settings.logo.alt_text" class="tembo-logo" /></a>
        </div>
        <contact-information
          :settings="settings"
          class="mt-2"
        ></contact-information>
      </div>

  </footer>
</template>
<script type="text/javascript">
  var AttributionGoogle = require('./AttributionGoogle.vue').default;
  var Attribution = require('./Attribution.vue').default;
  var ContactInformation = require('./ContactInformation.vue').default;
  module.exports = {
    props: ['settings', 'footerType'],
    components: { Attribution, AttributionGoogle, ContactInformation },
    computed: {
      leftAlignedContact: function leftAlignedContact() {
        return this.settings.align_contact_info && this.settings.align_contact_info === 'left';
      }
    }
  };
</script>