<template>
  <div class="d-flex align-items-center">
    <p class="flex-grow-1">
      <span v-if="entitiesLoaded">{{countMessage}}</span>
      <span v-if="entitiesLoaded && settings.change_filters && distanceIsRelevant">{{distanceMessage}}</span>
    </p>
    <a class="results-status__change flex-shrink-0 ml-2" v-on:click="showFilters" v-if="entitiesLoaded && settings.change_filters">{{settings.linked_text}}</a>
  </div>
</template>
<script type="text/javascript">
  var ResultStatusMixin = require('../mixins/ResultStatusMixin');
  module.exports = {
    mixins: [ResultStatusMixin]
  };
</script>