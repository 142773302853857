<template>
  <div class="map-filter">
    <div v-if="settings.prompt_text" class="map-filter-overlay center-contents text-center">{{settings.prompt_text}}</div>
    <div id="map"></div>
  </div>
</template>

<script type="text/javascript">
  const FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  const $ = require('jquery');
  import { map as leafletMap, geoJson as leafletGeoJson } from 'leaflet';
  import difference from 'lodash/difference';
  import slugify from 'tembo-js/slugify';
  import sendEvent from 'tembo-js/sendEvent';


  module.exports = {
    mixins: [FilterGroupMixin],
    computed: {
      slugifyReqs: function slugifyReqs() {
        return this.requirements.map(requirement => slugify(requirement.text));
      }
    },
    methods: {
      handleFilterClick: function handleFilterClick(value) {
        let action;
        const selectedValue = this.values.filter(a => a.value === value)[0];
        const updatedValue = JSON.parse(JSON.stringify(selectedValue));
        const checked = selectedValue.required;

        if (!checked) action = 'select';
        else action = 'deselct';

        updatedValue.required = !checked;
        this.$store.dispatch('updateFilterValue', {
          filterValue: updatedValue,
          updateEntities: true
        });
        sendEvent({
          category: 'filter',
          action: `${action}_${this.settings.metadata.label}`,
          label: value.value,
          id: value.id
        });
      }
    },
    watch: {
      slugifyReqs: {
        handler: function watchReqs(prev, curr) {
          const addedToReqs = difference(prev, curr) || [];
          const removedFromReqs = difference(curr, prev) || [];

          for (let i = 0, l = addedToReqs.length; i < l; i ++) {
            $(`#${addedToReqs[i]}`).addClass('is-clicked');
          }
          for (let i = 0, l = removedFromReqs.length; i < l; i ++) {
            $(`#${removedFromReqs[i]}`).removeClass('is-clicked');
          }
        },
        deep: true
      }
    },
    mounted() {
      /* eslint-disable no-underscore-dangle */

      const meta = this.settings.metadata;
      const mapOptions = {
        attributionControl: false,
        zoomControl: false,
        trackResize: false,
        zoomSnap: .1 // eslint-disable-line
      };

      if ($(window).width() < 630) {
        meta.zoom = meta.mobile_zoom;
      } else if ($(window).width() >= 630 && $(window).width() < 800) {
        meta.zoom = meta.mobile_zoom + ((meta.zoom - meta.mobile_zoom) / 2);
      }

      //
      // set up map
      //
      const map = leafletMap('map', mapOptions)
                  .setView([meta.center.lat, meta.center.lng], meta.zoom);
      const geojson = leafletGeoJson();
      const self = this;

      // will be populated when onEachFeature runs - used to add ids to paths
      const allLayers = [];

      //
      // disable default leaflet events
      //
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      map.dragging.disable();

      function featureClick(e) {
        //
        // handles click events on individual features
        //
        const targetCountyName = e.target.feature.properties.name;
        this.handleFilterClick(targetCountyName);
        if (!e.target.options.className) {
          geojson.resetStyle(e.target);
        }
      }

      const boundFeatureClick = featureClick.bind(this);

      function onEachFeature(feature, layer) {
        //
        // run for each feature as it is added to the map
        //
        layer.on({
          click: boundFeatureClick
        });
        allLayers.push(layer);
      }

      $.ajax({
        type: 'GET',
        url: '/api/geo',
        headers: {
          Accept: 'application/vnd.tembo.api+json'
        },
        dataType: 'json',
        success: (response) => {
          const responseJSON = response.geo[0];
          //
          // add each feature to the map with a callback
          // which adds the click handler
          // also adds each feature to allLayers (used for adding ids to <path> elements)
          //
          leafletGeoJson(responseJSON, { onEachFeature }).addTo(map);

          //
          // add id to each feature's <path> element
          //
          allLayers.forEach(layer => {
            layer._path.id = slugify(layer.feature.properties.name); // eslint-disable-line no-param-reassign, max-len
          });

          //
          // class each path if its id matches a requirement
          //
          for (let i = 0, l = self.slugifyReqs.length; i < l; i ++) {
            $(`#${self.slugifyReqs[i]}`).addClass('is-clicked');
          }

          // turns off default pointer events
          $('.leaflet-zoom-animated').removeAttr('pointer-events');
          // removes styles that make the page very long & wide
          $('.leaflet-zoom-animated').removeAttr('style');
        },
        error: (err) => {
          console.error(err); // eslint-disable-line no-console
        }
      });

      /*
      $(window).resize(function resizeMap() {

      });
      */
      /* eslint-enable */
    }
  };
</script>
<style scoped>

  .map-filter{
    position: relative;
  }
  .map-filter-overlay {
    z-index: 10;
    margin: 0px -15px;
    padding: 10px 0;
    line-height: 20px;
    font-size: 14px;
  }

</style>
