<template>
  <a v-if="entityData" :href="entityData" :target="entityData.indexOf('http') > -1 ? '_blank' : ''" v-on:click="sendLinkClickEvent">{{universe}}</a>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  module.exports = {
    props: ['entityData', 'universe'],
    methods: {
      sendLinkClickEvent() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_list` : 'list';
        sendEvent({
          category: category,
          action: 'report_link',
          label: this.entityData
        });
      }
    }
  };
</script>