<template>
  <modal-component :show="show" :click-away="true" class='match-modal'>
    <div class="modal-heading c-modal__head">
      <span class="heading-target c-modal__title">{{settings.header_text}}</span>
      <div tabindex="0" v-on:click="closeModal" v-on:keyup.enter="closeModal" class="modal_close c-modal__close"><modal-close-svg></modal-close-svg><span class="sr-only">{{settings.close_text}}</span></div>
    </div>
    <div class="modal-content">
      <video controls autoplay :src="video">
      </video>
      <!-- only show the change if we have an alternate video to start with -->
      <div v-if="alternateVideo" class="center-contents"><a @click="changeVideo">{{linkText}}</a></div>
      <!-- https://stackoverflow.com/questions/44746718/html-5-video-and-src-value-with-vue -->
    </div>


    <div class="modal_footer">

    </div>
  </modal-component>
</template>
<script type="text/javascript">
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';

  module.exports = {
    mixins: [ModalMixin],
    component: { ModalComponent },
    props: ['settings', 'show'],
    data() {
      return {
        video: this.settings.dynamic.video,
        originalVideo: this.settings.dynamic.video,
        alternateVideo: this.settings.dynamic.video_alternate,
        currentVideo: 'original',
        linkText: this.settings.video_view_alternate_text,
        viewAlternativeText: this.settings.video_view_alternate_text,
        viewOriginalText: this.settings.video_view_original_text
      };
    },
    methods: {
      changeVideo() {
        // do we need to be able to toggle back to the original language?
        if (this.currentVideo === 'original') {
          this.video = this.alternateVideo;
          this.currentVideo = 'alternate';
          this.linkText = this.viewAlternativeText;
        } else {
          this.video = this.originalVideo;
          this.currentVideo = 'original';
          this.linkText = this.viewOriginalText;
        }
      }
    }
  };
</script>
<style scoped>
video{
  width:  100%;
  height:  auto;
}
</style>
