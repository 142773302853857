<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <component
    :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'"
    :settings="settings"
    :selection-tally="selectionTally"
    :enabled="enabled"
    class="multi-filter filter-group o-two-column-layout__filter-list__group"
    v-show="!!!settings.metadata.hide_disabled || enabled">

    <!-- FILTER TITLE SLOT -->
    <template slot="filter-title">{{settings.group_title_text}}</template>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" v-if="settings.description_text">{{settings.description_text}}</div>

    <!-- FILTER DISABLED SLOT -->
    <div slot="filter-group-description" v-if="settings.disabled_text && !enabled">{{settings.disabled_text}}</div>

    <!-- FILTER CONTENT SLOT -->
    <ul slot="filter-content" class="filter-content sp-v-2" v-if="values">
      <li class="filter-item filter-description">
        <a class="a a-ui-grow" href='#' v-if="settings.metadata.descriptions" v-on:click="handleShowDescriptions">{{settings.options_description_text}}</a>
      </li>
      <li
        v-for="(value, index) in filteredValues"
        :class="layout.option_tag.tag + ' ' + enabled ? 'filter-item' : 'filter-item filter-item--disabled'">

        <!-- Filter control -->
        <div :is='layout.option_tag.tag' :value="value" :checked="value.required" @update:checked="checked => { handleFilterClick(checked, value) }" :disabled="!enabled"></div>
      </li>
    </ul>

  </component>
</template>
<script type="text/javascript">
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    mixins: [FilterGroupMixin],
    data: function data() {
      return {
        noresults: false,
      };
    },
    methods: {
      handleFilterClick: function handleFilterClick(checked, value) {
        var action;
        var newval = JSON.parse(JSON.stringify(value));
        if (checked) action = 'select';
        else action = 'deselct';
        newval.required = checked;
        this.$store.dispatch('updateFilterValue', { filterValue: newval, updateEntities: true });
        sendEvent({
          category: 'filter',
          action: `${action}_${this.settings.metadata.label}`,
          label: value.value
        });
        // if (this.filtered.length === 0) {
        //   this.noresults = true;
        // }
      }
    }
  };

</script>
