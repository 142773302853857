import sendEvent from 'tembo-js/sendEvent';


// TODO: combine with RadioButtonFilter
module.exports = {
  computed: {
    requiredValue: function requiredValue() {
      var reqVal = null;
      var i = 0;
      var l = this.values.length;
      while (!reqVal && i < l) {
        if (this.values[i].required) {
          reqVal = this.values[i].value;
        }
        i += 1;
      }
      return reqVal;
    },
    pendingValue: function pendingValue() {
      var pendVal = null;
      var i = 0;
      var l = this.values.length;
      while (!pendVal && i < l) {
        if (this.values[i].pending) {
          pendVal = this.values[i].value;
        }
        i += 1;
      }
      return pendVal;
    },
  },
  methods: {
    getItemByValue: function getItemByValue(val) {
      var selectedValue;
      var i = 0;
      var l = this.values.length;
      while (!selectedValue && i < l) {
        if (this.values[i].value === val) {
          selectedValue = this.values[i];
        }
        i += 1;
      }
      return selectedValue;
    },
    handleChange: function handleChange(newvalue) {
      var prevItem = this.requiredValue;
      var pendItem = this.pendingValue;
      var currItem;
      var oldItem;
      if (!newvalue) {
        this.clearThisFilter();
      } else if (pendItem) {
        pendItem = this.getItemByValue(pendItem);
        pendItem = JSON.parse(JSON.stringify(pendItem));
        pendItem.pending = false;
        pendItem.required = false;
        oldItem = pendItem;
      } else if (prevItem) {
        prevItem = this.getItemByValue(prevItem);
        prevItem = JSON.parse(JSON.stringify(prevItem));
        prevItem.required = false;
        oldItem = prevItem;
      }
      if (newvalue) {
        currItem = this.getItemByValue(newvalue);
        currItem = JSON.parse(JSON.stringify(currItem));
        currItem.required = true;
        if (oldItem) {
          this.$store.dispatch('updateFilterValue', { filterValue: oldItem, updateEntities: false })
          .then(() => this.$store.dispatch('updateFilterValue', { filterValue: currItem, updateEntities: true })); // eslint-disable-line max-len
        } else {
          this.$store.dispatch('updateFilterValue', { filterValue: currItem, updateEntities: true }); // eslint-disable-line max-len
        }
      } else {
        this.$store.dispatch('updateFilterValue', { filterValue: oldItem, updateEntities: false });
      }
      this.$emit('update:selected', newvalue);
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_filter` : 'filter';
      const action = `select_${this.settings.metadata.label}`;
      sendEvent({
        category: category,
        action: action,
        label: newvalue
      });
    }
  }
};
