var utils = require('./utils');
import flattenDeep from 'lodash/flattenDeep';
import arraysIncludeOrOverlap from 'tembo-js/arraysIncludeOrOverlap';
import arrayToLower from 'tembo-js/arrayToLower';
import flattenByProperty from 'tembo-js/flattenByProperty';
import getDataByProperty from 'tembo-js/getDataByProperty';
import overlappingArrays from 'tembo-js/overlappingArrays';
import overlappingArraysCount from 'tembo-js/overlappingArraysCount';
import valToLower from 'tembo-js/valToLower';
import replaceSpecialCharacters from 'tembo-js/replaceSpecialCharacters';

/* eslint-disable no-use-before-define */

function mergeUserFilterData(ctx, userFilters, filterGroupValueIds) {
  //
  // are there any user selections associated with this filter group?
  //
  let userSelectionsInGroup = userFilters.filter(f => filterGroupValueIds.indexOf(f.id) > -1); // eslint-disable-line max-len
  userSelectionsInGroup = userSelectionsInGroup.length > 0;
  if (Array.isArray(filterGroupValueIds)) {
    for (let j = 0, k = filterGroupValueIds.length; j < k; j++) {
      const filterValueId = filterGroupValueIds[j];
      const userval = userFilters.filter(f => f.id === filterValueId)[0];
      if (userval) {
        //
        // if the filter value is in the list of user-saved filters
        // apply the user's properties to the state
        //
        userSelectionsInGroup = true;
        // delete to prevent overwriting value text in previous language
        delete userval.value;
        ctx.commit('setFilterValueProps', userval);
      } else if (userSelectionsInGroup) {
        //
        // if there is no user-saved selection for this particular filter value
        // AND there are SOME other user-saved selections in this filter group
        // deselect this particular value
        //
        const newval = ctx.state.filterValues[filterValueId];
        if (newval.hasOwnProperty('master')) newval.master = false;
        newval.required = false;
        ctx.commit('setFilterValueProps', newval);
      }
    }
  } else {
    const filterValueId = filterGroupValueIds;
    const userval = userFilters.filter(f => f.id === filterValueId)[0];
    if (userval) {
      //
      // if the filter value is in the list of user-saved filters
      // apply the user's properties to the state
      //
      // assumes that if the filter does not have a list of values
      // the filter value is supplied by the user
      // therefore, do not delete it (as it is deleted above)
      //
      ctx.commit('setFilterValueProps', userval);
    }
  }
}

function initializeAllFilters(filters) {
  //
  // call initializeOneFilter on all filters to initialize/restore default values
  //
  const filterKeys = Object.keys(filters);
  const newFilters = {};
  for (let i = 0, l = filterKeys.length; i < l; i++) {
    const key = filterKeys[i];
    let filter = filters[key];
    if (filter) {
      if (filter.metadata.any_option > -1) {
        filter.value = initializeAnyOption(filter);
      }
      if (filter.metadata.all_options > -1) {
        filter.value = initializeAllOptions(filter);
      }
      filter = initializeOneFilter(filter, true); // true = apply default selections to filters
      newFilters[key] = filter;
    } else {
      console.error('cannot find data for filter', key); // eslint-disable-line
    }
  }
  return newFilters;
}

function initializeOneFilter(filter, applyDefaults) {
  //
  // returns update filter object with required and
  // type (strict/relaxed) set to defaults
  //
  var i;
  var l;
  var result = filter;
  if (result.value || result.value === '') {
    if (Array.isArray(result.value)) {
      //
      // for each filter option, apply defaults as set in config
      //
      for (i = 0, l = result.value.length; i < l; i++) {
        if (result.metadata.hasOwnProperty('default_master')) {
          result.value[i].master = result.metadata.default_master;
        }
        if (result.metadata.hasOwnProperty('facet')) {
          result.value[i].facet = result.metadata.facet;
          result.value[i].entity_count = 0;
        }
        result.value[i].required = result.metadata.default_val;
        result.value[i].type = result.metadata.default_type;
      }
      if (applyDefaults) result = applyDefaultSelections(result);
    } else {
      result.value = result.metadata.default_val;
      result.type = result.metadata.default_type;
      if (result.metadata.hasOwnProperty('facet')) {
        // name search as a facet doesn't need a count
        // other facets will
        if (typeof result.value === 'object') {
          result.value.facet = result.metadata.facet;
          result.value.entity_count = 0;
        }
      }
    }
  }
  if (result.subfilters) {
    result.subfilters = initializeAllFilters(result.subfilters);
  }
  return result;
}

//
// add option to filter group that will select all other options
//
function initializeAllOptions(filter) {
  var newFilters;
  var allOptions;
  //
  // check for additional filter option
  //
  allOptions = filter.value.find(val => val.value === 'all');
  if (allOptions) {
    return filter.value;
  }
  //
  // create additional filter option
  //
  allOptions = {
    text: filter.all_text,
    value: 'all',
    required: false
  };
  //
  // add to existing filter options
  //
  newFilters = filter.value.slice();
  if (filter.metadata.all_options === 0) {
    allOptions.order = 0;
    newFilters.unshift(allOptions);
  } else if (filter.metadata.all_options === 1) {
    allOptions.order = (newFilters.length + 1) * 10 + 20;
    newFilters.push(allOptions);
  }
  return newFilters;
}

//
// add option to filter group that will allow any value
//
function initializeAnyOption(filter) {
  var newFilters;
  var anyOption;
  //
  // check for additional filter option
  //
  anyOption = filter.value.find(val => val.value === 'any');
  //
  // create additional filter option
  //
  if (anyOption) {
    return filter.value;
  }
  anyOption = {
    text: filter.any_text,
    value: 'any',
    required: false
  };
  //
  // add to existing filter options
  //
  newFilters = filter.value.slice();
  if (filter.metadata.any_option === 0) {
    anyOption.order = 0;
    newFilters.unshift(anyOption);
  } else if (filter.metadata.any_option === 1) {
    anyOption.order = (newFilters.length + 1) * 10 + 20;
    newFilters.push(anyOption);
  }

  return newFilters;
}

function applyDefaultSelections(filter) {
  var result = filter;
  var defaults = result.metadata.default_requirements;
  var selectDefault = defaults && defaults.length > 0;
  var found;
  var i; // index of default item
  var l; // length of default items
  var j; // index of filter/result value
  var m;  // length of filter/result value
  var value; // filter/result value required by default item
  var key;  // key for filter/result value
  var keys;  // all keys for a given default item
  var n;  // length of keys
  var k;  // index of keys

  //
  // if the filter config has the default_selections property
  // update filter options where they match the given key & value
  //
  if (selectDefault) {
    m = result.value.length;
    for (i = 0, l = defaults.length; i < l; i++) {
      // for each item in defaults:
      found = false;
      key = defaults[i].where.field;
      value = defaults[i].where.value;
      keys = Object.keys(defaults[i]);
      n = keys.length;
      j = 0;
      while (j < m && !found) {
        // find the filter value that matches the key-value pair identified
        if (result.value[j][key] === value) {
          found = true;
          for (k = 0; k < n; k ++) {
          // update the filter values with all other key-value pairs
            result.value[j][keys[k]] = defaults[i][keys[k]];
          }
        }
        j += 1;
      }
    }
  }
  return result;
}


//
// methods to do the actual filtering
//
function applyFilterDependentFields(settings, filters, rows) {
  var updatedRows = rows;
  var dependencies = settings.filter_dependent_entity;
  var keys;
  var i;
  var j;
  var l;
  var filter;
  var fieldToUse;
  var dep;
  var fieldMap = {};
  if (!dependencies) return rows;
  keys = Object.keys(settings.filter_dependent_entity);
  for (i = 0, l = keys.length; i < l; i ++) {
    dep = dependencies[keys[i]];
    // for each dependency, determine which condition is met
    filter = getRequiredValues(filters[dep.filter]);
    filter = flattenByProperty('value', filter);
    j = dep.options.length;
    while (!fieldToUse && j > 0) {
      j -= 1;
      if (overlappingArrays(dep.options[j].value, filter)) {
        fieldToUse = dep.options[j].field;
      }
    }
    fieldMap[keys[i]] = fieldToUse;
  }

  return updatedRows.map((r) => {
    var newR = r;
    for (i = 0; i < l; i ++) {
      newR[keys[i]] = newR[fieldMap[keys[i]]];
    }
    return newR;
  });
}

function getFilterValuesByType(filter, type) {
  //
  // creates new filter, with values separated by strict/relaxed
  // includes all metadata of parent
  // for array values only
  //
  var result = JSON.parse(JSON.stringify(filter));
  var value = result.value;
  if (Array.isArray(value)) {
    value = value.filter(val => val.type === type && val.required && val.value !== 'any' && val.value !== 'all'); // eslint-disable-line max-len
  }
  result.value = value;
  return result;
}

function getRequiredValues(filter) {
  //
  // return the array of values where required = true
  // or the value of the filter
  //
  var req;
  var i;
  if (Array.isArray(filter.value)) {
    req = [];
    for (i = 0; i < filter.value.length; i ++) {
      if (filter.value[i].required) {
        if (filter.value[i].value !== 'any' && filter.value[i].value !== 'all') {
          req = req.concat(filter.value[i]);
        }
      }
    }
  } else if (filter.hasOwnProperty('value')) {
    req = filter.value.value;
  } else {
    req = [];
  }

  return req;
}

function hasAnyRequired(filter, row) {
  //
  // for use with OR filters
  // returns true if data matches any required value
  // or if data includes any required value
  //
  var i;
  var l;
  var rowData;
  var req;
  var requirementMatchFound = false;
  var requirementFilter;

  if (filter.metadata.match_data_path) {
    rowData = getDataByProperty(filter.metadata.match_data_path, row);
    rowData = flattenByProperty(filter.metadata.internal_data_path, rowData);
  }

  req = filter.value;
  // req = this.getRequiredValues(filter);

  if (Array.isArray(req)) {
    req = flattenByProperty('value', req);
    req = flattenDeep(req);
    req = arrayToLower(req);
  } else {
    req = valToLower(req);
  }

  if (!req || req.length === 0) return row;

  if (!rowData) {
    if (Array.isArray(req)) {
      const filterValues = JSON.parse(JSON.stringify(filter.value));
      for (i = 0, l = filterValues.length; i < l; i ++) {
        // if data for the given row doesn't exist
        // each requirement might have its own unique field
        // at which to check for a match on the given row
        if (filterValues[i].match_data_path) {
          requirementMatchFound = true;
          requirementFilter = { metadata: { match_data_path: 'value' }, value: filterValues };
          rowData = getDataByProperty(filterValues[i].match_data_path, row);
          rowData = flattenByProperty(filterValues[i].internal_data_path, rowData);
          if (rowData && hasAnyRequired(requirementFilter, { value: rowData })) return true;
        }
      }
      // if a filter-wide match_data_path is set
      // and no requirement-specific match_data_path was found
      // then the lack of matching rowData should
      // remove this row from the results list
      if (filter.metadata.match_data_path && !requirementMatchFound) return false;
      // if no filter-wide match_data_path is set
      // and no requirement-specific match_data_path was found
      // then this is a mistake in the configuration:
      // log an error, but
      // do not remove this row from the results list
      if (!filter.metadata.match_data_path && !requirementMatchFound) {
        // eslint-disable-next-line
        console.error(`Filter ${filter.metadata.label} is missing `
                    + 'metadata.match_data_path and no requirement-'
                    + 'level `match_data_path`s are set. This '
                    + 'filter will have no effect on the results list.');
        return true;
      }
      // requirement-specific match_data_path
      // must have been found and not met
      // therefore, remove this row from the results list
      return false;
    }
    console.error('filter', filter.metadata.label, 'does not have the appropriate metadata.match_data_path'); // eslint-disable-line
    return false;
  } else if (Array.isArray(req) && Array.isArray(rowData)) {
    if (overlappingArrays(arrayToLower(rowData), req)) { // handles strings or numbers
      return true;
    }
  } else if (Array.isArray(req)) {
    rowData = valToLower(rowData);
    const index = req.indexOf(rowData);
    if (index > -1) { // handles strings or numbers
      return true;
    }
  } else if (req === valToLower(rowData)) { // again, handle strings or numbers
    return true;
  } else if (!req || req.length === 0) {
    return true;
  }
  return false;
}

function hasAllRequired(filter, row) {
  //
  // for use with AND filters
  // returns true if data array includes all required values
  //
  var i;
  var l;
  var req;
  var data;

  if (filter.metadata.match_data_path) {
    data = getDataByProperty(filter.metadata.match_data_path, row);
    if (data) {
      data = flattenByProperty(filter.metadata.internal_data_path, data);
    }
  }

  req = filter.value;
  // req = this.getRequiredValues(filter);
  for (i = 0, l = req.length; i < l; i++) {
    if (!filter.metadata.match_data_path) {
      data = getDataByProperty(req[i].match_data_path, row);
      if (data) {
        data = flattenByProperty(req[i].internal_data_path, data);
      }
    }
    if (
      Array.isArray(data) &&
      arrayToLower(data).indexOf(valToLower(req[i].value)) === -1) {
      return false;
    } else if (Array.isArray(data) && data.indexOf(req[i].value) === -1) {
      if (typeof req[i].value !== 'string') return false;
    } else if (filter.metadata.bool) {
      if (!data) return false;
    } else if (filter.metadata.multi) {
      if (arrayToLower(req[i].value).indexOf(valToLower(data)) === -1) {
        return false;
      }
    } else if (!Array.isArray(data) &&
      valToLower(req[i].value) !== valToLower(data)) {
      return false;
    } else if (!Array.isArray(data) && req[i].value !== data) {
      if (typeof req[i].value !== 'string') return false;
    }
  }
  return true;
}

function includesRequiredText(filter, row) {
  //
  // for use with partial filter
  // checks for required substring in row's data
  // currently the only filter that supports multiple
  // match_data_path fields
  //
  let data;
  const req = replaceSpecialCharacters(filter.value.value.toLowerCase());
  if (Array.isArray(filter.metadata.match_data_path)) {
    data = filter.metadata.match_data_path.map(field => getDataByProperty(field, row));
  } else {
    data = getDataByProperty(filter.metadata.match_data_path, row);
  }
  if (!data) return false;
  if (Array.isArray(data)) {
    // convert data from all fields to lower case strings
    data = utils.convertToString(data);
    data = arrayToLower(data);
    data = replaceSpecialCharacters(data);
    // for data from each field: does it match requirement?
    // reduce to true if any in array are true
    return data.map(d => d.indexOf(req) > -1).reduce((acc, item) => (acc || item));
  }
  data = replaceSpecialCharacters(data.toLowerCase());
  if (data.indexOf(req) > -1) {
    return true;
  }
  return false;
}

function includesRequired(filter, row) {
  //
  // for use with INCLUDES filter
  //
  var data;
  var req;
  if (filter.metadata.match_data_path) {
    data = getDataByProperty(filter.metadata.match_data_path, row);
    data = flattenByProperty(filter.metadata.internal_data_path, data);
  }
  // req = filter.value.toLowerCase();
  req = getRequiredValues(filter);
  if (!req || req.length === 0) return true;
  if (Array.isArray(data)) {
    if (data.indexOf(req.value) > -1 || data.indexOf(req) > -1) return true;
  } else {
    if (data === req.value.value || data === req) return true;
  }
  return false;
}

//
// RELAXED filter
//

function resetMatches(rows) {
  return rows.map((r) => {
    var newR = r;
    if (!newR.computed) newR.computed = {};
    newR.computed.match_rate = 0;
    return newR;
  });
}

function getMatchDetails(filter, row, labels, parent) {
  var i;
  var l;
  var req;
  var reqVals;
  var rowData;
  var matchItem;
  var filterGroup = buildFilterGroup(filter, parent, labels);
  //
  // extract required data field from row
  //
  if (filter.metadata.match_data_path) {
    rowData = getDataByProperty(filter.metadata.match_data_path, row);
    rowData = flattenByProperty(filter.metadata.internal_data_path, rowData);
  }

  //
  // get all requirements
  //
  if (Array.isArray(filter.value) && rowData) {
    req = filter.value;
    reqVals = flattenByProperty('value', req);
    reqVals = arrayToLower(reqVals);
  } else {
    req = filter.value;
    req = valToLower(req);
  }

  //
  // if nothing is required, jump through no more hoops
  //
  if (!req || req.length === 0) return filterGroup;

  function makeRequirementItem(r) {
    var m;
    if (r.text) {
      m = { text: r.text, match: false };
    } else {
      m = { text: r, match: false };
    }
    return m;
  }

  if (!rowData) {
    //
    // only categorical filters currently have the ability to handle missing data rules
    //
    if (filter.metadata.show_requirements === 'categorical') {
      // categorical data should all be stored in one field on the entity
      // so, if entity is missing data for a categorical filter
      // it is because the data isn't available
      filterGroup.requirements = [{ text: filterGroup.missing_data_text, match: null }];
    } else if (Array.isArray(req)) {
      //
      // since requirements exist, overwrite the 'no selections' text
      //
      filterGroup.requirements = [];
      for (i = 0, l = req.length; i < l; i ++) {
        rowData = getDataByProperty(req[i].match_data_path, row);
        rowData = flattenByProperty(req[i].internal_data_path, rowData);
        matchItem = makeRequirementItem(req[i]);
        if (Array.isArray(rowData)) {
          if (arrayToLower(rowData).indexOf(valToLower(req[i].value)) > -1) {
            matchItem.match = true;
          }
        } else {
          if (valToLower(rowData) === valToLower(req[i].value)) {
            matchItem.match = true;
          }
        }
        filterGroup.requirements.push(matchItem);
      }
    } else {
      console.error('filter', filter.metadata.label, 'does not have the appropriate metadata.match_data_path'); // eslint-disable-line
      // return something here!!!
    }
  } else {
    //
    // since requirements exist, overwrite the 'no selections' text
    //
    filterGroup.requirements = [];
    if (filter.metadata.show_requirements === 'categorical') {
      //
      // if the filter group is categorical
      // show the actual value(s) the entity has and whether or not they meet the requirements
      //
      if (Array.isArray(rowData)) {
        for (i = 0, l = rowData.length; i < l; i ++) {
          matchItem = makeRequirementItem(rowData[i]);
          if (arraysIncludeOrOverlap(reqVals, valToLower(rowData[i]))) {
            matchItem.match = true;
          }
          filterGroup.requirements.push(matchItem);
        }
      } else {
        matchItem = makeRequirementItem(rowData);
        if (arraysIncludeOrOverlap(reqVals, valToLower(rowData))) {
          matchItem.match = true;
        }
        filterGroup.requirements.push(matchItem);
      }
    } else if (Array.isArray(req) && Array.isArray(rowData)) {
      rowData = arrayToLower(rowData);
      for (i = 0, l = req.length; i < l; i ++) {
        //
        // set text and match boolean for one requirement
        //
        matchItem = makeRequirementItem(req[i]);
        if (rowData.indexOf(reqVals[i]) > -1) {
          matchItem.match = true;
        }
        filterGroup.requirements.push(matchItem);
      }
    } else if (Array.isArray(req)) {
      rowData = valToLower(rowData);
      for (i = 0, l = req.length; i < l; i ++) {
        //
        // set text and match boolean for one requirement
        //
        matchItem = makeRequirementItem(req[i]);
        if (rowData === reqVals[i]) {
          matchItem.match = true;
        }
        filterGroup.requirements.push(matchItem);
      }
    } else {
      matchItem = makeRequirementItem(req[i]);
      if (req === valToLower(rowData)) {
        matchItem.match = true;
      }
    }
  }
  return filterGroup;
}

function getAllMatches(filters, row, parent, labels) {
  //
  // for use with relaxed multi AND/OR filters
  // returns the match object with title, show_header, show_matches
  // and requirements properties for rendering
  //
  var i;
  var l;
  var matches;
  var filter;
  var relaxed;
  var matchList = [];
  var keys = Object.keys(filters);
  var updatedRow = row;
  var submatches;
  updatedRow.computed.match_list = [];

  //
  // for each filter, get selected values
  //
  for (i = 0, l = keys.length; i < l; i ++) {
    filter = filters[keys[i]];
    matches = null;
    if (!filter.value) {
      // pass
    } else if (Array.isArray(filter.value)) {
      //
      // get only relaxed filter values
      //
      relaxed = getFilterValuesByType(filter, 'relaxed');
      //
      // get match details for each value -
      // does entity have required value(s)?
      //
      if (Array.isArray(relaxed.value)) {
        if (relaxed.value.length > 0) matches = getMatchDetails(relaxed, row, parent, labels);
      } else if (relaxed.value) {
        matches = getMatchDetails(relaxed, row, parent, labels);
      }
    } else {
      //
      // get only relaxed filter values
      //
      if (filter.type === 'relaxed') {
        //
        // get match details for each value -
        // does entity match required value/what is the entity's data?
        //
        matches = getMatchDetails(filter, row, parent, labels);
      }
    }
    if (filter.subfilters) {
      //
      // add subfilters to list at the same level as parents
      //
      submatches = getAllMatches(filter.subfilters, row, filter.group_title_text);
      if (matchList) {
        matchList = matchList.concat(submatches);
      } else {
        matchList = submatches;
      }
    }
    if (matches) {
      //
      // preserve each filter group's order (mapped from data)
      //
      matches.order = filter.order;
      matchList.push(matches);
    }
  }
  //
  // sort the list of filters by order mapped from data
  // if none is supplied, order will be unpredictable, but will not break modal
  //
  matchList = matchList.sort((a, b) => a.order - b.order);
  return matchList;
}

function buildFilterGroup(filter, parent, labels) {
  var noneText = labels.no_selections_text;
  var missingDataText;
  var entityField;
  var filterGroup = {
    title: filter.group_title_text,
    show_title: filter.metadata.show_title,
    show_requirements: filter.metadata.show_requirements,
    filter_met: null,
    requirements: [
      { text: noneText, match: null }
    ]
  };
  entityField = filter.metadata.match_data_path;
  // if applicable, add missing_data_text for later use
  if (labels.missing_data_rules && labels.missing_data_rules[entityField]) {
    missingDataText = labels.missing_data_rules[entityField].missing_data_text;
    filterGroup.missing_data_text = missingDataText;
  }
  if (parent && parent !== '') {
    filterGroup.parent_title = parent;
  }
  return filterGroup;
}

//
// RELAXED filtering functions - to apply match rate to entities
//

function getMatchCt(filter, row) {
  var ct = 0;
  var i;
  var l;
  var req;
  var rowData;
  if (filter.metadata.match_data_path) {
    rowData = getDataByProperty(filter.metadata.match_data_path, row);
    rowData = flattenByProperty(filter.metadata.internal_data_path, rowData);
  }

  req = filter.value;

  if (Array.isArray(req) && rowData) {
    req = flattenByProperty('value', req);
    req = arrayToLower(req);
  } else {
    req = valToLower(req);
  }
  if (!rowData) {
    if (Array.isArray(req)) {
      for (i = 0, l = req.length; i < l; i ++) {
        rowData = getDataByProperty(req[i].match_data_path, row);
        rowData = flattenByProperty(req[i].internal_data_path, rowData);
        if (rowData) {
           // if row has the field
           // required by the filter value, count & add
          ct += getMatchCt(
            { metadata: { match_data_path: 'value' }, value: [req[i]] },
            { value: rowData });
        }
      }
    } else {
      console.error('filter', filter.metadata.label, 'does not have the appropriate metadata.match_data_path'); // eslint-disable-line
      return 0;
    }
  } else if (Array.isArray(req) && Array.isArray(rowData)) {
    // if both requirements & entity data are arrays, return count of overlap
    return overlappingArraysCount(req, arrayToLower(rowData));
  } else if (Array.isArray(req)) {
    // if only requirements is array, return 1 if overlap, 0 if not
    if (req.indexOf(valToLower(rowData)) > -1) return 1;
  } else if (req === valToLower(rowData)) {
    return 1;
  }
  return ct;
}

function applyRelaxedFilterCt(filter, rows) {
  const updatedRows = rows;
  return updatedRows.map((r) => {
    const matchCt = getMatchCt(filter, r);
    const updatedR = r;
    updatedR.computed.match_rate += matchCt;
    return updatedR;
  }, this);
}

//
// STRICT filtering functions
//
function applyStrictExactFilter(filter, rows) {
  var req;
  // req = filter.value;
  req = getRequiredValues(filter);
  if (Array.isArray(req)) {
    req = req[0].value;
    if (typeof req === 'object') {
      req = req.value;
    }
  }
  //
  // remove rows that do not match exact value of filter
  //
  return rows.filter((r) => {
    var data = getDataByProperty(filter.metadata.match_data_path, r);
    return data === req;
  }, this);
}

function applyStrictPartialFilter(filter, rows) {
  //
  // for string matching only
  //
  return rows.filter(r => includesRequiredText(filter, r), this);
}

function applyStrictIncludesFilter(filter, rows) {
  //
  // for single filters matching
  //
  return rows.filter(r => includesRequired(filter, r), this);
}

function applyStrictOperatorFilter(filter, rows) {
  var req;
  var logic = filter.metadata.strict_logic.toUpperCase();
  req = getRequiredValues(filter);
  if (Array.isArray(req) && req.length === 1) {
    req = req[0];
  } else if (Array.isArray(req) && req.length === 0) {
    req = null;
  }
  if (req && req.hasOwnProperty('value')) {
    req = req.value;
  }
  if (typeof req === 'string') {
    req = Number(req);
    if (isNaN(req)) req = null;
  }
  if (!req) return rows;
  //
  // remove rows that do not match
  //
  return rows.filter((r) => {
    var data = getDataByProperty(filter.metadata.match_data_path, r);
    if (data || data === 0) {
      if (logic === 'LTE') {
        return data <= req;
      }
      if (logic === 'GTE') {
        return data >= req;
      }
      if (logic === 'LT') {
        return data < req;
      }
      if (logic === 'GT') {
        return data > req;
      }
    }
    return false;
  }, this);
}

//
// STRICT filtering functions for filters with multiple possible values
//
function applyStrictAndFilter(filter, rows) {
  //
  // remove rows that do not match all filters
  //
  return rows.filter(r => hasAllRequired(filter, r), this);
}

function applyStrictOrFilter(filter, rows) {
  //
  // remove rows that do not match any filters
  //
  var filteredRows;
  filteredRows = rows.filter(r => hasAnyRequired(filter, r), this);
  return filteredRows;
}

//
// STRICT filter
//
function applyStrictFilter(filter, rows) {
  var filteredRows;
  var logic = filter.metadata.strict_logic.toUpperCase();
  if (logic === 'OR') {
    filteredRows = applyStrictOrFilter(filter, rows);
  } else if (logic === 'AND') {
    filteredRows = applyStrictAndFilter(filter, rows);
  } else if (logic === 'PARTIAL') {
    filteredRows = applyStrictPartialFilter(filter, rows);
  } else if (logic === 'EXACT') {
    filteredRows = applyStrictExactFilter(filter, rows);
  } else if (logic === 'INCLUDES') {
    // filteredRows
    // this should probably be OR
  } else if (logic.indexOf('LT') > -1 || logic.indexOf('GT') > -1) {
    filteredRows = applyStrictOperatorFilter(filter, rows);
  }
  return filteredRows;
}


//
// apply all filters to all rows
//
function applyFilters(filters, rows, type, resetMatchCt, excludeFacets) {
  var filteredRows = rows;
  var filterKeys = Object.keys(filters);
  //
  // reset matches
  //
  if (type === 'relaxed' && resetMatchCt) {
    filteredRows = resetMatches(filteredRows);
  }

  for (let i = 0, l = filterKeys.length; i < l; i++) {
    const key = filterKeys[i];
    const filter = filters[key];
    if (!filter.value) {
      // pass
    } else if (Array.isArray(filter.value)) {
      const filterOfType = getFilterValuesByType(filter, type);
      if (excludeFacets && filterOfType.metadata.facet) {
        filterOfType.value = [];
      }
      if (filterOfType.value.length > 0) {
        if (type === 'strict') {
          filteredRows = applyStrictFilter(filterOfType, filteredRows);
        } else if (type === 'relaxed') {
          filteredRows = applyRelaxedFilterCt(filterOfType, filteredRows);
        }
      }
    } else {
      if (filter.type === type && type === 'strict' && !excludeFacets) {
        filteredRows = applyStrictFilter(filter, filteredRows);
      } else if (filter.type === type && type === 'strict' && excludeFacets && !filter.facet) {
        filteredRows = applyStrictFilter(filter, filteredRows);
      } else if (filter.type === type && type === 'relaxed') {
        filteredRows = applyRelaxedFilterCt(filter, filteredRows);
      }
    }
    if (filter.subfilters) {
      const newResetMatchCt = false;
      filteredRows = applyFilters(filter.subfilters, filteredRows, type, newResetMatchCt);
    }
  }
  return filteredRows;
}

//
// count all strict & relaxed filters
//
function countFilters(filters) {
  const ct = { strict: { reset: 0, noReset: 0 }, relaxed: { reset: 0, noReset: 0 }, facet: 0 };
  let subct;
  let strictCt;
  let relaxedCt;
  let facetCt;
  if (!filters) return ct;
  const filterKeys = Object.keys(filters);
  // count require
  for (let i = 0, l = filterKeys.length; i < l; i++) {
    const key = filterKeys[i];
    const filter = filters[key];
    const requirements = getRequiredValues(filter);
    if (Array.isArray(requirements) && requirements.length > 0) {
      strictCt = requirements.filter(function f(r) { // eslint-disable-line
        return r.type === 'strict';
      }).length;
      relaxedCt = requirements.filter(function f(r) { // eslint-disable-line
        return r.type === 'relaxed';
      }).length;
      facetCt = requirements.filter(function f(r) { // eslint-disable-line
        return r.facet && r.type === 'strict';
      }).length;

      if (filter.metadata.show_requirements === 'categorical') {
        // if the filter is categorical, relaxed count
        // should be either yes requirements (1) or no requirements (0)
        if (relaxedCt > 0) relaxedCt = 1;
      }

      if (filter.metadata.facet) {
        // facets are assumed to be strict & resettable
        ct.facet += facetCt;
      } else if (filter.metadata.reset) {
        // resettable strict filters
        ct.strict.reset += strictCt;
        // resettable relaxed filters
        ct.relaxed.reset += relaxedCt;
      } else {
        // non-resettable strict filters
        ct.strict.noReset += strictCt;
        // non-resettable relaxed filters
        ct.relaxed.noReset += relaxedCt;
      }
    } else if (!Array.isArray(requirements) && requirements) {
      if (filter.metadata.reset) {
        ct[filter.type].reset += 1;
      } else {
        ct[filter.type].noReset += 1;
      }
    }
    if (filter.subfilters) {
      subct = countFilters(filter.subfilters);
      ct.relaxed.reset += subct.relaxed.reset;
      ct.relaxed.noReset += subct.relaxed.noReset;
      ct.strict.reset += subct.strict.reset;
      ct.strict.noReset += subct.strict.noReset;
      ct.facet += subct.facet;
    }
  }
  return ct;
}


//
// count entities that match each facet
//
function getFacetEntities(facet, entities) {
  const fakedFacet = JSON.parse(JSON.stringify(facet));
  if (Array.isArray(fakedFacet.value)) {
    for (let i = 0, l = fakedFacet.value.length; i < l; i ++) {
      const val = fakedFacet.value[i];
      val.required = true;
    }
  }
  const filtered = applyFilters([fakedFacet], entities, 'strict');
  const ids = filtered.map(item => item.id);
  return ids;
}

/* eslint-enable no-use-before-define */

module.exports = {
  mergeUserFilterData: mergeUserFilterData,
  initializeAllFilters: initializeAllFilters,
  initializeOneFilter: initializeOneFilter,
  initializeAllOptions: initializeAllOptions,
  initializeAnyOption: initializeAnyOption,
  applyDefaultSelections: applyDefaultSelections,
  applyFilterDependentFields: applyFilterDependentFields,
  getFilterValuesByType: getFilterValuesByType,
  getRequiredValues: getRequiredValues,
  hasAnyRequired: hasAnyRequired,
  hasAllRequired: hasAllRequired,
  includesRequiredText: includesRequiredText,
  includesRequired: includesRequired,
  resetMatches: resetMatches,
  getMatchDetails: getMatchDetails,
  getAllMatches: getAllMatches,
  buildFilterGroup: buildFilterGroup,
  getMatchCt: getMatchCt,
  applyRelaxedFilterCt: applyRelaxedFilterCt,
  applyStrictExactFilter: applyStrictExactFilter,
  applyStrictPartialFilter: applyStrictPartialFilter,
  applyStrictIncludesFilter: applyStrictIncludesFilter,
  applyStrictOperatorFilter: applyStrictOperatorFilter,
  applyStrictAndFilter: applyStrictAndFilter,
  applyStrictOrFilter: applyStrictOrFilter,
  applyStrictFilter: applyStrictFilter,
  applyFilters: applyFilters,
  // filterTheFilter: filterTheFilter,
  // getNonSuperFilters: getNonSuperFilters,
  countFilters: countFilters,
  getFacetEntities: getFacetEntities,
};
