function addCommas(num) {
  let numString = num;
  if (typeof num !== 'string') {
    numString = num.toString();
  }
  return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatNumeric(num, options) {
  /*
      options = {
          precision: number, defaults to stripping trailing 0s from decimals
            ex: 3.20, without a precision option, will return '3.2'
          commas: boolean, defaults to false, or as given for strings
          format: percent, currency ($ only), ordinal
      }
  */
  let numberified = null;

  if (!options) return num;

  let originalCommas;

  // turn into a number
  if (typeof num === 'string') {
    originalCommas = num.indexOf(',') > -1;
    numberified = num.replace(/(?!^)-/g, '');
    numberified = numberified.replace(/[^0-9.-]/g, '');
    if (numberified === '') return null;
    numberified = Number.parseFloat(numberified);
  } else {
    numberified = Number.parseFloat(num);
  }

  // handle precision first
  if (Object.prototype.hasOwnProperty.call(options, 'precision')) {
    numberified = numberified.toFixed(options.precision);
  }

  // add commas
  if (Object.prototype.hasOwnProperty.call(options, 'commas') && options.commas) {
    numberified = addCommas(num);
  } else if (!Object.prototype.hasOwnProperty.call(options, 'commas') && originalCommas) {
    numberified = addCommas(num);
  }

  // handle number format
  if (options.format) {
    if (options.format === 'percent') {
      numberified = numberified.toString() + '%';
    } else if (options.format === 'currency') {
      numberified = '$' + numberified.toString();
    } else if (options.format === 'ordinal') {
      const endings = {
        0: 'th',
        1: 'st',
        2: 'nd',
        3: 'rd',
        4: 'th',
        5: 'th',
        6: 'th',
        7: 'th',
        8: 'th',
        9: 'th',
        10: 'th',
        11: 'th',
        12: 'th',
        13: 'th',
        14: 'th',
        15: 'th',
        16: 'th',
        17: 'th',
        18: 'th',
        19: 'th',
      };
      const numberString = numberified.toString();
      let numberEnd = numberString.slice(numberString.length - 2, numberString.length);
      if (numberEnd.length !== 1 && numberEnd[0] !== '1') {
        numberEnd = numberEnd[1];
      }
      numberified = numberString === '0' || numberString.indexOf('.') > -1 ? numberString : numberString + endings[numberEnd]; // eslint-disable-line max-len
    }
  }


  return numberified;
}

export default formatNumeric;
