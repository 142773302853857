<template>
  <main id="main-content">
    <!-- nav bar -->
    <component
      v-if="layout.site_nav"
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
    ></component>
    <!-- breadcrumb -->
    <component
        v-if="layout.breadcrumb"
        :is="layout.breadcrumb.tag"
        :settings="layout.breadcrumb.data"
      ></component>
    <div class="container py-4 sp-v-80">
      <!-- main content -->
      <section>
        <!-- page title -->
        <div class="row mb-3 mt-1">
          <div class="col">
            <h1 class="mb-3 mt-2">{{settings.page_header}}</h1>
          </div>
        </div>
        <div class="row">
          <!-- introduction content column -->
          <div class="col-sm-12 col-lg-7 sp-v-4">
            <template v-for="item in settings.introduction">
              <h2 v-if="item.type === 'header'">{{item.value}}</h2>
              <img v-else-if="item.type === 'img' || item.type === 'image'" :src="item.value" class="c-image--branding" />
              <rich-text v-else-if="item.type === 'text'" :text="item.value" :tag="'p'"></rich-text>
            </template>
          </div>

          <!-- sidebar column -->
          <div v-if="settings.sidebar" class="col-sm-12 col-lg-4 offset-lg-1 sp-v-60 mt-60 mt-lg-0">

            <!-- sidebar sections -->
            <template v-if="settings.sidebar.sections">
              <div v-for="item in settings.sidebar.sections">
                <h3 v-if="item.header">{{item.header}}</h3>
                <rich-text v-if="item.description" :tag="'p'" :text="item.description" class="mt-2"></rich-text>
                <a v-if="item.cta_text && item.cta_url"
                class="c-button c-button--secondary mt-4"
                v-on:click="linkClickEvent(item.cta_url)"
                :href="item.cta_url"
                :target="item.cta_new_tab ? '_blank' : '_self'">
                  <i v-if="item.icon" class="mr-2 fas" :class="item.icon"></i>{{item.cta_text}}</a>
              </div>
            </template>

            <!-- additional info table -->
            <div v-if="settings.sidebar.additional_info">
              <h3 class="mb-4">{{settings.sidebar.additional_info.header}}</h3>
              <table class="c-table--description">
                <tbody>
                  <!-- start loop -->
                  <template v-for="item in settings.sidebar.additional_info.items">
                    <tr v-if="item.text || item.description || item.url">
                      <td class="c-table__label"><a v-if="item.url" :href="item.url" target="_blank" v-on:click="linkClickEvent(item.url)">{{item.text || item.url}}</a><template v-else>{{item.text}}</template></td>
                      <td class="c-table__text">{{item.description}}</td>
                    </tr>
                  </template>
                  <!-- end loop -->
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </section>

      <!-- images -->
      <section v-if="settings.images">
        <div class="row">
          <div class="col">
            <h2 class="mb-2">{{settings.images.section_header}}</h2>
            <rich-text class="mb-4" :text="settings.images.description" :tag="'p'"></rich-text>
              <template v-for="section in settings.images.sections">
                <h3 class="mt-60 mb-20 h3--large" v-if="section.header" >{{section.header}}</h3>
                <div class="row " v-if="section.urls && section.urls.length">
                  <!-- start loop -->
                  <div class="col-6  col-sm-4 col-md-3 col-lg-2  d-flex flex-column !c-image-grid__item py-3 justify-content-center" v-for="image_url in section.urls"><img class="c-image-grid__image" :src="image_url" /></div>
                  <!-- end loop -->
                </div>
              </template>

          </div>
        </div>
      </section>

      <!-- frequently asked questions -->
      <section v-if="settings.faqs" id="frequently-asked-questions">
        <div class="row">
          <div class="col">
            <h2 class="mb-2">{{settings.faqs.section_header}}</h2>
            <p class="mb-4">{{settings.faqs.description}}</p>
            <div class="c-faq">

              <div class="row">
                <div class="col-sm-12 col-lg-4">

                  <div class="c-faq__item">
                    <span class="c-faq__icon">
                      <faq-icon></faq-icon>
                    </span>
                    <h6 class="c-faq__question">{{settings.faqs.question1}}</h6>
                    <div class="c-faq__answer sp-v-4">
                      <p v-for="faq in settings.faqs.question1_layout">
                        <strong v-if="faq.bold_text">{{faq.bold_text}}</strong>{{faq.paragraph_text}}
                      </p>
                      <ul class="u-ul--bullets"
                        v-if="settings.faqs.question1_list.length">
                        <li v-for="faq in settings.faqs.question1_list">{{faq}}</li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div class="col-sm-12 col-lg-8 columns-lg-2" column="2">

                  <!-- start loop -->
                  <div class="c-faq__item" v-for="faq in settings.faqs.default_layout">
                    <span class="c-faq__icon">
                      <faq-icon></faq-icon>
                    </span>
                    <h6 class="c-faq__question">{{faq.question}}</h6>
                    <div v-if="faq.answer_part1" class="c-faq__answer sp-v-4">
                      <p>{{faq.answer_part1}}</p>
                      <p v-if="faq.answer_part2">{{faq.answer_part2}}</p>
                    </div>
                  </div>
                  <!-- end loop -->

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    </div>
    <component
      v-if="layout.footer"
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class="mt-5 mt-md-80"
    ></component>
  </main>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: ['settings', 'layout'],
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    },
    methods: {
      linkClickEvent(href) {
        let action;
        if (href.indexOf('http') > -1) {
          action = 'external_link_selected';
        } else if (href.indexOf('mailto') > -1) {
          action = 'mail_link_selected';
        } else {
          action = 'link_selected';
        }
        sendEvent({
          category: 'find_about_page',
          action: action,
          label: href,
        });
      }
    }
  };
</script>

<style type="text/css" scoped="">
.u-ul--bullets {
  list-style-type: disc;
  margin-left: 1.5rem;
}
img {
  max-width: 100%; /* ? global */
}
.c-image-grid__image{
  max-height:  100px;
  object-fit: contain;
  /* filter: grayscale(1) opacity(0.8); */
}
.c-image-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.c-image-grid__item {
  max-width: calc(100% / 6);
  padding: 1rem;
}
</style>
<style type="text/css" >

@media screen and (min-width: 1000px){
  .c-faq [column="2"] {
    columns: 2;
    column-gap: 40px;
  }
}
.c-faq__item {
  position: relative;
  padding-left: 3.5rem;
  break-inside: avoid;
  display: inline-block;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.c-faq__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  transform: translateY(-50%);
}
.c-faq__icon svg {
  max-width: 100%;
}
.c-faq__icon path {
  fill: #f2516b; /* red */
}


[column="2"] .c-faq__item:nth-child(3n+2) .c-faq__icon path {fill: #664d4c;} /* brown */
[column="2"] .c-faq__item:nth-child(3n+1) .c-faq__icon path {fill: #ffb549;} /* gold */
[column="2"] .c-faq__item:nth-child(3n) .c-faq__icon path {fill: #f2516b;} /* red */
@media screen and (min-width: 1000px){
  [column="2"] .c-faq__item:nth-child(even) .c-faq__icon path {fill: #664d4c;} /* brown */
  [column="2"] .c-faq__item:nth-child(odd) .c-faq__icon path {fill: #ffb549;} /* gold */
  [column="2"] .c-faq__item:nth-child(3n) .c-faq__icon path {fill: #f2516b;} /* red */
}


</style>

