<template>
  <p class="entity-list-error-message">{{first}} <a v-on:click="clearFilters">{{settings.linked_text}}</a> {{second}}</p>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings'],
    computed: {
      first: function first() {
        var longText = this.settings.long_text;
        var linkedText = this.settings.linked_text;
        var idx = longText.toLowerCase().indexOf(linkedText.toLowerCase());
        return longText.slice(0, idx);
      },
      second: function second() {
        var longText = this.settings.long_text;
        var linkedText = this.settings.linked_text;
        var idx = longText.toLowerCase().indexOf(linkedText.toLowerCase());
        return longText.slice(idx + linkedText.length);
      }
    },
    methods: {
      clearFilters: function clearFilters() {
        // false == do not apply default values, actually clear
        this.$store.dispatch('resetAllFilters', false);
      }
    }
  };
</script>
<style type="text/css" scoped>
  .entity-list-error-message{
    text-align: center;
    margin: 20px;
    font-size: 20px;
    line-height: 1.5;
  }
  a{
    display: inline-block;
  }
</style>
