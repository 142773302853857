<template>
  <div v-if="usePagination && breakpoint !== 'smartphone'" class="sp-h-4">
    <span>{{settings.label_text}}</span>
    <a v-for="option in numericOptions" v-on:click="updateResultsPerPage(option)" :class="$store.state.resultsPerPage === option ? 'a--page-toggle--active' : ''" class="a--page-toggle ">{{option}}</a>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  import staticData from '../store/data';
  const PaginationMixin = require('../mixins/PaginationMixin');

  module.exports = {
    props: ['settings'],
    mixins: [PaginationMixin],
    data: function data() {
      return {
        usePagination: staticData.usePagination,
        default: staticData.settings.pagination.results_per_page
      };
    },
    computed: {
      numericOptions() {
        return this.settings.options
          .filter(opt => !isNaN(parseInt(opt, 10)))
          .map(opt => parseInt(opt, 10));
      },
      breakpoint() {
        return this.$store.state.breakpoint;
      }
    },
    methods: {
      updateResultsPerPage(newval) {
        this.$store.dispatch('updateResultsPerPage', newval);
        this.$store.dispatch('updatePage', 1);
        sendEvent({
          category: 'list',
          action: 'result_count_toggle',
          label: newval
        });
      }
    },
    watch: {
      breakpoint(curr, prev) {
        if (curr !== prev && this.breakpoint === 'smartphone') {
          this.updateResultsPerPage(this.default);
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  .pagination-toggle_option{
    font-weight: 600;
  }
  .pagination-toggle_option--active{
    font-weight: bold;
  }
  span + span {
    margin-left: 20px;
  }
</style>