<template>
  <modal-component class="filter-modal compare-modal modal-drawer" :show="show" :click-away="false">
      <!-- TITLE -->
      <div class="modal-heading c-modal__head o-modal__head">
        <h2 class="c-modal__heading-target o-modal__heading-target">{{settings.labels.address_grade_header_text}}</h2>
      </div>

      <div class="my-2 mx-3">
        <!-- ADDRESS ENTRY -->
        <div class="mt-3">
          <div class="col-12">
            <address-entry-typeahead :settings="settings.address_entry"></address-entry-typeahead>
          </div>
        </div>

        <!-- GRADE FILTER -->
        <div class="mt-3" v-if="!settings.labels.hide_grade">
          <div class="col-12">
            <wrapped-select-alert-filter
              :settings="settings.grade_filter"
              :action="action"
              @update:alert="val => { setAlert(val) }"
              @update:action="val => { action = val }"
            ></wrapped-select-alert-filter>
          </div>
        </div>

        <!-- GRADE CHANGE ALERT -->
        <div v-show="alert && !settings.labels.hide_grade" class="mt-3">
            <p class="text-center">{{settings.labels.grade_alert_text}}</p>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="mt-auto c-modal__footer mb-3 mx-3">
          <div class="col-auto ml-auto">
            <button class="button button--full c-button c-button--outline w-100" v-on:click="reset">{{settings.labels.cancel_text}}</button>
          </div>
          <div class="col-auto mt-3">
            <button class="c-button w-100 button button--active button--full"v-on:click="submit">{{settings.labels.update_text}}</button>
        </div>
      </div>
  </modal-component>
</template>
<script type="text/javascript">

  import ModalComponent from './Modal.vue';
  import AddressEntryTypeahead from '../AddressEntryTypeahead.vue';
  import WrappedSelectAlertFilter from '../filterGroups/WrappedSelectAlertFilter.vue';
  var ModalMixin = require('../../mixins/ModalMixin');

  module.exports = {
    props: ['settings', 'show'],
    components: {
      AddressEntryTypeahead,
      ModalComponent,
      WrappedSelectAlertFilter,
    },
    mixins: [ModalMixin],
    data: function data() {
      return {
        initAddress: [this.$store.state.home],
        action: null,
        alert: false
      };
    },
    computed: {
      home: function home() {
        return this.$store.state.home;
      }
    },
    methods: {
      submit: function submit() {
        this.$store.dispatch('updatePendingFilterAction', 'set');
        if (this.alert) {
          // true = do apply default selections to filters
          this.$store.dispatch('resetAllFilters', true);
        }
        this.alert = false;
        this.$nextTick(this.closeModal);
      },
      reset: function reset() {
        this.$store.dispatch('updateAddress', this.initAddress);
        this.$store.dispatch('updatePendingFilterAction', 'cancel');
        this.alert = false;
        this.$nextTick(this.closeModal);
      },
      setAlert: function setAlert(alert) {
        if (this.settings.grade_filter.metadata.super_filter) {
          this.alert = alert;
        } else {
          this.alert = false;
        }
      }
    },
    watch: {
      show: function show() {
        //
        // on show, update the address value, in case we need to revert
        //
        this.initAddress = [this.home];
      }
    }
  };
</script>