<template>
  <tr v-if="entity" :id="entity.id" v-bind:class="$store.getters.useCompare && entity.computed.compare ? 'c-table__row--active' : ''">
    <td v-if="$store.getters.useCompare" class="d-none d-md-table-cell">
      <check-box-compare
          :checked="entity.computed.compare"
          :value="entity.id"
          :disabled="(compares.length > 0) && (compares.length === compareConfig.max) && !entity.computed.compare"
          @update:compare="val => { handleCompareClick(val) }"
          :text-saved="settings.compare_success_text"
          :text-add="settings.compare_save_text"
          :text-remove="settings.compare_remove_text"
      ></check-box-compare>
    </td>

    <result-datum-tembo v-for="col in columns"
      :entity="entity"
      :settings="col"
      :key="col.id"
      :data-mobile-icon="col.icon"
      :class="col.hasOwnProperty('sort_direction') && col.sort_direction !== 0 ? 'c-table__td--sorted' : ''"
    ></result-datum-tembo>
  </tr>
</template>
<script type="text/javascript">
  import CheckBoxCompare from '../compare/CheckBoxCompare.vue';
  import ResultDatumTembo from '../ResultDatumTembo.vue';

  module.exports = {
    props: ['settings', 'entity', 'columns'],
    components: {
      CheckBoxCompare,
      ResultDatumTembo,
    },
    computed: {
      compareConfig() {
        return this.$store.state.compareConfig;
      },
      compares() {
        return this.$store.state.compares;
      },
    },
    methods: {
      handleCompareClick(compared) {
        //
        // currently assumes compare will never be auth-based
        //
        let limitReached = false;
        let maximum;
        let sessionItem;
        //
        // set default for compare maximum
        //
        if (this.compareConfig.max) {
          maximum = this.compareConfig.max;
        } else {
          maximum = 3;
        }
        if (this.compares.length >= maximum) {
          limitReached = true;
        }

        if (compared && limitReached) {

          // HANDLE TOOLTIP, ETC FOR ALERTING USER THAT THEY'VE PICKED
          // TOO MANY ENTITIES TO COMPARE

          //
          // TODO: may be a better way to handle this
          // this is a temporary fix: a brief save & then removing from compares
          // to prevent a flash when selecting a compare button shouldn't be allowed
          // may need additional UX input
          //
          // temporarily save
          //
          // **********
          // IF it is a requirement that the checkbox never be disabled,
          // put the following code back
          // **********
          // sessionItem = { id: this.entity.id, compare: true, name: this.entity.name };
          // this.$store.dispatch('updateCompare', sessionItem)
          // .then(() => {
          //   setTimeout(() => {
          //     sessionItem.compare = false;
          //     //
          //     // remove item from compare list
          //     //
          //     this.$store.dispatch('updateCompare', sessionItem);
          //   }, 300);
          // });
        } else {
          sessionItem = { id: this.entity.id, compare: compared, name: this.entity.name };
          this.$store.dispatch('updateCompare', sessionItem);
        }
      },
    }
  };
</script>
<style type="text/css" scoped>
</style>
