<template>

      <select-component
        :options="values"
        :selected="requiredValue"
        :placeholder-value="settings.prompt_text"
        :use-title="false"
        @update:selected="val => { handleChange(val) }"
      ></select-component>

</template>
<script type="text/javascript">
  import SelectWithMenu from '../SelectWithMenu.vue';
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  var WrappedSelectFilterMixin = require('../../mixins/WrappedSelectFilterMixin');
  module.exports = {
    components: { SelectWithMenu },
    mixins: [FilterGroupMixin, WrappedSelectFilterMixin]
  };
</script>
