<template>
  <div class="nav o-two-column-layout__mobile-nav px-3 pt-3 d-flex d-md-none justify-content-between">
    <div class="p-entity-nav__item m-0 p-1 " v-bind:class="mobileView === 'filter' ? 'p-entity-nav__item--active' : ''" v-on:click="handleClick('filter')">{{settings.view_filters_text}}</div>
    <div class="d-flex">
      <div class="p-entity-nav__item m-0 p-1  mr-3" v-bind:class="mobileView === 'list' ? 'p-entity-nav__item--active' : ''" v-on:click="handleClick('list')">{{settings.view_list_text}}</div>
      <div class="p-entity-nav__item m-0  p-1" v-bind:class="mobileView === 'map' ? 'p-entity-nav__item--active' : ''" v-on:click="handleClick('map')">{{settings.view_map_text}}</div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: [
      'settings',
      'layout'
    ],
    computed: {
      mobileView: function mobileView() {
        return this.$store.state.mobileView;
      }
    },
    methods: {
      handleClick: function handleClick(view) {
        sendEvent({
          category: 'nav',
          action: 'mobile_view_change',
          label: view
        });
        this.$store.dispatch('updateMobileView', view);
        this.settings.value = view;
      }
    }
  };
</script>