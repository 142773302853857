var $ = require('jquery');
global.jQuery = $;
require('bootstrap/js/tooltip');

module.exports = {
  props: ['value', 'checked', 'disabled', 'textSaved', 'textAdd', 'textRemove'],
  computed: {
    labelText: function labelText() {
      return this.checked ? this.textRemove : this.textAdd;
    }
  },
  mounted: function mounted() {
    this.$nextTick(() => {
      $(this.$el).tooltip({ title: this.labelText, placement: 'left' });
    });
  },
  methods: {
    handleClick: function handleClick() {
      var self = this;
      var $label = $(self.$el);
      if (self.checked) {
        $label
        .attr('data-original-title', self.textSaved)
        .tooltip('show')
        .one('mouseleave focusout', () => {
          $label.attr('data-original-title', self.labelText);
        });

        // hide Saved! tooltip after showing briefly
        setTimeout(() => {
          $label.tooltip('hide');
        }, 1500);
      }

      this.$nextTick(function nextTick() {
        $label.attr('data-original-title', this.labelText);
      });
    },
  },
  watch: {
    checked: function checked() {
      this.handleClick();
    }
  }
};
