<template>
	<div class="entity-attributes__video-modal-link">
		<div>
		<a class="a--media" v-on:click="handleVideoClick(entity)">
			<i class="far fa-play-circle ml-2"></i>

			<!-- <span class="a-ui-grow">{{ attributes.text }}</span> -->
			<span tabindex="1" class="">{{settings.video_launch_text}}</span>
		</a>
		</div>
	</div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: [
      'settings',
      'entity'
    ],
    methods: {
      handleVideoClick: function handleVideoClick(entity) {
        this.$store.dispatch('updateShowModal', { modal: 'video', data: entity });
        sendEvent({
          category: 'list',
          action: 'show_video',
          label: entity.id
        });
      }
    }
  };
</script>
<style type="text/css" scoped>
.entity-attributes__video-modal-link{
	display: flex;
    flex-direction: column-reverse;
}
</style>