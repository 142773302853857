<template>
  <footer id="footer-wrapper" class="p-footer p-2 p-md-3 px-lg-0 py-lg-4 d-flex justify-content-between flex-shrink-0" >

<div :class="[footerType === 'containerWidth' ?  'container' : 'container-fluid']">


 <div class="row">
      <div class="col-12 col-sm-6 col-xl-auto">
        <h4 class="p-footer__force-color mb-2 mb-sm-3">{{settings.about_sde.contact_sde.contact_sde_header}}</h4>

        <p class="p-footer__p">{{settings.name}}</p>

        <p class="mt-2 mt-sm-3 p-footer__p">
          <a :href="settings.website_url" class="p-footer__a" target="_blank">{{settings.website_url}}</a>
        </p>

        <p class=" p-footer__p">

          {{settings.email.text}}

        </p>

      </div>
      <div class="col-12 col-sm-6 col-xl-auto mt-4 mt-sm-0  mr-lg-auto">
        <h4 class="p-footer__force-color mb-3">{{settings.about_sde.additional_links.text}}</h4>
        <p class="mt-2 mt-sm-3 p-footer__p">
          <a :href='settings.about_sde.additional_links.state_report.url' class="p-footer__a">{{settings.about_sde.additional_links.state_report.text}}</a>
        </p>
        <p class="mt-2 mt-sm-3 p-footer__p">
          <a :href='settings.about_sde.additional_links.see_more_detail.url' class="p-footer__a" target="_blank">{{settings.about_sde.additional_links.see_more_detail.text}}</a>
        </p>
        <p class="mt-2 mt-sm-3 p-footer__p">
          <a :href='settings.about_sde.additional_links.accountablilty_guide.url' class="p-footer__a" target="_blank">{{settings.about_sde.additional_links.accountablilty_guide.text}}</a>
        </p>

      </div>
      <div class="col-12 col-md-auto ml-0 mt-4 mt-xl-0 justify-content-between align-items-start align-items-sm-center align-items-md-start d-flex flex-column flex-sm-row flex-md-column">
        <a href='//translate.google.com' target="_blank">
      <attribution-google :settings="settings"></attribution-google>
        </a>



           <span class="mt-3 mt-sm-0 mt-md-3 mt-xl-5">
              <attribution-inline :settings="settings"></attribution-inline>
          </span>

        <!--   <p class="p-footer__tembo-logo-text text-small">{{ settings.powered_by_text }}</p>


          <a href="http://www.temboinc.com" target="_blank">

          </a> -->


      </div>
    </div>
</div>

  </footer>
</template>
<script type="text/javascript">

  import AttributionInline from './AttributionInline.vue';
  import AttributionGoogle from './AttributionGoogle.vue';
  module.exports = {
    components: { AttributionInline, AttributionGoogle },
    props: ['settings', 'footerType'],
    computed: {
      leftAlignedContact: function leftAlignedContact() {
        return this.settings.align_contact_info && this.settings.align_contact_info === 'left';
      }
    }
  };
</script>