<template>
  <modal-component :show="show" @update:show="val => { show = val }" :click-away="false">
    <div
      v-if="layout.address_entry"
      :is="layout.address_entry.tag"
      :settings="layout.address_entry.data"
      :layout="layout.address_entry.layout"
    ></div>
    <div>
      <!-- ERROR MESSAGE -->
      <div class="no-address-alert">
      <img :src="settings.address_failure_image" alt="">
        <p class="text-center">{{settings.noresults_text.split(linkedText)[0]}}<a v-on:click="resetAddress">{{linkedText}}</a> {{settings.noresults_text.split(linkedText)[1]}}</p>
      </div>

    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  var ModalMixin = require('../../mixins/ModalMixin');

  module.exports = {
    mixins: [ModalMixin],
    components: { ModalComponent },
    props: ['show', 'layout', 'settings'],
    methods: {
      resetAddress: function resetAddress() {
        this.$store.dispatch('updateAddress', []);
        this.closeModal();
      }
    },
    computed: {
      linkedText: function linkedText() {
        var noresults = this.settings.noresults_text.toLowerCase();
        var linkedSearch = this.settings.noresults_linked_text.toLowerCase();
        var idx = noresults.indexOf(linkedSearch);
        var l = linkedSearch.length;
        var linked = this.settings.noresults_text.slice(idx, idx + l);
        return linked;
      }
    }
  };
</script>