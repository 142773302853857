<template>
  <div>
    <table id="entity-list" class="c-table" style="overflow-y: auto; table-layout:initial;">
      <thead class="d-none d-md-table-row">
        <th v-if="$store.getters.useCompare" class="c-table__col--compare"></th>
        <th
          v-for="(col, idx) in filteredColumns"
          scope="col"
          v-on:click="toggleSort(idx)"
          :key="idx"
          class="align-middle"
          :class="(col.is_row_header ? 'c-table__col--school-name' : '') + (col.is_sortable ? ' c-table__col--sortable': '') + (col.sort_direction === 1 || col.sort_direction === -1 ? ' c-table__col--sort-acitve' : '')"
        >
          <div v-if="col.is_sortable" class="d-flex">
            <span class="flex-grow-1">{{col.header_text}}</span>
            <div  class="!align-items-center d-inline-flex position-relative sort-icon">
              <i class="fa fa-sort fa-stack-1x"></i>
              <i class="fa fa-stack-1x" :class="col.sort_direction === 1 ? 'sort-active fa-sort-desc fa-sort-up' : col.sort_direction === -1 ? 'fa-sort-asc sort-active fa-sort-down' : '' "></i>
            </div>
          </div>
          <span v-else>{{col.header_text}}</span>
        </th>
      </thead>
      <tbody>
        <!-- LOOP THROUGH LIST OF SCHOOLS -->

        <tr
          v-if="!$store.state.entitiesLoaded"
          v-for="i in (usePagination ? settings.pagination.results_per_page : 20)"
          :key="'table-row' + i"
        ><td colspan="10" class="entity-list-placeholder"></td></tr>
        <template v-if="pageXEntities.length">
          <result-item-row
            v-for="(entity, index) in pageXEntities"
            :key="entity.id"
            :settings="settings"
            :columns="filteredColumns"
            :entity="entity"
            class="c-table__row--mobile-card"
          ></result-item-row>
        </template>
      </tbody>
    </table>
    <!-- ERROR TEXT -->
    <component
      v-if="$store.state.entitiesLoaded && (!pageXEntities || !pageXEntities.length) && layout.no_results"
      :is="layout.no_results.tag"
      :settings="layout.no_results.data"
    >{{layout.no_results.data}}</component>
    <p
      v-else-if="$store.state.entitiesLoaded && (!pageXEntities || !pageXEntities.length)"
    >[No results - please configure]</p>
  </div>
</template>
<script type="text/javascript">
  import arraysMatch from 'tembo-js/arraysMatch';
  var PaginationMixin = require('../mixins/PaginationMixin');
  var TableMixin = require('../mixins/TableMixin');
  import staticData from '../store/data';
  import ResultItemRow from './resultItems/ResultItemRow.vue';

  module.exports = {
    components: { ResultItemRow },
    mixins: [PaginationMixin, TableMixin],
    props: [
      'settings',
      'layout'
    ],
    data() {
      return {
        usePagination: staticData.usePagination,
      };
    },
    mounted() {
      const reset = false;
      this.setInitialTableSort(reset);
      const sorts = this.getMobileSorts();
      this.$emit('update:mobileSortOptions', sorts);
    },
    computed: {
      home() {
        return this.$store.state.home;
      },
    },
    methods: {
      getMobileSorts() {
        //
        // the sort options available when the mobile dropdown
        // is configured to update the table sort & the columns visible on mobile
        //
        const sorts = [];
        this.filteredColumns
          .filter(col => col.is_sortable)
          .forEach(col => {
            if (Array.isArray(col.sort_direction_mobile)) {
              col.sort_direction_mobile.forEach(sortDirection => {
                sorts.push(this.makeSortFromColumn(col, sortDirection, 'header_text'));
              });
            } else {
              sorts.push(this.makeSortFromColumn(col, col.sort_direction_mobile, 'header_text'));
            }
          }, this);
        return sorts;
      },
    },
    watch: {
      filteredColumnIds(curr, prev) {
        const reset = true;
        const match = arraysMatch(curr, prev);
        if (!match) {
          this.setInitialTableSort(reset);
          const sorts = this.getMobileSorts();
          this.$emit('update:mobileSortOptions', sorts);
        }
      },
      home: {
        handler() {
          const reset = true;
          this.setInitialTableSort(reset);
          const sorts = this.getMobileSorts();
          this.$emit('update:mobileSortOptions', sorts);
        },
        deep: true
      }
    }
  };
</script>
<style type="text/css">
  .c-table__col--sortable {
    cursor: pointer;
  }
  .sort-icon{
    width:  1em;
    margin-left:  12px;
    font-size: 21px;
  }
  .sort-icon i{
    opacity: 0.3;
  }
  .sort-icon i.sort-active{
    opacity: 1;
  }

@keyframes placeholderPulse{
    0%{
        background-color: #fff;
    }
    50%{
        background-color: #fafafa;
    }
    100%{
        background-color: #fff;
    }
}
  .entity-list-placeholder{
     animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderPulse;
    animation-timing-function: linear;
    height:  45px;

  }
</style>