<template>
  <select-component
    :options="values"
    :selected="selectedValue"
    :placeholder-value="settings.prompt_text"
    @update:selected="val => { updateSelected(val) }"
  >
    <h2 slot="select-title" class="c-select__title">{{settings.group_title_text}}</h2>
  </select-component>
</template>
<script type="text/javascript">
  import SelectComponent from '../Select.vue';
  import sendEvent from 'tembo-js/sendEvent';
  const FilterGroupMixin = require('../../mixins/FilterGroupMixin');

  module.exports = {
    mixins: [FilterGroupMixin],
    components: { SelectComponent },
    props: [
      'alert'
    ],
    data: function data() {
      return {
        selectedValue: null,
        pending: false
      };
    },
    mounted: function mounted() {
      //
      // on load, get value already selected, if available
      //
      this.selectedValue = this.getSelected();
    },
    computed: {
      action: function action() {
        return this.$store.state.pendingFilterAction;
      },
      requiredValue: function requiredValue() {
        var reqVal;
        var i = 0;
        var l = this.values.length;
        while (!reqVal && i < l) {
          if (this.values[i].required) {
            reqVal = this.values[i].value;
          }
          i += 1;
        }
        return reqVal;
      }
    },
    methods: {
      getSelected: function getSelected() {
        var selectedValue = null;
        var i = 0;
        var l = this.values.length;
        while (!selectedValue && i < l) {
          if (this.values[i].required) {
            selectedValue = this.values[i].value;
          }
          i += 1;
        }
        return selectedValue;
      },
      getItemByValue: function getItemByValue(val) {
        var selectedValue;
        var i = 0;
        var l = this.values.length;
        while (!selectedValue && i < l) {
          if (this.values[i].value === val) {
            selectedValue = this.values[i];
          }
          i += 1;
        }
        return selectedValue;
      },
      handleChange: function handleChange(newvalue) {
        var prevItem = this.requiredValue;
        var currItem;
        if (prevItem) {
          prevItem = this.getItemByValue(prevItem);
          prevItem = JSON.parse(JSON.stringify(prevItem));
          prevItem.required = false;
          this.$store.dispatch('updateFilterValue', { filterValue: prevItem, updateEntities: true });
        }
        currItem = this.getItemByValue(newvalue);
        currItem = JSON.parse(JSON.stringify(currItem));
        currItem.required = true;
        this.$store.dispatch('updateFilterValue', { filterValue: currItem, updateEntities: true });
        sendEvent({
          category: 'filter',
          action: 'change',
          label: newvalue
        });
      },
      updateSelected: function updateSelected(newvalue) {
        this.selectedValue = newvalue;
      }
    },
    watch: {
      action: function action(curr) {
        var selectedValue;
        if (curr === 'set') {
          if (this.pending) {
            this.handleChange(this.selectedValue);
            this.pending = false;
            this.$store.dispatch('updatePendingFilterAction', null);
          }
        } else if (curr === 'cancel') {
          if (this.pending) {
            selectedValue = this.getSelected();
            this.selectedValue = selectedValue;
            this.pending = false;
            this.$store.dispatch('updatePendingFilterAction', null);
          }
        } else {
          this.$store.dispatch('updatePendingFilterAction', null);
        }
      },
      selectedValue: {
        handler: function selectedValue() {
          if (this.selectedValue !== this.requiredValue) {
            this.pending = true;
            if (this.alert) {
              this.$store.dispatch('updateShowModal', { modal: 'filter_alert', data: 'test' });
            }
            this.$emit('update:alert', true);
          }
        },
        deep: true
      },
      requiredValue: {
        handler: function requiredValue() {
          this.selectedValue = this.requiredValue;
        },
        deep: true
      }
    }
  };

</script>
