<template>
  <!-- K-12 letter grade: display if available -->
  <div class="entity-attributes__k-12-rating" v-if="entityData">
    <div class="d-inline-flex flex-column align-items-start align-items-md-center">
      <div class="p-entity__attributes__title mb-1"><strong>{{strongText}}</strong> {{subText}}</div>
      <!-- SCHOOL REPORT SCORE -->
      <div v-if="!missing" class="d-inline-flex align-items-center">
        <span class="c-letter-grade-badge--active c-letter-grade-badge letter-grade-badge--sm" :class="'c-letter-grade-badge__' + settings.letter_grade_universe.indexOf(entityData.letter_grade)">{{entityData.letter_grade}} </span>
        <span v-if="entityData.score" class="ml-1">/ {{entityData.score}}</span>
      </div>
      <!-- missing data -->
      <div v-else>
        <span class="report-card-letter letter-grade__">{{entityData}}</span>
      </div>
    </div>
  </div>

</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'entityData', 'missing', 'strongText', 'subText']
  };
</script>