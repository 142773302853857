<!-- TODO: update for vue2 -->
<template>
  <modal-component :show='show' :click-away="false" class='wizard-modal' v-if='screens'>
    <wizard-bread-crumb
      :wizard-settings='settings'
      :options='currentOptions'
      :current-screen='currentScreen'
      :role='role'
      :year='year'
      :bread-crumbs='breadCrumbs'
    ></wizard-bread-crumb>
    <wizard-three-dots
      v-if='index > -1'
      :show='show'
      :wizard-settings="settings"
    ></wizard-three-dots>

    <!-- WIZARD CARD COMPONENTS -->
    <wizard-intro
      v-if="currentScreen.tag === 'wizard-intro'"
      :current-screen="currentScreen"></wizard-intro>
    <wizard-who-am-i
      v-if="currentScreen.tag === 'wizard-who-am-i'"
      :current-screen="currentScreen"
      :index='index'
      :previous-screens='previousScreens'
      :bread-crumbs='breadCrumbs'
      :roles='roles'
      :role='role'
      :years='years'
      :year='year'></wizard-who-am-i>
    <wizard-choose-one
      v-if="currentScreen.tag === 'wizard-choose-one'"
      :current-screen="currentScreen"
      :index='index'
      :previous-screens='previousScreens'
      :bread-crumbs='breadCrumbs'
      :role='role'
      :year='year'
      :options='currentOptions'
      :wizard-settings='settings'
      ></wizard-choose-one>
    <wizard-choose-category
      v-if="currentScreen.tag === 'wizard-choose-category'"
      :current-screen="currentScreen"
      :index='index'
      :previous-screens='previousScreens'
      :bread-crumbs='breadCrumbs'
      :role='role'
      :year='year'
      :options='currentOptions'
      :categories='categories'
      :wizard-settings='settings'></wizard-choose-category>
    <wizard-address-entry
      v-if="currentScreen.tag === 'wizard-address-entry'"
      :current-screen="currentScreen"
      :index='index'
      :previous-screens='previousScreens'
      :address='address'
      :home='home'
      :settings='currentOptions'
      :sorts='sorts'
      :wizard-settings='settings'></wizard-address-entry>
    <wizard-select-options
      v-if="currentScreen.tag === 'wizard-select-options'"
      :current-screen="currentScreen"
      :index='index'
      :previous-screens='previousScreens'
      :role='role'
      :options='currentOptions'
      :sorts='sorts'
      :wizard-settings='settings'></wizard-select-options>
    <wizard-found-schools
      v-if="currentScreen.tag === 'wizard-found-schools'"
      :current-screen="currentScreen"
      :index='index'
      :previous-screens='previousScreens'
      :role='role'
      :wizard-settings='settings'></wizard-found-schools>
    <wizard-to-find
      v-if="currentScreen.tag === 'wizard-to-find'"
      :current-screen="currentScreen"></wizard-to-find>
  </modal-component>
</template>
<script type='text/javascript'>
  var WizardMixin = require('../../mixins/WizardMixin');
  import getDataByProperty from 'tembo-js/getDataByProperty';

  module.exports = {
    props: ['show', 'settings', 'addressEntry', 'address', 'home', 'filters', 'sorts'],
    mixins: [WizardMixin],
    data: function data() {
      return {
        index: -1,
        role: null,
        year: null,
        previousScreens: [],
        currentScreen: null,
        breadCrumbs: [],
        repeats: 0,
        screens: null,
        roles: [],
        years: [],
        categories: []
      };
    },
    computed: {
      currentOptions: function currentOptions() {
        var key;
        var opts;
        if (!this.screens) return null;
        if (!this.currentScreen.options) return null;
        if (typeof this.currentScreen.options === 'object') {
          opts = this.currentScreen.options;
        } else {
          key = this.currentScreen.options;
          opts = getDataByProperty(key, this);
        }
        return opts;
      }
    },
    methods: {
      resetWizard: function resetWizard() {
        var screens = this.settings.screens;
        var categories = this.copy(this.settings.categories);
        screens = this.buildDetailScreens(screens, categories);
        this.index = 0;
        this.repeats = 0;
        this.role = null;
        this.year = null;
        this.previousScreens = [];
        this.breadCrumbs = [];
        this.currentScreen = screens.whoAmI;
        this.categories = categories;
        this.screens = screens;
      },
      closeWizard: function closeWizard() {
        this.show = false;
      },
      advanceTo: function advanceTo(cardKey) {
        this.currentScreenKey = cardKey;
      },
      goToLast: function goToLast() {
        this.currentIndex = this.screens.length - 1;
      },
      resetFilterGroup: function resetFilterGroup(groupKey) {
        var i;
        var l;
        var val;
        var filter = this.filters[groupKey];
        if (Array.isArray(filter.value)) {
          for (i = 0, l = filter.value.length; i < l; i ++) {
            val = filter.value[i];
            val.required = filter.metadata.default_val;
          }
        } else {
          filter.value = filter.metadata.default_val;
        }
      },
      buildDetailScreens: function buildDetailScreens(screens, categories) {
        var i;
        var l;
        var opt;
        var updatedScreens = screens;
        if (!updatedScreens) updatedScreens = {};
        for (i = 0, l = categories.length; i < l; i ++) {
          opt = categories[i];
          updatedScreens[opt.value] = {
            tag: opt.tag,
            options: opt.options,
            sortby: opt.sortby,
            nextText: this.settings.labels.next_text,
            submitText: this.settings.labels.submit_text,
            goTo: 'foundSchools',
            prompt: this.settings.labels.multi_select_text
          };
        }
        return updatedScreens;
      }
    },
    mounted: function mounted() { // MAY NEED TO USE NEXTTICK
      var screens;
      var categories;
      screens = this.settings.screens;
      categories = this.copy(this.settings.categories);
      screens = this.buildDetailScreens(screens, categories);
      this.currentScreen = screens.intro;
      this.roles = this.settings.roles;
      this.years = this.settings.years;
      this.categories = categories;
      this.screens = screens;
    },
    events: {
      Close: function Close(completed) {
        if (completed) this.$dispatch('viewUpdated', 'list');
        this.closeWizard();
      },
      GoTo: function GoTo(key) {
        if (this.index + 1 === this.previousScreens.length) {
          this.currentScreen = this.screens[key];
          this.index += 1;
        } else {
          this.currentScreen = this.previousScreens[this.index + 1];
          this.index += 1;
        }
      },
      GoToCrumb: function GoToCrumb(idx) {
        this.currentScreen = this.previousScreens[idx];
        this.index = idx;
        this.repeats = this.getRepeats(idx - 1);
      },
      ClearFilter: function ClearFilter(key) {
        this.resetFilterGroup(key);
      }
    },
    watch: {
      show: function show() {
        if (this.show) {
          // when wizard opens, reset the filters and clear address
          this.$dispatch('ResetAllFilters', true);  // true = apply default selections to filters
          this.$dispatch('ClearAddress');
        } else {
          // when wizard closes, reset to re-open cleanly
          this.resetWizard();
        }
      }
    }
  };
</script>