<template>
  <div>
    <span v-if="entitiesLoaded">{{countByTypeMessage}}&nbsp</span><!--
    --><span v-if="entitiesLoaded && !countyIsRelevant">{{settings.county_not_relevant}}</span>
    </span>
    <slot name="filter-content"></slot>
  </div>
</template>
<script type="text/javascript">
  var ResultStatusMixin = require('../mixins/ResultStatusMixin');
  module.exports = {
    mixins: [ResultStatusMixin],
    computed: {
      countByTypeMessage: function countByTypeMessage() {
        const entityType = this.filterRequirement('entity_type');
        if (!this.countMessage) return '';
        return this.countMessage.replace('<entity_type>', entityType);
      },
      countyIsRelevant: function countyIsRelevant() {
        if (this.filterRequirement('county_name')) return true;
        return false;
      },
      typeIsRelevant: function typeIsRelevant() {
        if (this.filterRequirement('entity_type')) return true;
        return false;
      }
    }
  };
</script>