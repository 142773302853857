<template>
  <div>
    <span v-if="entitiesLoaded">{{countByFilters}}</span>
  </div>
</template>
<script type="text/javascript">
  var ResultStatusMixin = require('../mixins/ResultStatusMixin');
  module.exports = {
    mixins: [ResultStatusMixin],
    computed: {
      countByFilters: function countByFilters() {
        if (!this.countMessage) return '';
        let message = this.countMessage;
        for (let i = 0, l = this.settings.filters.length; i < l; i ++) {
          const filterKey = this.settings.filters[i];
          const filterValue = this.filterRequirement(filterKey);
          message = message.replace(`[${filterKey}]`, filterValue);
        }
        return message;
      }
    }
  };
</script>