<template>
  <div>
    <button
      class="c-button font-weight-medium"
      :class="!settings.disable_allowed || pendingActionsAvailable ? '' : 'c-button--disabled'"
      :disabled="!settings.disable_allowed || pendingActionsAvailable > 0 ? false : true"
      v-on:click="applyFilters"
    ><i class="far fa-sliders-h mr-2 d-none d-md-inline-flex"></i><span>{{settings.button_text}}</span></button>
  </div>
</template>
<script type="text/javascript">
import sendEvent from 'tembo-js/sendEvent';

module.exports = {
  props: ['settings', 'layout'],
  computed: {
    pendingActionsAvailable() {
      return this.$store.state.pendingFilters.length > 0 ||
        this.$store.state.pendingAddress.length > 0;
    },
  },
  methods: {
    applyFilters() {
      this.$emit('close');
      this.$store.dispatch('applyPendingFilters')
      .then(() => this.$store.dispatch('applyPendingAddress'));
      // send google analytics event
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_filter` : 'filter';
      sendEvent({
        category: category,
        action: 'apply_filters',
      });
    },
  },
};
</script>
