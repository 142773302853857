<template>
  <div>
    <div class="entity-table--placeholder">
      <span class="loading"
        :class="noAnimation ? 'no-animation' : ''"
        :style="{ width: getWidth(30, 50) }"
      ></span>
    </div>
    <div class="entity-table--placeholder">
      <span class="loading"
        :class="noAnimation ? 'no-animation' : ''"
        :style="{ width: getWidth(40, 80) }"
      ></span>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['noAnimation', 'idx'],
    methods: {
      getWidth: function getWidth(min, max) {
        return `${Math.floor(Math.random() * (max - min)) + min}%`;
      }
    }
  };
</script>