<template>
  <div v-if="settingsForApp && staticData.config && $store.state.isLoaded" class="school-finder-app">
    <!-- props removed => show-intro, auth, save-after-login, user-sort -->
    <div :is="staticData.config.layout.app.tag"
      :defaults="staticData.defaults"
      :layout="staticData.config.layout"
      :settings="settingsForApp"
      :compares="$store.state.compares"
      :favorites="$store.state.favorites"
      :address="$store.state.address"
      :entities="$store.state.entities"
    ></div>
  </div>
</template>
<script type="text/javascript">
  var Promise = require('bluebird');
  import PageMixin from './mixins/PageMixin';

  module.exports = {
    mixins: [PageMixin],
    computed: {
      settingsForApp() {
        if (!this.$store.state.isLoaded) return null;
        const filters = this.$store.state.filters;
        if (!this.staticData.settings) return null;
        const settings = JSON.parse(JSON.stringify(this.staticData.settings));
        settings.filters = filters;
        return settings;
      }
    },
    methods: {
      getAllAuthData: function getAllAuthData() {
        //
        // load all data saved in session
        // as defined by the list in defaults
        //
        var authList;
        var len;
        var i;
        var self = this;
        var getAuthItems;
        try {
          authList = this.defaults.persist.auth;
        } catch (e) {
          if (!authList.length) authList = [];
        }
        if (this.defaults.auth_prefix) {
          getAuthItems = `${this.defaults.auth_prefix}GetAuthItems`;
        } else {
          console.error('To use auth-based persistance, Finder.yaml must include valid defaults.auth_prefix'); // eslint-disable-line
          authList = [];
        }

        return Promise.map(authList, s => self[getAuthItems](s))
        .then((authResults) => {
          var results = {};
          for (i = 0, len = authResults.length; i < len; i ++) {
            results[authList[i]] = authResults[i];
          }
          return results;
        });
      },
      applyAuthData: function applyAuthData(authData) {
        //
        // set all auth data on vue instance
        //
        var keys = Object.keys(authData);
        var i;
        var len = keys.length;
        for (i = 0; i < len; i ++) {
          this[keys[i]] = authData[keys[i]];
        }
      }
    },
    mounted: function mounted() {
      const findView = this.$route.params.findView || null;
      this.$store.dispatch('loadFinder', findView)
      .then(() => {
        const langOptions = this.staticData.config.defaults.language_options;
        let lang;
        if (this.$route.query && this.$route.query.lang) {
          lang = this.$route.query.lang;
        }
        const query = this.$route.query;
        if (lang === langOptions.default_value) {
          // reroute ?lang=<default> to /
          delete query.lang;
          this.$router.push({ name: 'find', query: query });
        } else if (langOptions.options.indexOf(lang) === -1) {
          // reroute ?lang=<invalid_option> to /
          delete query.lang;
          this.$router.push({ name: 'find', query: query });
        }
      });
    },
  };
</script>
