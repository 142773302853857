<template>
  <modal-component :click-away="true" :show="show" class="table-modal">
    <div class="modal-heading c-modal__head">
      <span class="c-modal__title heading-target">{{getEntityData(header)}}</span>
       <button v-on:click="closeModal" class=" c-modal__close modal_close c-button--mobile "><i class="fal fa-times" aria-hidden="true"></i><span class="sr-only">Close</span></button>
    </div>
    <div class="modal-content table-list">
      <div class="sp-v-3">
        <div v-for="item in nonHeaders">
          <h2>{{item.col_header}}</h2>
          <p :is="item.tag" :entityData="getEntityData(item)"></p>
        </div>
      </div>
      <table class="result-table d-none">
        <tr v-for="item in nonHeaders">
          <th scope="row">{{item.col_header}}</th>
          <td
            :is="item.tag"
            :entityData="getEntityData(item)"
          ></td>
        </tr>
      </table>
    </div>
    <div class="modal_footer c-modal__footer mt-auto">
      <div class="button-group">
        <a class="c-button w-100 button button--full" :href="entityLink" v-on:click="sendLinkClickEvent">{{settings.mobile_view_profile_text}}</a>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  var utils = require('../../store/utils');
  var ModalMixin = require('../../mixins/ModalMixin');
  var TableMixin = require('../../mixins/TableMixin');
  import ModalComponent from './Modal.vue';
  import getDataByProperty from 'tembo-js/getDataByProperty';

  module.exports = {
    props: ['settings', 'show'],
    components: { ModalComponent },
    mixins: [ModalMixin, TableMixin],
    data: function data() {
      return {
        entity: null
      };
    },
    created: function created() {
      this.entity = this.$store.state.entities[this.settings.dynamic];
    },
    computed: {
      header: function header() {
        if (!this.filteredColumns || !this.filteredColumns.length || !this.entity) return null;
        return this.filteredColumns.filter(col => col.is_row_header)[0];
          // .map(col => getDataByProperty(col.entity_prop, this.entity), this)[0];
      },
      entityLink: function entityLink() {
        if (!this.header || !this.entity) return null;
        return this.getEntityLink(this.header.link);
      },
      nonHeaders: function nonHeaders() {
        return this.filteredColumns.filter(col => !col.is_row_header);
      }
    },
    methods: {
      getEntityData: function getEntityData(item) {
        const data = getDataByProperty(item.entity_prop, this.entity);
        if (item.display && item.display === 'range') {
          return utils.displayListAsRange(data);
        }
        return data;
      },
      sendLinkClickEvent: function sendLinkClickEvent() {
        sendEvent({
          category: 'list',
          action: 'mobile_report_link',
          label: this.entity.id
        });
      }
    }
  };
</script>
<style type="text/css" scoped>

</style>
