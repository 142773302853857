<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <div class="multi-dropdown-input-filter filter-group o-two-column-layout__filter-list__group"
    :is="layout && layout.wrapper && layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" 
    :settings="settings" 
    :selection-tally="selectionTally">

    <!-- FILTER TITLE SLOT -->
    <span slot="filter-title">{{settings.group_title_text}}</span>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" 
      v-if="settings.description_text">{{settings.description_text}}</div>

    <!-- FILTER CONTENT SLOT -->
    <div class="filter-content flex-grow-1" slot="filter-content"
      v-on:keydown.down="highlightNext" 
      v-on:keydown.up="highlightPrev" 
      v-on:keydown.enter.stop="selectActive">
      <!-- <span class="tmp search"></span> -->
      <div class="mb-2" 
        v-if="userRequirements.length > 0">
        <span class="c-button c-button--sm req mr-2 mt-2" tabindex="0" 
          v-for="req in userRequirements"
          v-on:keydown.8="unsetAsRequirement(req)"
          v-on:keydown.27="unsetAsRequirement(req)"
          v-on:keydown.46="unsetAsRequirement(req)">
          {{req.text}}
          <span class="req__unset" 
            v-on:click.prevent.stop="unsetAsRequirement(req)">
            <i class="fa fa-close"></i>
          </span>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <label class="c-select__wrapper" tabindex="0"
          v-on:click="showMenu"
          v-on:blur="hideMenu"
          v-on:mouseleave="hideMenu">
          <div class="multi-dropdown-input wrapper c-input" 
            :class="show ? 'c-input--flat-bottom' : ''">
            <span class="sr-only">{{settings.prompt_text}}</span>
            <input class="search c-input__input" type="text"
              v-model="input" 
              v-on:keydown="handleKeyDown($event)" 
              v-on:focus="handleInputFocus"
              :placeholder="settings.prompt_text">
          </div>
          <div class="menu c-input__list"
            :class="show ? 'c-input__list--visible px-2 c-input__list--round-bottom' : 'c-input__list--hidden'"
            v-on:blur="hideMenu">
            <div class="option c-input__list__item py-2" 
              :class="index === highlightIdx ? 'highlight' : ''"
              v-for="(option, index) in filteredOptions" 
              v-on:click.stop="setAsRequirement(option)">{{option.text}}</div>
            <div class="option disabled c-input__list__item c-input__list__item--disabled text-center py-3"
              v-if="filteredOptions.length == 0">{{settings.no_results_text}}</div>
          </div>
        </label>
        <!-- Clear button -->
        <div class="filter-clear ml-2 flex-shrink-0" slot="filter-clear">
          <a class="a filter__clear" href="#" 
            :class="requirements.length ? '' : 'a--disabled'" 
            v-on:focus="hideMenu"
            v-on:keydown.enter.stop="clearThisFilter(false)"
            v-on:click.prevent.stop="clearThisFilter(false)">{{settings.clear_one_filter_text}}</a>
        </div>
      </div>      
    </div>
  </div>
</template>
<script type="text/javascript">
  const $ = require('jquery');
  import debounce from 'lodash/debounce';
  import isEqual from 'lodash/isEqual';
  const FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    mixins: [FilterGroupMixin],
    data: function data() {
      return {
        searchBy: '',
        show: false,
        highlightIdx: 0,
        userRequirements: [],
        focus: false,
        timeout: null
      };
    },
    mounted: function mounted() {
      this.userRequirements = this.requirements;
    },
    computed: {
      input: {
        get: function get() {
          return this.searchBy;
        },
        set: function set(newval) {
          this.handleInput(newval);
        }
      },
      filteredOptions: function filteredOptions() {
        return this.filteredValues.filter(function f(v) {
          var includesSearchTerm;
          // always search in `text` field, since that is what is visible to the user
          var optionVal = v.text.toLowerCase();
          var searchVal = this.searchBy.toLowerCase();
          includesSearchTerm = optionVal.indexOf(searchVal) > -1;
          return includesSearchTerm && !v.required;
        }, this);
      },
      requirements: function requirements() {
        return this.filteredValues.filter(v => v.required);
      }
    },
    methods: {
      handleInput: debounce(function handleInput(val) {
        this.searchBy = val;
      }, 300),
      hideMenu: function hideMenu() {
        $(this.$el).find('input.search').blur();
        this.show = false;
        this.focus = false;
      },
      toggleMenu: function toggleMenu() {
        if (this.show) this.hideMenu();
        else this.showMenu();
      },
      showMenu: function showMenu() {
        this.setFocus(); // focus input
        this.show = true; // show meu
        this.focus = true; // for classing wrappr
        this.highlightFirst();
      },
      setFocus: function setFocus() {
        $(this.$el).find('input.search').focus();
      },
      scrollToHighlighted: function scrollToHighlighted() {
        // if the item to be highlighted next is past the bottom of the menu
        // scroll it into place
        const nextItem = $(this.$el).find('.menu .option').eq(this.highlightIdx);
        const nextItemTop = nextItem.position().top;
        const nextItemHeight = nextItem.height();
        const nextItemBottom = nextItemTop + nextItemHeight;
        const menuHeight = $(this.$el).find('.menu').height();
        const menuScroll = $(this.$el).find('.menu').scrollTop();
        const itemMargin = parseInt(nextItem.css('marginTop'), 10);
        const itemPadding = parseInt(nextItem.css('paddingTop'), 10);

        const itemBelow = menuHeight <= nextItemBottom + itemMargin + itemPadding;
        const itemAbove = nextItemTop < 0;

        const scrollTop = menuScroll + nextItemTop;

        if (itemBelow || itemAbove) {
          $(this.$el).find('.menu').animate({ scrollTop: scrollTop }, 200);
        }
      },
      handleKeyDown: function handleKeyDown(ev) {
        var enterKey = 13;
        var downKey = 40;
        var upKey = 38;
        var backKey = 8;
        var delKey = 46;
        var tabKey = 9;
        var eventKey = ev.keyCode;
        if (ev.shiftKey && eventKey === tabKey) {
          this.hideMenu();
        } else if (eventKey === enterKey || eventKey === downKey || eventKey === upKey) {
          // prevent default behavior, so we can use arrow & enter keys
          ev.preventDefault();
        } else if (eventKey === backKey || eventKey === delKey) {
          // remove previous requirement, if user deletes & no input text
          if (eventKey === backKey && !this.searchBy) {
            this.unsetAsRequirement(this.userRequirements[this.userRequirements.length - 1]);
          }
        }
      },
      handleInputFocus: function handleInputFocus() {
        this.showMenu();
      },
      highlightNext: function highlightNext() {
        if (this.highlightIdx < this.filteredOptions.length - 1) {
          this.highlightIdx = this.highlightIdx + 1;
        }
      },
      highlightPrev: function highlightPrev() {
        if (this.highlightIdx > 0) {
          this.highlightIdx = this.highlightIdx - 1;
        }
      },
      highlightFirst: function highlightFirst() {
        this.highlightIdx = 0;
      },
      setAsActive: function setAsActive(index) {
        this.highlightIdx = index;
      },
      selectActive: function selectActive() {
        this.setAsRequirement(this.filteredOptions[this.highlightIdx]);
        $(this.$el).find('.menu').animate({ scrollTop: 0 }, 200);
      },
      setAsRequirement: function setAsRequirement(option) {
        let updatedValue;
        for (let i = 0, l = this.values.length; i < l; i ++) {
          updatedValue = JSON.parse(JSON.stringify(this.values[i]));
          if (!!option.value) {
            if (option.value === 'all') {
              updatedValue.required = true;
            } else if (updatedValue.value === option.value) {
              updatedValue.required = true;
            } else if (this.settings.metadata.selections === 'single') {
              updatedValue.required = false;
            }
            if (!isEqual(updatedValue, this.values[i])) {
              const action = updatedValue.required ? 'select' : 'deselect';
              sendEvent({
                category: 'filter',
                action: `${action}_${this.settings.metadata.label}`,
                label: updatedValue.value
              });
              this.$store.dispatch('updateFilterValue', {
                filterValue: updatedValue,
                updateEntities: true
              });
            }
          }
        }
        if (option.value === 'all') {
          this.userRequirements = [option];
        } else if (this.settings.metadata.selections === 'single') {
          this.userRequirements = [option];
        } else {
          this.userRequirements.push(option);
        }
        this.setFocus();
      },
      unsetAsRequirement: function unsetAsRequirement(option) {
        // set requirements properties
        for (let i = 0, l = this.values.length; i < l; i ++) {
          const updatedValue = JSON.parse(JSON.stringify(this.values[i]));
          if (option.value === 'all') {
            updatedValue.required = false;
          } else if (updatedValue.value === option.value) {
            updatedValue.required = false; // eslint-disable-line no-param-reassign
          }
          if (!isEqual(updatedValue, this.values[i])) {
            const action = updatedValue.required ? 'select' : 'deselect';
            sendEvent({
              category: 'filter',
              action: `${action}_${this.settings.metadata.label}`,
              label: updatedValue.value
            });
            this.$store.dispatch('updateFilterValue', {
              filterValue: updatedValue,
              updateEntities: true
            });
          }
        }
        this.setFocus();
      },
      removeFromRequirementList: function removeFromRequirementList(option) {
        let found = false;
        const l = this.userRequirements.length;
        let i = 0;
        // update list of selected requirements
        while (!found && i < l) {
          if (this.userRequirements[i].value === option.value) {
            this.userRequirements = this.userRequirements
              .slice(0, i)
              .concat(this.userRequirements.slice(i + 1));
            found = true;
          }
          i += 1;
        }
      },
      clearInput: function clearInput() {
        this.searchBy = '';
      }
    },
    watch: {
      highlightIdx: function highlightIdx() {
        this.scrollToHighlighted();
      },
      filteredOptions: {
        handler: function watchFiltered() {
          this.highlightFirst();
        },
        deep: true
      },
      requirements: {
        handler: function watchRequirements() {
          //
          // for updating user requirements when filter
          // is cleared in a single operation
          //
          var i;
          let l = this.userRequirements.length; // count of requirements displayed
          var valIsRequired;

          // clear input if requirements list is emtpy
          if (this.requirements.length === 0) this.clearInput();

          for (i = 0; i < l; i ++) {
            valIsRequired = this.requirements.filter(function f(v) { // eslint-disable-line
              return v.value === this.userRequirements[i].value;
            }, this).length > 0;
            if (!valIsRequired) {
              this.removeFromRequirementList(this.userRequirements[i]);
              i -= 1;
              l -= 1;
            }
          }
        },
        deep: true
      }
    }
  };
</script>
<style type="text/css" scoped>
  .highlight {font-weight: bold;}
  .c-select__wrapper:focus, 
  .filter-content:focus {outline: none;}
  .c-input--flat-bottom {border-bottom: 1px solid hsla(0, 0%, 0%, 0);}
</style>
