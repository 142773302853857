<template>
  <span>{{rangeText}}</span>
</template>
<script type="text/javascript">
  var utils = require('../store/utils');

  module.exports = {
    props: ['entityData', 'universe'],
    computed: {
      rangeText: function rangeText() {
        if (!this.entityData) return null;
        return utils.displayListAsRange(this.entityData, this.universe);
      }
    }
  };
</script>