<template>
  <a v-if="entityLink" :href="entityLink" :target="entityLink.indexOf('http') > -1 ? '_blank' : ''" v-on:click="sendLinkClickEvent">{{entityData}}</a>
  <span v-else >{{entityData}}</span>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: ['entityData', 'entityLink', 'entityId'],
    methods: {
      sendLinkClickEvent() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_list` : 'list';
        sendEvent({
          category: category,
          action: 'report_link',
          label: this.entityId
        });
      }
    }
  };
</script>