// currently only used in temob-find/.../MapFilter.vue

function slugify(string) {
  if (typeof string !== 'string') {
    throw new Error('can only slugify strings');
  }
  return string.toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/_+/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export default slugify;
