<!-- this component currently supports display/search by for a SINGLE field -->
<!-- can be extended later -->
<!-- each option requires a text attribute, others are optional depending on the needs of the parent -->
<template>
  <div>
  <label class="c-input"
    data-has-options="true"
    v-on:keydown.40.prevent="highlightNext"
    v-on:keydown.38.prevent="highlightPrev"
    v-on:keydown.13="selectActive()"
    :class="{'c-input--lg w-100': hero, 'c-input--open' : menuVisible && searchResults.length > 0, 'c-input--error': searchResults.length === 0 && searchText.length > 0  }"
    :title="placeholder"
    >
    <span class="sr-only">{{placeholder}}</span>
    <div class="w-100">


      <div class="c-input__value">

        <i class="fas fa-search c-input__icon--search"></i>

      <input
        type="text"
        v-model="input"
        :placeholder="placeholder || '[Enter your school\'s name]'"
        v-on:blur="hideMenuTimeout"
        v-on:focus="handleInput"

        class="c-input__input"
      >
      <i class="fas fa-times c-input__icon--clear "
      :class="input ? 'd-inline-block' : 'd-none'"
      v-on:click="input = ''"></i>
      </div>


      <!-- dropdown options -->
      <div
        class="c-input__options"
        :class="hero ? 'p-hero__input__options' : ''"
        v-show="menuVisible && searchResults.length"
        v-on:mouseover="cancelTimeout">
        <slot name="option-item"
          :search-results="searchResults"
          :highlight-index="highlightIndex"
          :get-entity-url="getEntityUrl"
          :select-active="selectActive"
        ></slot>
      </div>

   </div>
  </label>
   <p v-if="searchResults.length === 0 && searchText.length > 0 && noResultsHtml" class="mt-2 c-input__error-message" :class="hero ? 'p-hero__input__error position-absolute' : ''">
      <i class="fa fa-warning"></i>
      <span class="ml-1" v-html="noResultsHtml"></span>
    </p>
</div>
</template>
<script type="text/javascript">
  import debounce from 'lodash/debounce';
  import $ from 'jquery';
  import utils from '../store/utils';
  import sendEvent from 'tembo-js/sendEvent';
  import replaceSpecialCharacters from 'tembo-js/replaceSpecialCharacters';

  module.exports = {
    props: {
      placeholder: String,
      options: Array,
      enterKeyAction: {
        type: String,
        required: false,
      },
      linkTemplate: [String, Object],
      useOptionFocus: Boolean,
      noResults: {
        type: Object,
        default: () => ({
          text: 'No results match your search',
        }),
      },
      hero: {
        type: Boolean,
        default: false
      },
      dropdownType: {
        validator(value) {
          return ['home_school_search'].indexOf(value) !== -1;
        }
      }
    },
    data() {
      return {
        input: '', // value bound to input
        searchText: '', // value used for filtering options, so we can use a computed property
        menuVisible: false,
        highlightIndex: 0,
        timeout: null,
        prevent: false,
      };
    },
    mounted() {
      const self = this;
      const menu = $(this.$el).find('.c-input__options');
      if (this.useOptionFocus) {
        const menuOptions = menu.find('.c-input__option');
        $(menuOptions).each((index, option) => {
          $(option).focus(() => {
            self.cancelTimeout();
          });
          $(option).blur(() => {
            self.hideMenuTimeout();
          });
        });
      }
      $(menu).scroll(() => {
        self.cancelTimeout();
      });
    },
    computed: {
      searchResults() {
        const searchText = replaceSpecialCharacters(this.searchText.toLowerCase());
        if (!searchText) return [];
        return this.options
          .filter(option => {
            if (!option || !option.text) return false;
            const optionValue = replaceSpecialCharacters(option.text.toLowerCase());
            const passesFilter = optionValue.indexOf(searchText) > -1;
            return passesFilter;
          });
      },
      noResultsHtml() {
        const originalText = this.noResults.text;
        let replacements;

        if (this.noResults.linked_text && this.noResults.href) {
          replacements = [{ text: this.noResults.linked_text, href: this.noResults.href }];
        } else if (this.noResults.replacements && Array.isArray(this.noResults.replacements)) {
          replacements = this.noResults.replacements;
        } else {
          replacements = [];
        }

        return utils.linkedTextHtml({
          originalText: originalText,
          replacements: replacements,
        });
      },
    },
    methods: {
      hideMenuTimeout() {
        const self = this;
        this.timeout = setTimeout(() => {
          self.highlightIndex = -1;
          self.menuVisible = false;
        }, 500);
      },
      cancelTimeout() {
        // when mouse is over the menu (to select an option via click)
        // prevent the menu from hiding, so we can make a selection
        clearTimeout(this.timeout);
      },
      selectActive(item) {
        let activeItem = item;
        if (!item) {
          activeItem = this.searchResults[this.highlightIndex];
        }
        let action = this.enterKeyAction;
        if (!this.hasOwnProperty(action)) action = this.selectResult;
        else action = this[action];
        action(activeItem);
      },
      selectResult(option) {
        this.prevent = true;
        this.input = option.text;
        this.$emit('update:selected', option);
        $(this.$el).find('input').blur();
        this.menuVisible = false;
        this.$nextTick(() => {
          this.prevent = false;
        });
      },
      selectResultClearInput(item) {
        this.prevent = true;
        this.input = '';
        this.$emit('update:selected', item);
        this.menuVisible = false;
        this.$nextTick(() => {
          this.prevent = false;
        });
      },
      navigate(option) {
        const url = this.getEntityUrl(option);
        window.location.href = url;
      },
      getEntityUrl(entity) {
        if (!entity) return '';
        return utils.getEntityUrl(entity, this, this.linkTemplate);
      },
      handleInput: debounce(function handleInput() {
        const input = this.input;
        if (input.length) {
          this.menuVisible = true;
          this.highlightIndex = 0;
          this.searchText = input;
          if (this.dropdownType === 'home_school_search') {
            sendEvent({
              category: 'find_homepage',
              action: 'name_search',
              label: this.searchText,
            });
          }
        } else {
          this.menuVisible = false;
          this.searchText = '';
          this.highlightIndex = -1;
          this.$emit('update:selected', null);
        }
      }, 300),
      highlightNext() {
        if (this.highlightIndex < this.searchResults.length - 1) {
          this.highlightIndex += 1;
        }
      },
      highlightPrev() {
        if (this.highlightIndex > 0) {
          this.highlightIndex -= 1;
        }
      },
      scrollToHighlighted: function scrollToHighlighted() {
        if (this.searchResults.length < 1) return;

        // if the item to be highlighted next is past the bottom of the menu
        // scroll it into place
        const menu = $(this.$el).find('.c-input__options');
        const nextItem = menu.find('.c-input__option').eq(this.highlightIndex);
        const nextItemTop = nextItem.position().top;
        const nextItemHeight = nextItem.height();
        const nextItemBottom = nextItemTop + nextItemHeight;
        const menuHeight = menu.height();
        const menuScroll = menu.scrollTop();
        const itemMargin = parseInt(nextItem.css('marginTop'), 10);
        const itemPadding = parseInt(nextItem.css('paddingTop'), 10);

        const itemBelow = menuHeight <= nextItemBottom + itemMargin + itemPadding;
        const itemAbove = nextItemTop < 0;

        const scrollTop = menuScroll + nextItemTop;

        if (itemBelow || itemAbove) {
          $('.c-input__options').animate({ scrollTop: scrollTop }, 200);
        }
      },
      focusHighlighted() {
        const menu = $(this.$el).find('.c-input__options');
        const item = menu.find('.c-input__option').eq(this.highlightIndex);
        $(item).focus();
      },
      getInputClass() {
        const classList = [];
        if (this.hero) classList.push('p-hero__input w-100');
        else classList.push('c-input__value');
        if (this.menuVisible && this.searchResults.length) classList.push('u-no-br-bottom');
        return classList.join(' ');
      },
    },
    watch: {
      highlightIndex: function highlightIndex(curr) {
        //
        // when highlight index changes
        // scroll to highlighted item
        // focus the highlighted item (if appropriate)
        // cancel any timeouts that would close the menu
        //
        if (curr > -1) {
          // use next tick in cases of long lists
          // where the menu has been triggered,
          // but not loaded into the DOM yet
          this.$nextTick(() => {
            this.scrollToHighlighted();
            if (this.useOptionFocus) {
              this.focusHighlighted();
            }
          });
          this.cancelTimeout();
        }
      },
      menuVisible: function menuVisible(curr) {
        if (curr) {
          const menu = $(this.$el).find('.c-input__options');
          $(menu).animate({ scrollTop: 0 }, 300);
        }
      },
      input: function searchText() {
        // use a watcher here
        // this runs before the nextTick in selectResult
        // so it will accurately prevent showing results
        // from making a selection in the dropdown menu
        if (!this.prevent) {
          this.handleInput();
        }
      }
    }
  };
</script>
