<!-- this component currently supports display/search by for a SINGLE field -->
<!-- can be extended later -->
<!-- each option requires a text attribute, others are optional depending on the needs of the parent -->
<template>
  <typeahead-dropdown
    :options="options"
    :placeholder="placeholder"
    :enter-key-action="'navigate'"
    :use-option-focus="false"
    :no-results="noResults"
    :link-template="linkTemplate"
    :hero="hero"
    :dropdown-type="dropdownType">

    <template slot="option-item" slot-scope="slotProps">
      <a
        v-for="(option, index) in slotProps.searchResults"
        tabindex="0"
        class="c-select__option  c-input__option p-hero__input__option d-block"
        :class="(index === slotProps.highlightIndex ? 'c-select__option--current-selection c-input__option--current-selection' : '') + (option.class ? ' ' + option.class : '')"
        :href="slotProps.getEntityUrl(option)"
      ><div>{{option.text}}</div><p v-for="item in option.subtext" class="" style="font-size: 14px; line-height: 18px;">{{item}}</p></a>
    </template>
  </typeahead-dropdown>
</template>
<script type="text/javascript">
  import TypeaheadDropdown from './TypeaheadDropdown.vue';

  module.exports = {
    components: {
      TypeaheadDropdown,
    },
    props: {
      placeholder: String,
      options: Array,
      noResults: Object,
      linkTemplate: [String, Object],
      hero: Boolean,
      dropdownType: {
        validator(value) {
          return ['home_school_search'].indexOf(value) !== -1;
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  a {
    text-decoration: none;
    color: inherit;
  }
</style>