<template>
  <div tabindex="-1" aria-hidden="true" id="map-google"  class="d-flex flex-grow-1 flex-shrink-0 flex-column" v-bind:class="settings.metadata.show_map ? 'map-visible' : 'map-hidden'" >

    <div
      id="map-container"
      class="align-content-center align-items-center d-flex flex-grow-1 justify-content-center">
        <slot>
          <span class="fa-stack fa-6x animation--placeholder">
            <i class="fal fa-spinner-third fa-spin fa-stack-2x"></i>
            <i class="fas fa-map-marked-alt fa-stack-1x"></i>
          </span>
        </slot>
    </div>
    <div
      :is="layout.school_item.tag"
      :id="'map-result-item'"
      :settings="layout.school_item.data"
      :entity="mobileSchoolItem"
      @update:entity="val => { mobileSchoolItem = val }"
      :idx="mobileSchoolIndex"
      :x="true"
    ></div>
  </div>
</template>
<script>
  const MapMixin = require('../mixins/MapMixin');
  module.exports = {
    mixins: [MapMixin]
  };
</script>
