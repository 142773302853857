<template>
  <div class="row pt-40 px-20">
    <div class="col-3">
      <h2 class="mb-12 h2--compare">{{settings.title}}</h2>
      <p class="compare-data">{{settings.description}}</p>
    </div>

    <div v-for="entity in compareEntities"
    :class="'col-sm-' + Math.floor(9 / compareEntities.length)">
      <!-- comparison component -->
      <component
        :is="categoryComponent || 'compare-data'"
        :settings="settings"
        :entity="entity">
      </component>
    </div>
    <div v-if="!isLast" class="col-12 mx-n20">
      <hr class="mt-40 mb-0">
    </div>
  </div>
</template>

<script>
module.exports = {
  props: ['settings', 'compareEntities', 'categoryComponent', 'isLast']
};
</script>
