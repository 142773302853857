function getNthBearing(i, total) {
  let angle;
  if (total === 2) {
    angle = 360 / total * i;
  } else {
    const addition = 360 / total - 90;
    angle = 360 / total * i + addition;
  }
  const rad = angle * Math.PI / 180;
  return [Math.cos(rad), Math.sin(rad)];
}

export default getNthBearing;
