<template>
	<div class="p-screen-overlay" aria-modal="true" v-if="showOverlay" v-on:keydown.27="closeOverlay">
    <div class="container">

      <div class="row flex-row-reverse">
        <div class="col-auto ml-auto">
          <button id="closeOverlay" class="c-link c-link--utility p-screen-overlay__close" 
            v-on:click="closeOverlay()" 
            v-on:keydown="stopShiftTab"><i class="fas fa-times"></i><span class=" ml-2 d-none d-md-inline">{{closeText}}</span></button>
        </div>
        <div class="col-auto d-flex" v-if="secondaryAction">
          <button class="c-link c-link--utility my-auto" 
            v-on:click="secondaryActionEmit()"><i class="fas fa-chevron-left mr-2"></i>{{secondaryAction}}</button>
        </div>
      </div>
    </div>

    <slot></slot>
    <label aria-hidden="true" tabindex="0" v-on:focus="backToFirst()" for="closeOverlay" class="hidden">back to close</label>
  </div>
</template>

<style scoped>
  .hidden {
    height: 0;
    width: 0;
    overflow: hidden;
  }
</style>

<script>

export default {
  props: ['closeText', 'showOverlay', 'secondaryAction'],
  data() {
    return {
      id: null,
    };
  },
  watch: {
    showOverlay: function showOverlay() {
      if (this.showOverlay) {
        document.body.classList.add('unscroll');
      } else {
        document.body.classList.remove('unscroll');
      }
    }
  },
  methods: {
    closeOverlay: function closeOverlay() {
      this.$emit('closeOverlay');
    },
    secondaryActionEmit: function secondaryActionEmit() {
      this.$emit('secondaryAction');
    },
    backToFirst: function backToFirst() {
      if (this.showOverlay) {
        const container = this.$el && this.$el.querySelector('#closeOverlay');
        if (container) { container.focus(); }
      }
    },
    stopShiftTab: function stopShiftTab(e) {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
      }
    }
  },
  mounted() {
    this.id = 'p-screen-overlay-' + this._uid; // eslint-disable-line
  },
  updated() {
    function keyup(ev) {
      const escKey = 27;
      // only allow esc key to close if clickaway property set to true
      if (this.showOverlay && ev.keyCode === escKey) {
        this.closeOverlay();
      }
    }

    if (this.showOverlay) {
      const container = this.$el && this.$el.querySelector('#closeOverlay');
      document.addEventListener('keyup', keyup.bind(this));
      if (container) { container.focus(); }
    }
  },
};
</script>
