import isZero from './isZero';

function convertToNumberIfPossible(val, def) {
  if (typeof val === 'number') return val;
  if (typeof val !== 'string') {
    if (typeof def !== 'undefined') return def;
    return val;
  }
  // do not convert empty strings to 0
  if (val === '') return val;
  const numericVal = Number(val);
  if (numericVal || isZero(val)) return numericVal;
  return val;
}

export default convertToNumberIfPossible;
