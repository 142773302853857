<template>
  <modal-component class="intro-modal modal--fullscreen p-0 c-modal--full-screen c-modal__mask" :show="show" :click-away="false" :class="settings.show_header_in_intro_modal  ? 'intro-modal--has-header' : ''" :container-class="'c-modal--cta'">

    <div class="mobile-background-wrapper flex-grow-1 flex-shrink-1 d-flex flex-column" style="min-height: 1px;">
    <header class="header mb-1 mb-md-4 header p-header--static p-0 p-header flex-shrink-0" v-if="settings.show_header_in_intro_modal" style="min-height: 1px;">
      <div
        :is="layout.header.tag"
        :settings="layout.header.data"
        :layout="layout.header.layout"
        :class="layout.header.tag"
        :headerType="'containerWidth'"
        :hideCompare="true"
      ></div>
    </header>
    <div class="container mb-4 flex-shrink-0" style="min-height: 1px;">
      <div class="row">
        <div class="col-12 col-xl-7 offset-xl-1 col-lg-8  d-flex flex-column ">
          <div class=" d-flex align-items-center">
            <h1 class="flex-grow-1 my-2 my-md-5 c-modal--cta__title d-none d-md-block" style="line-height: 1.3;">{{settings.welcome_text}}</h1>
            <h1 class="flex-grow-1 my-2 my-md-5 c-modal--cta__title d-inline-block d-md-none" style="line-height: 28px; font-size: 20px;">{{settings.welcome_text}}</h1>
            <img class="d-lg-none m-0 mobile-image-clip " style="max-height: 30vw !important;" :src="settings.welcome_image_url_mobile" :alt="settings.welcome_image_alt_text || ''">
          </div>
          <p class="flex-order-3 flex-md-order-2 mt-4 mt-md-0 c-modal--cta__p ">{{settings.long_text}}</p>
          <div class="row flex-order-2 flex-md-order-3 mt-2 mt-md-5">
            <div class="col-12 col-lg-10">
              <div style="position: relative; height: 39px;">
                <div
                v-if="layout.name_search"
                :is="layout.name_search.tag"
                :settings="layout.name_search.data"
                :layout="layout.name_search.layout"
                class="highlight-container"
                ></div>
              </div>
              <strong class="text-center d-block mt-4">{{settings.or_text}}</strong>
              <p class="text-center mt-4">{{settings.main_prompt_text}}</p>
              <div class="row mt-1 mt-md-4  d-flex d-flex--row flex-md-row flex-column">
                <div class="col-12 col-md-6">
                  <div
                  v-if="layout.entity_filter"
                  :is="layout.entity_filter.tag"
                  :settings="layout.entity_filter.data"
                  :layout="layout.entity_filter.layout"
                  class=""
                  style="height: 44px;"

                ></div></div>

                <div class="col-12 mt-4 mt-md-0 col-md-6"><button class="c-button w-100" :class="btnActive" v-on:click="handleViewSchoolsClick">{{settings.view_schools_text}}</button></div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg col-xl-3 d-lg-flex d-none flex-column justify-content-center">
          <img class="img--responsive" :src="settings.welcome_image_url_desktop" :alt="settings.welcome_image_alt_text || ''">
        </div>
      </div>

      <div class="row row-eq-height--above-sm d-none">
        <div class="col-md-6 col-md-offset-3 center-contents">
          <div class="mobile-cta mobile-only d-sm-none">
            <div tabindex="0" v-on:keyup.13="handleViewSchoolsClick" class="button c-button" :class="btnActive" v-on:click="handleViewSchoolsClick">{{settings.view_schools_text}}</div>
          </div>
        </div>
      </div>
    </div>

      <div
        v-if="layout.footer"
        :is="layout.footer.tag"
        :settings="layout.footer.data"
        :layout="layout.footer.layout"
        :class="layout.footer.tag"
        :footerType="'containerWidth'"
        class="mt-auto"

      ></div>

    </div>
  </modal-component>
</template>
<script type="text/javascript">
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';
  import sendEvent from 'tembo-js/sendEvent';

  module.exports = {
    props: ['settings', 'layout', 'show'],
    components: { ModalComponent },
    mixins: [ModalMixin],
    mounted: function mounted() {
      //
      // hide this modal automatically if the requirements are already satisfied
      //
      var show;
      if (this.filterIsSatisfied) {
        show = false;
      } else {
        document.body.className += 'unscroll';
        show = true;
      }
      if (show !== this.show) {
        this.$emit('update:show', show);
      }
    },
    computed: {
      entities: function entities() {
        if (!this.$store.getters.entitySet) return [];
        return this.$store.getters.entitySet;
      },
      filter: function filter() {
        return this.$store.getters.filterSet[this.settings.filter_key];
      },
      filterIsSatisfied: function filterIsSatisfied() {
        var filterSatisfied = false;
        var i;
        var l;
        var filter;

        if (filterSatisfied === true) {
          return filterSatisfied;
        }

        filter = this.filter;
        for (i = 0, l = filter.value.length; i < l; i ++) {
          if (filter.value[i].required) {
            filterSatisfied = true;
          }
        }
        return filterSatisfied;
      },
      btnActive: function btnActive() {
        return this.filterIsSatisfied ? '' : 'c-button--disabled c-button--outline';
      }
    },
    methods: {
      handleViewSchoolsClick: function handleViewSchoolsClick() {
        if (this.filterIsSatisfied) {
          document.body.classList.remove('unscroll');
          this.closeModal();
          sendEvent({
            category: 'intro',
            action: 'view_schools'
          });
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  .alert{
    color: red;
  }
  .row + .row{
    /* margin-top: 20px; */
  }
  .linked-dropdown-menu.highlight-container{

/*     background: white;
    border:  none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.1); */
  }
  .linked-dropdown-menu.highlight-container .search-wrapper.search-container-light{
    /* border:  none; */


  }
/*   .button-padding{
    margin-left:  20px;
    padding: 10px 40px ;
    white-space: nowrap;
    display: inline-block;
        text-align: center;
        border-radius: 8px;

  } */
  strong,
  strong + div{
    /* margin-top:  1em; */
  }

</style>
