
function overlappingArraysCount(arr1, arr2) {
  //
  // does not account for duplicates in one or both arrays
  //
  let ct = 0;
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    throw new Error('overlappingArraysCount expects two arrays');
  }
  for (let i = 0, len = arr1.length; i < len; i++) {
    if (arr2.indexOf(arr1[i]) > -1) ct += 1;
  }
  return ct;
}

export default overlappingArraysCount;
