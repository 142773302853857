<template>
  <div class="" style="background: #fff; padding-top: 10px;">
    <div class="mb-2 px-3">
      <button class="c-link c-link--utility " v-on:click="$emit('update:mobileMapItem', null)"><i class="fa fa-angle-left mr-2"></i>{{settings.back_link_text}}</button>
    </div>
    <hr>
    <div class="px-3">
      <!-- linked entity name -->
      <h2><a v-if="entityUrl" :href="entityUrl" :target="entityUrl.indexOf('http') > -1 ? '_blank' : ''">{{entity.name}}</a><span v-else>{{entity.name}}</span></h2>
      <!-- entity info -->
      <ul class="mt-12">
        <entity-info-icon v-for="(item, index) in settings.entity_result_info" :item="item" :index="index" :entity="entity" :key="index" class="d-flex align-items-center"></entity-info-icon>
      </ul>
      <!-- media -->
      <div class="my-4" v-if="entityImage">
        <img class="mw-100" :src="entityImage">
      </div>
      <!-- headline -->
      <h2 v-if="entityTitle" class="mb-3">{{entityTitle}}</h2>
      <p v-if="entityParagraph">{{entityParagraph}}</p>
    </div>
  </div>
</template>
<script type="text/javascript">
  import EntityInfoIcon from './EntityInfoIcon.vue';
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import { getEntityUrl } from '../store/utils';

  module.exports = {
    components: { EntityInfoIcon },
    props: ['settings', 'entity'],
    computed: {
      entityUrl() {
        const templates = this.settings.url_templates || null;
        if (this.settings.url_prop) {
          const url = getDataByProperty(this.settings.url_prop, this.entity);
          if (url) return url.toString();
          return '';
        }
        return getEntityUrl(this.entity, this, templates);
      },
      entityTitle() {
        return getDataByProperty(this.settings.title_prop, this.entity);
      },
      entityParagraph() {
        return getDataByProperty(this.settings.paragraph_prop, this.entity);
      },
      entityImage() {
        const backgroundImage = getDataByProperty(this.settings.image_prop, this.entity);
        if (backgroundImage) return backgroundImage;
        if (this.settings.default_image) return this.settings.default_image;
        return null;
      },
    },
  };
</script>
<style scoped>
  hr{
    margin-top:  10px;
    margin-bottom:  20px;
  }
</style>
