<!--
  mobile_filters_facets:
    filter_button_text:
    mobile_filter_overlay_heading:
    mobile_filter_overlay_paragraph:
    mobile_facet_overlay_heading:
    mobile_facet_overlay_paragraph:
    refine_list:
    clear_facets_text:
 -->

<template>
  <main v-bind:class="mobileViewClass" v-if="renderableLayout" id="main-content">
      <header>
        <a href="#content" class="sr-only">Skip to main content</a>
        <component
          v-for="(header, index) in renderableLayout.header"
          :key="index"
          :is="header.tag"
          :settings="header.data"
          :layout="header.layout"
        ></component>
      </header>

      <!-- Page title and components above the map -->
      <div class="primary-controls position-relative" style="z-index: 2;" id="content">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-4">
            <h1 v-if="settings.page_title_short || settings.page_title" class="mt-2">{{settings.page_title_short || settings.page_title}}</h1>
            <rich-text
              v-if="settings.page_description"
              class="d-none d-md-block page-description"
              :text="settings.page_description"
              :tag="'p'"
            ></rich-text>
          </div>
        </div>
        <div class="row my-4 d-none d-md-flex" v-if="renderableLayout.above_map">
          <div v-if="!showMobileFilters" class="col-12 mt-2  d-flex align-items-center sp-h-12" style="z-index: 1;">
            <!-- CURRENTLY EXPECTING FILTERS, ADDRESS SEARCH & CONTROLS -->
            <component
              v-for="(item, index) in renderableLayout.above_map"
              :key="'a' + index"
              :is="item.tag"
              :settings="item.data"
              :layout="item.layout"

            ></component>
             <template v-if="renderableLayout.above_map_controls">
               <component
                v-for="(item, index) in renderableLayout.above_map_controls"
                :key="index"
                :is="item.tag"
                :settings="item.data"
                :layout="item.layout"
              ></component>
            </template>
          </div>
        </div>
      </div>
      </div>

          <!-- MAP -->
          <component
            v-if="renderableLayout.map_component"
            :is="renderableLayout.map_component.tag"
            :settings="renderableLayout.map_component.data"
            :layout="renderableLayout.map_component.layout"
            :class="renderableLayout.map_component.tag"
            @update:mobileMapItem="val => {mobileMapItem = val}"
            :mobile-map-item="mobileMapItem"
          ></component>

          <!-- mobile -->
            <!-- mobile filter & facet controls -->
            <div class="container d-md-none mt-3 mb-2" v-if="settings.mobile_filters_facets && !mobileMapItem">
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <!-- show filters -->
                  <button class="c-button mr-12 flex-shrink-0" v-on:click="showMobileFilters = true" v-if="renderableLayout.above_map">
                    <i class="far fa-sliders-h mr-12"></i>
                    <span>{{settings.mobile_filters_facets.filters_button_text}}</span>
                  </button>
                  <!-- mobile sort -->
                  <sort-dropdown
                    class="flex-shrink-1"
                    :options="mobileSortOptions"
                    :placeholder="renderableLayout.results.data.placeholder"
                    style="min-width: 1px;"
                  ></sort-dropdown>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 results-list__description mt-20" v-if="renderableLayout.results_status">
                  <component
                    v-for="(item, index) in renderableLayout.results_status"
                    :key="index"
                    :is="item.tag"
                    :settings="item.data"
                    :layout="item.layout"
                    class="d-inline-block"
                  ></component>
                  <button
                    class="c-link c-link--utility mr-12"
                    v-if="renderableLayout.facets && enabledFacetCount"
                    v-on:click="showMobileFacets = true">
                      <span>{{settings.mobile_filters_facets.refine_list}}</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- filters & address overlay -->
            <mobile-filters
              v-if="settings.mobile_filters_facets && renderableLayout.above_map"
              @close="closeOverlay('hideMobileFilters')"
              :show="showMobileFilters"
              :settings="settings.mobile_filters_facets"
              :layout="renderableLayout.above_map"
              :layout-controls="renderableLayout.above_map_controls"
            ></mobile-filters>

            <!-- facets overlay -->
            <mobile-facets
              v-if="settings.mobile_filters_facets && renderableLayout.facets"
              @close="closeOverlay('hideMobileFacets')"
              :show="showMobileFacets"
              :settings="settings.mobile_filters_facets"
              :layout="renderableLayout.facets"
              :super-filters="superFilters"
            ></mobile-facets>

            <!-- map item -->
            <mobile-map-item
              v-if="mobileMapItem"
              class="d-md-none"
              :entity="mobileMapItem"
              :settings="settings.mobile_map_item"
              @update:mobileMapItem="val => {mobileMapItem = val}"
            ></mobile-map-item>
          <!-- end mobile -->

          <div class="container" :class="mobileMapItem ? 'd-none d-md-block' : ''">
            <div class="row mt-40 mb-3 d-none d-md-flex">
              <div class="col layout-sidebar">
                  <!-- facet prompt & control(s) -->
                  <component
                    v-if="renderableLayout.facet_controls"
                    :is="renderableLayout.facet_controls.tag"
                    :settings="renderableLayout.facet_controls.data"
                    :layout="renderableLayout.facet_controls.layout"
                    class=""
                  >{{renderableLayout.facet_controls.data}}</component>
              </div>

              <!-- result table controls, i.e. displaying x of y & name search -->
              <div class="col d-flex">
                <div v-if="useCompare" class="">
                  <!-- compare launcher -->
                  <div class="d-inline-flex align-items-center flex-wrap">
                    <component
                      v-if="useCompare && renderableLayout.result_controls.compare_launcher"
                      :is="renderableLayout.result_controls.compare_launcher.tag"
                      :settings="renderableLayout.result_controls.compare_launcher.data"
                      :layout="renderableLayout.result_controls.compare_launcher.layout"
                      v-on:click="showCompare"
                      class="c-button c-button--secondary flex-shrink-0 mr-20"
                    ><i class="far fa-circle" style="font-size: 12px;"></i><i class="fa-circle far  mr-1" style="margin-left: -6px; font-size: 12px;"></i>{{renderableLayout.result_controls.compare_launcher.data}}</component>

                    <div class="d-inline-flex d-none d-lg-block flex-shrink-0" v-if="renderableLayout.result_controls.compare_status && renderableLayout.result_controls.compare_status.data">
                      <compare-status :settings='renderableLayout.result_controls.compare_status.data'></compare-status>
                    </div>
                  </div>

                  <compare-overlay
                    v-if="useCompare"
                    :settings="renderableLayout.compare.data"
                    :layout="renderableLayout.compare.layout"
                    :show-overlay="showCompareOverlay"
                    @close="hideCompare"
                  ></compare-overlay>
                </div>

                <div class="ml-auto d-inline-flex flex-wrap-reverse justify-content-end align-items-baseline">
                  <!-- result summary -->
                  <component
                    class=""
                    v-if="renderableLayout.result_controls.status"
                    :is="renderableLayout.result_controls.status.tag"
                    :settings="renderableLayout.result_controls.status.data"
                    :layout="renderableLayout.result_controls.status.layout"
                  ></component>
                  <!-- search -->
                  <component
                    v-if="renderableLayout.result_controls.search"
                    :is="renderableLayout.result_controls.search.tag"
                    :settings="renderableLayout.result_controls.search.data"
                    :layout="renderableLayout.result_controls.search.layout"
                    class="ml-20"
                  ></component>
                </div>


              </div>
            </div>
            <div class="row">
              <div class="col d-none d-md-block layout-sidebar">
                <component
                  v-if="!showMobileFacets && renderableLayout.facets && renderableLayout.facets.layout && renderableLayout.facets.layout.filters"
                  :is="renderableLayout.facets.tag"
                  :settings="renderableLayout.facets.data"
                  :layout="renderableLayout.facets.layout"
                  :class="renderableLayout.facets.tag"
                  class="sp-v-4"
                  :super-filters="superFilters"

                ></component>
              </div>
              <div class="col">

                <component
                  v-if="renderableLayout.results"
                  :is="renderableLayout.results.tag"
                  :settings="renderableLayout.results.data"
                  :layout="renderableLayout.results.layout"
                  :class="renderableLayout.results.tag"
                  @update:mobileSortOptions="val => {mobileSortOptions = val}"
                ></component>

                <component
                  v-if="usePagination && renderableLayout.paginator"
                  :is="renderableLayout.paginator.tag"
                  :settings="renderableLayout.paginator.data"
                  :layout="renderableLayout.paginator.layout"
                  class="mt-gutter"
                ></component>
              </div>
            </div>
          </div>

          <!-- FOOTER -->
          <component
            :is="renderableLayout.footer.tag"
            :settings="renderableLayout.footer.data"
            class=""
            style="margin-top: 100px;"
          ></component>


  </main>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  var AppMixinFilters = require('../mixins/AppMixinFilters');
  var AppMixinMap = require('../mixins/AppMixinMap');
  import staticData from '../store/data';
  import isHTML from 'tembo-js/isHTML';
  import sendEvent from 'tembo-js/sendEvent';

  import SortDropdown from '../components/SortDropdown.vue';
  import MobileFilters from '../components/overlays/MobileFilters.vue';
  import MobileFacets from '../components/overlays/MobileFacets.vue';
  import MobileMapItem from '../components/MobileMapItem.vue';
  import RichText from '../components/RichText.vue';
  import CompareStatus from '../components/compare/CompareStatus.vue';
  import CompareOverlay from '../components/overlays/CompareOverlay.vue';

  module.exports = {
    mixins: [AppMixinBase, AppMixinFilters, AppMixinMap],
    components: {
      SortDropdown,
      MobileFilters,
      MobileFacets,
      MobileMapItem,
      RichText,
      CompareStatus,
      CompareOverlay,
    },
    data: function data() {
      return {
        mobileSearchVisible: false,
        usePagination: staticData.usePagination,
        showMobileFilters: false,
        showMobileFacets: false,
        mobileMapItem: null,
        mobileSortOptions: [],
      };
    },
    computed: {
      mobileViewClass: function mobileViewClass() {
        //
        // sets view/class for mobile
        //
        var view = this.$store.state.mobileView;
        view = `o-two-column-layout--mobile-${view}`;
        return view;
      },
      compareConfig() {
        return this.$store.state.compareConfig;
      },
      useCompare() {
        return this.$store.getters.useCompare && this.renderableLayout.compare;
      },
      showCompareOverlay() {
        return this.$route.query.compare || false;
      },
      enabledFacetCount() {
        return this.$store.getters.enabledFacetCount;
      },
    },
    methods: {
      toggleMobileSearch: function toggleMobileSearch() {
        this.mobileSearchVisible = !this.mobileSearchVisible;
      },
      hideMobileSearch: function hideMobileSearch() {
        this.mobileSearchVisible = false;
      },
      closeOverlay: function closeOverlay(overlay) {
        if (overlay === 'hideMobileFilters') {
          this.showMobileFilters = false;
        }
        if (overlay === 'hideMobileFacets') {
          this.showMobileFacets = false;
        }
      },
      showCompare() {
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_compare` : 'compare';
        const label = this.$store.state.compares.map(el => el.id).join('|');
        if (this.$store.state.compares.length < this.compareConfig.min) {
          sendEvent({
            category: category,
            action: 'attempted_launch',
            label: label,
          });
          return;
        }
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.compare = true;

        const compareIds = this.$store.state.compares.map(c => c.id).join(',');
        query.compareids = compareIds;

        this.$router.push({ query: query });
        sendEvent({
          category: category,
          action: 'launch',
          label: label,
        });
      },
      hideCompare() {
        const query = JSON.parse(JSON.stringify(this.$route.query));
        delete query.compare;
        delete query.compareids;
        this.$router.push({ query: query });
      },
      isHTML: isHTML,
    }
  };
</script>
<style scoped>
  .layout-sidebar{
    flex: 0 0 250px;
    max-width: 250px;
  }
</style>