<template>
  <fieldset class="static-filter filter-group o-two-column-layout__filter-list__group">

    <!-- FILTER HEADER -->
    <div class="filter-header">
      <h2 v-bind:class="settings.is_subfilter ? 'filter-subtitle' : 'filter-title'" v-if="settings.group_title_text">
        <slot name="filter-title"></slot>
      </h2>
      <div class="selected-tally" v-if="settings.metadata.show_tally && selectionTally > 0"><slot name="filter-tally"></slot></div>
      <p class=" text-small filter-group-description"><slot name="filter-group-description" class="filter-group-description"></slot></p>
      <div class="filter-group-description"><slot name="filter-group-disabled" class="filter-group-description"></slot></div>
    </div>
    <div class="d-flex align-items-center">
         <!-- FILTER CONTENT -->
    <slot name="filter-content" class="flex-grow-1"></slot>

    <!-- CLEAR FILTER SELECTIONS -->
    <slot name="filter-clear" class="flex-shrink-0"></slot>
    </div>
 
  </fieldset>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'selectionTally']
  };
</script>