<template>
  <div class="o-ribbon py-3" v-show="show" :class="error ? 'o-ribbon--error' : '' ">
    <div class="container">
      <p class="text-center">
        <span>{{message}}</span>
        <a v-if="compares.length >= minimum && !error" v-on:click="redirect" class='o-ribbon__link'>{{settings.compare_ribbon_compare_now_link_text}}</a>
        <a v-if="error" v-on:click="manageCompares" class='o-ribbon__link'>{{settings.compare_ribbon_manage_link_text}}</a>
      </p>
    </div>
    <p class="o-ribbon__close d-flex  justify-content-center px-3" v-on:click="closeRibbon"><i class="fal fa-close text-center" style="line-height: 1.5;"></i><span class="ml-2" v-if="settings.close_text">{{settings.close_text}}</span></p>
  </div>
</template>
<script type="text/javascript">
// TODO: when multiple compares are clicked in a row, reset the ribbon hide timeout
  var $ = require('jquery');
  import getQueryString from 'tembo-js/getQueryString';

  module.exports = {
    props: [
      'settings',
      'layout'
    ],
    computed: {
      show: function show() {
        return this.$store.state.showRibbon;
      },
      compares: function compares() {
        return this.$store.state.compares;
      },
      error: function error() {
        return this.$store.state.ribbonError;
      },
      maximum: function maximum() {
        if (this.settings.maximum) {
          return this.settings.maximum;
        }
        return 3;
      },
      minimum: function minimum() {
        if (this.settings.minimum) {
          return this.settings.minimum;
        }
        return 2;
      },
      message: function message() {
        var ct = this.compares.length;
        var max = this.maximum;
        var min = this.minimum;
        var error = this.error;
        var rem;
        var text;
        //
        // set default for compare maximum & minimum
        //

        if (ct < min) {
          rem = min - ct;
          text = this.settings.compare_ribbon_not_ready_text;
          text = text.replace('XX', ct);
          text = text.replace('YY', max);
          text = text.replace('ZZ', rem);
        } else if (error) {
          text = this.settings.compare_ribbon_limit_text;
          text = text.replace('YY', max);
        } else {
          text = this.settings.compare_ribbon_ready_text;
          text = text.replace('XX', ct);
          text = text.replace('YY', max);
        }
        return text;
      }
    },
    methods: {
      showRibbon: function showRibbon() {
        this.show = true;
        // setTimeout(this.close, 3400);
        return this.show;
      },
      close: function close() {
        this.$store.dispatch('updateShowRibbon', false);
        this.$store.dispatch('updateRibbonError', false);
        return this.show;
      },
      closeRibbon: function closeRibbon() {
        $(this.$el).slideUp(200, this.close);
      },
      manageCompares: function manageCompares() {
        this.closeRibbon();
        this.$store.dispatch('updateShowModal', { modal: 'compares' });
      },
      redirect: function redirect() {
        var url = this.settings.compare_url;
        location.href = url + getQueryString(this.$route.query);
      }
    },
    watch: {
      // show: function show() {
      //   if (this.show) {
      //     setTimeout(this.close, 3400);
      //   }
      // }
    }
  };
</script>
