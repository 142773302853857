<template>
  <div class="letter-grade">
    <span class="active" :class="'letter-grade__' + universe.indexOf(entityData)">{{entityData}} </span>
    <span v-if="label">{{label}}</span>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['entityData', 'universe', 'label']
  };
</script>