<template>
  <label class="c-input d-inline-flex" :class="classes">
    <span class="sr-only">Search</span>
    <div class="c-input__value">
	    <slot name="icon" class="c-input__icon"></slot>
	    <slot name="input" class="flex-grow-1"></slot>
	    <slot name="clear" class="c-input__icon"></slot>
    </div>
  </label>
</template>
<script type="text/javascript">
module.exports = {
  props: ['id', 'classes']
};
</script>
