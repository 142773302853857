function displayListAsRange(list, universe, internalProp) {
  let first;
  let last;
  let curr;

  function getText(item, propOverride) {
    if (!item) return '';
    if (typeof item === 'string') return item;

    const props = ['name', 'display_text', 'text', 'value'];
    if (propOverride) {
      props.unshift(propOverride);
    }
    let itemText = '';
    let i = 0;
    const propLen = props.length;
    while (!itemText && i < propLen) {
      const prop = props[i];
      if (typeof item[prop] !== 'undefined') {
        itemText = item[prop];
      }
      i += 1;
    }
    if (!itemText) return item;
    return itemText;
  }


  function getOrder(item) {
    if (!item) return 0;
    if (typeof item !== 'object') return 0;
    if (item.order) return item.order;
    return 0;
  }


  if (typeof list === 'string') return list;
  if (!Array.isArray(list)) return '';
  if (list.length === 0) return '';

  const sortedList = JSON.parse(JSON.stringify(list));
  sortedList.sort((a, b) => (getOrder(a) - getOrder(b)));

  if (!universe) {
    first = getText(sortedList[0], internalProp);
    last = getText(sortedList[sortedList.length - 1], internalProp);
    if (last === first) return last;
    return `${first} - ${last}`;
  }

  const itemsWithId = list.filter(l => l.id); // eslint-disable-line max-len
  const listHasIdAttr = (list.length - itemsWithId.length) === 0;
  if (listHasIdAttr) {
    // use the universe
    let prevText = '';
    let prevUidx = -1;
    let resultStr = '';
    for (let i = 0, l = universe.length; i < l; i++) {
      const key = universe[i];
      const dataItem = list.find(item => item.id === key);
      if (dataItem) {
        const dataText = getText(dataItem);
        // add the first item
        if (dataItem && !prevText) {
          resultStr += dataText;
        } else if (dataItem && i - prevUidx > 1 && resultStr.indexOf(prevText) === -1) {
          resultStr += ` - ${prevText}, ${dataText}`;
        } else if (dataItem && i - prevUidx > 1) {
          resultStr += `, ${dataText}`;
        } else if (i === l - 1) {
          resultStr += ` - ${dataText}`;
        }
        prevUidx = i;
        prevText = dataText;
      } else if (prevText && i === l - 1 && resultStr.indexOf(prevText) === -1) {
        resultStr += ` - ${prevText}`;
      }
    }
    return resultStr;
  }


  for (let i = 0, l = sortedList.length; i < l; i++) {
    curr = getText(sortedList[i], internalProp);
    if (first) {
      if (universe.indexOf(first) > universe.indexOf(curr)) first = curr;
    } else {
      first = curr;
    }
    if (last) {
      if (universe.indexOf(last) < universe.indexOf(curr)) last = curr;
    } else {
      last = curr;
    }
  }
  if (last === first) return last;
  return `${first} - ${last}`;
}

export default displayListAsRange;
