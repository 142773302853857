<template>
  <div>
    <button
      class="c-link c-link--utility "
      :class="itemsToReset ? '' : ' d-none c-link--disabled'"
      style="margin-left: 28px;"
      v-on:click="clearFilters"
    >{{settings.button_text}}</button>
  </div>
</template>
<script type="text/javascript">
import sendEvent from 'tembo-js/sendEvent';

module.exports = {
  props: ['settings'],
  computed: {
    itemsToReset() {
      return this.$store.getters.strictFilterCount + this.$store.getters.relaxedFilterCount > 0 ||
        this.$store.state.address.length > 0;
    },
  },
  methods: {
    clearFilters() {
      // send google analytics event
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_filter` : 'filter';
      sendEvent({
        category: category,
        action: 'clear_filters',
      });
      return this.$store.dispatch('updateAddress', [])
      .then(() => this.$store.dispatch('resetAllFilters', true))
      .then(() => this.$store.dispatch('removeAllCompares'))
      .then(() => this.$store.commit('setPendingFilters', []));
    },
  },
};
</script>
