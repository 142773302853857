<template>
  <modal-component :show="show" @update:show="val => { show = val }" :click-away="true" class="redirect-modal">
    <h1 class="modal-h1 text-center mt-4 mb-3 mx-3">{{settings.primary_text}}</h1>
    <p class="modal-p text-center mx-3">{{settings.secondary_text}}</p>
    <div class="c-modal__footer mt-auto mb-4">
      <div class="row">
        <div class="col-sm-4 offset-sm-2">
          <div class="c-button c-button--outline w-100 button button-cancel button--full" v-on:click="closeModal">{{settings.cancel_text}}</div>
        </div>
        <div class="col-sm-4 mt-4 mt-sm-0">
          <button class="button c-button w-100 button--active button--full" v-on:click="redirect">{{settings.continue_text}}</button>
        </div>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  const auth = require('../../store/auth');
  const ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';

  module.exports = {
    props: ['show', 'settings'],
    components: { ModalComponent },
    mixins: [ModalMixin],
    methods: {
      redirect: function redirect() {
        var self = this;
        auth.sfSaveUrl()
        .then((res) => {
          var url;
          if (res.status === 200) {
            sendEvent({
              category: 'login',
              action: 'redirect_sf'
            });
            if (self.$route.query.lang && self.$route.query.lang === 'es') {
              url = self.settings.external_url.split('?').join('_spanish?');
            } else {
              url = self.settings.external_url;
            }
            location.href = url;
          }
        }, (err) => {
          // eslint-disable-next-line
          console.error(err);
          location.href = `${window.location}errors/error.html`;
        });
      }
    }
  };
</script>