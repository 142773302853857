<template>
  <div class="embed-responsive embed-responsive-16by9">
	<iframe width="560" height="315" :src="src" frameborder="0" allow="accelerometer; autoplay; gyroscope; picture-in-picture" allowfullscreen class="embed-responsive-item"></iframe>
  </div>
</template>

<script type="text/javascript">
module.exports = {
  props: ['src']
};
</script>