<template>
  <!-- DISTANCE: requires computed distance or missing data rule -->
  <div class="entity-attributes__distance" v-if="entityData || entityData === 0 || settings.missing_data_rules && settings.missing_data_rules.distance">
    <div>
      <div class="heading"><i class="fa" :class="iconClass" aria-hidden="true"></i>{{entityData.mode.text}}</div>
      <div v-if="entityData.distance || entityData.distance === 0" class="distance-attribute"><span>{{entityData.distance}}</span> {{settings.distance_units_text}}</div>
      <div v-if="hours" class="distance-attribute"><span>{{hours}}</span> {{settings.time_hours_text}}</div>
      <div v-if="minutes || (minutes === 0 && hours === 0)" class="distance-attribute"><span>{{minutes}}</span> {{settings.time_minutes_text}}</div>
      <!-- Missing data text for distance -->
      <div v-else class="">{{settings.missing_data_rules.distance.missing_data_text}}</div>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'entityData'],
    computed: {
      iconClass: function iconClass() {
        if (this.entityData.mode.value === 'WALKING') return 'fa-child';
        if (this.entityData.mode.value === 'DRIVING') return 'fa-car';
        return 'tmbo-distance';
      },
      hours: function hours() {
        // expects a string in the format h:m
        var timeArr;
        var hourStr;
        if (!this.entityData.time) return '';
        timeArr = this.entityData.time.split(':');
        hourStr = timeArr.shift();
        return parseInt(hourStr, 10);
      },
      minutes: function minutes() {
        // expects a string in the format h:m
        var timeArr;
        var minuteStr;
        if (!this.entityData.time) return '';
        timeArr = this.entityData.time.split(':');
        minuteStr = timeArr.pop();
        return parseInt(minuteStr, 10);
      }
    }
  };
</script>
<style type="text/css">
  .distance-attribute {
    display: inline-block;
    padding-left: 5px;
  }
  .distance-attribute:first-child{
    padding-left: 0px;
  }
</style>