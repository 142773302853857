<template>
  <label class="text-selector">
    <input type="radio" :name="name" :value="value.value" v-model="localSelection" class="sr-only">
    <button class="button--control button c-button c-button--control" :class="value.value === selected ? 'c-button--active' : 'c-button--outline'" v-on:click="updateSelected(value.value)">{{value.text}}</button><!--
     -->
  </label>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['value', 'selected', 'name', 'disabled'],
    computed: {
      localSelection: {
        get: function get() {
          return this.selected;
        },
        set: function set(newval) {
          this.$emit('update:selected', newval);
        }
      }
    },
    methods: {
      updateSelected: function updateSelected(newval) {
        this.$emit('update:selected', newval);
      }
    }
  };
</script>
