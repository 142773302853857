<template>
  <label v-on:mouseenter="showTooltip" v-on:focus="showTooltip" v-on:mouseleave="hideTooltip" v-on:blur="hideTooltip">
    <input type="checkbox" :name="value" v-model="localChecked">
  </label>
</template>
<script type="text/javascript">
  import $ from 'jquery';
  global.jQuery = $;
  require('bootstrap/js/tooltip');

  module.exports = {
    props: ['value', 'checked', 'textSaved', 'textAdd', 'textRemove'],
    data: function data() {
      return {
        autoHide: true
      };
    },
    computed: {
      localChecked: {
        get: function get() {
          return this.checked;
        },
        set: function set(newval) {
          this.$emit('update:compare', newval);
        }
      },
      labelText: function labelText() {
        return this.checked ? this.textRemove : this.textAdd;
      }
    },
    mounted: function mounted() {
      this.$nextTick(() => {
        // this sets up the tooltip on this.$el
        $(this.$el).tooltip({ title: this.labelText, placement: 'right', trigger: 'manual' });
      });
    },
    methods: {
      showTooltip: function showTooltip() {
        const self = this;
        $(self.$el).tooltip('show');
      },
      hideTooltip: function hideTooltip() {
        const self = this;
        if (this.autoHide) {
          $(self.$el).tooltip('hide');
        }
      },
      toggleCompare: function toggleCompare() {
        var self = this;
        if (self.checked) {
          this.autoHide = false;
          $(self.$el)
          .attr('data-original-title', self.textSaved)
          .tooltip('show');

          // hide tooltip after 3 seconds
          setTimeout(() => {
            $(self.$el).tooltip('hide');
            self.autoHide = true;
            $(self.$el).attr('data-original-title', self.labelText);
          }, 3000);
        } else {
          $(self.$el)
            .attr('data-original-title', self.labelText)
            .tooltip('hide');
        }
      }
    },
    watch: {
      checked: function watchChecked() {
        this.toggleCompare();
      }
    }
  };
</script>