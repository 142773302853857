<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <div :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" :settings="settings" :selection-tally="selectionTally" class="type-selection-filter">
    <!-- MODALS SLOT -->
    <!-- (none here yet) -->

    <!-- FILTER TITLE SLOT -->
    <span slot="filter-title">{{settings.group_title_text}}</span>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" v-if="settings.description_text">{{settings.description_text}}</div>

    <!-- FILTER CONTENT SLOT -->
    <table slot="filter-content" class="filter-content">
      <tr v-for="option in filteredValues">
        <th class="option">{{option.text}}</th>
        <td>
          <label v-on:click="handleTypeClick(option, 'strict')"><span class="type-control-visual" :class="option.required && option.type === 'strict' ? 'active' : ''">{{settings.strict_text}}</span></label>
          </td>
          <td>
        <label v-on:click="handleTypeClick(option, 'relaxed')"><span class="type-control-visual" :class="option.required && option.type === 'relaxed' ? 'active' : ''">{{settings.relaxed_text}}</span></label></td>
      </tr>
    </table>
  </div>
</template>
<script type="text/javascript">
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  module.exports = {
    mixins: [FilterGroupMixin],
    methods: {
      handleTypeClick: function handleTypeClick(option, type) {
        var updatedOption = option;
        if (updatedOption.required && updatedOption.type === type) {
          updatedOption.required = !updatedOption.required;
        } else {
          updatedOption.type = type;
          updatedOption.required = true;
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  input.type-control {
    display: none;
  }
  span{
    /* padding: 5px; */
  }
  span.option{
    display: inline-block;
    width: 33%;
    word-wrap: break-word;
  }
  span.type-control-visual {
    display: inline-block;
    border: 2px solid grey;
    border-radius: 5px;
    padding:  5px;
  }
  span.type-control-visual.active {
    border: 2px solid green;
  }
  th{
    text-align: left;
    padding-bottom:  20px;
  }
</style>
