<template>
  <div class="d-inline-flex align-items-center">
    <p class="p--utility">
    <span v-if="entitiesLoaded">{{countMessage}}&nbsp</span><!--
     --><span v-if="entitiesLoaded && selectionText && filtersSelected">{{selectionText}}&nbsp</span>
      </span>
    </p>
  </div>

</template>
<script type="text/javascript">
  var ResultStatusMixin = require('../mixins/ResultStatusMixin');
  module.exports = {
    mixins: [ResultStatusMixin],
    computed: {
      filterCount() {
        const count = this.$store.getters.filterCount;
        return count.facet +
          count.strict.reset + count.strict.noReset +
          count.relaxed.reset + count.relaxed.noReset;
      },
      filtersSelected() {
        return this.filterCount > 0;
      },
      selectionText() {
        if (this.filterCount === 1) return this.settings.selection_text_singular;
        if (this.filterCount) return this.settings.selection_text_plural;
        return '';
      }
    }
  };
</script>