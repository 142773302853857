<template>
  <div class="entity-card p-entity-card" v-on:click="goTo(entity)" :class="settings.entity_card_clickable ? 'clickable' : ''">
    <div v-if="backgroundImage" class="p-entity-card__img" :style="{backgroundImage: `url('${backgroundImage}')`}"></div>
    <div class="p-entity-card__info">
      <a v-if="entityUrl(entity)" class="p-entity-card__title" :href="entityUrl(entity)" :target="entityUrl(entity).indexOf('http') > -1 ? '_blank' : ''" @click="handleLinkClick">{{entity.name}}</a>
      <span v-else class="p-entity-card__title">{{entity.name}}</span>
      <ul>
      <template v-for="(item, index) in filteredEntityInfo">
        <entity-info-icon :item="item" :index="index" :entity="entity"></entity-info-icon>
      </template>
      </ul>
    </div>
  </div>
</template>
<script>
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import sendEvent from 'tembo-js/sendEvent';
  import { getEntityUrl } from '../store/utils';
  import EntityInfoIcon from './EntityInfoIcon.vue';

  module.exports = {
    components: { EntityInfoIcon, },
    props: {
      // Used to differentiate between different types of cards for GA.
      cardType: {
        validator(value) {
          return ['map', 'entity_search'].indexOf(value) !== -1;
        }
      },
      entity: Object,
      settings: Object,
    },
    computed: {
      backgroundImage() {
        const backgroundImage = getDataByProperty(this.settings.image_prop, this.entity);
        if (backgroundImage) return backgroundImage;
        if (this.settings.default_image) return this.settings.default_image;
        return null;
      },
      filteredEntityInfo() {
        const entity = this.entity;
        if (!this.settings || !this.settings.entity_result_info) return [];
        return this.settings.entity_result_info.filter((item) => {
          if (!item.conditional) return true;
          const entityData = getDataByProperty(item.conditional.entity_prop, entity);
          if (item.conditional.operation === 'equals') {
            return entityData === item.conditional.entity_value;
          }
          // eslint-disable-next-line no-console
          console.log('EntityCard does not handle', item.conditional.operation);
          return true;
        }, this);
      },
    },
    methods: {
      entityUrl(entity) {
        const templates = this.settings.url_templates || null;
        if (this.settings.url_prop) {
          const url = getDataByProperty(this.settings.url_prop, this.entity);
          if (url) return url.toString();
          return '';
        }
        return getEntityUrl(entity, this, templates);
      },
      goTo(entity) {
        if (this.settings.entity_card_clickable) {
          window.location.href = this.entityUrl(entity);
        }
      },
      handleLinkClick() {
        if (this.cardType) {
          const findView = this.$route.params.findView;
          const category = findView ? `${findView}_${this.cardType}` : this.cardType;
          sendEvent({
            category: category,
            action: 'report_link',
            label: this.entity.id,
          });
        }
      }
    }
  };
</script>

<style scoped>
/*  .entity-card {
     width: 300px;
    height: 270px;
    border-radius: 4px;
    border: solid 1px #d1cdc8;
    background-color: white;
  }*/
  .clickable{
    cursor: pointer;
  }
</style>