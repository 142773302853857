function getQueryString(queryObj, keysToDrop) {
  let queryString = '?';
  Object.keys(queryObj).forEach((key) => {
    if (queryObj[key] && (!keysToDrop || keysToDrop.indexOf(key) === -1)) {
      queryString += `${key}=${queryObj[key]}&`;
    }
  });
  return queryString.slice(0, -1);
}


export default getQueryString;
