import arraysIncludeOrOverlap from 'tembo-js/arraysIncludeOrOverlap';
module.exports = {
  computed: {
    filters: function filters() {
      return this.$store.getters.filterSet;
    },
    gradeDependentUrl: function gradeDependentUrl() {
      if (this.preKSelected) {
        if (this.hasK12) {
          if (this.entityUrl.indexOf('?') > -1) {
            return this.entityUrl + '&' + this.settings.early_ed_url;
          }
          return this.entityUrl + '?' + this.settings.early_ed_url;
        }
      }
      return this.entityUrl;
    },
    hasPreK: function hasPreK() {
      var entityGrades = this.entity.grades;
      var preKGrades = this.settings.pre_k_grades;
      return arraysIncludeOrOverlap(entityGrades, preKGrades);
    },
    hasK12: function hasK12() {
      var entityGrades = this.entity.grades;
      var k12Grades = this.settings.k_12_grades;
      return arraysIncludeOrOverlap(entityGrades, k12Grades);
    },
    preKSelected: function preKSelected() {
      var gradeSelection;
      var gradeValues;
      var i = 0;
      var l;
      gradeValues = this.filters.grade_levels.value;
      l = gradeValues.length;
      while (!gradeSelection && i < l) {
        if (gradeValues[i].required) gradeSelection = gradeValues[i].value;
        i += 1;
      }
      if (this.settings.pre_k_grades &&
          this.settings.pre_k_grades.indexOf(gradeSelection) > -1) {
        return true;
      }
      return false;
    }
  }
};
