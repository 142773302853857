<template>
  <label class="letter-grade" v-on:mouseleave="setFocus(false)">
    <input type="checkbox" :name="group" :value="value.value" v-model="localChecked" class="sr-only" v-on:focus="setFocus(true)" v-on:blur="setFocus(false)">
    <span :class="'c-letter-grade-badge c-letter-grade-badge--md c-letter-grade-badge__' + idx + ' ' + (checked ? '' : filled ? '' : hovered || focused ? '' : 'c-letter-grade-badge--outline')">{{value.text}}</span><!--
     -->
  </label>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['value', 'filled', 'hovered', 'focused', 'checked', 'group', 'idx'],
    computed: {
      localChecked: {
        get: function localChecked() {
          return this.checked;
        },
        set: function localChecked(newval) {
          this.$emit('update:checked', newval);
        }
      }
    },
    methods: {
      setFocus: function setFocus(state) {
        this.$emit('update:focus', state, this.idx);
      }
    }
  };

</script>
<style scoped>

</style>