<template>
  <label class="compare_label c-button c-button--round  c-button--action" :class="checked ? '' : 'c-button--outline'" v-on:mouseenter="showTooltip" v-on:focus="showTooltip" v-on:mouseleave="hideTooltip" v-on:blur="hideTooltip"><!--
     --><input class="compare sr-only" type="checkbox" :name="value" v-model="localChecked"><!--
     --><span class="compare_visual"><svg class="svg-arrow" width="20px" height="17px" viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" fill="#71777A" stroke-width="1" fill-rule="evenodd">
          <polygon class="svg-arrow__top" points="6.75147569 0.657899306 5.74880903 1.66056597 8.69866667 4.62222222 0.0444444444 4.62222222 0.0444444444 6.04444444 8.69866667 6.04444444 5.74880903 9.01920833 6.75147569 10.021875 11.4222222 5.33333333"></polygon>
          <polygon class="svg-arrow__bottom"  points="15.200434 7.11909722 14.1977674 8.12176389 17.147625 11.0834201 8.49340278 11.0834201 8.49340278 12.5056424 17.147625 12.5056424 14.1977674 15.4804062 15.200434 16.4830729 19.8711806 11.7945312"></polygon>
        </g>
      </svg>
    </span>
  </label>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  global.jQuery = $;
  require('bootstrap/js/tooltip');

  module.exports = {
    props: ['value', 'checked', 'textSaved', 'textAdd', 'textRemove'],
    data: function data() {
      return {
        autoHide: true
      };
    },
    computed: {
      localChecked: {
        get: function get() {
          return this.checked;
        },
        set: function set(newval) {
          this.$emit('update:compare', newval);
        }
      },
      labelText: function labelText() {
        return this.checked ? this.textRemove : this.textAdd;
      }
    },
    mounted: function mounted() {
      this.$nextTick(() => {
        // this sets up the tooltip on this.$el
        $(this.$el).tooltip({ title: this.labelText, placement: 'left', trigger: 'manual' });
      });
    },
    methods: {
      showTooltip: function showTooltip() {
        const self = this;
        $(self.$el).tooltip('show');
      },
      hideTooltip: function hideTooltip() {
        const self = this;
        if (this.autoHide) {
          $(self.$el).tooltip('hide');
        }
      },
      toggleCompare: function toggleCompare() {
        var self = this;
        if (self.checked) {
          this.autoHide = false;
          $(self.$el)
          .attr('data-original-title', self.textSaved)
          .tooltip('show');

          // hide tooltip after 3 seconds
          setTimeout(() => {
            $(self.$el).tooltip('hide');
            self.autoHide = true;
            $(self.$el).attr('data-original-title', self.labelText);
          }, 3000);
        } else {
          $(self.$el)
            .attr('data-original-title', self.labelText)
            .tooltip('hide');
        }
      }
    },
    watch: {
      checked: function watchChecked() {
        this.toggleCompare();
      }
    }
  };
</script>