<template>
  <label class="text-selector">
    <input type="checkbox" :name="group" :value="value.value" v-model="localChecked"><span class="button--control button c-button c-button--control w-100" :class="value.required || filled ? 'button--active' : 'c-button--outline'">{{value.text}}</span><!--
     -->
  </label>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['value', 'filled', 'checked', 'group', 'idx'],
    computed: {
      localChecked: {
        get: function localChecked() {
          return this.checked;
        },
        set: function localChecked(newval) {
          this.$emit('update:checked', newval);
        }
      }
    }
  };
</script>
<style type="text/css" scoped>
  input{
    /* display: none; */
    position: absolute;
    left:  -9999999px;
  }
</style>