var $ = require('jquery');
import sendEvent from 'tembo-js/sendEvent';
import staticData from '../store/data';

module.exports = {
  props: ['settings', 'layout'],
  data() {
    return {
      usePagination: staticData.usePagination,
    };
  },
  computed: {
    perPage: function perPage() {
      return this.$store.state.resultsPerPage;
    },
    pageCount: function pageCount() {
      var ct;
      if (!this.perPage) return 1;
      ct = this.$store.state.filtered.length / this.perPage;
      return Math.ceil(ct);
    },
    pageXEntities: function pageXEntities() {
      const itemsPerPage = this.perPage;
      const currentPage = this.$store.state.page;
      const filtered = this.$store.state.filtered;
      if (!this.usePagination) return filtered;
      const upper = currentPage * itemsPerPage;
      const lower = upper - itemsPerPage;
      const results = filtered.slice(lower, upper);
      return results;
    },
    page: function page() {
      return this.$store.state.page;
    }
  },
  methods: {
    pageBack: function pageBack(ev) {
      var page = this.$store.state.page - 1;
      $(ev.target).blur();
      return this.setPage(page);
    },
    pageFwd: function pageFwd(ev) {
      var page = this.$store.state.page + 1;
      $(ev.target).blur();
      return this.setPage(page);
    },
    setPage: function setPage(page, ev) {
      if (ev) {
        $(ev.target).blur();
      }
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_list` : 'list';
      sendEvent({
        category: category,
        action: 'page_change',
        label: page
      });
      this.$store.dispatch('updatePage', page);
      const scrollAllLayouts = true;
      this.scrollToTop(null, scrollAllLayouts);
      return page;
    },
    getEntityIndex: function getEntityIndex(idx) {
      const perPage = this.$store.state.resultsPerPage;
      const currPage = this.$store.state.page;
      return perPage * (currPage - 1) + idx + 1;
    },
    scrollToTop: function scrollToTop(setFocus, scrollAllLayouts) {
      // TODO: option for scrolling table to the top
      var $scrollingDiv = $('.o-two-column-layout__results > div');
      var $firstLink;
      var scrollPosition = 0;
      if ($scrollingDiv.length) {
        // v2 clients have a fixed hight sidebar on the right that scrolls
        // scroll to the top of that section on page change
        // this is a different layout than v3
        $firstLink = $scrollingDiv.find('a:visible').eq(0);
        $scrollingDiv.animate({ scrollTop: scrollPosition }, {
          duration: 200,
          complete: function focusFirstLink() {
            if (setFocus && $firstLink.length) {
              $firstLink.focus();
            }
          }
        });
      } else if (scrollAllLayouts) {
        // v3 clients do not have a scrolling section
        // so we need to scroll the page up to the top of the #entity-list
        $scrollingDiv = $('html, body');
        $firstLink = $('#entity-list').find('a:visible').eq(0);
        document.getElementById('entity-list').scrollIntoView({ behavior: 'smooth' });
        if (setFocus && $firstLink.length) {
          $firstLink.focus();
        }
      }
    }
  },
};
