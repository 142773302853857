<template>
  <label class="c-select__wrapper">
    <slot name="select-title" class="c-select__title"></slot>
    <select
      class="sort-select c-select"
      :class="useTitle === false ? 'sort-select--no-title h-100' : ''"
      v-model="localSelection"
    >
      <option v-if="placeholderValue" disabled :value="null" class="special">{{placeholderValue}}</option>
      <option v-for="option in options" :value="option.value" :disabled="option.disabled">{{option.text}}</option>
    </select>
  </label>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['options', 'selected', 'placeholderValue', 'useTitle'],
    computed: {
      localSelection: {
        get: function localSelection() {
          return this.selected;
        },
        set: function localSelection(newval) {
          this.$emit('update:selected', newval);
        }
      },
    },
  };
</script>
<style type="text/css" scoped>
  .sort-select--no-title{
    /* padding-top: 0; */
  }
</style>