<template>
<nav aria-label="breadcrumb" class="p-breadcrum mb-gutter d-none d-md-block">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb p-breadcrum__list mb-0">
          <li v-for="item in urlList" class="breadcrumb-item p-breadcrum__item" :class="item.current ? 'active' : ''" :aria-current="item.current ? 'page' : ''">
            <a class="p-breadcrum__link" v-if="!item.current" :href="item.href + queryString" @click="handleCrumbClick(item)"><i class="fas mr-2" :class="item.class"></i>{{item.text}}</a>
            <span v-else>{{item.text}}</span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
import sendEvent from 'tembo-js/sendEvent';

export default {
  props: {
    settings: {
      type: Object,
      required: true,
      validator: obj => obj.breadcrumbs && obj.entity_type_text && obj.page_name_text
    },
  },
  computed: {
    queryString() {
      let queryStr = '?';
      Object.keys(this.$route.query).forEach((key) => {
        if (this.$route.query[key]) {
          queryStr += `${key}=${this.$route.query[key]}&`;
        }
      });
      return queryStr.slice(0, -1);
    },
    urlList() {
      // for checking which breadcrumb item is current
      const routeName = this.$route.name;

     // for updating url & looking up link text
      const entityType = this.$route.path.replace('/', '');

      // for hiding breadcrumb items past the current index
      let currentIdx;
      this.settings.breadcrumbs.forEach((item, idx) => {
        if (item.id === routeName) {
          currentIdx = idx;
        }
      });

      const entityTypeLookup = this.settings.entity_type_text;
      const entityTypeText = entityTypeLookup[entityType];

      // show homepage breadcrumb and the breadcrumb for the current find/about page
      const crumbCollection = this.settings.breadcrumbs
          .filter(crumb => crumb.id === 'home' || crumb.id === this.$route.name);

      // update list of urls
      const urls = crumbCollection.map((item, idx) => ({
        text: item.text.replace('[entity_type_name]', entityTypeText),
        current: item.id === routeName,
        href: item.href.replace('[entity_type]', entityType),
        visible: idx <= currentIdx,
        class: item.id === 'home' ? 'fa-home' : '', // add home icon to home only
      }))
      // filter list to only those that should be visible
      .filter(item => item.visible);

      // make sure breadcrumbs are in order
      urls.sort((a, b) => a.order - b.order);
      return urls;
    },
  },
  methods: {
    handleCrumbClick(crumb) {
      sendEvent({
        category: 'find_breadcrumb',
        action: 'link_selected',
        label: crumb.href,
      });
    },
  },
};
</script>
<style scoped>
  .breadcrumb-item + .breadcrumb-item::before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 200;
      content: "\f105" !important;
    }
</style>
