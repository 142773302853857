<template>
  <th
    v-if="settings.is_row_header"
    :class="truncateEllipsis ? 'truncate-ellipsis' : ''"
    v-on:mouseover="showTooltip"
    v-on:mouseout="hideTooltip"
    >
    <div
      v-if="breakpoint === 'smartphone' && !isIe"
      :is="settings.tag_mobile ? settings.tag_mobile : settings.tag"
      :entity-data="entityData"
      :entity-link="entityLink"
      :universe="settings.universe"
      :entity-id="entity.id"
    ></div>
    <div v-else-if="settings.link"
      :is="settings.tag"
      :entity-data="entityData"
      :entity-link="entityLink"
      :universe="settings.universe"
      :entity-id="entity.id"
    ></div>
    <div v-else
      :is="settings.tag"
      :entity-data="entityData"
      :universe="settings.universe"></div>
  </th>
  <td v-else
    :class="truncateEllipsis ? 'truncate-ellipsis' : ''"
    v-on:mouseover="showTooltip"
    v-on:mouseout="hideTooltip"
    >
    <div v-if="settings.link" :is="settings.tag" :entity-data="entityData" :entity-link="entityLink" :universe="settings.universe"></div>
    <div v-else :is="settings.tag" :entity-data="entityData" :universe="settings.universe"></div>
  </td>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  var utils = require('../store/utils');
  var TableMixin = require('../mixins/TableMixin');
  global.jQuery = $;
  require('bootstrap/js/tooltip');
  import getDataByProperty from 'tembo-js/getDataByProperty';

  module.exports = {
    props: ['settings', 'entity', 'truncateEllipsis'],
    mixins: [TableMixin],
    data: function data() {
      const isIe = !!document.documentMode;
      return {
        isIe: isIe,
        overflows: null
      };
    },
    computed: {
      breakpoint: function breakpoint() {
        return this.$store.state.breakpoint;
      },
      entityData: function entityData() {
        const data = getDataByProperty(this.settings.entity_prop, this.entity);
        if (this.settings.display && this.settings.display === 'range') {
          return utils.displayListAsRange(data, this.universe);
        }
        return data;
      },
      entityLink: function entityLink() {
        return this.getEntityLink(this.settings.link);
      },
    },
    mounted() {
      if (this.truncateEllipsis) {
        $(this.$el).tooltip({ title: this.entityData, trigger: 'manual' });
      }
    },
    methods: {
      getOverflows($el) {
        const firstChild = $el.children().first();
        return firstChild.outerWidth() - $el.width() > 0;
      },
      showTooltip() {
        if (!this.truncateEllipsis) return;
        const overflows = this.getOverflows($(this.$el));
        if (overflows && (this.breakpoint !== 'smartphone' || !this.settings.is_row_header)) {
          $(this.$el).tooltip('show');
        }
      },
      hideTooltip() {
        if (!this.truncateEllipsis) return;
        $(this.$el).tooltip('hide');
      }
    },
    watch: {
      entityData(curr, prev) {
        //
        // when the table's contents are updated, the mounted trigger is not run again
        //
        //
        const self = this;
        if (curr !== prev && this.truncateEllipsis) {
          $(self.$el)
          .attr('data-original-title', self.entityData);
        }
      }
    }
  };
</script>