<template>
  <table class="result-table flex-table table--results table--flex" :class="settings.truncate_ellipsis ? 'fixed-row-height' : ''">
    <!-- header -->
    <thead>
      <tr>
        <template v-for="(col, index) in filteredColumns">
          <th
            v-if="colVisible(col)"
            scope="col"
            v-on:click="getColIsSortable(col) ? toggleSort(index) : ''"
            :class="colClass(col)"
            :style="!getColIsSortable(col) ? 'cursor: auto;' : ''"
            >
            <div>
              <strong>{{col.col_header}}</strong>
              <div  v-if="getColIsSortable(col)" class="fa-stack">
                  <i class="fa fa-sort fa-stack-1x"></i>
                  <i class="fa fa-stack-1x" :class="col.sort_direction === 1 ? 'sort-active fa-sort-desc fa-sort-up' : col.sort_direction === -1 ? 'fa-sort-asc sort-active fa-sort-down' : '' "></i>
              </div>
            </div>
          </th>
        </template>
      </tr>
    </thead>
    <!-- body -->
    <tbody>
      <!-- results -->
      <template v-if="entitiesLoaded && pageXEntities.length">
        <tr v-for="entity in pageXEntities">
          <th v-if="settings.include_compare_column && entity.entity_type === entity.entity_compare_prop" class="d-flex justify-content-center align-items-center table--results__compare ">
            <compare-checkbox
              :checked="entity.computed.compare"
              :value="entity.id"
              @update:compare="val => { handleCompareToggle(val, entity) }"
              :text-saved="settings.compare_saved_text"
              :text-add="settings.compare_add_text"
              :text-remove="settings.compare_remove_text"
              class="d-md-inline-block"
            ></compare-checkbox>
          </th>
          <template v-for="col in filteredColumns">
            <result-datum v-if="colVisible(col) && !col.is_compare_column"
              :settings="col"
              :entity="entity"
              :class="colClass(col)"
              :truncate-ellipsis="settings.truncate_ellipsis"
              style="line-height: 1.5;"
            ></result-datum>
          </template>
        </tr>
      </template>
      <!-- all results filtered out -->
      <template v-else-if="!pageXEntities.length && entitiesLoaded">
        <tr class="missing-table-results">
          <div
            v-if="layout && layout.no_results"
            :is="layout.no_results.tag"
            :settings="layout.no_results.data"
            :layout="layout.no_results.layout"
            :class="layout.no_results.tag"
          >{{layout.no_results.data}}</div>
        </tr>
      </template>

      <!-- placeholders while entities load -->
      <template v-else>
        <tr
          v-for="i in (usePagination ? settings.pagination.results_per_page : 20)"
          :key="i">
            <template v-for="(col, index) in filteredColumns">
              <td v-if="colVisible(col)">
                <table-placeholder :no-animation="index !== 0" :idx="index"></table-placeholder>
              </td>
            </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script type="text/javascript">
  const Vue = require('vue');
  const PaginationMixin = require('../mixins/PaginationMixin');
  const TableMixin = require('../mixins/TableMixin');
  import compares from '../mixins/ResultItemMixin';
  import arraysMatch from 'tembo-js/arraysMatch';
  import CompareCheckbox from './compare/CompareCheckbox.vue';
  import ResultDatum from './ResultDatum.vue';
  import TablePlaceholder from './TablePlaceholder.vue';

  module.exports = {
    components: {
      CompareCheckbox,
      ResultDatum,
      TablePlaceholder,
    },
    props: ['settings', 'layout'],
    mixins: [PaginationMixin, TableMixin, compares],
    computed: {
      entitiesLoaded: function entitiesLoaded() {
        return this.$store.state.entitiesLoaded;
      },
    },
    mounted: function mounted() {
      const reset = false;
      this.setInitialTableSort(reset);
      this.setInitialMobileCols();
    },
    methods: {
      handleCompareToggle: function handleCompareToggle(compared, entity) {
        //
        // currently assumes compare will never be auth-based
        //
        let limitReached = false;
        let maximum;
        let sessionItem;
        //
        // set default for compare maximum
        //
        if (this.settings.compare_maximum) {
          maximum = this.settings.compare_maximum;
        } else {
          maximum = 3;
        }
        if (this.compares.length >= maximum) {
          limitReached = true;
        }

        if (compared && limitReached) {
          //
          // if entity has been added and limit has been reached
          // reset compare property on entity & show ribbon with limit state
          //
          this.$store.dispatch('updateShowRibbon', true);
          this.$store.dispatch('updateRibbonError', true);
          sessionItem = { id: entity.id, compare: true, name: entity.name };
          this.$store.dispatch('updateCompare', sessionItem)
          .then(() => {
            setTimeout(() => {
              sessionItem.compare = false;
              //
              // remove item from compare list
              //
              this.$store.dispatch('updateCompare', sessionItem);
            }, 300);
          });
        } else {
          sessionItem = { id: entity.id, compare: compared, name: entity.name };
          if (compared) {
            //
            // if entity has been saved and is not past the limit, add to compares & show ribbon
            //
            this.$store.dispatch('updateShowRibbon', true);
          } else {
            //
            // if removing an entity from the compare list, hide the ribbon (if not hidden already)
            //
            this.$store.dispatch('updateShowRibbon', false);
          }
          this.$store.dispatch('updateCompare', sessionItem);
        }
      },
      colClass: function colClass(col) {
        const columnClass = [];
        if (col.sort_direction === 1 || col.sort_direction === -1) {
          columnClass.push('sort-active');
        }
        return columnClass.join(' ');
      },
      colVisible: function colVisible(col) {
        //
        // determines if a column is visible or hidden on mobile
        //
        if (this.breakpoint === 'smartphone') {
          if (col.is_row_header || col.visible_mobile) return true;
          return false;
        }
        return true;
      },
      setInitialMobileCols() {
        //
        // the result table will always show only two columns on mobile
        // ResultTableWithMobile.vue is a wrapper that will add
        // additional mobile functionality, as needed
        //
        const filteredColumns = this.filteredColumns;
        for (let i = 0, l = filteredColumns.length; i < l; i ++) {
          const col = filteredColumns[i];
          if (col.is_row_header || col.visible_mobile_default) {
            Vue.set(filteredColumns[i], 'visible_mobile', true);
          } else {
            Vue.set(filteredColumns[i], 'visible_mobile', false);
          }
        }
      },
      getColIsSortable(col) {
        //
        // determines if a column can be sorted using the <th> element
        // must be configured with is_sortable
        // no column can be sorted using the column header on mobile
        // unless configured with mobile_header_sort = true
        //
        return col.is_sortable && (this.breakpoint !== 'smartphone' || this.settings.mobile_header_sort); // eslint-disable-line max-len
      },
    },
    watch: {
      filteredColumnIds: function filteredColumnIds(curr, prev) {
        //
        // reset table sort when the available columns change
        //
        const reset = true;
        const match = arraysMatch(curr, prev);
        if (!match) {
          this.setInitialTableSort(reset);
          this.setInitialMobileCols();
        }
      }
    }
  };
</script>
