<template>
  <div class="search-wrapper search-container-light c-input">
    <label :for="id"><span class="sr-only">Search</span></label>
      <slot name="input" class="c-input__input flex-grow-1"></slot>
      <div class="search-icon c-input__icon"><slot name="icon"></slot></div>
  </div>
</template>
<script type="text/javascript">
module.exports = {
  props: ['id']
};
</script>
