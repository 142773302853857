<template>
  <fieldset v-if="valueCount" :class="settings.metadata.label">

    <!-- desktop or searchable title -->
    <legend class="mb-0" :class="searchable ? 'd-block' : 'd-none d-md-block'">{{settings.group_title_text}}</legend>

    <!-- mobile title & show/hide toggle -->
    <legend
      class="mb-0 d-md-none"
      v-if="!searchable"
      v-on:click="toggleShowAll">
      {{settings.group_title_text}}
      <i class="ml-2 fa" :class="showAll ? 'fa-angle-down' : 'fa-angle-right'" v-if="valueCount > mobileShowAllCount && !searchable" ></i>
    </legend>
    <rich-text
      v-if="settings.description_text"
      :text="settings.description_text"
      :tag="'p'"
      class="p--utility--light mt-12"
    ></rich-text>


    <!-- search for the extra-long list -->
    <div v-if="searchable && !showAll" class="d-flex">
      <typeahead-dropdown
        v-if="!showAll"
        class="filter-input mt-12 w-100"
        :options="filteredValues"
        :enter-key-action="'selectResultClearInput'"
        :placeholder="placeholder"
        :no-results="{text: settings.no_results_text}"
        @update:selected="val => { handleFilterClick(true, val)}"
        >
        <template slot="option-item" slot-scope="slotProps">
          <div
            v-for="(option, index) in slotProps.searchResults"
            tabindex="0"
            class="c-input__option d-block"
            :class="index === slotProps.highlightIndex ? 'c-input__option--current-selection' : ''"
            v-on:click.prevent="slotProps.selectActive(option)"
          >{{option.text}}</div>
        </template>
      </typeahead-dropdown>
    </div>

    <!-- checkboxes -->
    <div class="filter__options" data-simplebar
      v-if="showOptions.length && showOptions.length > 0">
      <div class="sp-v-2">
        <component
          v-for="(item, index) in showOptions"
          :class="showAll ? '' : getOptionClass(item, index)"
          :key="index"
          :style="!index ? 'margin-top:12px!important' : ''"
          :is="layout && layout.option_tag ? layout.option_tag.tag : 'check-box'"
          :value="item"
          :checked="item.required"
          @update:checked="checked => { handleFilterClick(checked, item) }"
          :disabled="!enabled"
        ></component>
      </div>
    </div>


    <!-- desktop show/hide toggle -->
    <!-- always show on mobile, if the list is searchable -->
    <button type="button" tabindex="0"
      class="c-link c-link--utility c-link--button mt-1 w-auto"
      :class="searchable ? 'd-block' : 'd-none d-md-block'"
      v-on:click="toggleShowAll"
      v-if="!settings.metadata.never_show_all && valueCount > minimizedCount"
    ><span class="c-link--button-content" tabindex="-1">{{showAll ? (showLessText || '[Show less]') : showMoreText}}</span></button>

  </fieldset>
</template>
<script type="text/javascript">
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  const $ = require('jquery');
  import max from 'lodash/max';
  import sendEvent from 'tembo-js/sendEvent';
  import TypeaheadDropdown from '../TypeaheadDropdown.vue';
  import CheckBox from '../CheckBox.vue';

  module.exports = {
    props: ['settings', 'layout'],
    mixins: [FilterGroupMixin],
    components: {
      CheckBox,
      TypeaheadDropdown,
    },
    data() {
      return {
        showAll: false,
        minimizedCount: (this.settings.minimized_count || 5),
        mobileShowAllCount: (this.settings.mobile_show_all_count || 10),
        searchCount: (this.settings.search_count || 25),
      };
    },
    computed: {
      searchable() {
        return this.settings.metadata.searchable && this.valueCount > this.searchCount;
      },
      showMoreText() {
        let remainder = this.valueCount - this.minimizedCount;
        let text = this.settings.show_more_text || '[Show [xx] more]';
        if (this.searchable) {
          remainder = this.valueCount;
          text = this.settings.show_all_text || '[Select from all [xx] options]';
        }
        return text.replace('[xx]', remainder);
      },
      showLessText() {
        let text = this.settings.show_less_text || '[Show less]';
        if (this.searchable) {
          text = this.settings.show_search_text || '[Search for [zz]]';
        }
        return text.replace('[zz]', this.settings.group_title_text);
      },
      placeholder() {
        const text = this.settings.placeholder_text || '[Search for [zz]]';
        return text.replace('[zz]', this.settings.group_title_text);
      },
      valueCount() {
        return this.filteredValues.length;
      },
      showOptions() {
        const searchable = this.searchable;
        const showAll = this.showAll;
        const minimizedCount = this.minimizedCount;
        const mobileShowAllCount = this.mobileShowAllCount;
        const searchCount = this.searchCount;
        const valueCount = this.valueCount;

        return this.filteredValues.filter((item, index) => {
          if (showAll) return true;
          if (searchable) {
            if (item.sticky) return true;
            return false;
          }
          const maxShow = max([mobileShowAllCount, minimizedCount, searchCount]);
          if (valueCount <= maxShow) return true;
          if (index <= maxShow) return true;
          return false;
        });
      }
    },
    methods: {
      getOptionClass(item, index) {
        function spliceMeOut(list, value) {
          const idx = list.indexOf(value);
          if (idx > -1) list.splice(idx, 1);
          return list;
        }

        const mobileShowAllCount = this.mobileShowAllCount;
        let optClass = ['d-none'];

        if (this.searchable) {
          if (item.sticky) {
            optClass = spliceMeOut(optClass, 'd-none');
            optClass.push('d-block');
          }
          if (!item.sticky) {
            optClass.push('d-none');
          }
          return optClass.join(' ');
        }

        if (this.valueCount <= mobileShowAllCount) {
          optClass = spliceMeOut(optClass, 'd-none');
          optClass.push('d-md-none');
        }

        if (index < this.minimizedCount) {
          optClass = spliceMeOut(optClass, 'd-md-none');
          optClass.push('d-md-flex');
        }
        return optClass.join(' ');
      },
      toggleShowAll() {
        this.showAll = !this.showAll;

        // on mobile: when a facet group is expanded,
        // scroll to show as much of the section as possible
        const mobileFacetScrollingDiv = $(this.$el).closest('.mobile-facet--scrolling-div');
        if (mobileFacetScrollingDiv.length && this.showAll) {
          const selfEl = $(this.$el);

          const currentItemPosition = selfEl.position().top;

          const firstSibling = mobileFacetScrollingDiv.find('.row').first();
          const firstSiblingHeight = firstSibling.height()
            + parseInt(firstSibling.css('marginTop'), 10)
            + parseInt(firstSibling.css('marginBottom'), 10)
            + parseInt(firstSibling.css('paddingTop'), 10)
            + parseInt(firstSibling.css('paddingBottom'), 10);

          const selfSpacing = parseInt(selfEl.css('marginTop'), 10)
            + parseInt(selfEl.css('paddingTop'), 10);

          // this is the padding at the top of the
          // FiltersContainer component
          // that's added in MobileFacets
          const filtersContainerPadding = 20;

          const newScroll = currentItemPosition
            + firstSiblingHeight
            + filtersContainerPadding
            + selfSpacing;
          mobileFacetScrollingDiv.animate({ scrollTop: newScroll }, 200);
        }
        //
        // send google analytics event
        //
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_filter` : 'filter';
        const action = this.showAll ? 'expand' : 'collapse';
        sendEvent({
          category: category,
          action: `${action}_category`,
          label: this.settings.metadata.label,
        });
      },
      handleFilterClick: function handleFilterClick(checked, value) {
        if (!value) return;
        let action;
        const newval = JSON.parse(JSON.stringify(value));
        if (checked) action = 'select';
        else action = 'deselct';
        newval.required = checked;

        if (this.searchable) newval.sticky = true;
        const findView = this.$route.params.findView;
        const category = findView ? `${findView}_filter` : 'filter';
        this.$store.dispatch('updateFilterValue', { filterValue: newval, updateEntities: true });
        sendEvent({
          category: category,
          action: `${action}_${this.settings.metadata.label}`,
          label: value.value
        });
      },
    },
  };
</script>
<style type="text/css" scoped>
  /*.scrollable{
    max-height: 200px;
    overflow-y: scroll;
  }*/
  fieldset > * {
    clear: both;
    float: left;
    width: 100%;
  }
  @media screen and (max-width: 899px) {
    .c-link--button {font-size: 16px;}
  }

  .filter-input .c-input__value{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>