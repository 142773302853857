<template>
  <div class="p-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-auto d-flex flex-column sp-v-3 align-items-center align-items-md-start">
          <h3>{{settings.contact_title}}</h3>
          <p class="">{{settings.name}}</p>
          <p><a target="_blank" :href="settings.website_url">{{settings.website_url}}</a></p>
          <p>{{settings.email.url}}</p>
          <p><a target="_blank" :href="settings.facebook_address"><i class="fab fa-facebook-square fa-2x"></i></a></p>
        </div>
        <div class="mt-5 mt-md-0 col-12 col-md-3 offset-lg-1 d-flex flex-column align-items-center align-items-md-start sp-v-3">
          <h3>{{settings.resources_title}}</h3>
          <p><a :href="settings.state_report_url">{{settings.state_report_text}}</a></p>
        </div>
        <div class="col-12 col-md-auto ml-md-auto ml-0 mt-4 mt-md-0 align-items-center align-items-md-start d-flex flex-column" >
          <attribution-google :settings="settings"></attribution-google>

          <span class="mt-3 mt-md-5">
              <attribution-inline :settings="settings"></attribution-inline>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">

  import AttributionInline from './AttributionInline.vue';
  import AttributionGoogle from './AttributionGoogle.vue';
  module.exports = {
    components: { AttributionInline, AttributionGoogle },
    props: ['settings']
  };
</script>