<template>
  <div v-bind:class="mobileView">
    <!-- INTRO COMPONENT -->
    <!-- REQUIRED PROPS:
      :is="renderable.intro.tag"
      :show="showIntro"
      :settings="renderable.intro.data"
     -->
    <!-- ADDITIONAL PROPS REQUIRED FOR WIZARD COMPONENT:
      :address-entry="settings.address_entry"
      :address="address"
      :home="home"
      :filters="filters"
      :sorts="sorts"
      -->
    <div :is="renderable.intro.tag"
      :show="showIntro"
      :settings="renderable.intro.data"
      :address-entry="settings.address_entry"
      :address="address"
      :home="home"
      :filters="filters"
      :sorts="sorts"
    ></div>

    <!-- MODALS -->
    <!-- each modal requires tag, data/settings and show
      compares, favorites & address are the only items required
      to sync with other components
      other properties are required by some, but not al
     -->
      <div v-if="showModal && modalTag && modalData"
        :is="modalTag"
        :show="showModal"
        :settings="modalData"

        :compares="compares"
        :favorites="favorites"
        :address="address"

        :home="home"
        :filters="filters"
      ></div>

    <!-- HEADERS -->
      <header class="header">
        <a href="#main-content" class="sr-only">Skip to main content</a>
        <div v-for="component in renderable.header"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"
          :home="home"
          :filtered="filtered"
          :compares="compares"
          :class="component.tag">
        </div>
      </header>

    <!-- SIDEBAR -->
    <div class="sidebar">
      <!-- ADDRESS ENTRY -->
      <address-entry-with-modals
         class="address-entry"
        :settings="renderable.sidebar.address_entry.data"
        :layout="renderable.sidebar.address_entry.layout"
        :address="address"
        :home="home"
      ></address-entry-with-modals>
      <!-- FILTERS -->
      <div v-for="component in renderable.sidebar.filters"
        :is="component.tag"
        :settings="component.data"
        :layout="component.layout"
      ></div>
      <filter-controls
        :class="renderable.sidebar.filter_controls.tag"
        :settings="renderable.sidebar.filter_controls.data"
        :layout="renderable.sidebar.filter_controls.layout"
      ></filter-controls>
    </div>

    <!-- MAIN COLUMN -->
    <div class="main-content" id="main-content">
      <div :is="renderable.content[0].tag"
        :settings="renderable.content[0].data"
        :layout="renderable.content[0].layout"
        :filters="filters"
        :home="home"
        :filtered="filtered"
        :compares="compares"
        :favorites="favorites"
        :neighborhood-schools="neighborhoodSchools"
        :class="renderable.content[0].tag">
      </div>
      <list-controls
        :layout='renderable.content[1].layout'
        :sort-options='settings.sort_options'
        :home='home'
        :filtered='filtered'
        :map-component='settings.map_component'
        :name-filter='filters.name_search'
        :class='renderable.content[1].tag'></list-controls>
      <div :is="renderable.content[2].tag"
        :settings="renderable.content[2].data"
        :layout="renderable.content[2].layout"
        :filters="filters"
        :home="home"
        :filtered="filtered"
        :compares="compares"
        :favorites="favorites"
        :neighborhood-schools="neighborhoodSchools"
        :class="renderable.content[2].tag">
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  var AppMixinFilters = require('../mixins/AppMixinFilters');
  var AppMixinMap = require('../mixins/AppMixinMap');
  import AddressEntryWithModals from '../components/AddressEntryWithModals.vue';
  import FilterControls from '../components/FilterControls.vue';
  import ListControls from '../components/Controls_2_col.vue';

  module.exports = {
    components: {
      AddressEntryWithModals,
      FilterControls,
      ListControls,
    },
    mixins: [AppMixinBase, AppMixinFilters, AppMixinMap]
  };
</script>