<template>
  <modal-component :show="show" @update:show="val => { show = val }" :click-away="true">

    <h1 class="modal-h1">You have filtered out all the schools. Try removing some filters.</h1>
    <p class="modal-p">Please change your Filter selections to find matches.</p>

    <div class="button button--cta" v-on:click="closeModal">Got it</div>
  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  module.exports = {
    props: ['show'],
    components: { ModalComponent },
    methods: {
      closeModal: function closeModal() {
        this.$emit('uodate:show', false);
      }
    }
  };
</script>