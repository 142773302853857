<!--
required props in settings:
  - entity_prop
optional props in settings:
  - data_handling: 'datapoint' -> expect data at entity prop to have at least 'value' or 'exception_code' attributes
  - exception_codes: dict with exception codes & short_name. always use short_name. could be configurable later
  - number_format:
    - commas: true/false
    - precision: integer
    - format: 'currency', 'percent', 'ordinal'
  - missing_data_message: string. message to display if no data is found using entity_prop or exception_codes
 -->
<template>
  <div>
    <!-- Use entity data -->
    <h3 class="h3--compare text-center mb-2">{{displayData || missingDataMessage}}</h3>
    <p v-if="settings.label && displayData" class="text-center compare-data">{{this.settings.label || 'subtitle here'}}</p>
  </div>
</template>
<script type="text/javascript">
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import formatNumeric from 'tembo-js/formatNumeric';

  module.exports = {
    props: ['settings', 'entity'],
    computed: {
      displayData() {
        let data = getDataByProperty(this.settings.entity_prop, this.entity);

        // if it's a datapoint, get the value attribute
        if (data && this.settings.data_handling && this.settings.data_handling === 'datapoint') {
          data = getDataByProperty('value', data);
        }

        // format number, if applicable
        if (data && this.settings.number_format) {
          return formatNumeric(data, this.settings.number_format);
        }

        return data;
      },
      missingDataMessage() {
        let data = getDataByProperty(this.settings.entity_prop, this.entity);

        // if it's a datapoint, get the exception code
        if (data && this.settings.data_handling && this.settings.data_handling === 'datapoint') {
          data = getDataByProperty('exception_code', data);
          if (data && this.settings.exception_codes) {
            return this.settings.exception_codes[data].short_name;
          }
        }

        // try for a general message
        if (this.settings.missing_data_message) return this.settings.missing_data_message;
        return '';
      },
    },
  };
</script>
