<!-- MAUDE -->
<template>
  <main class="d-flex flex-column flex-grow-1">
    <component
      :is="layout.site_nav.tag"
      :settings="layout.site_nav.data"
      :layout="layout.site_nav.layout"
      class="position-relative"

    ></component>
    <section class="d-flex flex-column flex-grow-1">
      <div class="p-hero pb-5 pb-md-100 pt-60 pt-md-80">
      <div class="container h-100">
        <div class="row h-100">

          <div class="col-12 col-md-6 px-md-gutter d-flex flex-column justify-content-center">
            <p class="p-hero__p mb-4" v-html="getTextToRender('hero_text')"></p>
            <h1 class="p-hero__h1 mb-5">{{ getTextToRender('hero_h1', 'What’s your story?') }}</h1>
            <h2 class="p-hero__h2 mb-3 d-none d-md-block">{{ getTextToRender('hero_h2', 'Learn all about your school.') }}</h2>
            <div class="d-flex" v-if="settings.search">
              <typeahead-dropdown-link class="flex-grow-1"
                :placeholder="settings.search.placeholder"
                :options="settings.search.options"
                :link-template="settings.entity_url_template"
                :no-results="settings.search.no_results"
                :hero="true"
              ></typeahead-dropdown-link>
            </div>
          </div>
          <div class="image-of-family-container col-12 col-md-6 d-flex flex-column px-lg-100 mt-5 mt-md-0">
            <img class="img-fluid image-of-family mt-auto flex-shrink-0 w-100" :src="settings.hero_img_src" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-100 pb-100 main-content-wrapper">

      <!-- FIND ROUTES -->
      <div class="row" v-if="settings.find_routes">
        <div class="col-12 p-hero-links d-flex flex-column flex-md-row justify-content-around align-items-center mt-80 mb-100 sp-v-gutter sp-v-md-0">
          <div v-for="route in settings.find_routes" class="d-flex align-items-center">

            <img class="d-none d-md-block" :srcset="route.img_srcset"
              :src="route.img_src"
              :alt="route.img_alt_text" width="110px" height="110px" style="margin-right: 40px;">

            <div class="text-center text-md-left">
              <p class="p-hero-links__title">{{route.prompt_text}}</p>
              <a class="p-hero-links__link" :href="route.href + getQueryString($route.query)" v-html="route.link_text"></a>
            </div>
          </div>

        </div>
      </div>

      <section class="row">
        <article class="col-12 col-md-10 d-flex flex-column offset-md-1" v-if="(settings.welcome && settings.welcome.length) || (settings.grid && settings.grid.length)">
          <template v-if="settings.welcome && settings.welcome.length">
          <template v-for="(section, index) in settings.welcome">
            <div class="d-flex flex-column text-center flex-column-reverse mb-4" :class="index > 0 ? 'order-last order-md-0 mt-60 mt-md-100' : ''">
              <h1 class="h1--hero text-center">{{ section.title }}</h1>
              <h2 class="h2--hero mb-2 text-center">{{ section.header }}</h2>
            </div>
            <rich-text
              class="text-center" :class="index > 0 ? 'order-last order-md-0' : ''"
              :text="section.description"
              :tag="'p'"
            ></rich-text>
          </template> <!-- endfor -->
          </template> <!-- endif -->

          <template v-if="settings.grid && settings.grid.length">
          <aside class="row mt-100 justify-content-around" v-for="row in Math.ceil(settings.grid.length / 2)" :class="row > 0 ? 'mt-gutter mt-md-100' : ''">
            <template v-for="col in 2">
              <div v-if="row * 2 + col - 3 < settings.grid.length" class="col-12 col-md-5" :class="col === 1 ? '' : 'mt-5 mt-md-0'">
                <img class="mx-auto d-block" :src="settings.grid[row * 2 + col - 3].image_src" alt="" width="240px" height="240px">
                <h3 class="h3--hero mt-20 text-center">{{ settings.grid[row * 2 + col - 3].title }}</h3>
                <p class="text-center mt-2">{{ settings.grid[row * 2 + col - 3].description }}</p>
              </div>
            </template>
          </aside>
          </template>
        </article>

      </section>
      </div>
    </section>
    <component
      v-if="layout.footer && layout.footer.data"
      :is="layout.footer.tag"
      :settings="layout.footer.data"
      class="mt-80 p-footer-has-before-image"
    ></component>
  </main>
</template>
<script type="text/javascript">
  import TypeaheadDropdownLink from '../TypeaheadDropdownLink.vue';
  import staticData from '../../store/data';
  import utils from '../../store/utils';
  import getQueryStringLibrary from 'tembo-js/getQueryString';
  import isHTML from 'tembo-js/isHTML';
  import RichText from '../RichText.vue';


  // console.log(staticData)
  module.exports = {
    components: {
      TypeaheadDropdownLink,
      RichText,
    },
    props: {
      settings: {
        type: Object,
        default: () => ({}),
        validator: obj => {
          // Props mapped from implementation mapping and config file (/assets/config/Finder.yml).
          const expectedProps = ['page_title', 'hero_text', 'hero_h1', 'hero_h2', 'hero_img_src',
            'hero_img_mobile_src', 'hero_img_alt'];
          const missingProps = expectedProps.filter(prop => !obj.hasOwnProperty(prop));
          if (staticData.homepage.config.defaults.debugger) {
            console.log(`Missing props from HomeHeroDetails: ${missingProps}`); // eslint-disable-line no-console, max-len
          }
          return true;
        }
      },
      layout: {
        type: Object
      },
    },
    data() {
      return {
        selectedEntity: null,
        debug: this.$store.state.debug,
      };
    },
    mounted() {
      var loc;
      if (!this.settings.page_title) {
        loc = window.location;
        document.title = loc.hostname + loc.pathname + loc.search;
      } else {
        document.title = this.settings.page_title;
      }
    },
    computed: {
      entityUrl() {
        if (!this.selectedEntity) return '';
        return utils.getEntityUrl(this.selectedEntity, this, this.settings.entity_url_template); // eslint-disable-line max-len
      },
    },
    methods: {
      isHTML: isHTML,
      getQueryString: getQueryStringLibrary,
      lineBreakJoin: utils.lineBreakJoin,
      getTextToRender: function getTextToRender(prop, placeholder) {
        const textToRender = (this.settings[prop]) || (this.debug ? `[${prop}]` : placeholder);
        return Array.isArray(textToRender) ? this.lineBreakJoin(textToRender) : textToRender;
      },
    }
  };
</script>
