<template>
  <div class="sort-wrapper o-two-column-layout__filter-list__group">
    <label>

      <select-component
        :options="values"
        :selected="requiredValue"
        :placeholder-value="settings.prompt_text"
        :use-title="settings.group_title_text ? true : false"
        @update:selected="val => { handleChange(val) }"
      >  <h2 slot="select-title" v-if="settings.group_title_text" class="c-select__title">{{settings.group_title_text}}</h2>
      </select-component>
    </label>
  </div>
</template>
<script type="text/javascript">
  import SelectComponent from '../Select.vue';
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  var WrappedSelectFilterMixin = require('../../mixins/WrappedSelectFilterMixin');
  module.exports = {
    components: { SelectComponent },
    mixins: [FilterGroupMixin, WrappedSelectFilterMixin]
  };
</script>
