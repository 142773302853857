import getDataByProperty from './getDataByProperty';

function flattenByProperty(prop, data, def) {
  let defaultVal;
  let flattened = [];
  if (!prop) return data;
  if (typeof def === 'undefined') {
    defaultVal = null;
  } else {
    defaultVal = def;
  }
  if (!data) return defaultVal;
  if (!Array.isArray(data)) {
    flattened = getDataByProperty(prop, data, defaultVal);
  } else {
    flattened = data.map(item => getDataByProperty(prop, item, defaultVal));
    // for (let i = 0, len = data.length; i < len; i++) {
    //   const row = data[i];
    //   flattened.push(getDataByProperty(prop, row, defaultVal));
    // }
  }
  return flattened;
}

export default flattenByProperty;
