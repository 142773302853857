import getDataByProperty from 'tembo-js/getDataByProperty';
const utils = require('./utils');
import staticData from './data';

const earthRadius = {
  km: 6371,
  mi: 3961
};

function toRad(deg) {
  return Math.PI * deg / 180;
}

const convertMeters = {
  mi: function metersToMiles(meters) {
    return meters * 0.000621371;
  },
  km: function metersToKm(meters) {
    return meters / 1000;
  }
};

function makeRounder(n) {
  return function roundToNDec(num) {
    var factor = Math.pow(10, n);
    return Math.round(num * factor) / factor;
  };
}

function convertSeconds(sec) {
  var hours;
  var minutes;
  var seconds = sec;
  hours = Math.floor(seconds / 3600);
  seconds = seconds - hours * 3600;
  minutes = Math.floor(seconds / 60);
  seconds = seconds - minutes * 60;
  return hours + ':' + minutes;
}


function applyGoogleDistance(rows) {
  var cacheUrl;
  var distanceMode;

  distanceMode = this.getGoogleDistanceMode();
  this.googleLastDistanceMode = distanceMode;


  cacheUrl = '/google_distance?mode=' + distanceMode.value +
             '&lat=' + this.home.address.geometry.location.lat +
             '&lng=' + this.home.address.geometry.location.lng;
  // check cache
  this.$http.get(cacheUrl)
  .then(function success(res) {
    var i;
    var l;
    for (i = 0, l = res.body.length; i < l; i ++) {
      this.applyOneDistance(res.body[i].id, res.body[i].distance);
    }
    this.$emit('AllDistancesUpdated', false);
  }, function fail(err) {
    if (err.status === 404) {
      //
      // home location/mode combination not found in cache
      // get distance from google service
      //
      if (!this.googleDisabled) {
        this.getGoogleDistances(rows);
      }
    }
  });
}

function makeGoogleCallback(destinationList, mode) {
  //
  // makes callback to run on completion of a single
  // request for a set of distances from google
  //
  var distanceMetadata = this.settings.distance_calculation.metadata;
  var units = distanceMetadata.units;

  return function callback(res, status) {
    //
    // converts meters to correct format, converts seconds to h:m
    // emits events to update entities
    //
    var element;
    var distance;
    var time;
    var row;
    var l;
    var i;
    var entity;
    var rounder;
    var results = [];
    if (status === 'OK') {
      row = res.rows[0];
      l = row.elements.length;
      rounder = makeRounder(this.settings.distance_calculation.metadata.round_to_decimals);
      for (i = 0; i < l; i ++) {
        entity = destinationList[i];
        element = row.elements[i];
        if (element.status === 'OK') {
          distance = convertMeters[units](element.distance.value);
          distance = rounder(distance);
          time = convertSeconds(element.duration.value);
          if (Number.isNaN(time)) time = null;
          this.$emit('DistanceReceived', entity.id, { distance: distance, time: time, mode: mode }); // eslint-disable-line max-len
          results.push({ distance: distance, time: time });
        } else {
          this.$emit('DistanceReceived', entity.id, { distance: null, time: null, mode: null });
          results.push({ distance: null, time: null });
        }
      }
    } else {
      console.error('Distances not received for', destinationList.length, 'destinations:', status); // eslint-disable-line no-console, max-len
      this.googleDistanceUpdateCount += destinationList.length;
    }
  };
}

function getGoogleDistances(rows) {
  //
  // for a set of rows, make requests to google
  // 25 entities at a time
  // to get travel distance & time from home
  //
  /* eslint-disable no-new, no-undef */
  var service;
  var destinations;
  var destinationSet;
  var distCallback;
  var entities = this.copy(rows);
  var destinationEntities;
  var distanceOptions;
  var drivingOptions;
  var dayAdjustment;
  var departureDate;
  var distanceMetadata = this.settings.distance_calculation.metadata;
  var now;
  var origins = [this.home.address.geometry.location];
  var unitSystem;
  var units;
  var distanceMode;

  distanceMode = this.getGoogleDistanceMode();

  this.googleDisabled = true;
  units = distanceMetadata.units;

  if (units === 'mi' || units.indexOf('mile') > -1) {
    unitSystem = google.maps.UnitSystem.IMPERIAL;
  } else {
    unitSystem = google.maps.UnitSystem.METRIC;
  }


  entities = entities.filter(e => e.location && e.location.lat && e.location.lng);
  destinations = entities.map(r => r.location);


  // use driving options in config, if available
  if (distanceMetadata.driving_options) {
    drivingOptions = {};
    if (distanceMetadata.driving_options.departure) {
      now = new Date();
      dayAdjustment = (7 + distanceMetadata.driving_options.departure.day - now.getDay()) % 7;
      departureDate = new Date(now.getTime());
      departureDate.setDate(now.getDate() + dayAdjustment);
      departureDate.setHours(distanceMetadata.driving_options.departure.hour);
      departureDate.setMinutes(0);
      departureDate.setSeconds(0);
      departureDate.setMilliseconds(0);
      if (departureDate.getTime() < now.getTime()) {
        departureDate.setDate(departureDate.getDate() + 7);
      }
      drivingOptions.departureTime = departureDate;
    } else {
      drivingOptions = null;
    }
  }

  service = new google.maps.DistanceMatrixService();

  //
  // make calls to google distance service
  // in groups of <= 25 entities
  //
  while (destinations.length) {
    destinationSet = destinations.splice(0, 25);
    destinationEntities = entities.splice(0, 25);
    distCallback = this.makeGoogleCallback(destinationEntities, distanceMode);
    distanceOptions = {
      origins: origins,
      destinations: destinationSet,
      travelMode: distanceMode.value,
      // transitOptions: TransitOptions,
      unitSystem: unitSystem
      // avoidHighways: Boolean,
      // avoidTolls: Boolean,
    };
    if (drivingOptions) {
      distanceOptions.drivingOptions = drivingOptions;
    }
    service.getDistanceMatrix(distanceOptions, distCallback.bind(this));
  }
  /* eslint-enable */
}

function getGoogleDistanceMode() {
  var distanceMode;
  var defaultMode = this.settings.distance_calculation.metadata.default_mode;
  try {
    distanceMode = getDataByProperty(this.settings.distance_calculation.metadata.related_filter, this); // eslint-disable-line max-len
    distanceMode = distanceMode.value.filter(mode => mode.required)[0];
  } catch (e) {
    distanceMode = defaultMode;
  }
  return distanceMode;
}

function saveToCache() {
  //
  // saves current entity distances to redis
  // using current home location & selected (or default) travel mode
  //
  var distanceMode = this.getGoogleDistanceMode();
  var cacheUrl = '/google_distance?mode=' + distanceMode.value +
             '&lat=' + this.home.address.geometry.location.lat +
             '&lng=' + this.home.address.geometry.location.lng;
  var formData = new FormData();
  var entities = this.copy(this.entities);
  entities = entities.map((e) => {
    var newEntity = {};
    newEntity.id = e.id;
    newEntity.distance = e.computed.distance_from_home;
    return newEntity;
  });
  formData.append('data', JSON.stringify(entities));
  this.$http.post(cacheUrl, formData);
}

const distanceMethods = {
  manhattan: function manhattan(pos1, pos2, units) {
    return [pos1, pos2, units];
  },
  haversine: function haversine(pos1, pos2, units) {
    var lat1 = pos1.lat;
    var lat2 = pos2.lat;
    var lng1 = pos1.lng;
    var lng2 = pos2.lng;
    var R = earthRadius[units];
    var φ1 = toRad(lat1);
    var φ2 = toRad(lat2);
    var Δφ = toRad(lat2 - lat1);
    var Δλ = toRad(lng2 - lng1);

    var a = Math.pow(Math.sin(Δφ / 2), 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.pow(Math.sin(Δλ / 2), 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var d = R * c;

    return d;
  }
};


function calculateDistance(distanceSettings, pos1, pos2) {
  var getDistance = distanceMethods[distanceSettings.method];
  return getDistance(pos1, pos2, distanceSettings.units, this);
}

function applyNullDistance(rows) {
  const keys = Object.keys(rows);
  const updatedRows = rows;
  for (let i = 0, l = keys.length; i < l; i++) {
    const key = keys[i];
    const row = rows[key];
    row.computed.distance_from_home = null;
    updatedRows[key] = row;
  }
  return updatedRows;
}


function applySynchronousDistance(distanceSettings, home, rows) {
  var rounder = makeRounder(distanceSettings.round_to_decimals);
  var i;
  var keys = Object.keys(rows);
  var l = keys.length;
  var updatedRows = rows;
  var row;
  var location;
  var distFromHome;
  for (i = 0; i < l; i++) {
    row = rows[keys[i]];
    location = getDataByProperty(distanceSettings.match_data_path, row);
    if (location && home) {
      location = utils.generalizeLoc(location);
      if (location.lat && location.lng) {
        distFromHome = calculateDistance(distanceSettings, location, home);
        distFromHome = rounder(distFromHome);
      } else {
        distFromHome = null;
      }
    } else {
      distFromHome = null;
    }
    row.computed.distance_from_home = distFromHome;
    updatedRows[keys[i]] = row;
  }
  return updatedRows;
}

function applyOneDistance(id, distance) {
  var idx;
  var i;
  i = this.entities.length;
  while (!idx && i - 1 > 0) {
    if (this.entities[i].id === id) idx = i;
  }
  if (idx || idx === 0) {
    this.entities[idx].computed.distance_from_home = distance;
  }
}


function applyDistanceToEntities(ctx, entities) {
  var entitiesWithDistance;
  if (!ctx.state.home || !(ctx.state.home.user ||
      (staticData.settings.distance_calculation &&
      staticData.settings.distance_calculation.metadata.calculate_on_default))) {
    entitiesWithDistance = applyNullDistance(entities);
  } else if (staticData.settings.distance_calculation.metadata.method === 'google') {
    //
    // safeties for google
    //
    if (ctx.state.entityList.length > 100) {
      //
      // if more than 100 entities supplied for distances, reset calculation method
      // to haversine (straight-line) method & apply this distance to entities
      //
      console.error('You may not use google on > 100 entities! You have', ctx.entityList.length, 'Calculating straight-line distance'); // eslint-disable-line
      this.settings.distance_calculation.metadata.method = 'haversine';
      entitiesWithDistance = applySynchronousDistance(entities);
    } else if (!ctx.state.mapsApiLoaded) {
      //
      // if the maps api hasn't been loaded yet, apply distance = null to all entities
      //
      console.error('Google is not ready for you yet!'); // eslint-disable-line
      entitiesWithDistance = applyNullDistance(entities);
    } else {
      entitiesWithDistance = applyGoogleDistance(entities);
    }
  } else {
    entitiesWithDistance = applySynchronousDistance(
      staticData.settings.distance_calculation.metadata,
      ctx.state.home.address.geometry.location,
      entities);
  }
  return entitiesWithDistance;
  // ctx.commit('setEntities', entitiesWithDistance);
}

module.exports = {
  calculateDistance: calculateDistance,
  applyNullDistance: applyNullDistance,
  applySynchronousDistance: applySynchronousDistance,
  applyGoogleDistance: applyGoogleDistance,
  getGoogleDistances: getGoogleDistances,
  makeGoogleCallback: makeGoogleCallback,
  getGoogleDistanceMode: getGoogleDistanceMode,
  saveToCache: saveToCache,
  applyOneDistance: applyOneDistance,
  applyDistanceToEntities: applyDistanceToEntities
};
