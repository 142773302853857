<template>
  <div class="modal-mask c-modal__mask" v-show="show" v-on:click="handleClickAway">
    <div class="modal-container c-modal__container d-flex flex-column" tabindex="-1" @click.stop :class=containerClass>
      <slot></slot>
    </div>
  </div>
</template>
<script type="text/javascript">
  var ModalMixin = require('../../mixins/ModalMixin');

  module.exports = {
    mixins: [ModalMixin],
    props: ['show', 'x', 'clickAway', 'containerClass'],
    methods: {
      handleClickAway: function handleClickAway() {
        // only allow clickaway if property set to true
        if (this.clickAway) this.closeModal();
      }
    },
    mounted: function mounted() { // MAY NEED TO USE NEXTTICK
      var container = this.$el && this.$el.querySelector('.modal-container');
      function keyup(ev) {
        var escKey = 27;
        // only allow esc key to close if clickaway property set to true
        if (this.show && ev.keyCode === escKey && this.clickAway) {
          this.closeModal();
        }
      }

      document.addEventListener('keyup', keyup.bind(this));
      if (container) { container.focus(); }
    }
  };
</script>
