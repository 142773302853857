<template>
  <div v-if="renderableLayout">

    <!-- HEADERS -->
      <header class="header">
        <a href="#main-content" class="sr-only">Skip to main content</a>
        <div v-for="component in renderableLayout.header"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"
          :home="home"
          :filtered="filtered"
          :compares="compares"
          :class="component.tag">
        </div>
      </header>

    <!-- CONTENTS -->
      <div class="container" id="main-content">
        <div v-for="component in renderableLayout.content"
          :is="component.tag"
          :settings="component.data"
          :layout="component.layout"

          :filters="filters"
          :filtered="filtered"
          :class="component.tag"
          :sorts="sorts">
        </div>
      </div>
    <!-- FOOTER -->
    <div
      :is="renderableLayout.footer.tag"
      :settings="renderableLayout.footer.data"
    ></div>
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  var AppMixinFilters = require('../mixins/AppMixinFilters');

  module.exports = {
    mixins: [AppMixinBase, AppMixinFilters]
  };
</script>
<style type="text/css" scoped>
</style>
