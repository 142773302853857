<template>
  <div>
    <div class="wizard__input__prompt">{{getRepeats(index)=== 1 ? currentScreen.mostImportant[role.value] : currentScreen.alsoImportant}}</div>
    <dropdown-modal :options='options ? options : prevOptions' :choice='selected'></dropdown-modal>
    <div class="wizard-button-container">
      <button v-on:click='goToDetails' :disabled="nextDisabled">{{currentScreen.nextText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');
  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen',
      'index',
      'previousScreens',
      'breadCrumbs',
      'role',
      'year',
      'options',
      'categories',
      'wizardSettings'
    ],
    data: function data() {
      var selected = null;
      var options = null;
      if (this.currentScreen.selected) selected = this.currentScreen.selected;
      if (this.currentScreen.options) options = this.currentScreen.options;
      return {
        selected: selected,
        prevOptions: options
      };
    },
    computed: {
      nextDisabled: function nextDisabled() {
        // check for all required information
        if (!this.selected) return true;
        return false;
      }
    },
    methods: {
      goToDetails: function goToDetails() {
        var idx;
        var i;
        var l;
        var current;
        var crumb;
        var oldGoTo;
        var newGoTo;
        var crumbIdx;

        // data for updating previous screens
        current = this.copy(this.currentScreen);
        current.selected = this.copy(this.selected);
        current.options = this.copy(this.options);
        current.goTo = this.selected.value;

        // reset category options to total/longer version if needed
        if (this.categories.length < this.options.length) {
          this.categories = this.options;
        }

        // find index of selected category option to remove from list for the next time around
        idx = -1;
        i = 0;
        l = this.categories.length;
        while (idx < 0 && i < l) {
          if (this.selected.value === this.categories[i].value) idx = i;
          i += 1;
        }

        // remove selected category from list
        this.categories.splice(idx, 1);

        // text and goto for bread crumbs
        crumb = this.copy(this.currentScreen);
        crumb.text = this.selected.text;
        crumb.idx = this.index;


        // if there are breadcrumbs to follow, re-set them and continue
        crumbIdx = (this.index - this.wizardSettings.repeat_start_index)
          / this.wizardSettings.repeat_cards +
          this.wizardSettings.repeat_start_crumb;
        if (this.index < this.previousScreens.length) {
          // if we are making a new breadcrumb trail,
          // delete all the previous ones & previous screens following this screen
          oldGoTo = this.previousScreens[this.index].goTo;
          newGoTo = this.selected.value;
          if (oldGoTo !== newGoTo) {
            this.resetThisAndFollowingFilters();

            this.previousScreens.splice(this.index + 1);
            // this.previousScreens.push(current);

            this.breadCrumbs.splice(crumbIdx + 1);
            // this.breadCrumbs.push(crumb);
          }
          this.breadCrumbs[crumbIdx] = crumb;
          this.previousScreens[this.index] = current;
        } else {
          this.breadCrumbs.push(crumb);
          this.previousScreens.push(current);
        }
        // this.repeats = reps;
        this.advanceTo(this.selected.value);
      }
    }
  };
</script>