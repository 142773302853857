<template>
  <label class="c-input" data-has-options="true">
    <span class="sr-only">{{placeholder}}</span>
    <div class="c-inputOLD w-100" :class="displayMenu ? 'c-input--open' : ''">
      <input
        type="text"
        class="c-input__value"
        v-model="input"
        v-on:input="handleInput"
        v-on:focus="handleInput"
        v-on:blur="hideMenuTimeout"
        :placeholder="placeholder">
    </div>
    <!-- options -->
    <div v-if="displayMenu" class="c-input__options" v-on:mouseover="cancelTimeout">
      <div class="entity row row-eq-height py-2" v-for="(entity, index) in sortedSearchResults">
        <div class="col-md-6">
          <a class="name" :href="entity.url" v-on:focus="cancelTimeout" v-on:blur="hideMenuTimeout">{{entity.title}}</a>
        </div>
        <div class="col-md-6" v-if="entity.secondary">
          <div class="name-district">{{entity.secondary}}</div>
        </div>
      </div>
    </div>
  </label>
</template>
<script type="text/javascript">
  import debounce from 'lodash/debounce';
  import sortBy from 'lodash/sortBy';
  import getDataByProperty from 'tembo-js/getDataByProperty';
  import { getEntityUrl } from '../store/utils';

  module.exports = {
    props: [
      'placeholder',
      'options',
      'noResults',
      'linkTemplate',
      'searchConfig',
      'displayConfig',
    ],
    data() {
      return {
        input: '',
        displayMenu: false,
        sortedSearchResults: [],
        timeout: null,
      };
    },
    methods: {
      showMenu() {
        this.displayMenu = true;
      },
      hideMenu() {
        this.displayMenu = false;
      },
      hideMenuTimeout() {
        const self = this;
        this.timeout = setTimeout(() => {
          self.displayMenu = false;
        }, 500);
      },
      cancelTimeout() {
        clearTimeout(this.timeout);
      },
      handleInput: debounce(function handleInput() {
        const input = this.input;
        const searchResults = this.getFilteredResults(input, this.options);
        const sortedResults = this.getSortedResults(searchResults, this.searchConfig.sort_fields);
        this.sortedSearchResults = sortedResults.map(item => {
          const id = item.id;
          const title = getDataByProperty(this.displayConfig.title, item);
          const secondary = getDataByProperty(this.displayConfig.secondary, item);
          const url = getEntityUrl(item, this, this.linkTemplate);
          return {
            id,
            title,
            secondary,
            url,
          };
        }, this);


        if (this.input.length && this.sortedSearchResults.length) {
          this.displayMenu = true;
        } else {
          this.displayMenu = false;
        }

        return this.sortedSearchResults;
      }, 300),
      getFilteredResults(input, items) {
        const inputLower = input.toLowerCase();
        return items.filter(item => {
          for (let i = 0, l = this.searchConfig.search_fields.length; i < l; i ++) {
            const field = this.searchConfig.search_fields[i];
            const fieldData = getDataByProperty(field, item);
            if (fieldData.toLowerCase().indexOf(inputLower) > -1) return true;
          }
          return false;
        });
      },
      getSortedResults(items, fields) {
        return sortBy(items, fields);
      },
    },
  };
</script>
<style type="text/css" lang="scss">
  .entity{
    color: grey;
    & a {
      color: grey;
      text-decoration: none;
    }

    &:hover{
      & a{
        color: white;
      }
      background: purple;
      color: white;
    }
  }
</style>