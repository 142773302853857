function isHTML(str) {
  try {
    const doc = new DOMParser().parseFromString(str, 'text/html'); // eslint-disable-line no-undef
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  } catch (e) {
    // pass
  }
  console.error('isHTML must be run in the browser'); // eslint-disable-line no-console
  return '';
}

export default isHTML;
