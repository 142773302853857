<!--
  this component handles the slightly different format
  of sort definition that comes from tables
 -->
<template>
  <div class="sort-wrapper">
    <label>
      <span class="sort-title">{{settings.title_text}}</span>
      <select-component
        :options="settings.options"
        :selected="$store.state.sort.value"
        :use-title="false"
        @update:selected="val => {handleChange(val)}"
        :enablers="enablers"></select-component>
    </label>
  </div>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  import SelectComponent from './Select.vue';

  module.exports = {
    components: { SelectComponent },
    props: [
      'settings',
      'enablers'
    ],
    methods: {
      handleChange: function handleChange(newSort) {
        if (!newSort) return;
        let newSortOption = this.settings.options.filter(opt => opt.value === newSort);
        if (newSortOption.length !== 1) {
          console.error('possible missing or duplicate sort values'); // eslint-disable-line no-console, max-len
        }
        newSortOption = newSortOption[0];

        // this event will be handled if the table has mobile_sort_behavior === 'sort-display'
        this.$emit('update:mobile-cols', newSort);

        this.$store.dispatch('updateSort', newSortOption);
        sendEvent({
          category: 'list',
          action: 'sort',
          label: newSort
        });
      }
    }
  };

</script>