<!-- TODO: update for vue2 -->
<template>
<div class="row row-helper">
  <div class="col-sm-6 sort-search-container">
    <wrapped-select-disable
      class="school-sort"
      :settings="layout.school_sort.data"
      :enablers="enablers"
    ></wrapped-select-disable>
    <div :is="layout.name_search.tag"
      :settings="layout.name_search.data"
      class='name-search'></div>
  </div>
  <div class="col-sm-6">
    <div
      :is="layout.page_control.tag"
      :settings="layout.page_control.data"
      :layout="layout.page_control.layout"
    >
    </div>
    <div
      :is="layout.map_control.tag"
      :value='toggleValue'
      :checked='mapComponent.metadata.show_map'
      :class='layout.map_control.tag'></div>
  </div>
</div>
</template>
<script type="text/javascript">
  import WrappedSelectDisable from './WrappedSelectDisable.vue';

  module.exports = {
    components: { WrappedSelectDisable },
    props: [
      'settings',
      'layout',
      'mapComponent',
      'sortOptions',
      'nameFilter'
    ],
    computed: {
      relaxedFilterCount: function relaxedFilterCount() {
        return this.$store.getters.relaxedFilterCount;
      },
      filtered: function filtered() {
        return this.$store.state.filtered;
      },
      home: function home() {
        return this.$store.state.home;
      },
      toggleValue: function toggleValue() {
        return {
          text: this.mapComponent.display_map_text,
          value: this.mapComponent.metadata.label
        };
      },
      enablers: function enablers() {
        var filtered = this.filtered;
        var home = this.home;
        var relaxedFilterCount = this.relaxedFilterCount;
        var e = {
          filtered: filtered,
          home: home,
          relaxedFilterCount: relaxedFilterCount
        };
        return e;
      }
    },
    methods: {
      childToggleMap: function childToggleMap() {
        this.$dispatch('ToggleMap');
      }
    }
  };

</script>