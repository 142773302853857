<template>
  <div class="tab-filter p-tab-filter">
    <div class="filter-content">
      <fieldset>
        <legend class="sr-only">Filter options</legend>
        <div
          v-for="(value, index) in filteredValues"
          :is="layout.option_tag.tag"
          :value="value"
          :selected="requiredValue"
          @update:selected="val => { handleChange(val) }"
          :name="settings.metadata.label"
          class="filter-item"
        >
          <!-- <label class="text-selector">
            <input
              class="radio"
              type="radio"
              :name="settings.metadata.label"
              :value="value.value"
              v-model="requiredValue">
            <span class="button--control button" :class="value.value === requiredValue ? 'button--active' : ''">{{value.text}}</span>
          </label> -->
        </div>
      </fieldset>
      </div>
  </div>
</template>
<script type="text/javascript">
  // TODO: combine with WrappedSelectFilter & RadioButtonFilter

  import sendEvent from 'tembo-js/sendEvent';
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');

  module.exports = {
    mixins: [FilterGroupMixin],
    computed: {
      requiredValue: function requiredValue() {
        var reqVal;
        var i = 0;
        var l = this.values.length;
        while (!reqVal && i < l) {
          if (this.values[i].required) {
            reqVal = this.values[i].value;
          }
          i += 1;
        }
        return reqVal;
      }
    },
    methods: {
      getItemByValue: function getItemByValue(val) {
        var selectedValue;
        var i = 0;
        var l = this.values.length;
        while (!selectedValue && i < l) {
          if (this.values[i].value === val) {
            selectedValue = this.values[i];
          }
          i += 1;
        }
        return selectedValue;
      },
      handleChange: function handleChange(newval) {
        var prev = this.requiredValue;
        var curr;
        if (prev) {
          prev = this.getItemByValue(prev);
          prev = JSON.parse(JSON.stringify(prev));
          prev.required = false;
          this.$store.dispatch('updateFilterValue', { filterValue: prev, updateEntities: true });
        }
        curr = this.getItemByValue(newval);
        curr = JSON.parse(JSON.stringify(curr));
        curr.required = true;
        this.$store.dispatch('updateFilterValue', { filterValue: curr, updateEntities: true });
        sendEvent({
          category: 'filter',
          action: 'change',
          label: newval
        });
      }
    }
  };
</script>
<style type="text/css" scoped>
.tab-filter{

}
</style>
