<template>
  <label class="star-selector">
    <input type="checkbox" :name="group" :value="value.value" v-model="localChecked">
    <span :class="checked || filled ? 'active fas' : hovered ? 'hovered fal' : 'fal'" class="fa-star"></span><!--
     -->
  </label>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['value', 'filled', 'checked', 'group', 'hovered'],
    computed: {
      localChecked: {
        get: function localChecked() {
          return this.checked;
        },
        set: function localChecked(newval) {
          this.$emit('update:checked', newval);
        }
      }
    }
  };
</script>
<style scoped>
  input {
    /* display: none; */
    position: absolute;
    left:  -9999999px;
  }
  span{
    font-size: 24px;
    transition:  all 100ms ease-in-out;
    cursor: pointer;
    color: #71777a;
  }
  input:focus + span{
    color: gold;
    outline:  1px dotted black;
  }
  span.hovered {
    color: gold;
  }
  span.active {
    color: gold;
  }
</style>