<template>

  <button class="d-inline-flex p-0" v-on:click="toggleCompare"  v-model="localChecked">
    <i class="fal mr-1" style="text-decoration: none;" :class="checked ? 'fa-minus-square' : 'fa-plus-square'"></i>
    <span class="a a--sm" style="line-height: 1rem;">{{labelText}}</span>
  </button>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  global.jQuery = $;
  require('bootstrap/js/tooltip');

  module.exports = {
    props: ['value', 'checked', 'textSaved', 'textAdd', 'textRemove'],
    data: function data() {
      return {
        autoHide: true
      };
    },
    computed: {
      localChecked: {
        get: function get() {
          return this.checked;
        },
        set: function set(newval) {
          this.$emit('update:compare', newval);
        }
      },
      labelText: function labelText() {
        return this.checked ? this.textRemove : this.textAdd;
      }
    },
    methods: {
      toggleCompare: function toggleCompare() {
        this.$emit('update:compare', !this.checked);
      }
    },
    watch: {
      checked: function watchChecked() {
        // this.toggleCompare();
      }
    }
  };
</script>
<style scoped>
  button{
    background: transparent;
  }
</style>