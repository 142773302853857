function sendEvent(data) {
  let exists;
  const event = {
    event_category: data.category,
    event_label: data.label
  };
  try {
    // eslint-disable-next-line no-undef
    if (gtag) exists = true;
  } catch (e) {
    exists = false;
  }
  if (exists) {
    // eslint-disable-next-line no-undef
    gtag('event', data.action, event);
  }
  return event;
}

export default sendEvent;
