<template>
  <!-- DISTANCE: requires computed distance or missing data rule -->
  <div v-if="entityData || entityData === 0">
    <div class="d-inline-flex flex-column align-items-start align-items-md-center">
      <div class="p-entity__attributes__title mb-1">{{header}}</div>
      <div v-if="entityData.value || entityData.value === 0" class=""><span>{{entityData.value}}</span></div>
      <div v-else-if="entityData.exception_code && exception_codes"><span>{{exception_codes[entityData.exception_code].short_name}}</span></div>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['entityData', 'header', 'exception_codes']
  };
</script>
