function replaceSpecialCharacters(text) {
  if (typeof text !== 'string') return text;
  const letterMap = {
    a: 'àáâäæãåāą',
    c: 'ćçč',
    e: 'èéêëēėęẽ',
    i: 'įîĩíïīì',
    l: 'ł',
    o: 'ôöòóœøōõǫ',
    n: 'ñń',
    s: 'š',
    u: 'ûüùúū',
    z: 'žźż',
    // ʻokina
    "'": 'ʻ',
  };
  /*
  uncertain mapping:
      γ
  missing:
      c with apostrophe,
      k with apostrophe,
      t with apotrophe,
      s with apostrophe,
  */
  const keys = Object.keys(letterMap);
  let plainText = text;
  for (let i = 0, l = keys.length; i < l; i++) {
    const key = keys[i];
    const letters = letterMap[key];
    const regstr = `[${letters}]`;
    const regex = new RegExp(regstr, 'ig');
    plainText = plainText.replace(regex, key);
  }
  // remove apostrophes, so they're not necessary
  plainText = plainText.replace("'", '');
  return plainText;
}

export default replaceSpecialCharacters;
