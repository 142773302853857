<template>
  <div class="wizard__breadcrumb">
      <div class="wizard__breadcrumb__item" v-for='crumb in breadCrumbs'v-on:click='goToCrumb(crumb.idx)'>{{crumb.text}}</div>
  </div>
</template>
<script type="text/javascript">
  var WizardMixin = require('../../mixins/WizardMixin');
  module.exports = {
    mixins: [WizardMixin],
    props: [
      'wizardSettings',
      'options',
      'currentScreen',
      'role',
      'year',
      'breadCrumbs'
    ]
  };
</script>