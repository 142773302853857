<template>
  <div class="dropdown-modal-component">
    <div class="dropdown-modal-launcher" tabindex="0" v-on:click="showOptions" v-on:keyup.enter="showOptions"><span v-if="choice">{{ choice.text }}</span></div>
    <div class="dropdown-modal" v-if="show">
      <div class="dropdown-modal__option-container">
         <div tabindex="0" class="dropdown-modal__option-container__option" v-for="option in options" v-on:click="option.disabled ? '' : closeModal(option)" v-on:keyup.enter="option.disabled ? '' : closeModal(option)" v-bind:class="option.disabled ? 'dropdown-modal__option-container__option--disabled' : ''" >{{ option.text }}</div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['options', 'choice'],
    methods: {
      showOptions: function showOptions() {
        this.show = true;
      },
      closeModal: function closeModal(choice) {
        this.show = false;
        this.choice = choice;
      }
    },
    data: function data() {
      return {
        show: false
      };
    }
  };
</script>
