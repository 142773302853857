<template>
  <div>
    <div>{{settings.start_text}}</div>
    <div v-if="!componentVisible" class="a uppercase" v-on:click="showComponent">{{settings.link_text}}</div>
    <div v-else :is="layout.tag" :settings="layout.data" :entities="entities"></div>
  </div>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['settings', 'layout', 'entities'],
    data: function data() {
      return {
        componentVisible: false
      };
    },
    methods: {
      showComponent: function showComponent() {
        this.componentVisible = true;
      }
    }
  };
</script>
<style type="text/css">
  .uppercase{
    text-transform: uppercase;
  }
</style>