<!-- TODO: update for vue2 -->
<template>
  <div class="search-container">
    <input class="search-input c-input__input" type="text" :placeholder="placeholder" v-model="dataInput"></input><div class="search-submit c-input__icon" v-on:click="sendSearchInput" tabindex="0"><i class="fa fa-search"></i></div>
  </div>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  module.exports = {
    props: ['placeholder', 'dataInput'],
    methods: {
      sendSearchInput: function sendSearchInput() {
        $(this.$el).blur();
        this.$dispatch('handleSearchClick');
        $(this.$el).find('.search-submit').blur();
      }
    }
  };
</script>
