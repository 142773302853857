//
// This mixin provides basic FIND functionality:
//
// This mixin does NOT provide:
// * filter functionality
// * map/address entry functionality
//

import getDataByProperty from 'tembo-js/getDataByProperty';

module.exports = {
  props: [
    'defaults',
    'layout',
    'settings'
  ],
  data: function data() {
    return {
      show_lang: false,
    };
  },
  mounted: function mounted() {
    var loc;
    // MAY NEED TO USE NEXTTICK
    //
    // on load, set the document title
    //
    if (!this.settings.page_title) {
      loc = window.location;
      document.title = loc.hostname + loc.pathname + loc.search;
    } else {
      document.title = this.settings.page_title;
    }
  },
  computed: {
    defaultSortOption: function defaultSortOption() {
      return this.$store.getters.defaultSortOption;
    },
    renderableLayout: function renderableLayout() {
      return this.$store.state.renderableLayout;
    },
    title: function title() {
      if (!this.settings) return null;
      return this.settings.page_title;
    },
    filtered: function filtered() {
      return this.$store.state.filtered;
    },
    sortEnablers: function sortEnablers() {
      var e = {};
      var i;
      var l;
      var j;
      var k;
      var key;
      var keys;
      var sortOptions;
      if (!this.settings) return e;
      if (!this.settings.sort_options) return e;
      if (!this.settings.sort_options.options) return e;
      sortOptions = this.settings.sort_options.options;
      for (i = 0, l = sortOptions.length; i < l; i ++) {
        key = sortOptions[i].enabled_by;
        if (Array.isArray(key)) {
          keys = key;
          for (j = 0, k = keys.length; j < k; j++) {
            key = keys[i];
            if (!e.hasOwnProperty(key)) {
              e[key] = getDataByProperty(key, this);
            }
          }
        } else {
          if (!e.hasOwnProperty(key)) {
            e[key] = getDataByProperty(key, this);
          }
        }
      }
      return e;
    }
  },
  watch: {
    defaultSortOption: {
      handler: function defaultSortOption(curr, prev) {
        //
        // when the default sort changes, update sort value
        //
        if (curr !== prev) {
          this.$store.dispatch('updateSort', curr);
        }
      },
      deep: true
    }
  }
};
