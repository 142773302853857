<template>
  <modal-component :show="show" :click-away="true" :containerClass="'p-0'">
    <div class="c-modal__head p-3">
      <div class="c-modal__title">{{settings.dynamic.group_title_text}}</div>
      <button v-on:click="closeModal" class="modal_close c-modal__close c-button--mobile "><i class="fal fa-times" aria-hidden="true"></i></button>
    </div>

    <div class="option-container sp-v-3 p-3">
      <div class="option" v-for="option in settings.dynamic.value">
        <h2 class="option-title">{{option.text}}</h2>
        <p class="option-description">{{option.description}}</p>
      </div>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';
  module.exports = {
    props: ['show', 'settings'],
    components: { ModalComponent },
    mixins: [ModalMixin]
  };
</script>