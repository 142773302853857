
const ModalMixin = require('../mixins/ModalMixin');
const PaginationMixin = require('../mixins/PaginationMixin');
let MarkerWithLabel;
const makeInfo = require('../../plugins/info.js');
const $ = require('jquery');
import padStart from 'lodash/padStart';
const utils = require('../store/utils');
import staticData from '../store/data';
import arraysMatch from 'tembo-js/arraysMatch';
import arraysMatchInOrder from 'tembo-js/arraysMatchInOrder';
import getDataByProperty from 'tembo-js/getDataByProperty';
import overlappingArrays from 'tembo-js/overlappingArrays';
import sendEvent from 'tembo-js/sendEvent';
import getNthBearing from 'tembo-js/getNthBearing';

function toggleMap() {
  var label;
  this.settings.metadata.show_map = !this.settings.metadata.show_map;
  if (!this.settings.metadata.show_map) label = 'on';
  else label = 'off';
  sendEvent({
    category: 'map',
    action: 'toggle',
    label: label
  });
}

function drawMap() {
  var map;
  var center;
  var oms;
  var boundIdle;
  var boundZoom;


  //
  // check for necessary libraries
  //
  try {
    // if (!google) {
    // eslint-disable-next-line no-undef
    if (!google || !OverlappingMarkerSpiderfier) {
      // this.$emit('MapFailed')
      // eslint-disable-next-line
      console.error('the scripts required to run the map have failed to load');
      return null;
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error('the scripts required to run the map have failed to load');
    return null;
  }

  //
  // prevent map from calling idle events
  //
  this.mapHasIdled = false;

  //
  // get the appropriate center for the map - home or default
  //
  if (this.home) {
    center = this.home.address.geometry.location;
  } else {
    center = this.settings.center_on;
  }

  //
  // initialize the map
  //
  // eslint-disable-next-line no-undef
  map = new google.maps.Map(document.querySelector('#map-container'), {
    center: center,
    zoom: this.settings.metadata.zoom
  });

  //
  // apply marker spiderfier library to map
  //
  // eslint-disable-next-line
  oms = new OverlappingMarkerSpiderfier(map,
    {
      keepSpiderfied: true,
      nearbyDistance: 28,
      circleFootSeparation: 48,
      spiralFootSeparation: 50
    });

  //
  // set component-globals for ease of access
  //
  this.map = map;
  this.oms = oms;
  this.zoom = map.getZoom();
  //
  // handle marker click & hover events,
  // depending on proximity of other markers
  //
  this.addOMSEvents();

  //
  // custom event handlers for idle & zoom events
  //
  function idle() {
    //
    // when map idles, alert component
    //
    this.mapHasIdled = true;
  }
  function zoom() {
    //
    // when zoom changes, check if markers were previously adjusted
    // if, so re-draw
    // TODO:
    // zoom is the only place where map markers are adjusted
    // to be at least 3px apart. this should probably change
    //
    var prevZoom = this.zoom;
    var currZoom = this.map.getZoom();
    if (prevZoom - currZoom > 0) {
      this.adjustMarkers();
    } else if (prevZoom - currZoom < 0 && this.budged) {
      this.resetPositions();
      this.adjustMarkers();
    }
    this.zoom = this.map.getZoom();
  }
  //
  // bind event handlers to component context
  //
  boundIdle = idle.bind(this);
  boundZoom = zoom.bind(this);
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(this.map, 'idle', boundIdle);
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(this.map, 'zoom_changed', boundZoom);
  //
  // allow marker updates to begin
  //
  this.mapsDrawn = true;
  this.drawInitialMarkers();
  return map;
}

function drawPlaceholderMap() {
  var map;
  var center;

  //
  // check for necessary library
  //
  try {
    // eslint-disable-next-line no-undef
    if (!google) {
      // eslint-disable-next-line
      console.error('the scripts required to run the map have failed to load');
      return null;
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error('the scripts required to run the map have failed to load');
    return null;
  }

  //
  // get the appropriate center for the map - home or default
  //
  if (this.home) {
    center = this.home.address.geometry.location;
  } else {
    center = this.settings.center_on;
  }

  //
  // initialize the map
  //
  // eslint-disable-next-line no-undef
  map = new google.maps.Map(document.querySelector('#map-container'), {
    center: center,
    zoom: this.settings.metadata.zoom
  });

  return map;
}

function addOMSEvents() {
  //
  // handle click events, depending on whether or not marker is near others
  //
  var boundScroll;
  var boundSendEvent;
  var boundSetMobileItem;
  var oms;
  if (!this.oms) { return; }
  oms = this.oms;

  function scrollOnOMSClick(marker) {
    //
    // if marker is not close enough to anther marker to
    // use the spiderfier, click event should trigger
    // the results list to scroll
    //
    var id;
    var el;
    var scrollingDiv;
    var scrollTopPos;
    var firstSibling;
    var margin;
    var scrollTo;
    var markerNearOthers = oms.markersNearMarker(marker, true).length;
    if (!markerNearOthers || marker.hasOwnProperty('_omsData')) {
      if (marker.labelClass.indexOf(this.settings.marker.metadata.scroll_on_click) > -1) {
        id = `#${marker.data.id}`;
        el = $(id);
        scrollingDiv = el.closest('.o-two-column-layout__results > div');
        scrollTopPos = el.position().top;
        firstSibling = $('.p-entity:first', scrollingDiv);
        margin = firstSibling.outerHeight(true) - firstSibling.outerHeight();
        if (!el.prevAll('.p-entity').length) {
          scrollTo = 0;
        } else {
          scrollTo = scrollTopPos + margin - firstSibling.position().top;
        }
        scrollingDiv.animate({
          scrollTop: scrollTo
        }, 200);
      }
    }
  }
  function setMobileItem(marker) {
    var idx;
    var markerNearOthers = oms.markersNearMarker(marker, true).length;
    //
    // if marker is not close enough to anther marker to
    // use the spiderfier, click event should trigger
    // the mobile result item overlay to render on mobile
    //
    if (!markerNearOthers || marker.hasOwnProperty('_omsData')) {
      if (marker.labelClass.indexOf(this.settings.marker.metadata.scroll_on_click) > -1) {
        this.mobileSchoolItem = marker.data;

        idx = marker.data.idx;
        this.mobileSchoolIndex = idx;
      }
    }
  }
  function sendClickEvent(marker) {
    //
    // always send click event to google analytics
    //
    sendEvent({
      category: 'map',
      action: 'marker_click',
      label: marker.data.id
    });
  }

  boundScroll = scrollOnOMSClick.bind(this);
  boundSendEvent = sendClickEvent.bind(this);
  boundSetMobileItem = setMobileItem.bind(this);

  oms.addListener('click', boundScroll);
  oms.addListener('click', boundSetMobileItem);
  oms.addListener('click', boundSendEvent);
}

function reCenterMap(center) {
  this.map.panTo(center);
}

function makeInfoWindow(markerSpec, data) {
  var infowindow;
  if (markerSpec.infowindow) {
    // eslint-disable-next-line no-undef, no-new, new-cap
    infowindow = new google.maps.InfoWindow({
      content: makeInfo[markerSpec.infowindow](data),
      disableAutoPan: true
    });
  }
  return infowindow;
}

function makeSVGContainer(markerSpec) {
  var x0 = 0;
  var y0 = 0;
  var x1 = markerSpec.width;
  var y1 = markerSpec.height;
  var pathArr = [{ x: x0, y: y0 }, { x: x1, y: y0 }, { x: x1, y: y1 }, { x: x0, y: y1 }];
  var path = 'M ';
  pathArr.forEach((point) => {
    path += `${point.x},${point.y} `;
  });
  path += 'z';
  return {
    path: path, // box
    strokeWeight: 0,
    // eslint-disable-next-line
    anchor: new google.maps.Point(x1/2, y1)
  };
}

function makeMarker(icon, label, data) {
  var marker;
  var location;
  var zindex;
  var map = this.map;
  var oms = this.oms;
  if (!MarkerWithLabel) {
    // eslint-disable-next-line global-require
    MarkerWithLabel = require('../../plugins/markerwithlabel');
  }
  location = getDataByProperty(this.settings.metadata.match_data_path, data);
  if (!location) location = data.location;
  if (label.labelClass.indexOf('center-marker') > -1) {
    // eslint-disable-next-line
    zindex = google.maps.Marker.MAX_ZINDEX + 1;
  }
  location = utils.generalizeLoc(location);
  marker = new MarkerWithLabel({
    icon: icon,
    position: location,
    map: map,
    labelAnchor: { x: 0, y: 0 },
    labelContent: label.labelContent,
    labelClass: label.labelClass,
    data: data,
    zIndex: zindex
  });
  oms.addMarker(marker);
  this.markers.push(marker);
  return marker;
}

function toggleIcon(marker) {
  var icon = marker.icon;
  marker.setIcon(marker.altIcon);
  // eslint-disable-next-line no-param-reassign
  marker.altIcon = icon;
}

function project(latLng) {
  var TILE_SIZE = 256;
  var siny = Math.sin(latLng.lat * Math.PI / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  // siny = Math.min(Math.max(siny, -1), 1);
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  // eslint-disable-next-line no-undef
  return new google.maps.Point(
      TILE_SIZE * (0.5 + latLng.lng / 360),
      TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)));
}

function getPixelPosition(marker, map) {
  var zoom = map.getZoom();
  var scale = 1 << zoom;
  var loc = marker.data.location;
  var worldCoordinate = project(loc);
  var pixelCoordinate;
  // eslint-disable-next-line no-undef, no-new, new-cap
  pixelCoordinate = new google.maps.Point(
      Math.floor(worldCoordinate.x * scale),
      Math.floor(worldCoordinate.y * scale));
  return pixelCoordinate;
}

function addLateMarkerEvents(marker) {
  //
  // marker events that cannot be controlled by oms
  // must be added after map has idled
  //
  var oms = this.oms;
  // change marker icon on mouseover
  var shouldGrow;
  // remove old events first
  google.maps.event.clearListeners(marker, 'mouseover'); // eslint-disable-line no-undef
  google.maps.event.clearListeners(marker, 'mouseout'); // eslint-disable-line no-undef
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(marker, 'mouseover', function mouseover() {
    var markerNearOthers;
    var infowindow = marker.info;
    markerNearOthers = oms.markersNearMarker(marker, true).length;
    shouldGrow = marker.labelClass.indexOf('-grow') === -1;
    if (marker.hasOwnProperty('_omsData') || !markerNearOthers) {
      if (shouldGrow) {
        marker.set('labelClass', `${marker.labelClass}-grow`);
      }
      if (infowindow) infowindow.open(this.map, marker);
    }
  });
  // change back on mouseout
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(marker, 'mouseout', () => {
    var infowindow = marker.info;
    marker.set('labelClass', marker.labelClass.replace('-grow', ''));
    // marker.setZIndex(zindex);
    if (infowindow) infowindow.close();
  });
}


function setMapOnAllMarkersOfType(map, type) {
  this.markers.forEach((m) => {
    if (m.labelClass.indexOf(type) > -1) {
      m.setMap(map);
    }
  });
}

function clearListenersOnAllMarkersOfType(type) {
  this.markers.forEach((m) => {
    if (m.labelClass.indexOf(type) > -1) {
      // eslint-disable-next-line no-undef
      google.maps.event.clearInstanceListeners(m);
    }
  });
}

function clearAllMarkersOfType(type) {
  this.setMapOnAllMarkersOfType(null, type);
  this.clearListenersOnAllMarkersOfType(type);
  this.markers = this.markers.filter(m => m.labelClass.indexOf(type) === -1);
}

function getMarkerSpec(matchArr, markers) {
  var i;
  var len;
  var arr;
  if (!matchArr) arr = ['Other'];
  else arr = matchArr;
  for (i = 0, len = markers.length; i < len; i ++) {
    if (overlappingArrays(markers[i].type, arr)) {
      return markers[i];
    }
  }
  return this.getMarkerSpec(['Other'], markers);
}


function getLabelText(markerSpec, arg) {
  var labels = {
    idx: function idx(a) {
      return (a).toString();
    },
    paddedIdx: function paddedIdx(a) {
      var str = (a).toString();
      return padStart(str, this.settings.pad_index_to, '0');
    }
  };
  var labelTextMaker;
  if (markerSpec.label && labels[markerSpec.label]) {
    labelTextMaker = labels[markerSpec.label];
    return labelTextMaker(arg);
  }
  return null;
}

function buildLabelClass(markerSpec, data) {
  var i;
  var len;
  var labelClass = markerSpec.class;
  var addition;
  if (markerSpec.class && markerSpec.class_additions) {
    for (i = 0, len = markerSpec.class_additions.length; i < len; i ++) {
      addition = markerSpec.class_additions[i];
      if (getDataByProperty(addition.match_data_path, data)) {
        labelClass += addition.append;
      }
    }
  }
  return labelClass;
}

function addOneFiltered(data, type, idx) {
  // array of category values to match in marker settings
  var matchArr = [type];
  var markerSpec;
  var icon;
  var labelContent;
  var labelNumber;
  var labelClass = 'entity-marker';
  var marker;
  var infowindow;
  var updatedData = data;
  updatedData.idx = idx;
  markerSpec = this.getMarkerSpec(matchArr, this.settings.marker.value);
  icon = this.makeSVGContainer({ width: 48, height: 60 });
  labelNumber = getLabelText(markerSpec, idx);
  labelContent = '<div class="map-marker__number">' + labelNumber + '</div>';
  labelContent += '<svg width="48px" height="60px" viewBox="0 0 48 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g class="map-marker" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path  class="map-marker__marker" d="M46,22.6942713 C46,39.8368938 22.9997882,58 22.9997882,58 C22.9997882,58 2.84217094e-14,40.1979495 2.84217094e-14,22.6942713 C2.84217094e-14,10.1605412 10.297515,0 22.9997882,0 C35.7029085,0 46,10.1605412 46,22.6942713 Z" id="Stroke-11-Copy-17" stroke-linecap="round" stroke-linejoin="round"></path><path class="map-marker__heart" d="M23.04,52.012 L22.952,51.924 C18.7632,48.1312 16,45.6232 16,43.08 C16,41.32 17.32,40 19.08,40 C20.4352,40 21.7552,40.8712 22.2216,42.0768 L23.8672,42.0768 C24.3248,40.8712 25.6448,40 27,40 C28.76,40 30.08,41.32 30.08,43.08 C30.08,45.6232 27.3168,48.1312 23.128,51.924 L23.04,52.012 Z" id="Path"></path></g></svg>';
  labelClass = this.buildLabelClass(markerSpec, data);
  marker = this.makeMarker(icon,
    { labelContent: labelContent, labelClass: labelClass },
    data);
  infowindow = this.makeInfoWindow(markerSpec, data);
  marker.info = infowindow;
  marker.setMap(this.map);
  return marker;
}

function addCenterMarker(data, type) {
  var matchArr = [type];
  var markerSpec;
  var labelClass;
  var labelContent;
  var infowindow;
  var marker;
  var icon;
  markerSpec = this.getMarkerSpec(matchArr, this.settings.marker.value);
  icon = this.makeSVGContainer({ width: 15, height: 30 });
  labelClass = markerSpec.class;
  labelContent = getLabelText(markerSpec);
  marker = this.makeMarker(icon,
    { labelContent: labelContent, labelClass: labelClass },
    data.geometry);
  infowindow = this.makeInfoWindow(markerSpec, data);
  marker.info = infowindow;
  marker.setMap(this.map);
}

function pixelToLatLng(point, map) {
  var scale = 1 << map.getZoom();
  var worldPoint = { x: point.x / scale, y: point.y / scale };
  return map.getProjection().fromPointToLatLng(worldPoint);
}

function budgeOneMarkerGroup(group, radius) {
  // must be called after map has initialized -
  // called by watcher of mapHasIdled
  var i;
  var l;
  var marker;
  var theta;
  var newPixels;
  var newLatLng;
  var markerPixelLocation;
  for (i = 0, l = group.length; i < l; i ++) {
    marker = group[i];
    markerPixelLocation = getPixelPosition(marker, this.map);
    theta = getNthBearing(i, l);
    newPixels = {
      x: Math.ceil(markerPixelLocation.x + theta[0] * radius),
      y: Math.ceil(markerPixelLocation.y + theta[1] * radius)
    };
    newLatLng = pixelToLatLng(newPixels, this.map);
    marker.setPosition(newLatLng);
  }
}

function buildMarkerGroupsWithRadius(marker, radius) {
  var nearby = [];
  var nearbyIds;
  var i;
  var l;
  var j;
  var k;
  var currentMarker;
  var notSameMarker;
  var referenceLoc;
  var currentLoc;
  var idx;
  idx = this.markers.indexOf(marker);
  referenceLoc = getPixelPosition(marker, this.map);
  for (i = 0, l = this.markers.length; i < l; i ++) {
    notSameMarker = idx !== i;
    currentMarker = this.markers[i];
    if (notSameMarker) {
      currentLoc = getPixelPosition(currentMarker, this.map);
      if (
        Math.abs(referenceLoc.x - currentLoc.x) < radius &&
        Math.abs(referenceLoc.y - currentLoc.y) < radius) {
        if (!currentMarker.data.id) currentMarker.data.id = -1;
        nearby.push(currentMarker);
      }
    }
  }
  if (nearby.length > 0) {
    // eslint-disable-next-line
    if (!marker.data.id) marker.data.id = -1;
    nearby.push(marker);
    nearby.sort((a, b) => a.data.id < b.data.id);
    for (j = 0, k = this.groupsIds.length; j < k; j ++) {
      // if this exact group has already been created, do not duplicate!
      nearbyIds = nearby.map(n => n.data.id);
      if (arraysMatch(nearbyIds, this.groupsIds[j])) return [];
    }
  }
  return nearby;
}

function budgeMarkerGroups(markers) {
  var radius = 3;
  var i;
  var l;
  var marker;
  var groups = [];
  var group;
  var groupIds;
  this.groupsIds = [];
  for (i = 0, l = markers.length; i < l; i ++) {
    marker = markers[i];
    group = this.buildMarkerGroupsWithRadius(marker, radius);
    groupIds = group.map(g => g.data.id);
    if (group.length) {
      groups.push(group);
      this.groupsIds.push(groupIds);
    }
  }
  if (groups.length) this.budged = true;
  else this.budged = false;
  for (i = 0, l = groups.length; i < l; i ++) {
    group = groups[i];
    this.budgeOneMarkerGroup(group, radius);
  }
  return groups;
}

function adjustMarkers() {
  this.budgeMarkerGroups(this.markers);
  this.markers.forEach(function each(marker) {
    this.addLateMarkerEvents(marker);
  }, this);
}

function resetPositions() {
  var i;
  var l;
  var marker;
  for (i = 0, l = this.markers.length; i < l; i ++) {
    marker = this.markers[i];
    marker.setPosition(marker.data.location);
  }
}

function addAllFiltered() {
  var entities;
  if (staticData.usePagination) {
    entities = this.pageXEntities || [];
  } else {
    entities = this.filtered;
  }
  entities.forEach(function eachFiltered(f, i) {
    var location = getDataByProperty(this.settings.metadata.match_data_path, f);
    var idx = this.getEntityIndex(i); // gets index in total filtered list from PaginationMixin
    location = utils.generalizeLoc(location);
    if (location.lat && location.lng) {
      this.addOneFiltered(f, 'Entity', idx);
    }
  }, this);
  this.setMapOnAllMarkersOfType(this.map, 'entity-marker');
}

function fitToMarkers() {
  var center;
  var bounds;
  var markers = this.markers;
  var i;
  var l;
  var map = this.map;
  var mapBounds;
  var contained = true;

  if (this.settings.metadata.re_center === 'home') {
    if (this.home) {
      center = this.home.address.geometry.location;
    } else {
      center = this.settings.center_on;
    }
  } else if (this.settings.metadata.re_center === 'geo') {
    // center = bounds.getCenter();
    center = map.getCenter();
  } else {
    center = map.getCenter();
  }

  // eslint-disable-next-line no-undef
  bounds = new google.maps.LatLngBounds(center, center);

  //
  // add each marker to bounds
  //
  for (i = 0, l = markers.length; i < l; i ++) {
    // skip center marker, because its position is unreliable until rendered
    if (markers[i].labelClass !== 'center-marker') {
      bounds.extend(markers[i].getPosition());
    }
  }

  bounds.extend(center);

  map.fitBounds(bounds);

  map.setCenter(center);
  mapBounds = map.getBounds();

  for (i = 0, l = markers.length; i < l; i ++) {
    if (!mapBounds.contains(markers[i].getPosition())) {
      contained = false;
    }
  }

  if (!contained) map.setZoom(map.getZoom() - 1);

  if (map.getZoom() > this.settings.metadata.max_zoom) {
    map.setZoom(this.settings.metadata.max_zoom);
  }
}

module.exports = {
  mixins: [ModalMixin, PaginationMixin],
  props: [
    'settings',
    'layout'
  ],
  data: function data() {
    return {
      map: {},
      oms: {},
      spiderLoaded: false,
      mapsDrawn: false, // move to map mixin?
      budged: false,
      zoom: null,
      mobileZoomSet: false,
      markers: [],
      mobileSchoolItem: null,
      mobileSchoolIndex: null,
      mapHasIdled: false,
      groupsIds: [],
      updateEntityAttemptCt: 0,
      updateHomeAttmeptCt: 0,
      entityUpdateFailed: false,
      homeUpdateFailed: false
    };
  },
  computed: {
    favorites: function favorites() {
      return this.$store.state.favorites;
    },
    filtered: function filtered() {
      return this.$store.state.filtered;
    },
    home: function home() {
      return this.$store.state.home;
    },
    entitiesLoaded: function entitiesLoaded() {
      return this.$store.state.entitiesLoaded;
    },
    mobileView: function mobileView() {
      return this.$store.state.mobileView;
    },
    mapsApiLoaded: function mapsApiLoaded() {
      return this.$store.state.mapsApiLoaded;
    },
    neighborhoodSchoolIds: function neighborhoodSchoolIds() {
      return this.$store.state.neighborhoodSchoolIds;
    }
  },
  methods: {
    toggleMap: toggleMap,
    drawMap: drawMap,
    drawPlaceholderMap: drawPlaceholderMap,
    addOMSEvents: addOMSEvents,
    reCenterMap: reCenterMap,
    makeInfoWindow: makeInfoWindow,
    buildMarkerGroupsWithRadius: buildMarkerGroupsWithRadius,
    budgeMarkerGroups: budgeMarkerGroups,
    budgeOneMarkerGroup: budgeOneMarkerGroup,
    makeMarker: makeMarker,
    makeSVGContainer: makeSVGContainer,
    toggleIcon: toggleIcon,
    addLateMarkerEvents: addLateMarkerEvents,
    setMapOnAllMarkersOfType: setMapOnAllMarkersOfType,
    clearListenersOnAllMarkersOfType: clearListenersOnAllMarkersOfType,
    clearAllMarkersOfType: clearAllMarkersOfType,
    getMarkerSpec: getMarkerSpec,
    buildLabelClass: buildLabelClass,
    resetPositions: resetPositions,
    addOneFiltered: addOneFiltered,
    addCenterMarker: addCenterMarker,
    adjustMarkers: adjustMarkers,
    fitToMarkers: fitToMarkers,
    addAllFiltered: addAllFiltered,
    loadMaps: function loadMaps() {
      var script;
      if (window.initGoogle) {
        window.initGoogle();
        return;
      }
      script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + staticData.config.defaults.env_vars.google_api_key + '&libraries=places&callback=initGoogle';
      document.getElementsByTagName('body')[0].appendChild(script);
      window.initGoogle = this.initGoogle;
    },
    loadSpider: function loadSpider() {
      var omsScript;
      var initializeSpider = this.initializeSpider;
      if (window.spiderLoaded) {
        initializeSpider();
        return;
      }
      omsScript = document.createElement('script');
      omsScript.setAttribute('type', 'text/javascript');
      if (omsScript.readyState) {
        omsScript.onreadystatechange = function onreadystatechange() {
          if (omsScript.readyState === 'loaded' || omsScript.readyState === 'complete') {
            omsScript.onreadystatechange = null;
            initializeSpider();
          }
        };
      } else {
        omsScript.onload = function onload() {
          initializeSpider();
        };
      }
      omsScript.src = '/js/oms.js';
      document.getElementsByTagName('body')[0].appendChild(omsScript);
      window.spiderLoaded = true;
    },
    initGoogle: function initGoogle() {
      this.loadSpider();
      this.initializeMap();
    },
    updateMarkers: function updateMarkers(refitToMarkers) {
      if (this.mapHasIdled && this.mapsDrawn &&
        this.spiderLoaded && this.mapsApiLoaded && this.filtered) {
        this.updateEntityAttemptCt = 0;
        this.clearAllMarkersOfType('entity-marker');
        this.addAllFiltered();
        if (refitToMarkers) {
          this.fitToMarkers();
        }
        this.adjustMarkers();
      } else if (!this.mapsDrawn && this.spiderLoaded && this.mapsApiLoaded && this.filtered) {
        this.updateEntityAttemptCt = 0;
        this.drawMap();
      } else if (this.updateEntityAttemptCt < 1) {
        // retry updating on next tick, but once only
        this.$nextTick(function nextTick() {
          this.updateEntityAttemptCt += 1;
          this.updateMarkers(refitToMarkers);
        });
      } else {
        this.entityUpdateFailed = true;
        this.updateEntityAttemptCt = 0;
      }
    },
    initializeMap: function initializeMap() {
      //
      // called by initGoogle, the callback to the maps api script
      //

      //
      // TODO:
      // what if we have address bar but no maps???
      // this script-loading sequence should probably live elsewhere
      //
      this.$store.dispatch('updateMapsApiLoaded', true);

      if (!this.mapsDrawn && this.spiderLoaded) {
        this.drawMap();
      } else if (this.mapsDrawn && this.spiderLoaded) {
        this.clearAllMarkersOfType('center-marker');
        this.clearAllMarkersOfType('entity-marker');
        this.drawMap();
      } else {
        this.drawPlaceholderMap();
      }
    },
    initializeSpider: function initializeSpider() {
      //
      // called by the onload function for the spiderfier script tag
      //
      this.spiderLoaded = true;
      if (!this.mapsDrawn && this.mapsApiLoaded) {
        this.drawMap();
      }
    },
    drawInitialMarkers: function drawInitialMarkers() {
      //
      // called by the drawmap function
      //
      this.clearAllMarkersOfType('entity-marker');
      if (this.entitiesLoaded) {
        this.addAllFiltered();
      }
      if (this.home && (this.settings.metadata.plot_default || this.home.user)) {
        this.clearAllMarkersOfType('center-marker');
        this.addCenterMarker(this.home.address, 'Default');
      }
    },
    updateHomeMarker: function updateHomeMarker() {
      //
      // called by the watcher for home
      //
      if (this.mapHasIdled && this.mapsDrawn &&
        this.spiderLoaded && this.mapsApiLoaded) {
        this.clearAllMarkersOfType('center-marker');
        this.updateHomeAttmeptCt = 0;
        if (this.home && (this.settings.metadata.plot_default || this.home.user)) {
          this.addCenterMarker(this.home.address, 'Home');
          this.reCenterMap(this.home.address.geometry.location);
        } else {
          this.reCenterMap(this.settings.center_on);
        }
      } else if (this.spiderLoaded && this.mapsApiLoaded) {
        this.updateHomeAttmeptCt = 0;
        this.drawMap();
      } else if (this.updateHomeAttmeptCt < 1) {
        // retry updating on next tick, but once only
        this.$nextTick(function nextTick() {
          this.updateHomeAttmeptCt += 1;
          this.updateHomeMarker();
        });
      } else {
        this.homeUpdateFailed = true;
        this.updateHomeAttmeptCt = 0;
      }
    }
  },
  watch: {
    home: {
      handler: function home() {
        this.updateHomeMarker();
      },
      deep: true
    },
    pageXEntities: {
      handler: function pageXEntities(curr, prev) {
        //
        // when the list of entities change,
        // update markers
        // and reset the mobileSchoolItem
        //
        const prevList = prev.map(e => e.id);
        const currList = curr.map(e => e.id);
        const updateItems = !arraysMatch(prevList, currList);
        if (updateItems) {
          this.mobileZoomSet = false;
          this.$nextTick(this.updateMarkers(true));
        }
        const updateOrder = !arraysMatchInOrder(prevList, currList);
        if (updateOrder) {
          this.mobileSchoolItem = null;
        }
      },
      deep: true
    },
    neighborhoodSchoolIds: {
      handler: function neighborhoodSchoolIds(curr, prev) {
        var update = false;
        var idsNotInBoth = utils.differenceOfTwoArraySets(curr, prev);
        for (let i = 0, l = this.pageXEntities.length; i < l; i ++) {
          // TODO: sf_id should be configurable
          const entity = this.pageXEntities[i];
          if (idsNotInBoth.indexOf(entity.sf_id) > -1) update = true;
        }
        if (update) {
          this.mobileZoomSet = false;
          this.$nextTick(this.updateMarkers(true));
        }
      },
      deep: true
    },
    mobileView: function mobileView() {
      if (this.mobileView === 'map' && !this.mobileZoomSet) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.updateMarkers(true);
            this.mobileZoomSet = true;
          }, 300);
        });
      }
    },
    favorites: {
      handler: function fav() {
        this.updateMarkers(false);
      },
      deep: true
    },
    mapHasIdled: function watchIdle(curr, prev) {
      var entities;
      if (prev === false && curr === true) {
        entities = this.markers.filter(m => m.labelClass.indexOf('entity-marker') > -1);
        if (this.entityUpdateFailed) {
          this.updateEntityAttemptCt = 0;
          this.clearAllMarkersOfType('entity-marker');
          this.addAllFiltered();
          this.adjustMarkers();
          this.fitToMarkers();
        }
        if (entities.length > 0) {
          this.fitToMarkers();
          // this.adjustMarkers();
        } else {
          this.clearAllMarkersOfType('entity-marker');
          this.addAllFiltered();
          this.fitToMarkers();
          // this.adjustMarkers();
        }
      }
    }
  },
  events: {
    // entitiesLoaded: function entitiesLoaded() {
    //   this.$nextTick(function nextTick() {
    //     this.entitiesLoaded = true;
    //     if (this.mapsDrawn) {
    //       this.drawInitialMarkers();
    //     }
    //   });
    // },
    // SpiderLoaded: function SpiderLoaded() {
    //   this.initializeSpider();
    // },
    // MapsApiLoaded: function MapsApiLoaded() {
    //   this.initializeMap();
    // },
    // MapLoaded: function MapLoaded() {
    //   this.drawInitialMarkers();
    // },
    // UpdateMarkers: function UpdateMarkers() {
    //   this.$nextTick(this.updateMarkers);
    // },
    // HomeChanged: function HomeChanged() {
    //   this.updateHomeMarker();
    // },
    // ToggleMap: function ToggleMap() {
    //   this.toggleMap();
    // }
  },
  mounted: function mounted() {
    //
    // loads google maps script
    //
    this.loadMaps();
  }
};
