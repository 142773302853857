<template>
  <div class="o-two-column-layout" v-bind:class="mobileViewClass" v-if="renderableLayout">
    <!-- MODALS -->
    <!-- each modal requires tag, data/settings and show
      compares, favorites & address are the only items required
      to sync with other components
      other properties are required by some, but not all
     -->
    <!-- additional prop required for wizard:
      :address-entry="settings.address_entry"
      TODO: may also need event handlers, since sync is no longer a thing
      -->
    <div v-if="$store.state.showModal && $store.state.modalTag && $store.state.modalData"
      :is="$store.state.modalTag"
      :show="$store.state.showModal"
      @update:show="$store.dispatch('updateShowModal', false)"
      :settings="$store.state.modalData"

      :layout="$store.state.modalLayout"
    ></div>

    <!-- HEADERS -->
    <header class="header p-header--static p-0">
      <a href="#main-content" class="sr-only">Skip to main content</a>
      <div v-for="component in renderableLayout.header"
        :is="component.tag"
        :settings="component.data"
        :layout="component.layout"
        :class="component.tag"
      ></div>
    </header>

    <div class="controls o-two-column-layout__controls py-2 flex-shrink-0">
      <div class="flex-column  px-3 position-relative o-two-column-layout__controls__address-filters">
        <div class="row">
          <div class="col-12 col-xl">
            <div
            :is="renderableLayout.left_column.controls.address_entry.tag"
            :settings="renderableLayout.left_column.controls.address_entry.data"
            :layout="renderableLayout.left_column.controls.address_entry.layout"
            class="h-100"
            ></div>
          </div>
          <div class="col mt-2 mt-xl-0 col-xl-auto">
            <div
              :is="renderableLayout.left_column.controls.grade_selection.tag"
              :settings="renderableLayout.left_column.controls.grade_selection.data"
              :layout="renderableLayout.left_column.controls.grade_selection.layout"
              :alert="true"
            ></div>
          </div>
          <div class="col-auto mt-2 mt-xl-0">
            <div
              :is="renderableLayout.left_column.controls.more_filters_toggle.tag"
              :settings="renderableLayout.left_column.controls.more_filters_toggle.data"
            ></div>
          </div>
        </div>
      </div>
      <div class="flex-column px-3 justify-content-center o-two-column-layout__controls__result-status">
        <div
          :is="renderableLayout.right_column.status.tag"
          :settings="renderableLayout.right_column.status.data"
          :class="renderableLayout.right_column.status.tag"
          class="results-status flex-shrink-0"
        ></div>
      </div>
    </div>

    <div id="main-content" class="o-two-column-layout__main-content d-flex flex-grow-1">
      <!-- LEFT COLUMN -->
      <div class="left-col o-two-column-layout__map flex-column w-50 position-relative">
        <div
          :is="renderableLayout.left_column.controls.more_filters.tag"
          :settings="renderableLayout.left_column.controls.more_filters.data"
          :layout="renderableLayout.left_column.controls.more_filters.layout"
          :class="renderableLayout.left_column.controls.more_filters.tag"

          :super-filters="superFilters"
          class=""
        ></div>

        <!-- MAP -->
        <div
          :is="renderableLayout.left_column.map_component.tag"
          :settings="renderableLayout.left_column.map_component.data"
          :layout="renderableLayout.left_column.map_component.layout"
          :class="renderableLayout.left_column.map_component.tag"
        ></div>
      </div>
      <!-- RIGHT COLUMN -->
      <div class="right-col o-two-column-layout__results flex-column w-50 position-relative">
        <div class="d-flex flex-column flex-grow-1" style="overflow-y: auto;">
          <div class="row mx-0 my-2 flex-shrink-0" :class="mobileSearchVisible === true ? 'show-search' : 'show-sort'">
            <div class="d-none col-2 visible-xs-block d-sm-none">
              <div class="mobile-search-toggle visible-xs-inline-block" v-on:click="toggleMobileSearch"><i class="fa" aria-hidden="true" :class="mobileSearchVisible === true ? 'fa-times' : 'fa-search'"></i></div>
            </div>
            <div class="col-12 offset-sm-0  col-sm-6 col-md-6 col-lg-5 sort">
              <div
                :is="renderableLayout.right_column.controls.sort.tag"
                :settings="renderableLayout.right_column.controls.sort.data"
                :layout="renderableLayout.right_column.controls.sort.layout"
                :class="renderableLayout.right_column.controls.sort.tag"
                :enablers="sortEnablers"
              ></div>
            </div>
            <div class="col-12 mt-2 mt-sm-0 offset-sm-0 col-sm-6 col-md-6 col-lg-7 search">
              <div
                :is="renderableLayout.right_column.controls.search.tag"
                :settings="renderableLayout.right_column.controls.search.data"
                :layout="renderableLayout.right_column.controls.search.layout"
                :class="renderableLayout.right_column.controls.search.tag"
                class="h-100"
              ></div>
            </div>
          </div>
          <div
            :is="renderableLayout.right_column.results.tag"
            :settings="renderableLayout.right_column.results.data"
            :layout="renderableLayout.right_column.results.layout"
            :class="renderableLayout.right_column.results.tag"
            class="mx-3 mb-2"
          ></div>
          <div
            v-if="usePagination"
            :is="renderableLayout.right_column.paginator.tag"
            :settings="renderableLayout.right_column.paginator.data"
            :layout="renderableLayout.right_column.paginator.layout"
            class="mx-3 mb-2"
          ></div>
          <div
            :is="renderableLayout.right_column.footer.tag"
            :settings="renderableLayout.right_column.footer.data"
            class="flex-shrink-0 flex-column flex-xl-row"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  var AppMixinBase = require('../mixins/AppMixinBase');
  var AppMixinFilters = require('../mixins/AppMixinFilters');
  var AppMixinMap = require('../mixins/AppMixinMap');
  import staticData from '../store/data';

  module.exports = {
    mixins: [AppMixinBase, AppMixinFilters, AppMixinMap],
    data: function data() {
      return {
        usePagination: staticData.usePagination,
        mobileSearchVisible: false
      };
    },
    computed: {
      mobileViewClass: function mobileViewClass() {
        //
        // sets view/class for mobile
        //
        var view = this.$store.state.mobileView;
        view = `o-two-column-layout--mobile-${view}`;
        return view;
      }
    },
    methods: {
      toggleMobileSearch: function toggleMobileSearch() {
        this.mobileSearchVisible = !this.mobileSearchVisible;
      },
      hideMobileSearch: function hideMobileSearch() {
        this.mobileSearchVisible = false;
      }
    }
  };
</script>
