<template>
  <div class="p-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-auto d-flex flex-column sp-v-3 align-items-center align-items-md-start">
          <h3 class="p-footer__force-color">{{settings.contact_title}}</h3>
          <p class="p-footer__p">{{settings.name}}</p>
          <p class="p-footer__p"><a class="p-footer__a" target="_blank" :href="settings.website_url">{{settings.website_url}}</a></p>
          <p>{{settings.email.url}}</p>
        </div>
        <div class="mt-5 mt-md-0 col-12 col-md-3 offset-lg-1 d-flex flex-column align-items-center align-items-md-start sp-v-3">
          <h3 class="p-footer__force-color">{{settings.resources_title}}</h3>
          <p class="p-footer__p"><a class="p-footer__a" :href="settings.state_report_url">{{settings.state_report_text}}</a></p>
          <p class="p-footer__p"><a class="p-footer__a" target="_blank" :href="settings.additional_report_url">{{settings.additional_report_text}}</a></p>
          <p class="p-footer__p"><a class="p-footer__a" target="_blank" :href="settings.feedback_survey_url">{{settings.feedback_survey_text}}</a></p>
        </div>
        <div class="col-12 col-md-auto ml-auto mt-4 mt-md-0 align-items-center align-items-md-end d-flex flex-column" >

          <span class="">
              <attribution-inline :settings="settings"></attribution-inline>
          </span>
          <a class='mt-gutter' href="//translate.google.com" target="_blank">
              <img src="/assets/img/google-attribution.png" alt="Translated by Google" class="google-attribution" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">

  import AttributionInline from './AttributionInline.vue';
  module.exports = {
    components: { AttributionInline },
    props: ['settings']
  };
</script>
