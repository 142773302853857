<template>
  <div>
    <div class="wizard-message" v-for="(item, index) in currentScreen.promptText1">
      <div class="paragraph-numerator">{{index + 1}}</div>
      <p>{{item}}</p>
    </div>
     <div class="wizard-button-container">
        <button  v-on:click='close(true)'>{{currentScreen.exitText}}</button>
    </div>
  </div>
</template>
<script type="text/javascript">
  var $ = require('jquery');
  var WizardMixin = require('../../mixins/WizardMixin');
  module.exports = {
    mixins: [WizardMixin],
    props: [
      'currentScreen'
    ],
    mounted: function mounted() { // MAY NEED TO USE NEXTTICK
      var highlights = this.currentScreen.highlights;
      var text;
      var textArray;
      var i;
      var l = highlights.length;
      var word;
      var highlightedWord;
      $('.wizard-message p').each(function each() {
        text = $(this).html();
        for (i = 0; i < l; i ++) {
          word = highlights[i];
          highlightedWord = `<em>${word}</em>`;
          textArray = text.split(word);
          text = textArray.join(highlightedWord);
        }
        $(this).html(text);
      });
    }
  };
</script>