<template>
  <modal-component class="p-0 c-modal--full-screen c-modal__mask" :show="show" :click-away="false">
  <header class="header p-header--static p-0 p-header" v-if="settings.show_header_in_intro_modal">
    <div
      :is="layout.header.tag"
      :settings="layout.header.data"
      :layout="layout.header.layout"
      :class="layout.header.tag"
      :headerType="'containerWidth'"

    ></div>
  </header>
  <div class="inline-video-modal" v-show="showVideoModal">
    <div class="modal-mask c-modal__mask c-modal__mask--transition-opacity h-100 justify-content-center d-flex align-items-center" v-on:click="closeVideoModal">
    <div class="modal-container c-modal__container d-flex flex-column p-0 position-relative"  style="min-height: auto; background: none; overflow: visible;" tabindex="-1" @click.stop>
      <div class="modal-heading c-modal__head position-absolute" style="border-bottom: 0; top: -66px; right: -16px;">
      <span class="heading-target c-modal__title">{{settings.header_text}}</span>
      <div v-on:click="closeVideoModal" class="modal_close c-modal__close"><modal-close-svg :fill="'white'"></modal-close-svg><span class="sr-only">{{settings.close_text}}</span></div>
    </div>
    <div class="modal-content">
      <youtube-embed v-if="showVideoModal" :src="settings.video_src"></youtube-embed>

    </div>

  </div>
    </div>
  </div>

  <div class="flex-grow-1 d-flex flex-column" style="overflow-y: auto;">
    <div class="p-entity-header py-4 py-md-5 position-relative flex-shrink-0">
      <div class="container position-absolute" style=" left: 0; right: 0; top: 0; bottom: 0; overflow: hidden">

        <div class="row h-100">
          <div class="col col-xl-10 offset-xl-1">
            <div :is="layout.background.tag"></div>
          </div>
        </div>
      </div>

      <div class="container my-4 position-relative">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 offset-xl-1">

            <div>
              <h1 class="p-entity-header__title">{{settings.short_text}}</h1>
              <p class="p-entity-header__p my-4">{{settings.long_text}}</p>
              <div>
                <div
                    v-if="layout.school_search"
                    :is="layout.school_search.tag"
                    :settings="layout.school_search.data"
                    :layout="layout.school_search.layout"
                    :entities="entities"
                    :inputSize="'c-input--lg'"
                    class="c-input--icon-right c-input--icon-cta position-relative"
                  ></div>
              </div>
              <p class="p-entity-header__p my-3 text-center">{{settings.or_text}}</p>
              <div class="" :class="mobileSlideClass">
                <button v-on:keyup.13="handleViewSchoolsClick" class="c-button c-button--cta w-100" v-on:click="handleViewSchoolsClick">{{settings.view_schools_text}}</button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-6 mt-gutter mt-md-0 d-flex flex-column">

              <div class="d-flex flex-column sp-v-4 flex-xl-row sp-v-xl-0 justify-content-around mt-auto">
                <span tabindex="1" class="a p-entity-header__link" v-on:click="handleVideoClick()"> <span class="fa-stack mr-1" style="font-size: 8px;"><i class="far fa-circle fa-stack-2x"></i><i class="fas fa-play fa-stack-1x" style="letter-spacing: -1px;"></i></span>{{settings.play_intro_video}}</span>

                <a :href="settings.view_state_report_href" class="p-entity-header__link d-flex"><dc-icon class="mr-1"></dc-icon>{{settings.view_state_report_text}}</a>
              </div>

            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <footer-osse :settings="layout.footer.data" class="flex-grow-1 flex-column d-flex"></footer-osse>
    </div>
  </modal-component>
</template>
<script type="text/javascript">
  import sendEvent from 'tembo-js/sendEvent';
  var ModalMixin = require('../../mixins/ModalMixin');
  import ModalComponent from './Modal.vue';
  import YoutubeEmbed from '../YoutubeEmbed.vue';

  module.exports = {
    props: ['settings', 'layout', 'show'],
    components: { ModalComponent, YoutubeEmbed },
    mixins: [ModalMixin],
    data: function data() {
      return {
        addressSectionVisible: false,
        nameSectionVisible: false,
        showVideoModal: false
      };
    },
    computed: {
      home: function home() {
        return this.$store.state.home;
      },
      entities: function entities() {
        if (!this.$store.getters.entitySet) return [];
        return this.$store.getters.entitySet;
      },
      gradeFilter: function gradeFilter() {
        return this.$store.getters.filterSet.grade_levels;
      },
      gradeIsSatisfied: function gradeIsSatisfied() {
        var gradeSatisfied = this.settings.grade_required;
        var i;
        var l;
        var gradeFilter;

        gradeSatisfied = gradeSatisfied !== undefined && !gradeSatisfied;

        if (gradeSatisfied === true) {
          return gradeSatisfied;
        }

        gradeFilter = this.gradeFilter;
        for (i = 0, l = gradeFilter.value.length; i < l; i ++) {
          if (gradeFilter.value[i].required) {
            gradeSatisfied = true;
          }
        }
        return gradeSatisfied;
      },
      addressIsSatisfied: function addressIsSatisfied() {
        var addressSatisfied = this.settings.address_required;
        addressSatisfied = addressSatisfied !== undefined && !addressSatisfied;

        if (this.home && this.home.user) addressSatisfied = true;
        return addressSatisfied;
      },
      mobileSlideClass: function mobileSlideClass() {
        if (this.settings.address_grade_optional) {
          return this.gradeIsSatisfied || this.addressIsSatisfied ? 'mobile-slide-up' : '';
        }
        return this.gradeIsSatisfied && this.addressIsSatisfied ? 'mobile-slide-up' : '';
      },
      btnActive: function btnActive() {
        if (this.settings.address_grade_optional) {
          return this.gradeIsSatisfied || this.addressIsSatisfied ? 'button--active' : 'button--disabled'; // eslint-disable-line
        }
        return this.gradeIsSatisfied && this.addressIsSatisfied
         ? 'button--active' : 'button--disabled';
      }
    },
    methods: {
      handleVideoClick: function handleVideoClick() {
        this.showVideoModal = true;
        // this.$store.dispatch('updateShowModal',
        //   { modal: 'video', data: { 'dynamic.video': 'google.com' } });
        // sendEvent({
        //   category: 'list',
        //   action: 'show_video',
        //   label: entity.id
        // });
      },
      closeVideoModal: function closeVideoModal() {
        this.showVideoModal = false;
      },
      showSection: function showSection(section) {
        var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content').replace(/"/g, '');
        if (breakpoint === 'smartphone') {
          if (section === 'name') {
            this.addressSectionVisible = false;
            this.nameSectionVisible = true;
          }
          if (section === 'address') {
            this.addressSectionVisible = true;
            this.nameSectionVisible = false;
          }
        }
      },
      closeSection: function closeSection(section) {
        if (section === 'name') {
          this.nameSectionVisible = false;
        }
        if (section === 'address') {
          this.addressSectionVisible = false;
        }
      },
      handleViewSchoolsClick: function handleViewSchoolsClick() {
        // Set default address to home when entering the tool
        const isUserAddress = this.home.user || false;
        this.$store.dispatch('updateAddress',
        [{ user: isUserAddress, address: this.home.address }]);
        this.$store.dispatch('saveShowIntroToSession', false);
        this.closeModal();
        sendEvent({
          category: 'intro',
          action: 'view_schools'
        });
      },
    }
  };
</script>
<style type="text/css" >
.search-wrapper.search-container-light.c-input.c-input--lg{
  font-size:  15px;
  height:  42.9px;
}
/*   .alert{
    color: red;
  } */
/*   .row + .row{
    margin-top: 20px;
  } */
</style>
