<template>
  <div>
    <div class="container py-3 py-md-5 flex-grow-1 flex-shrink-0 d-flex">
      <div class="row mt-auto w-100">
        <div class="col-12 col-md">
          <h2>{{settings.contact_title}}</h2>
          <p class="mt-3">{{settings.name}}
            <span v-if="settings.address"><br>
           {{settings.address.street}}, {{settings.address.city}}, {{settings.address.state}}  {{settings.address.postal_code}}</span></p>

       <p v-if="settings.email" class="mt-1">{{settings.email_text}} <a class="p-footer__link" :href="'mailto:' + settings.email">{{settings.email}}</a></p>

          <div class="d-flex mt-4 sp-h-4">
            <a  target="_blank" :href="settings.facebook_address"><i class="fab fa-facebook-square fa-2x"></i></a>
            <a target="_blank" :href="settings.twitter_address"><i class="fab fa-twitter-square fa-2x"></i></a>
            <a target="_blank" :href="settings.youtube_address"><i class="fab fa-youtube-square fa-2x"></i></a>
          </div>
        </div>
        <div class="col-auto d-flex flex-column align-items-start sp-v-3 mt-gutter mt-md-0">
           <h2>{{settings.links_title}}</h2>

           <a class="p-footer__link" v-for="link in settings.links" target="_blank" :href="link.href">{{link.text}}</a>

          <!--  <a target="_blank" :href="settings.for_families_href">{{settings.for_families_text}}</a>
           <a target="_blank" :href="settings.for_schools_teachers_href">{{settings.for_schools_teachers_text}}</a>
           <a target="_blank" :href="settings.for_lea_leaders_href">{{settings.for_lea_leaders_text}}</a> -->
           <!-- <a href="">STAR Framework</a> -->
        </div>
      </div>
    </div>
    <div class="mt-auto p-footer py-3">
      <div class="container">
        <div class="row">
          <div class="col">
            <attribution-inline :settings="settings"></attribution-inline>
          </div>
          <div class="col">
            <attribution-google :settings="settings"></attribution-google>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">

  import AttributionInline from './AttributionInline.vue';
  import AttributionGoogle from './AttributionGoogle.vue';
  module.exports = {
    components: { AttributionInline, AttributionGoogle },
    props: ['settings']
  };
</script>