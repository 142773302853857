<template>
  <modal-component :show="show" :click-away="false">
    <h2 class="text-center my-3">{{settings.alert_title_text}}</h2>
    <p class="text-center">{{settings.alert_subtitle_text}}</p>
    <div class="c-modal__footer mt-auto mb-3 mx-3">
      <div class="row">
        <div class="ml-auto col-auto">
        <button class="c-button c-button--outline button--full" v-on:click="cancelRequirement">{{settings.alert_cancel_button_text}}</button>
      </div>
      <div class="col-auto">
        <button class="c-button button button--full button--active" v-on:click="setRequirement">{{settings.alert_ok_button_text}}</button>
      </div>
      </div>
    </div>

  </modal-component>
</template>
<script type="text/javascript">
  import ModalComponent from './Modal.vue';
  var ModalMixin = require('../../mixins/ModalMixin');

  module.exports = {
    mixins: [ModalMixin],
    components: { ModalComponent },
    props: ['show', 'settings'],
    methods: {
      setRequirement: function setRequirement() {
        this.$store.dispatch('updatePendingFilterAction', 'set');
        // true = do apply default selections to filters
        this.$store.dispatch('resetAllFilters', true);
        this.closeModal();
      },
      cancelRequirement: function cancelRequirement() {
        this.$store.dispatch('updatePendingFilterAction', 'cancel');
        this.closeModal();
      }
    }
  };
</script>
