var ModalMixin;

function openModal(data) {
  this.show = true;
  this.modalData = data;
}

function closeModal() {
  this.$store.dispatch('updateShowModal', false);
}

ModalMixin = {
  data: function data() {
    return {
      showModal: false,
      modalData: null
    };
  },
  methods: {
    openModal: openModal,
    closeModal: closeModal
  }
};

module.exports = ModalMixin;
