<!--
  mobile_filters_facets:
    mobile_facet_overlay_heading:
    mobile_facet_overlay_paragraph:
    refine_list:
    clear_facets_text:
 -->
<template>
  <screen-overlay
    :show-overlay="show"
    v-on:closeOverlay="$emit('close')"
    class="d-flex flex-column" style="min-height: 1px; overflow-y: hidden">
    <div class="container flex-grow-1 d-flex flex-column" style="min-height: 1px; justify-content: space-between;">

      <div class="row flex-grow-1 mobile-facet--scrolling-div" style="overflow-y: auto;">
        <div class="col-12">
          <div class="row mb-3">
            <div class="col-12">
              <h1 v-if="settings.mobile_facet_overlay_heading" class="p-screen-overlay__mobile-h1">{{settings.mobile_facet_overlay_heading}}</h1>
              <p v-if="settings.mobile_facet_overlay_paragraph">{{settings.mobile_facet_overlay_paragraph}}</p>
            </div>
          </div>
          <div class="row pt-20">
            <div class="col-12">
              <component
                v-if="layout"
                :is="layout.tag"
                :settings="layout.data"
                :layout="layout.layout"
                :class="layout.tag"
                class="sp-v-40 flex-grow-1"
                :super-filters="superFilters"
              ></component>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-auto flex-shrink-0">
        <div class="col-12">
          <div class="flex-shrink-0 pb-12 pt-4 mt-auto">
            <button class="c-button" v-on:click="$emit('close')">{{settings.refine_list || '[refine_list]'}}</button>
            <button v-if="$store.getters.facetCount > 0" class="c-link ml-12" v-on:click="clearFacets">{{settings.clear_facets_text || '[clear_facets_text]'}}</button>
          </div>
        </div>
      </div>
    </div>
  </screen-overlay>
</template>
<script type="text/javascript">
import sendEvent from 'tembo-js/sendEvent';
import ScreenOverlay from './ScreenOverlay.vue';

module.exports = {
  components: { ScreenOverlay },
  props: ['settings', 'layout', 'superFilters', 'show'],
  methods: {
    clearFacets() {
      this.$store.dispatch('resetFacets', { forceReset: false, applyDefaults: false, updateEntities: true }); // eslint-disable-line max-len
      const findView = this.$route.params.findView;
      const category = findView ? `${findView}_filter` : 'filter';
      sendEvent({
        category: category,
        action: 'clear_facets',
      });
    }
  }
};
</script>