<template>
  <!-- WRAPPER FOR FILTER GROUP -->
  <fieldset :is="layout.wrapper.tag ? layout.wrapper.tag : 'static-filter-group'" :settings="settings" :selection-tally="selectionTally" class="categorical-filter filter-group o-two-column-layout__filter-list__group">

    <!-- FILTER TITLE SLOT -->
    <h2 slot="filter-title">{{settings.group_title_text}}</h2>

    <!-- FILTER TALLY SLOT -->
    <div slot="filter-tally">{{selectionTally}} {{settings.tally_text}}</div>

    <!-- FILTER DESCRIPTION SLOT -->
    <div slot="filter-group-description" v-if="settings.description_text">{{settings.description_text}}</div>


    <!-- FILTER PROMPT SLOT -->
    <div slot="filter-prompt" v-if="settings.prompt_text" class="filter-prompt">{{settings.prompt_text}}</div>
    <div slot="filter-alt-prompt" v-if="settings.alt_prompt_text" class="filter-prompt">{{settings.alt_prompt_text}}</div>
    <a class="filter-description" href='#' v-if="settings.metadata.descriptions" v-on:click="handleShowDescriptions">{{settings.options_description_text}}</a>
    <!-- FILTER CONTENT SLOT -->
    <ul slot="filter-content" class="filter-content d-flex sp-h-2 mt-2" v-if="values">

      <li class="filter-item"
        v-for="(value, index) in filteredValues"
        v-on:mouseover="handleHovers(true, index)"
        v-on:mouseleave="handleHovers(false, index)"
      >
        <!-- Filter control -->
        <span
          :is="layout.option_tag.tag"
          :value="value"
          :checked="value.master"
          :filled="filled.indexOf(index) > -1"
          :hovered="hovered.indexOf(index) > -1"
          :focused="focused.indexOf(index) > -1"
          :group="settings.metadata.label"
          :idx="index"
          @update:checked="checked => { handleFilterClick(checked, value, index) }"
          @update:focus="(focusState, index) => { handleFocus(focusState, index) }"
        ></span>
      </li>
    </ul>

    <!-- FILTER LINK SLOT -->
    <div v-if="settings.link_text" slot="filter-group-link"><span>{{settings.link_description_text}} </span><a :href="settings.link_url" target="_blank">{{settings.link_text}}</a></div>

  </fieldset>
</template>
<script type="text/javascript">
  var CategoricalFilterMixin = require('../../mixins/CategoricalFilterMixin');
  var FilterGroupMixin = require('../../mixins/FilterGroupMixin');
  module.exports = {
    mixins: [CategoricalFilterMixin, FilterGroupMixin]
  };
</script>