<template>
  <p class="d-flex justify-content-between">
    <slot v-for="(data, index) in entityData">
      <span>{{data}}</span>
    </slot>
  </p>
</template>
<script type="text/javascript">
  module.exports = {
    props: ['entityData']
  };
</script>