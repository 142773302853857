var Vue = require('vue');
var VueResource = require('vue-resource');

Vue.use(VueResource);

function sfSaveUrl() {
  var postData = new FormData();
  postData.append('saved_url', window.location.pathname + window.location.search);
  return Vue.http.post('/api/users/url', postData);
}

function sfLogOut(ctx) {
  return Vue.http.post('/api/users/logout')
  .then(() => {
    ctx.commit('setLoginStatus', false);
    ctx.commit('setFavorites', []);
  }, () => {
    ctx.commit('setLoginStatus', false);
  });
}

function sfAutoLogOut() {
  return sfLogOut()
  .then(() => {
    this.$store.dispatch('updateShowModal', { modal: 'redirect' });
  });
}

function sfGetAuthItems(field) {
  return Vue.http.get(`/api/users/${field}`)
  .then((res) => {
    if (field === 'favorites') {
      return res.body.map((item) => { // eslint-disable-line arrow-body-style
        return {
          id: item.school_id,
          name: item.school_name,
          fav_id: item.id
        };
      });
    }
    return res.body;
  }, () => {
    sfAutoLogOut();
  });
}

function sfAddAuthItem(field, item) {
  var postData = new FormData();
  postData.append('school_id', item.id);
  return Vue.http.post(`/api/users/${field}`, postData)
  .then(res => ({ id: res.body.school_id, name: res.body.school_name, fav_id: res.body.id }),
  () => {
    sfAutoLogOut();
  });
}

function sfRemoveAuthItem(field, item) {
  var idToRemove;
  try {
    idToRemove = item.fav_id.toString();
  } catch (e) {
    return null;
  }

  return Vue.http.delete('/api/users/' + field + '/' + idToRemove)
  .then(() => item, // remove favorite from the browser's list
  () => {
    sfAutoLogOut();
  });
}

module.exports = {
  sfSaveUrl: sfSaveUrl,
  sfLogOut: sfLogOut,
  sfAutoLogOut: sfAutoLogOut,
  sfGetAuthItems: sfGetAuthItems,
  sfAddAuthItem: sfAddAuthItem,
  sfRemoveAuthItem: sfRemoveAuthItem
};
