/* eslint-disable global-require, max-len */
module.exports = [
// app for loading data & template
  {
    name: 'App',
    component: require('../src/vue/App.vue').default
  },
// templates
  {
    name: 'TemboLayout',
    component: require('../src/vue/templates/TemboLayout.vue').default
  },
  {
    name: 'OsseessaLayout',
    component: require('../src/vue/templates/OsseessaLayout.vue').default
  },
  {
    name: 'SidebarFiltersStickyMap',
    component: require('../src/vue/templates/SidebarFiltersStickyMap.vue').default
  },
  {
    name: 'TwoColumnMoreFilters',
    component: require('../src/vue/templates/TwoColumnMoreFilters.vue').default
  },
  {
    name: 'HeaderContentsFooter',
    component: require('../src/vue/templates/HeaderContentsFooterNoFiltersNoMap.vue').default
  },
  {
    name: 'HeaderContentsFooterFilter',
    component: require('../src/vue/templates/HeaderContentsFooterWithFiltersNoMap.vue').default
  },
  {
    name: 'TnK12TableLayout',
    component: require('../src/vue/templates/TnK12TableLayout.vue').default
  },
  {
    name: 'TeaTableLayout',
    component: require('../src/vue/templates/TeaTableLayout.vue').default
  },
  {
    name: 'NhTableLayout',
    component: require('../src/vue/templates/NhTableLayout.vue').default
  },
// components:
// nav menus
  {
    name: 'SiteNav',
    component: require('../src/vue/components/SiteNav.vue').default
  },
  {
    name: 'SiteNavTea',
    component: require('../src/vue/components/SiteNavTea.vue').default
  },
  {
    name: 'NavGeneral',
    component: require('../src/vue/components/NavGeneral.vue').default
  },
  {
    name: 'NavMobile',
    component: require('../src/vue/components/NavMobile.vue').default
  },
  {
    name: 'HeaderSecondary',
    component: require('../src/vue/components/HeaderSecondary.vue').default
  },
  {
    name: 'Breadcrumb',
    component: require('../src/vue/components/Breadcrumb.vue').default
  },
// nav sub-components
  {
    name: 'LanguageOptions',
    component: require('../src/vue/components/LanguageOptions.vue').default
  },
// ribbon component
  {
    name: 'Ribbon',
    component: require('../src/vue/components/Ribbon.vue').default
  },
// footers
  {
    name: 'TemboFooter',
    component: require('../src/vue/components/TemboFooter.vue').default
  },
  {
    name: 'FooterTnMoreInfo',
    component: require('../src/vue/components/FooterTnMoreInfo.vue').default
  },
  {
    name: 'FooterTea',
    component: require('../src/vue/components/FooterTea.vue').default
  },
  {
    name: 'FooterCep',
    component: require('../src/vue/components/FooterCep.vue').default
  },
  {
    name: 'FooterOsse',
    component: require('../src/vue/components/FooterOsse.vue').default
  },
  {
    name: 'FooterNh',
    component: require('../src/vue/components/FooterNh.vue').default
  },
  {
    name: 'FooterIndiana',
    component: require('../src/vue/components/FooterIndiana.vue').default
  },
  {
    name: 'FooterIdaho',
    component: require('../src/vue/components/FooterIdaho.vue').default
  },
// footer sub-components
  {
    name: 'ContactInformation',
    component: require('../src/vue/components/ContactInformation.vue').default
  },
  {
    name: 'Attribution',
    component: require('../src/vue/components/Attribution.vue').default
  },
  {
    name: 'AttributionInline',
    component: require('../src/vue/components/AttributionInline.vue').default
  },
  {
    name: 'TemboLogoSvg',
    component: require('../src/vue/assets/TemboLogoSvg.vue').default
  },
  {
    name: 'HoonuitLogoSvg',
    component: require('../src/vue/assets/HoonuitLogoSvg.vue').default
  },
  {
    name: 'AttributionGoogle',
    component: require('../src/vue/components/AttributionGoogle.vue').default
  },
  {
    name: 'AttributionGoogleSvg',
    component: require('../src/vue/assets/AttributionGoogleSvg.vue').default
  },
// home pages
  {
    name: 'HomeHeroDetails',
    component: require('../src/vue/components/home/HomeHeroDetails.vue').default
  },
  {
    name: 'HomepageSanAntonio',
    component: require('../src/vue/components/home/HomepageSanAntonio.vue').default
  },
  {
    name: 'HomepageNewMexico',
    component: require('../src/vue/components/home/HomepageNewMexico.vue').default
  },
  {
    name: 'HomeTeaV3',
    component: require('../src/vue/components/home/HomeTeaV3.vue').default
  },
  {
    name: 'HomeTnEpp',
    component: require('../src/vue/components/home/HomeTnEpp.vue').default
  },
  {
    name: 'HomeLaEpp',
    component: require('../src/vue/components/home/HomeLaEpp.vue').default
  },
// about pages
  {
    name: 'AboutDetails',
    component: require('../src/vue/components/AboutDetails.vue').default
  },
// layout pages
  {
    name: 'StateLaEpp',
    component: require('../src/vue/components/layouts/StateLaEpp.vue').default
  },
// icons
  {
    name: 'FaqIcon',
    component: require('../src/vue/assets/FaqIcon.vue').default
  },
// address entry components
  {
    name: 'AddressEntryTypeahead',
    component: require('../src/vue/components/AddressEntryTypeahead.vue').default
  },
  {
    name: 'AddressEntryTypeaheadWithMenu',
    component: require('../src/vue/components/AddressEntryTypeaheadWithMenu.vue').default
  },
  {
    name: 'AddressEntryWithModals',
    component: require('../src/vue/components/AddressEntryWithModals.vue').default
  },
// filter group containers
  {
    name: 'CollapsableFilterGroup',
    component: require('../src/vue/components/filterWrappers/CollapsableFilterGroup.vue').default
  },
  {
    name: 'CollapsableWithPromptFilterGroup',
    component: require('../src/vue/components/filterWrappers/CollapsableWithPromptFilterGroup.vue').default
  },
  {
    name: 'StaticFilterGroup',
    component: require('../src/vue/components/filterWrappers/StaticFilterGroup.vue').default
  },
  {
    name: 'ExpandableFilterGroup',
    component: require('../src/vue/components/filterWrappers/ExpandableFilterGroup.vue').default
  },
// filter groups
  {
    name: 'TabFilter',
    component: require('../src/vue/components/filterGroups/TabFilter.vue').default
  },
  {
    name: 'MultiFilter',
    component: require('../src/vue/components/filterGroups/MultiFilter.vue').default
  },
  {
    name: 'CombinedFilter',
    component: require('../src/vue/components/filterGroups/CombinedFilter.vue').default
  },
  {
    name: 'WrappedSelectFilter',
    component: require('../src/vue/components/filterGroups/WrappedSelectFilter.vue').default
  },
  {
    name: 'SelectDropdownFilter',
    component: require('../src/vue/components/filterGroups/SelectDropdownFilter.vue').default
  },

  {
    name: 'WrappedSelectFilterNoTitle',
    component: require('../src/vue/components/filterGroups/WrappedSelectFilterNoTitle.vue').default
  },
  {
    name: 'WrappedSelectAlertFilter',
    component: require('../src/vue/components/filterGroups/WrappedSelectAlertFilter.vue').default
  },
  {
    name: 'CategoricalFilter',
    component: require('../src/vue/components/filterGroups/CategoricalFilter.vue').default
  },
  {
    name: 'CategoricalFilterVertical',
    component: require('../src/vue/components/filterGroups/CategoricalFilterVertical.vue').default
  },
  {
    name: 'RadioButtonFilter',
    component: require('../src/vue/components/filterGroups/RadioButtonFilter.vue').default
  },
  {
    name: 'MultiDropdownInputFilter',
    component: require('../src/vue/components/filterGroups/MultiDropdownInputFilter.vue').default
  },
  {
    name: 'DeselectFilter',
    component: require('../src/vue/components/filterGroups/DeselectFilter.vue').default
  },
  {
    name: 'TypeSelectionFilter',
    component: require('../src/vue/components/filterGroups/TypeSelectionFilter.vue').default
  },
  {
    name: 'MapFilter',
    component: require('../src/vue/components/filterGroups/MapFilter.vue').default
  },
// filter-option control components
// for categorical
  {
    name: 'LetterGradeCheckbox',
    component: require('../src/vue/components/LetterGradeCheckbox.vue').default
  },
  {
    name: 'StarSelector',
    component: require('../src/vue/components/StarSelector.vue').default
  },
  {
    name: 'TextButton',
    component: require('../src/vue/components/TextButton.vue').default
  },
  {
    name: 'RadioTextButton',
    component: require('../src/vue/components/RadioTextButton.vue').default
  },
// for multi-filter, combined filter
  {
    name: 'ToggleSwitch',
    component: require('../src/vue/components/ToggleSwitch.vue').default
  },
  {
    name: 'CheckBox',
    component: require('../src/vue/components/CheckBox.vue').default
  },
// general radio button
  {
    name: 'RadioButton',
    component: require('../src/vue/components/RadioButton.vue').default
  },
// filter control components
  {
    name: 'FilterControls',
    component: require('../src/vue/components/FilterControls.vue').default
  },
  {
    name: 'MoreFilters',
    component: require('../src/vue/components/MoreFilters.vue').default
  },
  {
    name: 'FiltersContainer',
    component: require('../src/vue/components/FiltersContainer.vue').default
  },
  {
    name: 'MoreFiltersToggle',
    component: require('../src/vue/components/MoreFiltersToggle.vue').default
  },
  {
    name: 'FiltersApplyButton',
    component: require('../src/vue/components/FiltersApplyButton.vue').default
  },
  {
    name: 'FilterResetButton',
    component: require('../src/vue/components/FilterResetButton.vue').default
  },
  {
    name: 'FacetControls',
    component: require('../src/vue/components/FacetControls.vue').default
  },
// map component
  {
    name: 'MapComponent',
    component: require('../src/vue/components/MapGoogle.vue').default
  },
  {
    name: 'MapFullWidth',
    component: require('../src/vue/components/MapGoogleFullWidth.vue').default
  },
  {
    name: 'MobileMapItem',
    component: require('../src/vue/components/MobileMapItem.vue').default
  },
// select components (non-filters)
  {
    name: 'SelectComponent',
    component: require('../src/vue/components/Select.vue').default
  },
  {
    name: 'SelectWithMenu',
    component: require('../src/vue/components/SelectWithMenu.vue').default
  },
  {
    name: 'WrappedSelectDisable',
    component: require('../src/vue/components/WrappedSelectDisable.vue').default
  },
  {
    name: 'GradeDependentWrappedSelect',
    component: require('../src/vue/components/GradeDependentWrappedSelect.vue').default
  },
// name search components
  {
    name: 'NameSearch',
    component: require('../src/vue/components/SearchByName.vue').default
  },
  {
    name: 'SearchFilter',
    component: require('../src/vue/components/SearchFilter.vue').default
  },
  {
    name: 'SearchFilterNew',
    component: require('../src/vue/components/SearchFilterNew.vue').default
  },
  {
    name: 'EntitySearch',
    component: require('../src/vue/components/EntitySearch.vue').default
  },
  {
    name: 'DropdownTypeaheadLink',
    component: require('../src/vue/components/DropdownTypeaheadLink.vue').default
  },
  {
    name: 'TypeaheadDropdown',
    component: require('../src/vue/components/TypeaheadDropdown.vue').default
  },
  {
    name: 'TypeaheadDropdownLink',
    component: require('../src/vue/components/TypeaheadDropdownLink.vue').default
  },
  {
    name: 'TypeaheadPseudoSelect',
    component: require('../src/vue/components/TypeaheadPseudoSelect.vue').default
  },
  {
    name: 'TypeaheadDropdownTea',
    component: require('../src/vue/components/TypeaheadDropdownTea.vue').default
  },
  {
    name: 'EntityCard',
    component: require('../src/vue/components/EntityCard.vue').default
  },
  {
    name: 'EntityInfoIcon',
    component: require('../src/vue/components/EntityInfoIcon.vue').default
  },
// input components
  {
    name: 'SearchInput',
    component: require('../src/vue/components/SearchInput.vue').default
  },
  {
    name: 'SearchInputExpand',
    component: require('../src/vue/components/SearchInputExpand.vue').default
  },
  {
    name: 'InputLight',
    component: require('../src/vue/components/InputLight.vue').default
  },
  {
    name: 'InputLightNew',
    component: require('../src/vue/components/InputLightNew.vue').default
  },
// result list headers
  {
    name: 'ListControls',
    component: require('../src/vue/components/Controls_2_col.vue').default
  },
  // TODO: condense/rename these
  {
    name: 'ResultsStatus',
    component: require('../src/vue/components/ResultsStatus.vue').default
  },
  {
    name: 'ResultsStatusFiltersFacets',
    component: require('../src/vue/components/ResultsStatusFiltersFacets.vue').default
  },
  {
    name: 'ResultsStatusTwoFilters',
    component: require('../src/vue/components/ResultsStatusTwoFilters.vue').default
  },
  {
    name: 'ResultsStatusCountyFilter',
    component: require('../src/vue/components/ResultsStatusCountyFilter.vue').default
  },
  {
    name: 'ResultsStatusFilters',
    component: require('../src/vue/components/ResultsStatusFilters.vue').default
  },
  {
    name: 'ResultsStatusWrapper',
    component: require('../src/vue/components/ResultsStatusWrapper.vue').default
  },

// result list components
  {
    name: 'ResultList',
    component: require('../src/vue/components/ResultList.vue').default
  },
  {
    name: 'NoResultsClickToClear',
    component: require('../src/vue/components/NoResultsClickToClear.vue').default
  },
// table components - can be used in result items
  {
    name: 'ResultTable',
    component: require('../src/vue/components/ResultTable.vue').default
  },
  {
    name: 'ResultTableTembo',
    component: require('../src/vue/components/ResultTableTembo.vue').default
  },
  {
    name: 'ResultTableWithMobile',
    component: require('../src/vue/components/ResultTableWithMobile.vue').default
  },
  {
    name: 'ResultTableSimple',
    component: require('../src/vue/components/ResultTableSimple.vue').default
  },
  {
    name: 'ResultDatumSimple',
    component: require('../src/vue/components/ResultDatumSimple.vue').default
  },
  {
    name: 'ResultDatum',
    component: require('../src/vue/components/ResultDatum.vue').default
  },
  {
    name: 'ResultDatumTembo',
    component: require('../src/vue/components/ResultDatumTembo.vue').default
  },
  {
    name: 'LetterGradeDatum',
    component: require('../src/vue/components/LetterGradeDatum.vue').default
  },
  {
    name: 'TextSpanDatum',
    component: require('../src/vue/components/TextSpanDatum.vue').default
  },
  {
    name: 'TextSpanMulti',
    component: require('../src/vue/components/TextSpanMulti.vue').default
  },
  {
    name: 'TextSpanMultiLine',
    component: require('../src/vue/components/TextSpanMultiLine.vue').default
  },
  {
    name: 'TextSpanDatumLink',
    component: require('../src/vue/components/TextSpanDatumLink.vue').default
  },
  {
    name: 'RangeDatum',
    component: require('../src/vue/components/RangeDatum.vue').default
  },
  {
    name: 'LabeledTextDatum',
    component: require('../src/vue/components/LabeledTextDatum.vue').default
  },
  {
    name: 'TextListDatum',
    component: require('../src/vue/components/TextListDatum.vue').default
  },
  {
    name: 'ExternalDatumLink',
    component: require('../src/vue/components/ExternalDatumLink.vue').default
  },
// sort component for use with the table only
  {
    name: 'WrappedSelectTableSort',
    component: require('../src/vue/components/WrappedSelectTableSort.vue').default
  },
  {
    name: 'SortDropdown',
    component: require('../src/vue/components/SortDropdown.vue').default
  },
// placeholders
  {
    name: 'ResultItemPlaceholder',
    component: require('../src/vue/components/resultItems/ResultItemPlaceholder.vue').default
  },
  {
    name: 'TablePlaceholder',
    component: require('../src/vue/components/TablePlaceholder.vue').default
  },
// result item components
  {
    name: 'ResultItemRow',
    component: require('../src/vue/components/resultItems/ResultItemRow.vue').default
  },
  {
    name: 'ResultItem',
    component: require('../src/vue/components/resultItems/ResultItem.vue').default
  },
  {
    name: 'ResultItemGradeDependentFourCols',
    component: require('../src/vue/components/resultItems/ResultItemGradeDependentFourCols.vue').default
  },
  {
    name: 'ResultItemTwoRowsFourCols',
    component: require('../src/vue/components/resultItems/ResultItemTwoRowsFourCols.vue').default
  },
  {
    name: 'ResultItemWithEnrollment',
    component: require('../src/vue/components/resultItems/ResultItemWithEnrollment.vue').default
  },
  {
    name: 'ResultItemThreeRows',
    component: require('../src/vue/components/resultItems/ResultItemThreeRows.vue').default
  },
  {
    name: 'ResultItemWithStarRating',
    component: require('../src/vue/components/resultItems/ResultItemWithStarRating.vue').default
  },
// result-item sub-components
  {
    name: 'StreetAddress',
    component: require('../src/vue/components/StreetAddress.vue').default
  },
  {
    name: 'GenericBadge',
    component: require('../src/vue/components/GenericBadge.vue').default
  },
  {
    name: 'DistanceBadge',
    component: require('../src/vue/components/DistanceBadge.vue').default
  },
  {
    name: 'DistanceTimeBadge',
    component: require('../src/vue/components/DistanceTimeBadge.vue').default
  },
  {
    name: 'EnrollmentBadge',
    component: require('../src/vue/components/EnrollmentBadge.vue').default
  },
  {
    name: 'StarRatingBadge',
    component: require('../src/vue/components/StarRatingBadge.vue').default
  },
  {
    name: 'PreferencesBadge',
    component: require('../src/vue/components/PreferencesBadge.vue').default
  },
  {
    name: 'LetterGradeBadge',
    component: require('../src/vue/components/LetterGradeBadge.vue').default
  },
  {
    name: 'LetterGradeBadgeTwoHeaders',
    component: require('../src/vue/components/LetterGradeBadgeTwoHeaders.vue').default
  },
  {
    name: 'PerformanceBadge',
    component: require('../src/vue/components/PerformanceBadge.vue').default
  },
// modal components
  {
    name: 'ScreenOverlay',
    component: require('../src/vue/components/overlays/ScreenOverlay.vue').default
  },
  {
    name: 'ModalComponent',
    component: require('../src/vue/components/overlays/Modal.vue').default
  },
  {
    name: 'ModalCloseSvg',
    component: require('../src/vue/assets/ModalCloseSvg.vue').default
  },
  {
    name: 'MatchModal',
    component: require('../src/vue/components/overlays/MatchModal.vue').default
  },
  {
    name: 'ToggleModal',
    component: require('../src/vue/components/overlays/ToggleModal.vue').default
  },
  {
    name: 'FilterAlert',
    component: require('../src/vue/components/overlays/FilterAlert.vue').default
  },
  {
    name: 'AddressGradeModal',
    component: require('../src/vue/components/overlays/AddressGradeModal.vue').default
  },
  {
    name: 'TableContentModal',
    component: require('../src/vue/components/overlays/TableContentModal.vue').default
  },
  {
    name: 'NoResultsModal',
    component: require('../src/vue/components/overlays/NoResultsModal.vue').default
  },
  {
    name: 'OptionDescriptionsModal',
    component: require('../src/vue/components/overlays/OptionDescriptionsModal.vue').default
  },
  {
    name: 'RedirectSalesforceModal',
    component: require('../src/vue/components/overlays/RedirectSalesforceModal.vue').default
  },
  {
    name: 'FavoritesModal',
    component: require('../src/vue/components/overlays/FavoritesModal.vue').default
  },
  {
    name: 'CompareList',
    component: require('../src/vue/components/overlays/CompareList.vue').default
  },
  {
    name: 'AddressResolveModal',
    component: require('../src/vue/components/overlays/AddressResolveModal.vue').default
  },
  {
    name: 'AddressRetryModal',
    component: require('../src/vue/components/overlays/AddressRetryModal.vue').default
  },
  {
    name: 'AddressAlertModal',
    component: require('../src/vue/components/overlays/AddressAlertModal.vue').default
  },
  {
    name: 'VideoModal',
    component: require('../src/vue/components/overlays/VideoModal.vue').default
  },
  {
    name: 'MobileFilters',
    component: require('../src/vue/components/overlays/MobileFilters.vue').default
  },
  {
    name: 'MobileFacets',
    component: require('../src/vue/components/overlays/MobileFacets.vue').default
  },
  {
    name: 'CompareOverlay',
    component: require('../src/vue/components/overlays/CompareOverlay.vue').default
  },
  // intro modals
  {
    name: 'OsseEssaIntroCard',
    component: require('../src/vue/components/overlays/OsseEssaIntroCard.vue').default
  },
  {
    name: 'NhIntroCard',
    component: require('../src/vue/components/overlays/NhIntroCard.vue').default
  },
  {
    name: 'IntroModal',
    component: require('../src/vue/components/overlays/IntroModal.vue').default
  },
  {
    name: 'OsseEssaIntroCard',
    component: require('../src/vue/components/overlays/OsseEssaIntroCard.vue').default
  },
  {
    name: 'IntroCardGradeAddress',
    component: require('../src/vue/components/overlays/IntroCardGradeAddress.vue').default
  },
  {
    name: 'IndianaIntroCard',
    component: require('../src/vue/components/overlays/IndianaIntroCard.vue').default
  },
  {
    name: 'IntroCardImageTextFilter',
    component: require('../src/vue/components/overlays/IntroCardImageTextFilter.vue').default
  },
// modal launchers
  {
    name: 'VideoModalLauncher',
    component: require('../src/vue/components/VideoModalLauncher.vue').default
  },
// component wrapper
  {
    name: 'ComponentBehindLink',
    component: require('../src/vue/components/ComponentBehindLink.vue').default
  },
// favorite components
  {
    name: 'FavoriteHeart',
    component: require('../src/vue/components/FavoriteHeart.vue').default
  },
  {
    name: 'FavoriteHeartNoText',
    component: require('../src/vue/components/FavoriteHeartNoText.vue').default
  },
// compare components
  {
    name: 'Compare',
    component: require('../src/vue/components/compare/Compare.vue').default
  },
  {
    name: 'CompareCategory',
    component: require('../src/vue/components/compare/CompareCategory.vue').default
  },
  {
    name: 'CompareDataRow',
    component: require('../src/vue/components/compare/CompareDataRow.vue').default
  },
  {
    name: 'CompareEntityHeader',
    component: require('../src/vue/components/compare/CompareEntityHeader.vue').default
  },
  {
    name: 'CompareData',
    component: require('../src/vue/components/compare/CompareData.vue').default
  },
  {
    name: 'CompareCounter',
    component: require('../src/vue/components/compare/CompareCounter.vue').default
  },
  {
    name: 'CompareToggle',
    component: require('../src/vue/components/compare/CompareToggle.vue').default
  },
  {
    name: 'CompareCheckbox',
    component: require('../src/vue/components/compare/CompareCheckbox.vue').default
  },
  {
    name: 'ButtonCompare',
    component: require('../src/vue/components/compare/ButtonCompare.vue').default
  },
  {
    name: 'CheckBoxCompare',
    component: require('../src/vue/components/compare/CheckBoxCompare.vue').default
  },
  {
    name: 'CompareStatus',
    component: require('../src/vue/components/compare/CompareStatus.vue').default
  },
// wizard components
  {
    name: 'WizardComponent',
    component: require('../src/vue/components/wizard/WizardComponent.vue').default
  },
  {
    name: 'WizardThreeDots',
    component: require('../src/vue/components/wizard/WizardThreeDots.vue').default
  },
  {
    name: 'WizardIntro',
    component: require('../src/vue/components/wizard/WizardIntro.vue').default
  },
  {
    name: 'WizardBreadCrumb',
    component: require('../src/vue/components/wizard/WizardBreadCrumb.vue').default
  },
  {
    name: 'WizardWhoAmI',
    component: require('../src/vue/components/wizard/WizardWhoAmI.vue').default
  },
  {
    name: 'WizardAddressEntry',
    component: require('../src/vue/components/wizard/WizardAddressEntry.vue').default
  },
  {
    name: 'WizardFoundSchools',
    component: require('../src/vue/components/wizard/WizardFoundSchools.vue').default
  },
  {
    name: 'WizardChooseCategory',
    component: require('../src/vue/components/wizard/WizardChooseCategory.vue').default
  },
  {
    name: 'WizardChooseOne',
    component: require('../src/vue/components/wizard/WizardChooseOne.vue').default
  },
  {
    name: 'WizardSelectOptions',
    component: require('../src/vue/components/wizard/WizardSelectOptions.vue').default
  },
  {
    name: 'WizardToFind',
    component: require('../src/vue/components/wizard/WizardToFind.vue').default
  },
  {
    name: 'DropdownModal',
    component: require('../src/vue/components/wizard/DropdownModal.vue').default
  },
// related to paging
  {
    name: 'PageControl',
    component: require('../src/vue/components/PageControl.vue').default
  },
  {
    name: 'PageControlButtons',
    component: require('../src/vue/components/PageControlButtons.vue').default
  },
  {
    name: 'PaginationButtonLinks',
    component: require('../src/vue/components/PaginationButtonLinks.vue').default
  },
  {
    name: 'PaginationToggle',
    component: require('../src/vue/components/PaginationToggle.vue').default
  },
// mobile components
  {
    name: 'MobileButtonContainer',
    component: require('../src/vue/components/MobileButtonContainer.vue').default
  },
  {
    name: 'IllustratedHeroBackground',
    component: require('../src/vue/assets/IllustratedHeroBackground.vue').default
  },
  {
    name: 'DcIcon',
    component: require('../src/vue/assets/DcIcon.vue').default
  },
  {
    name: 'YoutubeEmbed',
    component: require('../src/vue/components/YoutubeEmbed.vue').default
  },
// text components
  {
    name: 'RichText',
    component: require('../src/vue/components/RichText.vue').default
  }

];
/* eslint-enable */
