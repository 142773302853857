<template>
  <label class="favorite_label c-button c-button--round  c-button--action" :class="checked ? '' : 'c-button--outline'">
<!--     <span class="button-status">
      <span class="button-status__status" :class="!checked ? '' : 'hide'">{{textAdd}}</span>
      <span class="button-status__status" :class="checked ? '' : 'hide'">{{textSaved}}</span>
    </span> -->
    <input class="favorite sr-only" type="checkbox" v-on:click.prevent="$emit('update:favorite', !checked)" :checked="checked" v-bind:disabled="disabled" >
    <span class="favorite_visual">
      <svg class="svg-heart" width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
          <path d="M13.05,0 C11.484,0 9.981,0.70626703 9,1.82234332 C8.019,0.70626703 6.516,0 4.95,0 C2.178,0 3.55271368e-15,2.11008174 3.55271368e-15,4.79564033 C3.55271368e-15,8.09155313 3.06,10.7771117 7.695,14.8577657 L9,16 L10.305,14.8490463 C14.94,10.7771117 18,8.09155313 18,4.79564033 C18,2.11008174 15.822,0 13.05,0 L13.05,0 Z" id="Shape"></path>
        </g>
      </svg>
    </span>
  </label>
</template>
<script type="text/javascript">
  var FavoriteHeartMixin = require('../mixins/FavoriteHeartMixin');
  module.exports = {
    mixins: [FavoriteHeartMixin]
  };
</script>